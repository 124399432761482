import type { FC } from 'react'
import { ZoneDescriptionPopover } from './ZoneDescriptionPopover'
import type { RouteBetweenZonesOptionFragment } from '~generated/graphql'

import { ReactComponent as IconTaxiMarker } from '~assets/icon-taxi-marker.svg'
import { usePopover } from '~hooks/usePopover'

type Props = {
  zoneName?: string
  routeOption: RouteBetweenZonesOptionFragment
  onClick: () => void
}

export const ZoneDestinationMarker: FC<Props> = ({ zoneName, routeOption, onClick }) => {
  const { isPopoverOpen, popoverProps, parentProps, closePopover } = usePopover({
    isOpen: true,
    placement: 'top',
  })

  return (
    <>
      <IconTaxiMarker {...parentProps} />
      {isPopoverOpen ? (
        <ZoneDescriptionPopover
          title={zoneName}
          waitingTime={routeOption.waitingTime}
          distanceMeters={routeOption.distance}
          onClick={onClick}
          onClose={closePopover}
          {...popoverProps}
        />
      ) : null}
    </>
  )
}
