import { BehaviorSubject, Subject } from 'rxjs'
import type {
  AbilityLastingStatusEffectsGroupFragment,
  AbilityPeriodicStatusEffectsGroupFragment,
} from '~generated/match-graphql'
import type { UUID } from '~shared-types'
import type { CharacterEntity } from '../CharacterEntity'
import type { CrowdControl, StatusEffectChanges } from './CharacterStatusEffectsGroup'
import { CharacterStatusEffectsGroup } from './CharacterStatusEffectsGroup'

export type StatusEffectsGroupFragment =
  | AbilityPeriodicStatusEffectsGroupFragment
  | AbilityLastingStatusEffectsGroupFragment

export class CharacterStatusEffectsGroups {
  private isLoaded = false
  #effectsGroups: Set<CharacterStatusEffectsGroup> = new Set()
  #crowdControls: Set<CrowdControl> = new Set()
  public subject: BehaviorSubject<StatusEffectsGroupFragment[]>
  public crowdControlsSubject: BehaviorSubject<CrowdControl[]>
  private changeSubject = new Subject<StatusEffectChanges>()

  constructor(effects: StatusEffectsGroupFragment[], private character: CharacterEntity) {
    this.subject = new BehaviorSubject<StatusEffectsGroupFragment[]>([])
    this.crowdControlsSubject = new BehaviorSubject<CrowdControl[]>([])
    this.changeSubject = new Subject<StatusEffectChanges>()
    this.changeSubject.subscribe(
      ({ action, crowdControls, statsChanges, effect, shieldEffects }) => {
        if (action === 'add') {
          if (crowdControls.length) {
            crowdControls.forEach((newControl) => this.#crowdControls.add(newControl))
            this.crowdControlsSubject.next(Array.from(this.#crowdControls))
          }
          if (statsChanges.length) {
            const areCharacterStatsAlreadyContainsEffect = !this.isLoaded
            if (areCharacterStatsAlreadyContainsEffect) {
              return
            }
            statsChanges.forEach(({ value, statType }) => {
              const newValue = (this.character.stats[statType] ?? 0) + value
              if (statType === 'invisibilityRange' && newValue === 0) {
                this.character.stats = { ...this.character.stats, [statType]: null }
              } else {
                this.character.stats = { ...this.character.stats, [statType]: newValue }
              }
            })
          }
          if (shieldEffects.length) {
            shieldEffects.forEach((shield) => {
              this.character.addShieldEffect(shield)
            })
          }
        } else {
          this.#effectsGroups.delete(effect)
          this.subject.next(this.list.map((statusEffect) => statusEffect.effectsGroup))

          if (crowdControls.length) {
            crowdControls.forEach((newControl) => this.#crowdControls.delete(newControl))
            this.crowdControlsSubject.next(Array.from(this.#crowdControls))
          }
          if (statsChanges.length) {
            statsChanges.forEach(({ value, statType }) => {
              const newValue = (this.character.stats[statType] ?? 0) - value
              if (statType === 'invisibilityRange' && newValue === 0) {
                this.character.stats = { ...this.character.stats, [statType]: null }
              } else {
                this.character.stats = { ...this.character.stats, [statType]: newValue }
              }
            })
          }
          if (shieldEffects.length) {
            shieldEffects.forEach((shield) => {
              this.character.removeShieldEffect(shield)
            })
          }
        }
      },
    )

    effects.forEach((effect) => this.add(effect))
    this.isLoaded = true
  }

  get list() {
    return Array.from(this.#effectsGroups)
  }

  get crowdControlList() {
    return Array.from(this.#crowdControls)
  }

  add(effect: StatusEffectsGroupFragment) {
    const statusEffect = new CharacterStatusEffectsGroup(effect, this.changeSubject)
    this.#effectsGroups.add(statusEffect)
    this.subject.next(this.list.map((statEffect) => statEffect.effectsGroup))
  }

  removeById(id: UUID): void {
    const effectToRemove = this.list.find(({ effectsGroup: effect }) => effect.id === id)
    if (effectToRemove) {
      this.remove(effectToRemove)
    }
  }

  remove(effect: CharacterStatusEffectsGroup) {
    effect.cancel()
  }
}
