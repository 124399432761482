import { useMatchStore } from '~store'
import {
  useCastAbilityCoordinateTargetedMutation,
  useCastAbilityUnitTargetedMutation,
} from '~generated/match-graphql'

export const useAbility = () => {
  const [wsClient] = useMatchStore((state) => [state.wsClient])
  const [castAbilityCoordinateTargeted] = useCastAbilityCoordinateTargetedMutation({
    client: wsClient,
  })
  const [castAbilityUnitTargeted] = useCastAbilityUnitTargetedMutation({
    client: wsClient,
  })

  return {
    castAbilityUnitTargeted,
    castAbilityCoordinateTargeted,
  }
}
