import type { Map } from 'maplibre-gl'
import { Marker } from 'maplibre-gl'
import { createRoot } from 'react-dom/client'
import type { ReactElement } from 'react'
import type { Point } from '~shared-types'

export class AnimationMarker {
  private marker: Marker

  constructor(
    map: Map,
    component: ReactElement,
    position: Point,
    showUnderCharacter = false,
    angle?: number,
  ) {
    const div = document.createElement('div')
    const root = createRoot(div)
    root.render(component)
    this.marker = new Marker({
      element: div,
      className: showUnderCharacter ? 'effect-animation-under' : 'effect-animation',
    })
    this.marker.setLngLat(position)
    if (angle) this.marker.setRotation(angle)
    this.marker.addTo(map)
  }

  public remove() {
    this.marker.remove()
  }

  public setLngLat(position: Point) {
    this.marker.setLngLat(position)
  }

  public setRotation(angle: number) {
    this.marker.setRotation(angle)
  }
}
