import type { FC } from 'react'
import styled from '@emotion/styled'
import { from } from 'rxjs'
import type { Character, LastingCrowdControlEffect } from '~generated/match-graphql'
import RankShield from '~assets/rank-shield.svg'
import RankShieldOverlay from '~assets/rank-shield-overlay.svg'
import type { MarkerAvatarFragment } from '~generated/graphql'
import { CharacterTwilightRelation } from '~map-tools'
import type { Millisecond, Race, Side } from '~shared-types'
import { MarkerIconUI } from '../../map/MapCharacterMarker/MarkerIconUI'
import type { TwilightLevel } from '../../../types'
import { HealthBar } from '../../map/MapCharacterMarker/HealthBar'
import { EnergyBar } from '../../map/MapCharacterMarker/EnergyBar'
import { CharacterActionMenu } from '../../map/CharacterActionMenu'
import { usePopover } from '~hooks/usePopover'
import type { Stats } from '../../../views/match/model/CharacterEntity'

export type MarkerCharacter = Pick<
  Character,
  'id' | 'position' | 'firstName' | 'lastName' | 'alive'
> & {
  stats: Pick<Stats, 'health' | 'energy' | 'healthMax' | 'energyMax' | 'shield'>
  avatar?: Partial<MarkerAvatarFragment>
  twilightLevel: TwilightLevel
  race: Race
  side: Side
}

type Props = {
  character: MarkerCharacter
  actionLabel?: string
  onClick?: () => void
}

export type CrowdControl = {
  type: LastingCrowdControlEffect
  duration: Millisecond
  startAt: Millisecond
  controlAbilityImageUrl: string
}

export const CharacterTarget: FC<Props> = ({ character, actionLabel, onClick }) => {
  const { isPopoverOpen, popoverProps, parentProps } = usePopover()

  const menuItems = actionLabel
    ? [
        {
          label: actionLabel,
          onClick,
        },
      ]
    : []

  const {
    race,
    firstName,
    lastName,
    side,
    alive,
    stats: { health, energy, healthMax, energyMax, shield },
    avatar,
  } = character

  const twilightRelation = CharacterTwilightRelation.REACHABLE

  return (
    <Container {...parentProps}>
      <MarkerContainer twilightRelation={twilightRelation} alive={alive}>
        <Title twilightRelation={twilightRelation}>
          <Name>
            {firstName} {lastName}
          </Name>
        </Title>
        <StyledMarkerIcon
          race={race}
          characterAvatar={avatar}
          twilightRelation={twilightRelation}
          side={side}
          alive={alive}
        />
        {[
          CharacterTwilightRelation.REACHABLE,
          CharacterTwilightRelation.ONE_BELOW_REACHABLE,
        ].includes(twilightRelation) && alive ? (
          <>
            {race !== 'HUMAN' ? (
              <EnergyBar
                id={character.id}
                side={side}
                statsObservable={from([{ health, healthMax, energy, energyMax, shield }])}
                // disabled={CharacterTwilightRelation.ONE_BELOW_REACHABLE}
              />
            ) : null}
            <HealthBar
              id={character.id}
              race={race}
              statsObservable={from([{ health, healthMax, energy, energyMax, shield }])}
              // disabled={CharacterTwilightRelation.ONE_BELOW_REACHABLE}
            />
          </>
        ) : null}
        {alive ? ( //! castingAction && twilightRelation !== CharacterTwilightRelation.NOT_VISIBLE ? (
          <Rank twilightRelation={twilightRelation}>5</Rank>
        ) : null}
      </MarkerContainer>
      {isPopoverOpen ? <CharacterActionMenu {...popoverProps} items={menuItems} /> : null}
    </Container>
  )
}

const Container = styled.div<{ range?: number }>`
  position: relative;
`

const MarkerContainer = styled.div<{ twilightRelation: CharacterTwilightRelation; alive: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 92px;
  height: 92px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;
  ${({ twilightRelation }) =>
    [CharacterTwilightRelation.ONE_BELOW_REACHABLE, CharacterTwilightRelation.REACHABLE].includes(
      twilightRelation,
    )
      ? `&:hover {
    filter: drop-shadow(2px 1px 6px rgba(255, 255, 255, 0.2));
    transition: filter 0.1s;
  }`
      : `&:hover {
    cursor: grab;
  }`}

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border-radius: 50%;
    border: 2px solid #131927;
    display: ${({ twilightRelation, alive }) =>
      [CharacterTwilightRelation.ONE_BELOW_REACHABLE, CharacterTwilightRelation.REACHABLE].includes(
        twilightRelation,
      ) && alive
        ? 'block'
        : 'none'};
  }
`

const StyledMarkerIcon = styled(MarkerIconUI)`
  grid-row-start: 1;
  grid-row-end: 4;
  box-shadow: 0 1px 3px 1px #081f2dbc;
`

const Title = styled.div<{ twilightRelation: CharacterTwilightRelation }>`
  width: 130px;
  margin-left: -65px;
  left: 50%;
  text-align: center;
  top: -20px;
  position: absolute;=
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  pointer-events: none;

  display : ${({ twilightRelation }) =>
    twilightRelation === CharacterTwilightRelation.NOT_VISIBLE ? 'none' : 'flex'};
  color: ${({ twilightRelation }) =>
    twilightRelation === CharacterTwilightRelation.ONE_BELOW_REACHABLE
      ? '#A7AFC4'
      : twilightRelation === CharacterTwilightRelation.VISIBLE
      ? '#646D85'
      : '#fff'};
`
const Name = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`

const Rank = styled.div<{ twilightRelation: CharacterTwilightRelation }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 21px;
  height: 28px;
  background: url(${RankShield}) center no-repeat;
  color: #131927;
  font-size: 14px;
  line-height: 15px;
  font-weight: 700;
  letter-spacing: -0.7px;
  position: absolute;
  z-index: 2;
  bottom: -3px;
  filter: drop-shadow(0 1px 3px #081f2dbc);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url(${RankShieldOverlay}) center no-repeat;
    background-size: contain;

    display: ${({ twilightRelation }) =>
      [CharacterTwilightRelation.ONE_BELOW_REACHABLE, CharacterTwilightRelation.REACHABLE].includes(
        twilightRelation,
      )
        ? 'none'
        : 'block'};
  }
`
