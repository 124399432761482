import type { FC } from 'react'
import styled from '@emotion/styled'
import { Minimap } from '~components/map/Minimap/Minimap'
import TopLeftBg from '~assets/bg-map-top-corner.svg'
import BottomRightBg from '~assets/bg-map-bottom-corner.svg'
import { useStore } from '~store'
import { CharacterPanel } from '~components/CharacterPanel/CharacterPanel'
import { useZoneInit } from './hooks/useZoneInit'
import { useZoneSubscriptions } from './hooks/useZoneSubscriptions'
import { TaxiTimer } from './components/TaxiTimer'
import { QuestDialogManager } from '~components/quest/QuestDialogManager'
import { ZoneChat } from './components/chat/ZoneChat'

const Zone: FC = () => {
  const [currentCharacter, twilightLevel, setTwilightLevel] = useStore((state) => [
    state.currentCharacter,
    state.twilightLevel,
    state.setTwilightLevel,
  ])

  useZoneInit()
  useZoneSubscriptions()

  return (
    <Container>
      {currentCharacter ? <CharacterPanel character={currentCharacter} /> : null}
      <MapView id="zone-map" />
      <Minimap twilightLevel={twilightLevel} setTwilightLevel={setTwilightLevel} />
      <TaxiTimer />
      <QuestDialogManager />
      <ZoneChat />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    user-select: none;
    pointer-events: none;
    background: url(${TopLeftBg}) no-repeat left -45px, url(${BottomRightBg}) no-repeat right bottom;
  }
`
const MapView = styled.div`
  width: 100%;
  height: 100%;
`

export default Zone
