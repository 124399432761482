import { createRoot } from 'react-dom/client'
import { Marker } from 'maplibre-gl'
import React from 'react'
import { DateTime } from 'luxon'
import { JoinOpenMatchDocument, MatchStatus } from '~generated/graphql'
import type { ZoneOpenMatchFragment } from '~generated/graphql'
import type { OpenMatchMarkerRef } from '~components/map/OpenMatchMarker/OpenMatchMarker'
import { OpenMatchMarker } from '~components/map/OpenMatchMarker/OpenMatchMarker'
import type { From0To100, UUID } from '~shared-types'
import type { LayerManager } from '../../match/model/LayerManager'
import { Border } from '../../match/model/MatchBorder'
import type { OpenMatchCharacterSubject } from './OpenMatchManager'
import { client } from '../../../apolloClient'
import { store } from '~store'

export type MatchParticipant = {
  characterId: UUID
  teamId: UUID
  matchId: UUID
  value: number
}

type Status = 'InProgress' | 'Ended'

export class OpenMatch {
  #matchParticipants: MatchParticipant[]
  #marker: Marker
  #status: Status
  private ref = React.createRef<OpenMatchMarkerRef>()
  private leftTeamId: UUID
  private leftTeamValue: number
  private leftTeamDominance: From0To100
  private leftTeamParticipantsCount: number
  private matchValue: number
  private border: Border

  constructor(
    private layerManager: LayerManager,
    private miniMapLayerManager: LayerManager,
    private characterSubject: OpenMatchCharacterSubject,
    private openMatch: ZoneOpenMatchFragment,
  ) {
    this.#matchParticipants = []
    this.matchValue = 0
    this.leftTeamValue = 0
    this.leftTeamDominance = 0
    this.leftTeamId = openMatch.leftTeamId
    this.leftTeamParticipantsCount = 0
    this.#status = 'InProgress'

    openMatch.participants.map((participant) =>
      this.addMatchParticipant({
        characterId: participant.characterId,
        matchId: openMatch.id,
        value: participant.value,
        teamId: participant.teamId,
      }),
    )

    this.border = new Border(
      this.layerManager,
      this.miniMapLayerManager,
      'zone-open-match',
      openMatch.id,
      { fill: this.getColor(), stroke: this.getColor() },
    )
    this.border.update({ path: openMatch.border })

    const div = document.createElement('div')
    const root = createRoot(div)

    const component = (
      <OpenMatchMarker
        leftTeam={{
          character: {
            firstName: openMatch.participants[0].characterFirstName,
            lastName: openMatch.participants[0].characterLastName,
          },
          dominancePercent: this.leftTeamDominance,
          value: this.leftTeamValue,
          side: openMatch.leftTeamSide,
          participantsCount: this.leftTeamParticipantsCount,
        }}
        rightTeam={{
          character: {
            firstName: openMatch.participants[1].characterFirstName,
            lastName: openMatch.participants[1].characterLastName,
          },
          dominancePercent: 100 - this.leftTeamDominance,
          value: this.matchValue - this.leftTeamValue,
          side: openMatch.rightTeamSide,
          participantsCount: this.#matchParticipants.length - this.leftTeamParticipantsCount,
        }}
        startedAt={DateTime.fromISO(openMatch.createdAt).toMillis()}
        address={openMatch.positionName ?? undefined}
        onJoinMatch={async () => {
          const { currentCharacter } = store.getState()
          const currentCharacterSide = currentCharacter?.side
          const currentCharacterTeam =
            this.openMatch.leftTeamSide === currentCharacterSide
              ? this.openMatch.leftTeamId
              : this.openMatch.rightTeamId

          await client.mutate({
            mutation: JoinOpenMatchDocument,
            variables: {
              matchId: this.openMatch.id,
              teamId: currentCharacterTeam,
            },
          })
        }}
        ref={this.ref}
      />
    )

    root.render(component)
    this.#marker = new Marker({ element: div, className: 'maplibregl-marker-character' })
    this.#marker.setLngLat(openMatch.position)
    layerManager.addMarker(this.#marker)

    this.#marker.getElement().addEventListener('click', async (e) => {
      e.stopPropagation()
    })
  }

  private getColor(): string {
    const percentage = Math.max(Math.min(this.leftTeamDominance, 100), 0)

    let red = 0
    let blue = 0
    let green = 0

    /** 0 - 255 */
    const softness = 70
    const colorThreshold = 30
    if (percentage <= colorThreshold) {
      red = softness
      green = softness
      blue = 255
    } else if (percentage >= 100 - colorThreshold) {
      red = 255
      green = softness
      blue = softness
    } else if (percentage >= 50 - colorThreshold && percentage <= 50) {
      const transitionScale = 1 - (percentage - colorThreshold) / (50 - colorThreshold)

      blue = Math.round(128 + transitionScale * 127)
      green = Math.round(128 - transitionScale * (128 - softness))
      red = Math.round(128 - transitionScale * (128 - softness))
    } else if (percentage > 50 && percentage <= 50 + colorThreshold) {
      const transitionScale = (percentage - 50) / (50 - colorThreshold)

      red = Math.round(128 + transitionScale * 127)
      green = Math.round(128 - transitionScale * (128 - softness))
      blue = Math.round(128 - transitionScale * (128 - softness))
    }

    const hexRed = red.toString(16).padStart(2, '0')
    const hexGreen = green.toString(16).padStart(2, '0')
    const hexBlue = blue.toString(16).padStart(2, '0')

    return `#${hexRed}${hexGreen}${hexBlue}`
  }

  public get id() {
    return this.openMatch.id
  }

  public get status() {
    return this.#status
  }

  private set status(status: Status) {
    this.#status = status
    if (this.#status === 'Ended') {
      this.#matchParticipants.forEach((participant) => this.removeMatchParticipant(participant))
      this.#marker.remove()
      this.border.clear()
    }
  }

  public updateData(newData: ZoneOpenMatchFragment) {
    if (newData.status === 'ENDED') {
      this.status = 'Ended'
      return
    }
    const newParticipants = newData.participants.filter(
      (participant) =>
        !this.#matchParticipants
          .map(({ characterId }) => characterId)
          .includes(participant.characterId),
    )
    newParticipants.forEach((participant) =>
      this.addMatchParticipant({ ...participant, matchId: this.id }),
    )
    this.border.update({ colors: { fill: this.getColor(), stroke: this.getColor() } })
  }

  public isInMatch(characterId: UUID): boolean {
    return this.#matchParticipants.some(
      (matchParticipant) => matchParticipant.characterId === characterId,
    )
  }

  private addMatchParticipant(matchParticipant: MatchParticipant) {
    this.#matchParticipants.push(matchParticipant)
    this.characterSubject.next({ ...matchParticipant, status: 'joinedMatch' })

    this.matchValue += matchParticipant.value
    this.leftTeamValue += matchParticipant.teamId === this.leftTeamId ? matchParticipant.value : 0
    this.leftTeamDominance = (this.leftTeamValue / this.matchValue) * 100
    this.leftTeamParticipantsCount = this.#matchParticipants.filter(
      ({ teamId }) => teamId === this.leftTeamId,
    ).length

    this.ref.current?.updateTeamsStats({
      leftTeam: {
        value: this.leftTeamValue,
        dominancePercent: this.leftTeamDominance,
        participantsCount: this.leftTeamParticipantsCount,
      },
      rightTeam: {
        value: this.matchValue - this.leftTeamValue,
        dominancePercent: 100 - this.leftTeamDominance,
        participantsCount: this.#matchParticipants.length - this.leftTeamParticipantsCount,
      },
    })
  }

  private removeMatchParticipant(matchParticipant: MatchParticipant) {
    this.#matchParticipants.filter(
      ({ characterId }) => characterId !== matchParticipant.characterId,
    )
    this.characterSubject.next({ ...matchParticipant, status: 'leftMatch' })
  }
}
