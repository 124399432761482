import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'
import type { Subscription } from 'rxjs'
import { finalize, timer } from 'rxjs'
import type { Int, Meter, Millisecond } from '~shared-types'
import { getWsClient } from '../apolloClient'
import type { MatchAccess } from '../generated/graphql'
import type { CharacterEntity } from '../views/match/model/CharacterEntity'
import type {
  AbilityChannelActionFragment as AbilityChannelAction,
  MatchCharacterAbilityFragment,
  MatchCharacterStatusEffectFragment,
  MatchMapCharacterFragment,
  AbilityCastActionFragment as AbilityCastAction,
  StatsFragment,
} from '../generated/match-graphql'
import type { TwilightLevel } from '../types'
import { eventBus } from '../eventBus'

export type AbilityCooldown = {
  abilityId: Int
  duration: Millisecond
  remainingCooldown: Millisecond
  startAt: Millisecond
}

type State = {
  wsClient?: ReturnType<typeof getWsClient>
  startAt?: Millisecond
  characters: MatchMapCharacterFragment[]
  currentCharacter?: CharacterEntity
  currentCharacterStats?: StatsFragment
  currentCharacterStatusEffects: MatchCharacterStatusEffectFragment[]
  currentCharacterTwilightLevel?: TwilightLevel
  selectedAbility?: MatchCharacterAbilityFragment
  castAction?: AbilityCastAction | AbilityChannelAction
  targetCharacter?: CharacterEntity
  targetCharacterStats?: StatsFragment
  targetCharacterStatusEffects?: MatchCharacterStatusEffectFragment[]
  targetCharacterTwilightLevel?: TwilightLevel
  subscriptions?: {
    targetCharacterStats?: Subscription
    targetCharacterTwilightLevel?: Subscription
    targetCharacterStatusEffects?: Subscription
    currentCharacterStats?: Subscription
    currentCharacterTwilightLevel?: Subscription
    currentCharacterStatusEffects?: Subscription
    currentCharacterPosition?: Subscription
  }
  abilitiesCooldown: AbilityCooldown[]
  abilityRange?: Int
  meterToPixel: number
}

type Actions = {
  setWsClient: (matchAccess: MatchAccess) => void
  setStartAt: (startAt: Millisecond) => void
  setCharacters: (characters: MatchMapCharacterFragment[]) => void
  addCharacter: (character: MatchMapCharacterFragment) => void
  updateCharacter: (characterId: string, character: Partial<MatchMapCharacterFragment>) => void
  setCurrentCharacter: (character: CharacterEntity) => void
  setSelectedAbility: (ability: MatchCharacterAbilityFragment) => void
  clearSelectedAbility: () => void
  setCastAction: (castAction?: AbilityCastAction | AbilityChannelAction) => void
  setAbilityCooldown: (abilityCooldown: Omit<AbilityCooldown, 'remainingCooldown'>) => void
  clearMatchStore: () => void
  setTargetCharacter: (character?: CharacterEntity) => void
  setAbilityRange: (abilityRange?: Meter) => void
  setMeterToPixel: (meterToPixel: number) => void
}

type Store = State & Actions

// TODO: optimise performance https://github.com/pmndrs/zustand#transient-updates-for-often-occurring-state-changes
export const matchStore = create(
  subscribeWithSelector<Store>((set, get) => ({
    wsClient: undefined,
    setWsClient: ({ host, port, token }: MatchAccess) =>
      set({ wsClient: getWsClient({ host, port, token }) }),
    currentCharacterStatusEffects: [],
    currentCharacterTwilightLevel: undefined,
    targetCharacter: undefined,
    triggeredAbility: undefined,
    abilitiesCooldown: [],
    startAt: undefined,
    setStartAt: (startAt) => set({ startAt }),
    characters: [],
    setCharacters: (characters) => set({ characters }),
    addCharacter: (character) => set((state) => ({ characters: [...state.characters, character] })),
    updateCharacter: (characterId, character) => {
      set((state) => ({
        characters: state.characters.map((c) =>
          c.id === characterId ? { ...c, ...character } : c,
        ),
      }))
    },
    meterToPixel: 0,
    setMeterToPixel: (meterToPixel) => set({ meterToPixel }),
    currentCharacter: undefined,
    setCurrentCharacter: (currentCharacter) =>
      set(() => {
        if (currentCharacter) {
          const statsSubscription = currentCharacter.statsSubject.subscribe((stats) => {
            const { currentCharacterStats } = get()
            set({ currentCharacterStats: { ...currentCharacterStats, ...stats } })
          })
          const twilightLevelSubscription = currentCharacter.twilightLevelSubject.subscribe(
            (twilightLevel) => {
              set({ currentCharacterTwilightLevel: twilightLevel })
            },
          )
          const statusEffectsSubscription = currentCharacter.statusEffectsGroups.subject.subscribe(
            (statusEffects) => {
              set({ currentCharacterStatusEffects: [...statusEffects] })
            },
          )
          set({
            subscriptions: {
              currentCharacterStats: statsSubscription,
              currentCharacterTwilightLevel: twilightLevelSubscription,
              currentCharacterStatusEffects: statusEffectsSubscription,
            },
          })
        } else {
          const { subscriptions } = get()
          subscriptions?.currentCharacterStats?.unsubscribe()
          subscriptions?.currentCharacterStatusEffects?.unsubscribe()
          subscriptions?.currentCharacterTwilightLevel?.unsubscribe()
        }

        return {
          currentCharacter,
          currentCharacterStats: currentCharacter?.stats,
          currentCharacterTwilightLevel: currentCharacter?.twilightLevel,
        }
      }),
    setTargetCharacter: (targetCharacter) =>
      set(() => {
        if (targetCharacter) {
          const statsSubscription = targetCharacter.statsSubject.subscribe((stats) => {
            const { targetCharacterStats } = get()
            set({ targetCharacterStats: { ...targetCharacterStats, ...stats } })
          })
          const twilightLevelSubscription = targetCharacter.twilightLevelSubject.subscribe(
            (twilightLevel) => {
              set({ targetCharacterTwilightLevel: twilightLevel })
            },
          )
          const statusEffectsSubscription = targetCharacter.statusEffectsGroups.subject.subscribe(
            (statusEffects) => {
              set({ targetCharacterStatusEffects: [...statusEffects] })
            },
          )
          set({
            subscriptions: {
              targetCharacterStats: statsSubscription,
              targetCharacterTwilightLevel: twilightLevelSubscription,
              targetCharacterStatusEffects: statusEffectsSubscription,
            },
          })
        } else {
          const { subscriptions } = get()
          subscriptions?.targetCharacterStats?.unsubscribe()
          subscriptions?.targetCharacterStatusEffects?.unsubscribe()
          subscriptions?.targetCharacterTwilightLevel?.unsubscribe()
        }

        return {
          targetCharacter,
          targetCharacterStats: targetCharacter?.stats,
          targetCharacterTwilightLevel: targetCharacter?.twilightLevel,
        }
      }),
    setSelectedAbility: (selectedAbility) =>
      set(() => {
        const { setAbilityRange } = get()
        setAbilityRange(selectedAbility.range.max)
        return { selectedAbility }
      }),
    clearSelectedAbility: () => set({ selectedAbility: undefined, abilityRange: undefined }),
    castAction: undefined,
    setCastAction: (castAction) => set({ castAction, abilityRange: undefined }),
    channelAbilityAction: undefined,
    setAbilityCooldown: (abilityCooldown) =>
      set(() => {
        const {
          abilitiesCooldown,
          selectedAbility,
          clearSelectedAbility: clearCurrentAbility,
        } = get()
        const remainingCooldownTime =
          abilityCooldown.startAt + abilityCooldown.duration - Date.now()
        if (remainingCooldownTime < 0) {
          return {}
        }
        timer(remainingCooldownTime)
          .pipe(
            finalize(() => {
              const { abilitiesCooldown: abilitiesCooldownAfterTimer } = get()
              const withoutAbility = abilitiesCooldownAfterTimer.filter(
                ({ abilityId }) => abilityId !== abilityCooldown.abilityId,
              )
              set(() => ({ abilitiesCooldown: withoutAbility }))
            }),
          )
          .subscribe()

        const isCurrentAbilityInCooldown = selectedAbility?.id === abilityCooldown.abilityId

        if (isCurrentAbilityInCooldown) {
          clearCurrentAbility()
        }
        return {
          abilitiesCooldown: [
            ...abilitiesCooldown,
            { ...abilityCooldown, remainingCooldown: remainingCooldownTime },
          ],
        }
      }),
    clearMatchStore: () =>
      set({
        wsClient: undefined,
        currentCharacter: undefined,
        selectedAbility: undefined,
        castAction: undefined,
      }),
    setAbilityRange: (abilityRange) =>
      set(() => {
        if (abilityRange === undefined) {
          return { abilityRange }
        }

        const { meterToPixel } = get()
        const abilityRangePx = abilityRange * meterToPixel
        return { abilityRange: abilityRangePx }
      }),
  })),
)

eventBus.on('action.triggered', () => {
  matchStore.getState().clearSelectedAbility()
})

export const useMatchStore = <U>(selector: (state: Store) => U) => matchStore(useShallow(selector))
