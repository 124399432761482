import styled from '@emotion/styled'
import { forwardRef } from 'react'

export type ActionMenuItem = {
  label: string
  onClick?: () => void
}

type Props = {
  items: ActionMenuItem[]
  className?: string
  style?: React.CSSProperties
}

type Ref = HTMLDivElement

export const CharacterActionMenu = forwardRef<Ref, Props>(({ items, className, style }, ref) => (
  <MenuContainer className={className} style={style} ref={ref}>
    {items.map(({ label, onClick }) => (
      <MenuItem key={label} onClick={onClick}>
        {label}
      </MenuItem>
    ))}
  </MenuContainer>
))

const MenuContainer = styled.div`
  font-size: 13px;
  z-index: 2;
  /* width: 213px; */
  width: 180px;
  border-radius: 12px;
  background: linear-gradient(178deg, #131927 1.95%, rgba(42, 48, 65, 0.75) 98.21%);
  box-shadow: 0px 12px 48px 0px rgba(8, 13, 19, 0.5);
`

const MenuItem = styled.div<{ onClick?: () => void }>`
  position: relative;
  font-weight: 600;
  color: #ada08a;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  padding: 10px 12px;
  transition: color 0.2s;
  height: 48px;
  display: flex;
  align-items: center;

  &:not(:first-of-type):before {
    content: '';
    position: absolute;
    top: 0;
    height: 1px;
    left: 12px;
    right: 12px;
    background: #3c4860;
  }

  &:hover {
    color: #fff;
  }
`
