import styled from '@emotion/styled'
import { LevelShield } from '~components/LevelShield'
import { t } from '~utils/i18n'
import { CharacterImage } from './CharacterImage'
import { LoadingProgress } from './LoadingProgress'
import { store } from '~store'

import ImgAttackShield from '~assets/img-attack-shield.png'
import ImgAttackShield2x from '~assets/img-attack-shield_2x.png'
import ImgAttackShieldGlass from '~assets/img-attack-shield-glass.png'
import ImgAttackShieldGlass2x from '~assets/img-attack-shield-glass_2x.png'
import ImgAttackCircle from '~assets/img-attack-circle.png'
import ImgAttackCircle2x from '~assets/img-attack-circle_2x.png'

export const OpenMatchWaitDialog = () => {
  const [openMatchWaitDialog] = store((state) => [state.openMatchWaitDialog])

  if (!openMatchWaitDialog) {
    return null
  }

  const { character, isAttacker } = openMatchWaitDialog || { character: null, title: null }
  const title = openMatchWaitDialog.matchStartAt
    ? t('dialog:openMatchWaitDialog.waitToBeginText')
    : isAttacker !== undefined
    ? isAttacker
      ? t('dialog:openMatchWaitDialog.youAttackedText')
      : t('dialog:openMatchWaitDialog.youWereAttackedText')
    : null

  const subTitle = openMatchWaitDialog.matchStartAt
    ? null
    : t('dialog:openMatchWaitDialog.waitToBeginText')

  return (
    <Container>
      <Shield>
        <RotatingCircle />
        <StyledLoadingProgress startAt={openMatchWaitDialog.matchStartAt} />
        {character ? <CharacterImage avatar={character?.avatar} /> : null}
        <TextContainer>
          {title ? <Title>{title}</Title> : null}
          <Name>
            {character ? (
              <>
                {character?.firstName} {character?.lastName}{' '}
                <StyledLevelShield>{character?.level}</StyledLevelShield>
              </>
            ) : null}
          </Name>
          {subTitle ? <Subtitle>{subTitle}</Subtitle> : null}
        </TextContainer>
      </Shield>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #131927bf;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  backdrop-filter: blur(8px);

  /* opacity: 0; */
  /* animation: fade-in 1s forwards;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  } */
`

const Shield = styled.div`
  width: 450px;
  height: 578px;
  background: image-set(url('${ImgAttackShield}') 1x, url('${ImgAttackShield2x}') 2x) center
    no-repeat;
  background-size: contain;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 296px;
    height: 360px;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    background: image-set(url('${ImgAttackShieldGlass}') 1x, url('${ImgAttackShieldGlass2x}') 2x)
      center no-repeat;
    background-size: contain;
    z-index: -1;
  }
`

const Title = styled.div`
  font-size: 42px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 1px 10px #081f2d;
  margin-bottom: 5px;
  padding: 0 50px;
`

const Subtitle = styled.div`
  font-size: 15px;
  color: #d9d1bb;
  text-shadow: 0 1px 10px #081f2d;
`

const TextContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 100px;
  text-align: center;
  filter: drop-shadow(0px 1px 20px #081f2d);
`

const Name = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 1px 10px #081f2d;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
`

const StyledLevelShield = styled(LevelShield)`
  margin-left: 8px;
`

const RotatingCircle = styled.div`
  width: 570px;
  height: 570px;
  position: absolute;
  bottom: -53px;
  left: 50%;
  transform: translateX(-50%);
  z-index: -2;
  background: image-set(url('${ImgAttackCircle}') 1x, url('${ImgAttackCircle2x}') 2x) center
    no-repeat;
  pointer-events: none;
  animation: spin 60s linear infinite;

  @keyframes spin {
    100% {
      transform: translateX(-50%) rotate(360deg);
    }
  }
`

const StyledLoadingProgress = styled(LoadingProgress)`
  position: absolute;
  top: 119px;
  left: 50%;
  margin-left: -231px;
`
