import type { GeoJSONSourceSpecification, LayerSpecification } from 'maplibre-gl'
import { Subject, throttleTime } from 'rxjs'
import { getRemainingPath } from '~map-tools'
import type { From0To100, Path } from '~shared-types'
import type { LayerManager } from './LayerManager'

export class MapRoute {
  private updateProgressCalls = new Subject<From0To100>()

  constructor(
    public characterId: string,
    private path: Path,
    public layerManager: LayerManager,
    type: 'map' | 'minimap',
  ) {
    const source = {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: [...path].reverse(),
        },
      },
    } as const satisfies GeoJSONSourceSpecification
    const layerPaint =
      type === 'map'
        ? {
            'line-color': '#808080',
            'line-width': 4,
            'line-dasharray': [0, 3],
          }
        : {
            'line-color': '#D9D1BB',
            'line-width': 1,
          }
    const layer = {
      id: this.layerId,
      type: 'line',
      source: this.sourceId,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: layerPaint,
    } as const satisfies LayerSpecification
    layerManager.createLayerWithSource(layer, source)

    const updateEventNMillisecond = type === 'map' ? 500 : 2000

    this.updateProgressCalls
      .pipe(throttleTime(updateEventNMillisecond))
      .subscribe((progress) => this.updateProgressThrottled(progress))
  }

  public updateProgress(progress: From0To100) {
    this.updateProgressCalls.next(progress)
  }

  private updateProgressThrottled(progress: From0To100) {
    const path = getRemainingPath(this.path, progress)
    if (!path) {
      return
    }

    const sourceData = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'LineString',
        coordinates: [...path].reverse(),
      },
    } as const satisfies GeoJSON.GeoJSON

    this.layerManager.setNewSourceData(`route-${this.characterId}-source`, sourceData)
  }

  public clearRoute() {
    this.layerManager.removeLayer(this.layerId)
    this.layerManager.removeSource(this.sourceId)
    this.updateProgressCalls.complete()
  }

  get layerId(): string {
    return `route-${this.characterId}-layer`
  }

  get sourceId(): string {
    return `route-${this.characterId}-source`
  }
}
