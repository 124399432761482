import type { TwilightLevel } from '~types'
import type { TwilightAbilityId } from '~constants/map'

export const goTwilightLevelAbilityIdMap: Record<TwilightLevel, TwilightAbilityId> = {
  0: 6,
  1: 7,
  2: 8,
  3: 9,
}

export const twilightLevelAbilityMap = {
  0: 1,
  1: 2,
  2: 3,
  3: 4,
}
