/* eslint-disable react/jsx-props-no-spreading */
import type { FC } from 'react'
import styled from '@emotion/styled'
import type { Race, Side } from '~generated/graphql'
import { ReactComponent as IconEnergy } from '~assets/icon-energy.svg'
import { ReactComponent as IconHealth } from '~assets/icon-health.svg'
import {
  getEnergyClipPath,
  getHealthClipPath,
  getSafeProgress,
  raceColorMap,
  raceHealthIconShadowMap,
  sideColorMap,
} from '../../utils/character'

const energyClipPath = getEnergyClipPath(196)
const healthClipPath = getHealthClipPath(192)

type HealthBarProps = {
  side: Side
  healthProgress: number
  shieldProgress: number
  value: number
  race: Race
  sectorsCount?: number
}

export const HealthBar: FC<HealthBarProps> = ({
  side,
  healthProgress,
  shieldProgress,
  value,
  race,
  sectorsCount,
}) => {
  const color = raceColorMap[race]
  const healthSafeProgress = getSafeProgress(healthProgress, 4)
  const shieldSafeProgress = getSafeProgress(shieldProgress, 4)

  return (
    <StyledHealthBar>
      <HealthBase clipPath={healthClipPath} />

      <ProgressContainer width={healthSafeProgress}>
        <HealthProgress clipPath={healthClipPath} gradientColor={color}>
          <HealthBarIcon as={IconHealth} race={race} />
        </HealthProgress>
      </ProgressContainer>
      <ShieldProgressContainer width={shieldSafeProgress}>
        <ShieldProgress clipPath={healthClipPath} />
      </ShieldProgressContainer>

      {sectorsCount
        ? Array.from({ length: sectorsCount + 1 }, (_, index) => {
            const progress =
              (healthProgress > shieldProgress ? healthProgress : shieldProgress) / 100
            const isInShieldArea = healthProgress / 100 < index / sectorsCount
            const hidden =
              progress < index / sectorsCount || (progress === 1 && index === sectorsCount)
            const key = `sector-${index}`
            return (
              <Sector
                key={key}
                index={index}
                count={sectorsCount}
                hidden={hidden}
                color={isInShieldArea ? '#3C4860' : raceColorMap[race].dark}
              />
            )
          })
        : null}

      <Value side={side}>{value}</Value>
    </StyledHealthBar>
  )
}

type EnergyBarProps = {
  side: Side
  progress: number
  value: number
}

export const EnergyBar: FC<EnergyBarProps> = ({ side, progress, value }) => {
  const safeProgress = getSafeProgress(progress, 6)

  return (
    <StyledEnergyBar>
      <EnergyBase side={side} clipPath={energyClipPath} />
      <EnergyProgressContainer side={side} width={safeProgress}>
        <EnergyProgress side={side} clipPath={energyClipPath}>
          <EnergyBarIcon as={IconEnergy} side={side} />
        </EnergyProgress>
      </EnergyProgressContainer>
      <Value side={side}>{value}</Value>
    </StyledEnergyBar>
  )
}

const Container = styled.div<{ large?: boolean }>`
  position: relative;
  height: 16px;
`

const StyledHealthBar = styled(Container)`
  margin-left: 9px;
  width: 192px;
  overflow: hidden;
  position: relative;
`

const StyledEnergyBar = styled(Container)`
  margin-top: 4px;
  width: 196px;
  z-index: 1;
`

const Base = styled.div<{ clipPath: string }>`
  width: 100%;
  height: 100%;
  clip-path: ${({ clipPath }) => clipPath};
`

const HealthBase = styled(Base)`
  background: #131927;
`

const EnergyBase = styled(Base)<{ side: Side }>`
  background: ${({ side }) => (side === 'DARKNESS' ? `#6B222F` : `#11337D`)};
`

const ProgressContainer = styled.div<{ width: number }>`
  position: absolute;
  height: 100%;
  left: 0;
  top: 0;
  width: ${({ width }) => width}%;
  z-index: 2;
`

const ShieldProgressContainer = styled(ProgressContainer)`
  left: auto;
  left: 0;
  z-index: 1;
`

const EnergyProgressContainer = styled(ProgressContainer)<{ side: Side }>`
  filter: ${({ side }) =>
    `drop-shadow(0px 0px 5px ${sideColorMap[side].dark}) drop-shadow(0px 0px 5px ${sideColorMap[side].dark}bf)`};
`

const Progress = styled.div<{ clipPath: string }>`
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;
  clip-path: ${({ clipPath }) => clipPath};
  box-shadow: 0px 1px 3px 0px rgba(255, 255, 255, 0.75) inset;
`

const ShieldProgress = styled(Progress)`
  background: linear-gradient(to bottom, #a7afc4 0%, #3f4b64 100%);
  box-shadow: none;
`

const HealthProgress = styled(Progress)<{
  gradientColor: {
    main: string
    dark: string
  }
}>`
  background: ${({ gradientColor }) =>
    `linear-gradient(180deg, ${gradientColor.main} 0%, ${gradientColor.dark} 100%);`};
`

const EnergyProgress = styled(Progress)<{ side: Side }>`
  background: ${({ side }) =>
    side === 'LIGHT'
      ? `linear-gradient(180deg, ${sideColorMap[side].light} -65.62%, ${sideColorMap[side].medium} 1.78%, ${sideColorMap[side].dark} 84.37%);`
      : `linear-gradient(180deg, ${sideColorMap[side].light} -65.62%, ${sideColorMap[side].medium} 21.56%, ${sideColorMap[side].dark} 84.37%);`};
`

const Value = styled.div<{ side: Side }>`
  color: #fff;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  padding-top: 2px;
  padding-right: 9px;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-shadow: 0px 0.5px 2px #000;
  z-index: 4;
`

const BarIcon = styled.div`
  position: relative;
  flex-shrink: 0;
  margin-left: 8px;
  z-index: 10;
`

const EnergyBarIcon = styled(BarIcon)<{ side: Side }>`
  height: 15px;
  width: 12px;
  ${({ side }) =>
    side === 'LIGHT'
      ? `fill: #11337D;
filter: drop-shadow(0px 0.7500000596046448px 0.7500000596046448px rgba(157, 255, 249, 0.75));`
      : `fill: #742230;
filter: drop-shadow(0px 0.7500000596046448px 0.7500000596046448px rgba(250, 219, 133, 0.75));`};
`

const HealthBarIcon = styled(BarIcon)<{ race: Race }>`
  height: 12px;
  width: 12px;
  margin-left: 6px;
  fill: #131927;
  filter: ${({ race }) => raceHealthIconShadowMap[race]};
`

const Sector = styled.div<{ index: number; count: number; hidden: boolean; color: string }>`
  width: 2px;
  height: calc(100% + 4px);
  left: ${({ index, count }) => `calc(100% * ${index} / ${count})`};
  background: ${({ color }) => color};
  transform: rotate(15deg);
  display: ${({ hidden }) => (hidden ? 'none' : 'block')};
  position: absolute;
  top: -2px;
  z-index: 3;
`
