import { useMemo } from 'react'
import type { FC } from 'react'
import { distinctUntilChanged } from 'rxjs'
import type { Observable } from 'rxjs'
import type { Race } from '~shared-types'
import type { MainStats } from '../../CharacterPanel/CharacterPanelMainStats'
import { useValueFromObservable } from '../../quest/useValueFromObservable'
import { HealthBarSvg } from './HealthBarSvg'

type Props = {
  race: Race
  id: string
  statsObservable: Observable<MainStats>
}

export const HealthBar: FC<Props> = ({ race, id, statsObservable }) => {
  const healthObservable = useMemo(
    () =>
      statsObservable.pipe(
        distinctUntilChanged(
          (prev, cur) =>
            !(
              prev.health !== cur.health ||
              prev.healthMax !== cur.healthMax ||
              prev.shield !== cur.shield
            ),
        ),
      ),
    [statsObservable],
  )
  const { health, healthMax, shield } = useValueFromObservable(healthObservable)

  const maxValue = Math.max(health + (shield ?? 0), healthMax)
  const healthProgress = health / maxValue
  const shieldProgress = (shield ? health + shield : 0) / maxValue
  const sectorsCount = maxValue / 250

  return (
    <HealthBarSvg
      healthProgress={healthProgress}
      shieldProgress={shieldProgress}
      sectorsCount={sectorsCount}
      race={race}
      id={id}
    />
  )
}
