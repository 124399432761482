import styled from '@emotion/styled'
import type { FC, PropsWithChildren } from 'react'
import { ReactComponent as IconDialogClose } from '~assets/icon-dialog-close.svg'
import { ReactComponent as IconDialogCloseSmall } from '~assets/icon-dialog-close-small.svg'

type Props = {
  relative?: boolean
  onClose?: () => void
  className?: string
  smallCloseButton?: boolean
  withBackground?: boolean
}

export const Dialog: FC<PropsWithChildren<Props>> = ({
  relative = false,
  children,
  onClose,
  className,
  smallCloseButton = false,
  withBackground = false,
}) => (
  <>
    {withBackground ? <Background /> : null}
    <Container className={className} relative={relative}>
      {onClose ? (
        <CloseButton onClick={onClose} small={smallCloseButton}>
          {smallCloseButton ? <IconDialogCloseSmall /> : <IconDialogClose />}
        </CloseButton>
      ) : null}
      {children}
    </Container>
  </>
)

const Container = styled.div<{ relative: boolean }>`
  position: ${({ relative }) => (relative ? 'relative' : 'fixed')};
  top: ${({ relative }) => (relative ? 'auto' : '50%')};
  left: ${({ relative }) => (relative ? 'auto' : '50%')};
  transform: ${({ relative }) => (relative ? 'none' : 'translate(-50%, -50%)')};
  z-index: 30;
  box-shadow: 0px 12px 48px 0px #080d13;
  padding: 48px 46px;
  color: #646d85;

  &:before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    z-index: -2;
    background: linear-gradient(331deg, #808080 0%, #ada08a 30.73%, #d9d1bb 69.27%, #eae4cf 100%);
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: linear-gradient(180deg, #080c13 -13.69%, #131927 26.34%, #2a3041 100%);
  }

  a {
    color: #646d85;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
`

const Background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(19, 25, 39, 0.75);
  backdrop-filter: blur(2px);
  width: 100vw;
  height: 100vh;
  z-index: 20;
`

const CloseButton = styled.div<{ small: boolean }>`
  position: absolute;
  top: ${({ small }) => (small ? '6px' : '14px;')};
  right: ${({ small }) => (small ? '6px' : '14px;')};
  cursor: pointer;
`
