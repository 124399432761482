import styled from '@emotion/styled'
import type { FC, PropsWithChildren } from 'react'

type Props = {
  className?: string
}

export const LevelShield: FC<PropsWithChildren<Props>> = ({ className, children }) => (
  <Container className={className}>{children}</Container>
)

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  padding-top: 2px;
  color: #131927;
  font-size: 12px;
  line-height: 28px;
  font-weight: 700;
  z-index: 2;
  box-shadow: 0 1px 3px #081f2dbc;
  background: linear-gradient(to bottom, #d9d1bb 0, #808080 100%);
  position: relative;
  text-shadow: none;

  &:before {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: 50%;
    border: 1px solid #131927;
    pointer-events: none;
  }
`
