import type { FC } from 'react'
import styled from '@emotion/styled'
import type { MarkerAvatarFragment } from '~generated/graphql'
import { sideColorMap } from '~utils/character'
import type { Side } from '~shared-types'
import { imageUrlMap } from '~constants/imageUrlMap'

type Props = {
  side?: Side
  avatar?: Partial<MarkerAvatarFragment>
}

export const MinimapCharacterMarker: FC<Props> = ({ side = 'NEUTRAL', avatar }) => (
  <Container side={side}>
    <Image src={avatar} />
  </Container>
)

const Container = styled.div<{ side: Side }>`
  position: relative;
  padding: 1px;
  width: 24px;
  height: 24px;
  background: ${({ side }) =>
    `linear-gradient(135deg, ${sideColorMap[side].light} 14.58%, ${sideColorMap[side].medium} 36.39%, ${sideColorMap[side].dark} 85.42%)`};
  border-radius: 50%;
`

const Image = styled.div<{ src?: Partial<MarkerAvatarFragment> }>`
  background: ${({ src }) =>
    src?.headShot
      ? `#131927 url("${src?.headShot}")`
      : `#3C4860 url("${imageUrlMap.ImgFallbackCharacter.headShot}"`};
  background-size: contain;
  border-radius: 50%;
  border: 1px solid;
  z-index: 1;
  width: 100%;
  height: 100%;
`
