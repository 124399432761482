import type { Map } from 'maplibre-gl'
import { throttleTime, timer } from 'rxjs'
import type { CharacterEntity } from '../CharacterEntity'
import { AnimationMarker } from './AnimationMarker'
import { AbilityDamageSourceUI } from '../../components/animation/AbilityDamageSourceUI'
import { angleBetweenTwoPoints } from '~map-tools'

export class TickDamageAnimation {
  constructor(map: Map, caster: CharacterEntity, target: CharacterEntity) {
    const angle = Math.round(angleBetweenTwoPoints(caster.position, target.position))

    const component = <AbilityDamageSourceUI race={caster.race} />
    const marker = new AnimationMarker(map, component, target.position, true, angle)

    const targetPositionSubscription = target.positionObservable
      .pipe(throttleTime(20))
      .subscribe((position) => {
        const angleUpdated = Math.round(angleBetweenTwoPoints(caster.position, position))
        marker.setRotation(angleUpdated)
        marker.setLngLat(position)
      })

    const casterPositionSubscription = caster.positionObservable
      .pipe(throttleTime(20))
      .subscribe((position) => {
        const angleUpdated = Math.round(angleBetweenTwoPoints(position, target.position))
        marker.setRotation(angleUpdated)
      })

    const subscription = timer(800).subscribe(() => {
      marker.remove()
      targetPositionSubscription.unsubscribe()
      casterPositionSubscription.unsubscribe()
      subscription.unsubscribe()
    })
  }
}
