import styled from '@emotion/styled'
import { useForm } from 'react-hook-form'
import { useState } from 'react'
import { Button } from '~components/Button'
import { Input } from '~components/Input'
import type { CreateCharacterAvatarFragment, Gender } from '~generated/graphql'
import { useAvatarsQuery } from '~generated/graphql'
import { useCreateCharacter } from './useCreateCharacter'
import { GenderRadioButton } from './GenderRadioButton'
import { t } from '~utils/i18n'

import BgCreateCharacter from '~assets/bg-create-character.svg'
import BgPodiumCreateCharacter from '~assets/bg-podium-create-character.svg'
import IconSelect from '~assets/icon-select.svg'
import { imageUrlMap } from '~constants/imageUrlMap'

type CreateCharacterInputs = {
  firstName: string
  lastName: string
  gender: Gender
  characterAvatar: CreateCharacterAvatarFragment
}

type Images = Record<Gender, CreateCharacterAvatarFragment[]>

export const CreateCharacter = () => {
  const [showError, setShowError] = useState<boolean>(false)
  const [images, setImages] = useState<Images | null>(null)

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<CreateCharacterInputs>({
    defaultValues: {
      gender: 'MALE',
    },
  })
  const gender = watch('gender')

  useAvatarsQuery({
    onCompleted: (data) => {
      if (data?.avatars.length) {
        const maleImages = data.avatars.filter(({ gender: aGender }) => aGender === 'MALE')
        const femaleImages = data.avatars.filter(({ gender: aGender }) => aGender === 'FEMALE')
        setImages({
          MALE: maleImages,
          FEMALE: femaleImages,
        })
        setValue('characterAvatar', gender === 'FEMALE' ? femaleImages[0] : maleImages[0])
      }
    },
  })

  const characterAvatar = watch('characterAvatar')
  const [createCharacter, { error }] = useCreateCharacter()

  const handleCreateCharacter = async (data: CreateCharacterInputs) => {
    setShowError(true)
    await createCharacter({
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      avatarId: data.characterAvatar.id,
    })
  }

  return (
    <Container>
      <Content>
        <FormContainer>
          <Title>{t('character:createCharacter.title')}</Title>

          <Form onSubmit={handleSubmit(handleCreateCharacter)}>
            <Section>
              <SectionTitle>{t('character:createCharacter.characterName')}</SectionTitle>

              <InputHalf
                register={register}
                name="firstName"
                rules={{ required: { value: true, message: t('form:requiredField')! } }}
                label={t('character:firstName')}
                placeholder={t('character:firstNamePlaceholder')}
                error={errors.firstName?.message}
                onChange={() => {
                  setShowError(false)
                }}
              />

              <InputHalf
                register={register}
                name="lastName"
                rules={{
                  required: { value: true, message: t('form:requiredField')! },
                }}
                label={t('character:lastName')}
                placeholder={t('character:lastNamePlaceholder')}
                error={errors.lastName?.message}
                onChange={() => {
                  setShowError(false)
                }}
              />

              {showError && error && <Error>{error.message}</Error>}
            </Section>
            <Section>
              <SectionTitle>{t('character:createCharacter.characterGender')}</SectionTitle>

              <GenderSelectionContainer>
                <GenderRadioButton
                  register={register}
                  name="gender"
                  gender="MALE"
                  onChange={(e) =>
                    images
                      ? setValue('characterAvatar', images[e.target.value as Gender][0])
                      : undefined
                  }
                />
                <GenderRadioButton
                  register={register}
                  name="gender"
                  gender="FEMALE"
                  onChange={(e) =>
                    images
                      ? setValue('characterAvatar', images[e.target.value as Gender][0])
                      : undefined
                  }
                />
              </GenderSelectionContainer>
              {errors.gender && <Error>{errors.gender?.message}</Error>}
            </Section>
          </Form>
        </FormContainer>
        <Character>
          <ImageContainer>
            <img
              srcSet={
                characterAvatar
                  ? `${characterAvatar?.fullBody} 1x, ${characterAvatar?.fullBody2x} 2x`
                  : `${imageUrlMap.ImgFallbackCharacter.fullBody} 1x, ${imageUrlMap.ImgFallbackCharacter.fullBody2x} 2x`
              }
              alt="character"
            />
          </ImageContainer>
          <Button onClick={handleSubmit(handleCreateCharacter)}>
            {t('character:createCharacter.button')}
          </Button>
        </Character>
        <CharacterThumbList>
          {images?.[gender]?.map((image) => (
            <CharacterThumbListItem
              key={image.id}
              active={image.id === characterAvatar.id}
              onClick={() => {
                setValue('characterAvatar', image)
              }}
            >
              <CharacterThumbImage
                active={image.id === characterAvatar.id}
                src1x={image.halfBody}
                src2x={image.halfBody2x}
              />
            </CharacterThumbListItem>
          ))}
        </CharacterThumbList>
      </Content>
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: url(${BgCreateCharacter}) no-repeat center, #131927;
  padding: 48px;
  display: flex;
  justify-items: center;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1652px;
  margin: auto;
`

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
`

const Character = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 24px;
  flex: 1;

  > * {
    flex-shrink: 0;
  }
`

const ImageContainer = styled.div`
  flex: 1;
  height: calc(100% - 48px);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 50%;
    transform: translateX(-50%);
    bottom: -140px;
    width: 130%;
    height: 130%;
    background: url(${BgPodiumCreateCharacter}) no-repeat center;
    background-size: contain;
  }

  img {
    position: relative;
    z-index: 1;
    height: 100%;
    width: auto;
  }
`

const CharacterThumbList = styled.div`
  height: 100%;
  width: 450px;

  display: grid;
  grid-template-rows: 134px;
  grid-template-columns: repeat(auto-fit, 134px);
  gap: 24px;
  overflow-y: auto;
  flex-shrink: 0;
`

const FormContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 24px;
  max-width: 450px;
`

const Title = styled.div`
  font-size: 60px;
  font-weight: 600;
  color: #fff;
  margin-top: 104px;
  line-height: 66px;
`

const SectionTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: #ada08a;
  margin-bottom: 19px;
  width: 100%;
  position: relative;
  padding: 6px 0;

  &:before {
    content: '';
    position: absolute;
    left: 0x;
    top: 100%;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #d9d1bb 0%, #808080 50%, #131927 100%);
  }
`

const InputHalf = styled(Input<CreateCharacterInputs>)`
  width: calc(50% - 12px);
  height: 108px;

  & + & {
    margin-left: 24px;
  }
`

const Section = styled.div`
  margin-top: 42px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`

const Error = styled.div`
  width: 100%;
  color: #ea4357;
  font-size: 14px;
  padding-left: 18px;
  margin-top: -17px;
`

const GenderSelectionContainer = styled.div`
  width: 100%;
  display: flex;
  margin-top: 10px;
  padding-right: 15%;
  justify-content: space-between;
`

const CharacterThumbListItem = styled.div<{ active?: boolean }>`
  cursor: pointer;
  position: relative;
  aspect-ratio: 1 / 1;

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    transition: 0.2s;
    opacity: ${({ active }) => (active ? 1 : 0)};
    border-radius: 12px;
    border: 2px solid transparent;
    background: linear-gradient(to bottom, #d9d1bb 0%, #808080 100%) border-box;
    -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:before {
    content: '';
    transition: 0.2s;
    opacity: ${({ active }) => (active ? 1 : 0)};
    position: absolute;
    left: 2px;
    top: 2px;
    bottom: 2px;
    right: 2px;
    z-index: 1;
    border: 1px solid #131927;
    border-radius: 10px;
  }
`

const CharacterThumbImage = styled.div<{ src1x: string; src2x: string; active?: boolean }>`
  position: absolute;
  z-index: 0;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: ${({ src1x, src2x }) => `image-set(url(${src1x}) 1x, url(${src2x}) 2x)`} no-repeat
      center,
    linear-gradient(178deg, #131927 1.95%, #202636 98.21%);
  background-size: cover;
  border-radius: 12px;

  &:after {
    content: '';
    transition: 0.2s;
    position: absolute;
    width: 24px;
    height: 24px;
    right: 3px;
    bottom: 3px;
    z-index: 2;
    opacity: ${({ active }) => (active ? 1 : 0)};
    background: url(${IconSelect}) no-repeat center;
    background-size: contain;
  }
`
