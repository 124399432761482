import styled from '@emotion/styled'
import { useState } from 'react'
import type { FC } from 'react'
import { useForm } from 'react-hook-form'
import { take, timer } from 'rxjs'
import { Duration } from 'luxon'
import { Input } from '~components/Input'
import { Dialog } from '~components/Dialog'
import { Button } from '~components/Button'
import {
  SettingsUserDocument,
  useCompleteEmailConfirmationMutation,
  useRequestEmailConfirmationMutation,
} from '~generated/graphql'
import { TextLink } from './Layout'
import { t } from '~utils/i18n'

import IconReload from '~assets/icon-reload.svg'

type Props = {
  email: string
  onClose: () => void
}

type ConfirmEmailInput = {
  securityCode: string
}

type EmailConfirmationStatus = 'NotRequested' | 'EnterCode'

export const ConfirmEmailDialog: FC<Props> = ({ email, onClose }) => {
  const [confirmationStatus, setConfirmationStatus] =
    useState<EmailConfirmationStatus>('NotRequested')
  const [error, setError] = useState('')
  const [secondsToWait, setSecondsToWait] = useState(0)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmEmailInput>({})

  const [requestEmailConfirmation] = useRequestEmailConfirmationMutation({
    onError: (requestError) => {
      console.error(requestError)
      setError(requestError.message)
    },
    onCompleted: ({ requestEmailConfirmation: { nextRequestPossibleIn } }) => {
      const allSecondsToWait = Duration.fromISO(nextRequestPossibleIn).as('seconds')
      timer(0, 1000)
        .pipe(take(allSecondsToWait + 1))
        .subscribe((passedSeconds) => {
          setSecondsToWait(allSecondsToWait - passedSeconds)
        })
      setConfirmationStatus('EnterCode')
    },
  })

  const [completeEmailConfirmation] = useCompleteEmailConfirmationMutation({
    refetchQueries: [SettingsUserDocument],
    onError: (requestError) => {
      console.error(requestError)
      setError(requestError.message)
    },
    onCompleted: (data) => {
      if (data.completeEmailConfirmation.__typename === 'EmailConfirmationFailure') {
        setError(data.completeEmailConfirmation.reason)
      } else {
        onClose()
      }
    },
  })
  const handleRequestEmailConfirmation = async () => {
    await requestEmailConfirmation()
  }
  const handleCompleteEmailConfirmation = async (formData: { securityCode: string }) => {
    await completeEmailConfirmation({ variables: { token: formData.securityCode } })
  }
  return confirmationStatus === 'NotRequested' ? (
    <StyledDialog withBackground onClose={onClose}>
      <Title>{t('settings:account.confirmEmail.title')}</Title>

      <Content>{t('settings:account.confirmEmail.content', { email })}</Content>
      <Content>
        <TextLink onClick={() => setConfirmationStatus('EnterCode')}>
          {t('settings:account.confirmEmail.haveCodeAlready')}
        </TextLink>
      </Content>
      {error && <Error>{error}</Error>}
      <ButtonContainer>
        <StyledButton onClick={onClose} outline>
          {t('button:cancel')}
        </StyledButton>
        <StyledButton onClick={handleSubmit(handleRequestEmailConfirmation)}>
          {t('button:submit')}
        </StyledButton>
      </ButtonContainer>
    </StyledDialog>
  ) : (
    <StyledDialog withBackground onClose={onClose}>
      <Title>{t('settings:account.confirmEmail.title')}</Title>
      <Content>{t('settings:account.confirmEmail.emailIsSent', { email })}</Content>
      <Form>
        <Input
          register={register}
          name="securityCode"
          rules={{
            required: { value: true, message: t('form:requiredField')! },
          }}
          type="text"
          label={t('settings:account.confirmEmail.confirmationCode')}
          error={errors.securityCode?.message}
        />
        <CodeRequest>
          {secondsToWait ? (
            <>{t('settings:account.confirmEmail.nextTryIn', { secondsToWait })}</>
          ) : (
            <RequestCodeLink onClick={handleRequestEmailConfirmation}>
              <img src={IconReload} alt="codeRerequest" />
              {t('settings:account.confirmEmail.reRequest')}
            </RequestCodeLink>
          )}
        </CodeRequest>
        {error && <Error>{error}</Error>}
        <ButtonContainer>
          <StyledButton onClick={onClose} outline>
            {t('button:cancel')}
          </StyledButton>
          <StyledButton onClick={handleSubmit(handleCompleteEmailConfirmation)}>
            {t('button:submit')}
          </StyledButton>
        </ButtonContainer>
      </Form>
    </StyledDialog>
  )
}

const Error = styled.div`
  width: 100%;
  text-align: center;
  color: #ea4357;
  font-size: 14px;
  margin-top: 20px;
`

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 32px;
  line-height: 37px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 55px;
`

const StyledDialog = styled(Dialog)`
  width: 445px;
`

const Form = styled.form``

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
`

const StyledButton = styled(Button)`
  min-width: 165px;
`

const Content = styled.div`
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`

const CodeRequest = styled.div`
  text-align: center;
  margin-top: 24px;
`

const RequestCodeLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ada08a;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;

  img {
    display: block;
    transition: transform 0.2s;
    position: relative;
    top: -1px;
    margin-right: 4px;
    will-change: transform;
  }

  &:hover {
    img {
      transform: rotate(45deg);
      transform-origin: center;
    }
  }
`
