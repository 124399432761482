import styled from '@emotion/styled'
import type { FC } from 'react'
import type { TwilightLevel } from '~types'
import { TwilightButton } from './TwilightButton'
import { t } from '~utils/i18n'
import withTooltip from '~components/Tooltip/withTooltip'
import type { Ability } from '~generated/graphql'

import MinimapBorder from '~assets/minimap-border.svg'
import { ReactComponent as IconTwilight0 } from '~assets/icon-twilight0.svg'
import { ReactComponent as IconTwilight1 } from '~assets/icon-twilight1.svg'
import { ReactComponent as IconTwilight2 } from '~assets/icon-twilight2.svg'
import { ReactComponent as IconTwilight3 } from '~assets/icon-twilight3.svg'
import BgTwilight0 from '~assets/bg-minimap-twilight0.png'
import BgTwilight02x from '~assets/bg-minimap-twilight0_2x.png'
import BgTwilight1 from '~assets/bg-minimap-twilight1.png'
import BgTwilight12x from '~assets/bg-minimap-twilight1_2x.png'
import BgTwilight2 from '~assets/bg-minimap-twilight2.png'
import BgTwilight22x from '~assets/bg-minimap-twilight2_2x.png'
import BgTwilight3 from '~assets/bg-minimap-twilight3.png'
import BgTwilight32x from '~assets/bg-minimap-twilight3_2x.png'

const decorImageByLevel = (level: TwilightLevel) => {
  switch (level) {
    case 1:
      return {
        src: BgTwilight1,
        src2x: BgTwilight12x,
      }
    case 2:
      return {
        src: BgTwilight2,
        src2x: BgTwilight22x,
      }
    case 3:
      return {
        src: BgTwilight3,
        src2x: BgTwilight32x,
      }
    default:
      return {
        src: BgTwilight0,
        src2x: BgTwilight02x,
      }
  }
}

type Props = {
  twilightLevel?: TwilightLevel
  setTwilightLevel: (level: TwilightLevel) => void
  twilightAbilities?: Pick<
    Ability,
    'id' | 'name' | 'description' | 'castTime' | 'cooldown' | 'cost' | 'costType'
  >[]
}

export const Minimap: FC<Props> = ({ twilightLevel, setTwilightLevel, twilightAbilities }) => {
  const Twilight0Button = twilightAbilities?.length
    ? withTooltip(
        TwilightButton,
        {
          type: 'ability',
          ability: twilightAbilities.find((ability) => ability.id === 6)!,
        },
        {
          placement: 'top',
        },
      )
    : TwilightButton

  const Twilight1Button = twilightAbilities?.length
    ? withTooltip(
        TwilightButton,
        {
          type: 'ability',
          ability: twilightAbilities.find((ability) => ability.id === 7)!,
        },
        {
          maxWidth: 180,
          placement: 'left',
        },
      )
    : TwilightButton

  const Twilight2Button = withTooltip(
    TwilightButton,
    {
      type: 'text',
      content: t('tooltip:twilight.disabledText', { twilightLevel: 2, rank: 4 }),
    },
    {
      maxWidth: 180,
      placement: 'left',
    },
  )

  const Twilight3Button = withTooltip(
    TwilightButton,
    {
      type: 'text',
      content: t('tooltip:twilight.disabledText', { twilightLevel: 3, rank: 2 }),
    },
    {
      maxWidth: 180,
      placement: 'left',
    },
  )

  return (
    <Container twilightLevel={twilightLevel}>
      <Twilight0Button
        active={twilightLevel === 0}
        top={-68}
        right={58}
        Icon={IconTwilight0}
        onClick={twilightLevel === 0 ? undefined : () => setTwilightLevel(0)}
      />
      <Twilight1Button
        active={twilightLevel === 1}
        top={-32}
        left={-23}
        shiftVertical={-4}
        shiftHorizontal={-4}
        Icon={IconTwilight1}
        onClick={twilightLevel === 1 ? undefined : () => setTwilightLevel(1)}
      />
      <Twilight2Button
        active={twilightLevel === 2}
        top={43}
        left={-65}
        shiftHorizontal={-4}
        Icon={IconTwilight2}
        // TODO: mocked disabled state, change to dynamic after rank implementation
        disabled
        // onClick={() => setTwilightLevel(2)}
      />
      <Twilight3Button
        active={twilightLevel === 3}
        top={127}
        left={-51}
        shiftVertical={4}
        shiftHorizontal={-4}
        Icon={IconTwilight3}
        // TODO: mocked disabled state, change to dynamic after rank implementation
        disabled
        // onClick={() => setTwilightLevel(3)}
      />
      <MapContainer>
        <Map id="minimap" />
      </MapContainer>
    </Container>
  )
}

const Container = styled.div<{ twilightLevel?: TwilightLevel }>`
  position: relative;
  height: 12px;
  width: 184px;
  height: 184px;
  bottom: 34px;
  right: 28px;
  position: absolute;
  z-index: 5;

  &:before {
    content: '';
    position: absolute;
    width: 252px;
    height: 252px;
    bottom: -34px;
    right: -33px;
    z-index: 1;
    background: url(${MinimapBorder}) center no-repeat;
  }

  &:after {
    content: '';
    position: absolute;
    width: 277px;
    height: 285px;
    top: -55px;
    left: -52px;
    z-index: 0;
    background-image: ${({ twilightLevel }) =>
      `image-set(url("${decorImageByLevel(twilightLevel || 0).src}") 1x, url("${
        decorImageByLevel(twilightLevel || 0).src2x
      }") 2x)`};
    background-position: center left;
    background-repeat: no-repeat;
  }
`

const MapContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  background: #000;
  z-index: 2;

  /* &:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    background-image: url(${BgTwilight0}), url(${BgTwilight1}), url(${BgTwilight2}),
      url(${BgTwilight3});
    background-position: -285px -285px, -285px -285px, -285px -285px, -285px -285px;
  } */
`
const Map = styled.div`
  width: 100%;
  height: 100%;
`
