import styled from '@emotion/styled'
import { useEffect, useRef, useState } from 'react'
import {
  animationFrameScheduler,
  delay,
  filter,
  from,
  interval,
  map,
  startWith,
  switchMap,
} from 'rxjs'
import { PingDocument } from '~generated/match-graphql'
import { useMatchStore } from '~store'

export const MatchInfoPanelFps = () => {
  const [fps, setFps] = useState(0)
  const [serverTime, setServerTime] = useState(0)
  const lastFrameTimeRef = useRef(performance.now())
  const [wsClient] = useMatchStore((state) => [state.wsClient])

  useEffect(() => {
    const fps$ = interval(0, animationFrameScheduler).pipe(
      map(() => {
        const now = performance.now()
        const delta = now - lastFrameTimeRef.current
        lastFrameTimeRef.current = now
        return delta
      }),
      filter((_, index) => index % 120 === 0),
      map((delta) => ({
        fps: 1000 / delta,
        frameTime: delta,
      })),
    )

    const subscription = fps$.subscribe((next) => {
      setFps(Math.round(next.fps))
    })

    return () => subscription.unsubscribe()
  }, [])

  useEffect(() => {
    if (!wsClient) return

    const ping$ = interval(6000).pipe(
      startWith(0),
      delay(1000),
      switchMap(() => {
        const startTime = performance.now()
        return from(wsClient.query({ query: PingDocument, fetchPolicy: 'network-only' })).pipe(
          map(() => {
            const endTime = performance.now()
            return endTime - startTime
          }),
        )
      }),
    )

    const subscription = ping$.subscribe((duration) => {
      setServerTime(Math.trunc(duration))
    })

    return () => subscription.unsubscribe() // Cleanup on unmount
  }, [wsClient])

  return (
    <Container>
      <Item>FPS: {fps} </Item>
      <Item>{serverTime} ms</Item>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 32px;
  font-weight: 600;
`

const Item = styled.div`
  position: relative;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    right: calc(-32px / 2 - 2px);
    top: 50%;
    margin-top: -3px;
    display: block;
    width: 4px;
    height: 4px;
    background: #a7afc4;
    border-radius: 50%;
  }
`
