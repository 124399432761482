import styled from '@emotion/styled'
import { DateTime, Duration } from 'luxon'
import { memo } from 'react'
import type { FC } from 'react'
import type {
  AbilityChannelActionFragment,
  AbilityCastActionFragment,
} from '~generated/match-graphql'
import type { Side } from '~generated/graphql'
import type { Millisecond } from '~shared-types'
import { sideColorMap } from '../utils/character'
import { RhombusItem } from './RhombusItem'

type Props = {
  castAction?: AbilityCastActionFragment | AbilityChannelActionFragment
  side?: Side
  compact?: boolean
  className?: string
}

export const CastProgressUI: FC<Props> = memo(
  ({ castAction, compact = false, className, side }) => {
    if (!castAction) {
      return null
    }

    const type = castAction.__typename === 'AbilityCastAction' ? 'cast' : 'channel'
    const startAtMillis = DateTime.fromISO(castAction.startAt).toMillis()
    const durationMillis = Duration.fromISO(castAction.duration).toMillis()
    const currentProgress = Math.floor(((Date.now() - startAtMillis) / durationMillis) * 100)
    const endAt = startAtMillis + durationMillis
    const animationDuration = endAt - Date.now()

    if (compact) {
      return (
        <CompactContainer
          className={className}
          progress={currentProgress}
          duration={animationDuration}
          type={type}
        />
      )
    }
    return (
      <Container className={className}>
        <AbilityIcon
          icon={{ icon: castAction.ability.icon, icon2x: castAction.ability.icon2x }}
          size={24}
        />
        <ProgressBarContainer>
          <ProgressBar
            side={side}
            progress={currentProgress}
            duration={animationDuration}
            type={type}
          />
        </ProgressBarContainer>
        <Value durationSeconds={animationDuration / 1000} type="cast" />
      </Container>
    )
  },
)

const CompactContainer = styled.div<{
  progress: number
  duration: Millisecond
  type: 'cast' | 'channel'
}>`
  --progress: ${({ progress }) => `${progress}%`};
  z-index: 10;
  background: #808080;
  height: 2px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #d9d1bb;
    animation: ${({ type }) => (type === 'cast' ? 'spellCastProgress' : 'spellChannelProgress')}
      ${({ duration }) => duration}ms forwards;
    animation-timing-function: linear;
    will-change: width;
  }
`

const Container = styled.div`
  background: linear-gradient(to bottom, #2a3041 0%, #2a304180 100%);
  display: flex;
  align-items: center;
  padding: 7px 8px;
  padding-left: 24px;
  position: relative;
  border-radius: 12px;
`

const shadowColorMap = {
  DARKNESS: '#D51F35',
  LIGHT: '#28A1F1',
  NEUTRAL: '#5a5a5a',
}

const ProgressBarContainer = styled.div`
  position: relative;
  width: 178px;
  border: 1px solid #3c4860;
  background: #131927;
  height: 10px;
  border-radius: 12px;
  padding: 2px;
`

const ProgressBar = styled.div<{
  progress: number
  duration: Millisecond
  type: 'cast' | 'channel'
  side?: Side
}>`
  position: relative;

  &:before {
    content: '';
    --progress: ${({ progress }) => `${progress}%`};
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    border-radius: 12px;
    background: ${({ side = 'NEUTRAL' }) =>
      `linear-gradient(to right, ${sideColorMap[side].light} 0%, ${sideColorMap[side].medium} 50%,${sideColorMap[side].dark} 100%)`};
    background-size: 178px;
    animation: ${({ type }) => (type === 'cast' ? 'spellCastProgress' : 'spellChannelProgress')}
      ${({ duration }) => duration}ms forwards;
    animation-timing-function: linear;
    will-change: width;
    box-shadow: ${({ side }) =>
      side
        ? `0px 0px 12px 0px ${shadowColorMap[side]}80, 0px 0px 2px 0px ${shadowColorMap[side]}80`
        : 'none'};
  }
`

const AbilityIcon = styled(RhombusItem)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -17px;
`

const Value = styled.div<{
  durationSeconds: number
  type: 'cast' | 'channel'
}>`
  @property --castbarDuration {
    syntax: '<number>';
    initial-value: 0;
    inherits: false;
  }
  @property --castbarDurationInt {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
  }
  @property --castbarDurationDec {
    syntax: '<integer>';
    initial-value: 0;
    inherits: false;
  }

  margin-left: 10px;
  flex: 1;
  text-align: right;

  &:after {
    width: 22px;
    display: inline-block;
    animation: castbar ${({ durationSeconds }) => durationSeconds}s 1 reverse linear;
    --castbarTemp: calc(var(--castbarDuration) * 100);
    --castbarDurationInt: max(var(--castbarTemp) - 0.5, 0);
    --castbarDurationDec: max((var(--castbarTemp) - var(--castbarDurationInt)) * 10 - 0.5, 0);
    counter-reset: castbarDurationInt var(--castbarDurationInt) castbarDurationDec
      var(--castbarDurationDec);
    animation-fill-mode: forwards;

    @keyframes castbar {
      from {
        --castbarDuration: 0;
      }

      to {
        --castbarDuration: ${({ durationSeconds }) => durationSeconds / 100};
      }
    }

    content: counter(castbarDurationInt) '.' counter(castbarDurationDec);
    color: #646d85;
    font-size: 12px;
    text-shadow: 0px 1px 2px #081f2dbf;
  }
`
