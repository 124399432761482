import type { FC } from 'react'
import { useState } from 'react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import { useForm } from 'react-hook-form'
import { Button } from '~components/Button'
import { Dialog } from '~components/Dialog'
import { Input } from '../../components/Input'
import { useCompletePasswordResetMutation } from '~generated/graphql'
import { t } from '~utils/i18n'

import BgHome from '~assets/bg-home.jpg'
import BgHome2x from '~assets/bg-home_2x.jpg'
import LogoLight from '~assets/logo-light.png'
import LogoLight2x from '~assets/logo-light_2x.png'

type ResetPasswordInputs = {
  newPassword: string
  confirmPassword: string
}

export const ResetPassword: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ResetPasswordInputs>()
  const [params] = useSearchParams()
  const token = params.get('token')

  const navigate = useNavigate()

  const [error, setError] = useState('')

  const [setNewPassword] = useCompletePasswordResetMutation({
    onError: (requestError) => {
      console.error(requestError)
      setError(requestError.message)
    },
    onCompleted: (data) => {
      if (data.completePasswordReset.__typename === 'PasswordResetFailure') {
        setError(data.completePasswordReset.reason)
      } else {
        navigate('/')
      }
    },
  })

  if (!token) {
    return <Navigate replace to="/" />
  }

  const handleConfirmPasswordResetSubmit = async (formData: { newPassword: string }) => {
    await setNewPassword({ variables: { token, newPassword: formData.newPassword } })
  }

  return (
    <Container>
      <Main overlay>
        <ResetDialog relative>
          <FormLogo srcSet={`${LogoLight} 1x, ${LogoLight2x} 2x`} alt="logo" />
          <Title>{t('auth:passwordChangeTitle')}</Title>
          {t('auth:passwordRecovery.content')}
          <Form onSubmit={handleSubmit(handleConfirmPasswordResetSubmit)}>
            <Input
              register={register}
              label={t('auth:passwordRecovery.newPassword')}
              name="newPassword"
              type="password"
              autoComplete="off"
              rules={{
                minLength: { value: 6, message: t('form:minLength', { value: 6 })! },
                maxLength: { value: 30, message: t('form:maxLength', { value: 30 })! },
                required: { value: true, message: t('form:requiredField')! },
              }}
              error={errors.newPassword?.message}
            />
            <Input
              register={register}
              label={t('auth:passwordRecovery.confirmPassword')}
              name="confirmPassword"
              type="password"
              autoComplete="off"
              rules={{
                minLength: { value: 6, message: t('form:minLength', { value: 6 })! },
                maxLength: { value: 30, message: t('form:maxLength', { value: 30 })! },
                required: { value: true, message: t('form:requiredField')! },
                validate: (value, fields) =>
                  value === fields.newPassword ? undefined : t('form:rulePasswordsMustMatch')!,
              }}
              error={errors.confirmPassword?.message}
            />

            {error && <Error>{error}</Error>}
            <FormButton onClick={handleSubmit(handleConfirmPasswordResetSubmit)}>
              {t('button:save')}
            </FormButton>
          </Form>
        </ResetDialog>
      </Main>
    </Container>
  )
}

const FormButton = styled(Button)`
  margin-top: 36px;
  min-width: 182px;
`

const Form = styled.form`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const Error = styled.div`
  width: 100%;
  text-align: center;
  color: #ea4357;
  font-size: 14px;
  margin-top: 20px;
`

const FormLogo = styled.img`
  margin: auto;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  background: image-set(url(${BgHome}) 1x, url(${BgHome2x}) 2x) no-repeat center;
  background-size: cover;
  display: grid;
  place-items: center;
`

const Main = styled.div<{ overlay?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;

  &:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #131927;
    transition: opacity 0.2s;
    opacity: ${({ overlay }) => (overlay ? 0.75 : 0)};
    z-index: 0;
    pointer-events: none;
  }
`

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  margin-top: 60px;
`

const ResetDialog = styled(Dialog)`
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
