import styled from '@emotion/styled'
import type { BehaviorSubject, Observable } from 'rxjs'
import type { Race, ZoneCharacterAvatarFragment, Side } from '~generated/graphql'
import { RhombusItem } from '~components/RhombusItem'
import { TWILIGHT_ABILITY_IDS } from '~constants/map'
import type { StatusEffectsGroupFragment } from '../../views/match/model/statusEffectsGroups/CharacterStatusEffectsGroups'
import type { StatsFragment } from '../../generated/match-graphql'
import type { MainStats } from './CharacterPanelMainStats'
import { CharacterPanelMainStats } from './CharacterPanelMainStats'
import { CharacterExperienceBar } from './CharacterExperienceBar'
import type { AdvancedStats } from './CharacterAdvancedStats'
import { CharacterAdvancedStats } from './CharacterAdvancedStats'
import { CharacterStatusEffects } from './CharacterStatusEffects'
import { imageUrlMap } from '~constants/imageUrlMap'

import AvatarPanel from '~assets/bg-avatar-panel.png'
import AvatarPanel2x from '~assets/bg-avatar-panel_2x.png'
import AvatarPanelFrame from '~assets/bg-avatar-panel-frame.png'
import AvatarPanelFrame2x from '~assets/bg-avatar-panel-frame_2x.png'

type PanelCharacter = {
  firstName: string
  lastName: string
  race: Race
  side: Side
  level: number
  levelExperienceMax?: number | null
  experience: number
  statusEffectsGroups?: { subject: Observable<StatusEffectsGroupFragment[]> }
  stats: AdvancedStats & MainStats
  statsSubject?: Observable<AdvancedStats & MainStats>
  avatar?: ZoneCharacterAvatarFragment
}

type Props<T extends PanelCharacter> = {
  character?: T
}

export const CharacterPanel = <T extends PanelCharacter>({ character }: Props<T>) => {
  if (!character) return null

  const { race, side, firstName, lastName } = character

  const isHuman = race === 'HUMAN'

  return (
    <Container target="self">
      <CharacterAvatar src={character.avatar || imageUrlMap.ImgFallbackCharacter} target="self" />
      <CharacterInfo>
        <Name>
          {firstName} {lastName}
        </Name>
        {/* <GuildName>Guild name</GuildName> */}

        <CharacterExperienceBar
          level={character.level}
          experience={character.experience}
          levelExperienceMax={character.levelExperienceMax}
        />

        {character.statsSubject ? (
          <CharacterPanelMainStats
            statsObservable={character.statsSubject}
            race={race}
            side={side}
          />
        ) : null}
        {character.statusEffectsGroups?.subject ? (
          <CharacterStatusEffects
            statusEffectsGroupsSubject={character.statusEffectsGroups.subject}
          />
        ) : null}
      </CharacterInfo>
      {character.statsSubject && !isHuman ? (
        <CharacterAdvancedStats statsObservable={character.statsSubject} target="self" />
      ) : null}
    </Container>
  )
}

type TargetPanelCharacter = {
  firstName: string
  lastName: string
  race: Race
  side: Side
  level: number
  abilities?: {
    id: number
    name: string
    icon: string
  }[]
  avatar?: ZoneCharacterAvatarFragment
  statusEffectsGroups?: { subject: Observable<StatusEffectsGroupFragment[]> }
  stats: AdvancedStats & MainStats
  statsSubject?: Observable<AdvancedStats & MainStats>
}

type TargetProps<T extends TargetPanelCharacter> = {
  character?: T
  statsSubject?: BehaviorSubject<Omit<StatsFragment, '__typename'>>
  statusEffectsGroups?: StatusEffectsGroupFragment[]
}

export const TargetCharacterPanel = <T extends TargetPanelCharacter>({
  character,
}: TargetProps<T>) => {
  if (!character) return null

  const { race, side, firstName, lastName, abilities } = character
  const filteredAbilities = abilities
    ? abilities.filter(({ id }) => !TWILIGHT_ABILITY_IDS.includes(id))
    : []

  const isHuman = race === 'HUMAN'

  return (
    <Container target="target">
      <CharacterAvatar src={character.avatar || imageUrlMap.ImgFallbackCharacter} target="target" />
      <CharacterInfo>
        <Name>
          {firstName} {lastName}
        </Name>
        {/* <GuildName>Guild name</GuildName> */}
        {abilities ? (
          <AbilitiesContainer>
            {Array.from({ length: 7 }, (_, i) => i).map((_, i) => (
              <AbilityItem
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                icon={filteredAbilities[i]?.icon}
                borderColor={!filteredAbilities[i] ? '#3C4860' : undefined}
              />
            ))}
          </AbilitiesContainer>
        ) : null}
        {character.statsSubject ? (
          <CharacterPanelMainStats
            statsObservable={character.statsSubject}
            race={race}
            side={side}
          />
        ) : null}
        {character.statusEffectsGroups?.subject ? (
          <CharacterStatusEffects
            statusEffectsGroupsSubject={character.statusEffectsGroups.subject}
          />
        ) : null}
      </CharacterInfo>
      {character.statsSubject && !isHuman ? (
        <CharacterAdvancedStats statsObservable={character.statsSubject} target="target" />
      ) : null}
    </Container>
  )
}

const Container = styled.div<{ target: 'self' | 'target' }>`
  position: fixed;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  z-index: 5;
  top: calc(33px + 48px);
  color: #646d85;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 400;
  pointer-events: none;

  ${({ target }) =>
    target === 'self'
      ? `
  left: 20px;
  padding-right: 13px;
  `
      : `
  right: 10px;
  `}
`

const Name = styled.div`
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-left: 14px;
  margin-bottom: 8px;
`

const CharacterInfo = styled.div`
  order: 2;
  margin-top: 16px;
  min-width: 219px;
`

const CharacterAvatar = styled.div<{
  src?: Pick<ZoneCharacterAvatarFragment, 'halfBody' | 'halfBody2x'>
  target: 'self' | 'target'
}>`
  order: ${({ target }) => (target === 'target' ? 2 : 1)};
  width: 160px;
  height: 160px;
  margin-right: 10px;
  flex-shrink: 0;
  position: relative;
  background: image-set(url('${AvatarPanelFrame}') 1x, url('${AvatarPanelFrame2x}') 2x) no-repeat;

  &:before {
    content: '';
    position: absolute;
    width: 170px;
    height: 170px;
    bottom: 0px;
    left: -5px;
    z-index: -1;
    background: ${({ src }) =>
        src ? `image-set(url("${src.halfBody}") 1x, url("${src.halfBody2x}") 2x)` : `none`}
      center calc(100% - 3px) no-repeat;
    clip-path: ellipse(52% 63% at 50% 36%);
  }

  &:after {
    content: '';
    position: absolute;
    left: ${({ target }) => (target === 'target' ? '4px' : '-4px')};
    bottom: 5px;
    width: 100%;
    height: 163px;
    background: image-set(url('${AvatarPanel}') 1x, url('${AvatarPanel2x}') 2x) center bottom
      no-repeat;
    z-index: -2;
    ${({ target }) => target === 'target' && 'transform: scale(-1, 1);'}
  }
`

const AbilitiesContainer = styled.div`
  display: flex;
  margin-left: 14px;
  margin-bottom: 9px;
  margin-top: 15px;
`

const AbilityItem = styled(RhombusItem)`
  margin-right: 3px;
`
