import { HttpLink, ApolloClient, InMemoryCache, from, split, ApolloLink } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { getMainDefinition } from '@apollo/client/utilities'
import generatedIntrospection from './generated/fragment-matcher'
import matchGeneratedIntrospection from './generated/match-fragment-matcher'
import { environment } from './environments/environment'

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

const httpLink = new HttpLink({ uri: environment.HTTP_API_URL, credentials: 'include' })
const wsWorldLink = new GraphQLWsLink(createClient({ url: environment.WS_API_URL }))

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
  },
  wsWorldLink,
  httpLink,
)

export const client = new ApolloClient({
  queryDeduplication: false,
  cache: new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    typePolicies: {
      Session: {
        keyFields: ['userId'],
      },
    },
  }),
  link: from([errorLink, splitLink]),
})

type MatchAccess = {
  host: string
  port: number
  token: string
}

export const getWsClient = ({ host, port, token }: MatchAccess) => {
  const wsLink = new GraphQLWsLink(
    createClient({
      url: `ws${host === 'localhost' ? '' : 's'}://${host}:${port}/graphql`,
      connectionParams: {
        authorization: `Bearer ${token}`,
      },
    }),
  )

  return new ApolloClient({
    queryDeduplication: false,
    cache: new InMemoryCache({ possibleTypes: matchGeneratedIntrospection.possibleTypes }),
    link: from([errorLink, wsLink]),
  })
}
