import type { InkObject } from 'inkjs/engine/Object'
import type { VariablesState } from 'inkjs/engine/VariablesState'
import type { MarkerIconType } from '~components/map/MapMarker'
import type { Point, Side, Race } from '~shared-types'

export type StoryTagTarget = StoryTagMarker & {
  actionLabel?: string
}

export type StoryTagMarker = {
  name?: string
  position: Point
  description?: string
  address?: string
  side?: Side
  race?: Race
  stats?: {
    health?: number
    healthMax?: number
    energy?: number
    energyMax?: number
  }
  avatar?: {
    headShot?: string
    headShot2x?: string
  }
  icon?: MarkerIconType
  city?: boolean
}

export type StoryTagSpeaker = {
  name?: string
  side?: Side
  race?: Race
  avatar?: {
    fullBody?: string
    fullBody2x?: string
  }
}

export type StoryTagListener = {
  id: string
  avatar?: {
    fullBody?: string
    fullBody2x?: string
  }
}

export type StoryTagBackground = {
  url?: string
  positionX?: string
  positionY?: string
  blur?: boolean
}

export type StoryTagHint = {
  text: string
}

export type StoryChoiceTags = Pick<
  StoryTags,
  | 'showWhenPosition'
  | 'showWhenPositionRadius'
  | 'selectWhenPosition'
  | 'selectWhenSide'
  | 'selectWhenRace'
>
export type StoryTextTags = Pick<
  StoryTags,
  | 'background'
  | 'targets'
  | 'markers'
  | 'speaker'
  | 'listeners'
  | 'hideDialog'
  | 'isAction'
  | 'showWhenPosition'
  | 'showWhenPositionRadius'
  | 'goNextWhenPositionRadius'
  | 'goNextWhenPosition'
  | 'goNextWhenSide'
  | 'goNextWhenRace'
  | 'openScreen'
  | 'hint'
>

export class StoryTags {
  public background?: StoryTagBackground
  public targets: StoryTagTarget[]
  public markers: StoryTagMarker[]
  public speaker?: StoryTagSpeaker
  public listeners: StoryTagListener[]
  public showWhenPosition?: Point
  public showWhenPositionRadius?: number
  public goNextWhenPositionRadius?: number
  public goNextWhenPosition?: Point
  public goNextWhenSide?: Side[]
  public goNextWhenRace?: Race[]
  public selectWhenPosition?: Point
  public selectWhenSide?: Side[]
  public selectWhenRace?: Race[]
  public openScreen?: string
  public hideDialog?: boolean
  public isAction?: boolean
  public hint?: StoryTagHint

  constructor(tags: string[] | null, variablesState: VariablesState) {
    this.targets = []
    this.markers = []
    this.listeners = []

    tags
      ?.map((tag): [string, string | undefined] => {
        const index = tag.indexOf(':')
        return index !== -1 ? [tag.slice(0, index), tag.slice(index + 1).trim()] : [tag, undefined]
      })
      .forEach(([tagName, tagValue]: [string, string | undefined]) => {
        if (tagValue?.startsWith('_')) {
          const varValue = (
            variablesState.GetVariableWithName(tagValue) as (InkObject & { value: string }) | null
          )?.value
          if (!varValue) {
            console.error(`Variable ${tagValue} not found`)
          } else {
            // eslint-disable-next-line no-param-reassign
            tagValue = varValue
          }
        }
        if (tagName === 'background' && tagValue) {
          try {
            const background = JSON.parse(tagValue)
            this.background = { ...(this.background ?? {}), ...background }
          } catch (error) {
            console.error(`Failed to parse tag ${tagName}, value ${tagValue}`, error)
          }
        }
        if (tagName === 'blur') {
          this.background = { ...(this.background ?? {}), blur: true }
        }
        if (tagName === 'hide') {
          this.hideDialog = true
        }
        if (tagName === 'action') {
          this.isAction = true
        }
        if (tagName === 'hint' && tagValue) {
          try {
            const hint = JSON.parse(tagValue)
            this.hint = hint
          } catch (error) {
            console.error(`Failed to parse tag ${tagName}, value ${tagValue}`, error)
          }
        }
        if (tagName === 'target' && tagValue) {
          try {
            const target = JSON.parse(tagValue)
            this.targets.push({ ...(this.targets.at(-1) ?? {}), ...target })
          } catch (error) {
            console.error(`Failed to parse tag ${tagName}, value ${tagValue}`, error)
          }
        }
        if (tagName === 'marker' && tagValue) {
          try {
            const marker = JSON.parse(tagValue)
            this.markers.push(marker)
          } catch (error) {
            console.error(`Failed to parse tag ${tagName}, value ${tagValue}`, error)
          }
        }
        if (tagName === 'listener' && tagValue) {
          try {
            const listener = JSON.parse(tagValue)
            this.listeners.push({ id: Math.random().toString(), ...listener })
          } catch (error) {
            console.error(`Failed to parse tag ${tagName}, value ${tagValue}`, error)
          }
        }
        if (tagName === 'speaker' && tagValue) {
          try {
            const speaker = JSON.parse(tagValue)
            this.speaker = speaker
          } catch (error) {
            console.error(`Failed to parse tag ${tagName}, value ${tagValue}`, error)
          }
        }
        if (tagName === 'openScreen' && tagValue) {
          this.openScreen = tagValue
        }
        if (['goNextWhenSide', 'selectWhenSide'].includes(tagName) && tagValue) {
          const gameSides = ['DARKNESS', 'LIGHT', 'NEUTRAL'] satisfies Side[]
          try {
            const sides = JSON.parse(tagValue)
            if (Array.isArray(sides) && sides.every((side) => gameSides.includes(side))) {
              if (tagName === 'goNextWhenSide') {
                this.goNextWhenSide = [...(this.goNextWhenSide ?? []), ...sides]
              } else {
                this.selectWhenSide = [...(this.selectWhenSide ?? []), ...sides]
              }
            } else {
              throw new Error('Invalid side value')
            }
          } catch (error) {
            if (typeof tagValue === 'string' && gameSides.includes(tagValue as Side)) {
              if (tagName === 'goNextWhenSide') {
                this.goNextWhenSide = [...(this.goNextWhenSide ?? []), tagValue as Side]
              } else {
                this.selectWhenSide = [...(this.selectWhenSide ?? []), tagValue as Side]
              }
            } else {
              console.error(`Failed to parse tag ${tagName}, value ${tagValue}`, error)
            }
          }
        }
        if (['goNextWhenRace', 'selectWhenRace'].includes(tagName) && tagValue) {
          const gameRaces = [
            'CLAIRVOYANT',
            'HUMAN',
            'MAGICIAN',
            'SHAPESHIFTER_CAT_LIKE',
            'SHAPESHIFTER_DOG_LIKE',
            'VAMPIRE',
            'WEREWOLF',
            'WITCH',
          ] satisfies Race[]
          try {
            const races = JSON.parse(tagValue)
            if (Array.isArray(races) && races.every((race) => gameRaces.includes(race as Race))) {
              if (tagName === 'goNextWhenRace') {
                this.goNextWhenRace = [...(this.goNextWhenRace ?? []), ...races]
              } else {
                this.selectWhenRace = [...(this.selectWhenRace ?? []), ...races]
              }
            } else {
              throw new Error('Invalid race value')
            }
          } catch (error) {
            if (typeof tagValue === 'string' && gameRaces.includes(tagValue as Race)) {
              if (tagName === 'goNextWhenRace') {
                this.goNextWhenRace = [...(this.goNextWhenRace ?? []), tagValue as Race]
              } else {
                this.selectWhenRace = [...(this.selectWhenRace ?? []), tagValue as Race]
              }
            } else {
              console.error(`Failed to parse tag ${tagName}, value ${tagValue}`, error)
            }
          }
        }

        if (tagName === 'showWhenPositionRadius' && !Number.isNaN(Number(tagValue))) {
          this.showWhenPositionRadius = Number(tagValue)
        }
        if (tagName === 'goNextWhenPositionRadius' && !Number.isNaN(Number(tagValue))) {
          this.goNextWhenPositionRadius = Number(tagValue)
        }
        if (
          [
            'showWhenPosition',
            'goNextWhenPosition',
            'selectWhenPosition',
            'targetPosition',
          ].includes(tagName) &&
          tagValue
        ) {
          try {
            const coordinates = JSON.parse(tagValue)
            if (this.isPoint(coordinates)) {
              if (tagName === 'showWhenPosition') {
                this.showWhenPosition = coordinates
              }
              if (tagName === 'targetPosition') {
                this.targets.push({ ...(this.targets.at(-1) ?? {}), position: coordinates })
              }
              if (tagName === 'goNextWhenPosition') {
                this.goNextWhenPosition = coordinates
              }
              if (tagName === 'selectWhenPosition') {
                this.selectWhenPosition = coordinates
              }
            } else if ('position' in coordinates && this.isPoint(coordinates.position)) {
              if (tagName === 'showWhenPosition') {
                this.showWhenPosition = coordinates.position
              }
              if (tagName === 'goNextWhenPosition') {
                this.goNextWhenPosition = coordinates.position
              }
              if (tagName === 'selectWhenPosition') {
                this.selectWhenPosition = coordinates.position
              }
              if (tagName === 'targetPosition') {
                this.targets.push({
                  ...(this.targets.at(-1) ?? {}),
                  position: coordinates.position,
                })
              }
            } else {
              throw new Error('invalid coordinate')
            }
          } catch (error) {
            console.error(`Failed to parse quest coordinate, tag ${tagName} ${tagValue}`, error)
          }
        }
      })
  }

  private isPoint(point: unknown): point is Point {
    return (
      point !== undefined &&
      Array.isArray(point) &&
      point.length === 2 &&
      typeof point[0] === 'number' &&
      typeof point[1] === 'number'
    )
  }
}
