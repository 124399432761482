import { useEffect } from 'react'

const preventDefault = (ev: Event) => {
  ev.preventDefault()
}

export const useDisableRightClick = () => {
  useEffect(() => {
    window.addEventListener('contextmenu', preventDefault)
    return () => {
      window.removeEventListener('contextmenu', preventDefault)
    }
  }, [])
}
