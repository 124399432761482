import 'reflect-metadata'
import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Subject } from 'rxjs'
import type { Race, Second, Side } from '~shared-types'
import {
  animationDurationMap,
  animationIterationCountMap,
  animationSpriteCountMap,
  animationSpriteUrlMap,
  animationSizeMap,
} from '~utils/animation'
import { useValueFromObservable } from '~components/quest/useValueFromObservable'

type Props = {
  side: Side
  race: Race
  id: string
  sizeObservable: Subject<number>
  motionDuration?: Second
}

export const AbilityAttackRangedAnimationUI: FC<Props> = ({
  race,
  side,
  sizeObservable,
  id,
  motionDuration,
}) => {
  const sprite = animationSpriteUrlMap[side]?.[race]?.ATTACK_RANGED
  const size = useValueFromObservable(sizeObservable)

  return (
    <Container height={size} width={animationSizeMap.ATTACK_RANGED}>
      <AnimationContainer
        duration={animationDurationMap.ATTACK_RANGED}
        motionDuration={motionDuration}
        count={animationSpriteCountMap.ATTACK_RANGED}
        size={animationSizeMap.ATTACK_RANGED}
        spriteUrl={sprite}
        id={id}
        iterationCount={animationIterationCountMap.ATTACK_RANGED}
      />
    </Container>
  )
}

const Container = styled.div<{
  width: number
  height: number
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin-top: -${({ height }) => height}px;
  position: relative;
  pointer-events: none;
`

const AnimationContainer = styled.div<{
  duration: Second
  count: number
  spriteUrl?: string
  id: string
  size?: number
  delay?: Second
  iterationCount: 'infinite' | number
  motionDuration?: Second
}>`
  background: url(${({ spriteUrl }) => spriteUrl || ''}) no-repeat;
  background-size: ${({ count }) => count * 100}%;
  background-position-y: center;
  background-repeat: no-repeat;

  width: ${({ size = 0 }) => size}px;
  height: ${({ size = 0 }) => size}px;
  transform: scaleY(-1);
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -${({ size = 0 }) => size / 2}px;
  opacity: 0;

  animation-name: ${({ id }) => `ability-animation-${id}`},
    ${({ id }) => `ability-animation-${id}-move`};
  animation-duration: ${({ duration }) => duration}s, ${({ motionDuration }) => motionDuration}s;
  animation-timing-function: ${({ count }) => `steps(${count})`}, linear;
  animation-iteration-count: ${({ iterationCount }) => iterationCount}, 1;
  animation-fill-mode: forwards;
  animation-delay: ${({ delay = 0 }) => delay}s, 0s;
  transition: bottom 0.1s opacity 0.1s;
  will-change: background-position-x;

  @keyframes ${({ id }) => `ability-animation-${id}`} {
    0% {
      background-position-x: 0;
    }

    100% {
      background-position-x: -${({ size = 0, count }) => size * count}px;
    }
  }

  @keyframes ${({ id }) => `ability-animation-${id}-move`} {
    0% {
      bottom: 0;
      opacity: 1;
    }

    99% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      bottom: calc(100% - ${({ size = 0 }) => size}px);
    }
  }
`
