import styled from '@emotion/styled/macro'
import { forwardRef, Fragment } from 'react'
import type { FC } from 'react'
import { RhombusItem } from '~components/RhombusItem'
import withTooltip from '../Tooltip/withTooltip'
import { CharacterStatusEffectsGroup } from '../../views/match/model/statusEffectsGroups/CharacterStatusEffectsGroup'
import { AnimatedSecondsCooldown } from '../AnimatedSecondsCooldown'

type Props = {
  effectGroup: {
    id: string
    __typename?: string
    duration?: string
    startAt: string
    statusEffects: {
      description: string
    }[]
  }
  ability: {
    name: string
    icon: string
    icon2x: string
  }
}

type Ref = HTMLDivElement

export const EffectItem: FC<Props> = forwardRef<Ref, Props>(
  ({ effectGroup, ability, ...props }, ref) => {
    const EffectItemWithTooltip = withTooltip(
      Wrapper,
      {
        type: 'text',
        content: (
          <>
            <TooltipTitle>{ability.name}</TooltipTitle>
            <TooltipDescription>
              {effectGroup.statusEffects.map((effect, i) => (
                <Fragment key={i}>
                  {effect.description}
                  {i !== effectGroup.statusEffects.length - 1 ? <br /> : null}
                </Fragment>
              ))}
            </TooltipDescription>
          </>
        ),
      },
      {
        maxWidth: 250,
        placement: 'bottom',
      },
    )

    const remainingDuration = effectGroup.duration
      ? CharacterStatusEffectsGroup.getRemainingDuration({
          startAt: effectGroup.startAt,
          duration: effectGroup.duration,
        })
      : 'INFINITE'
    return (
      <EffectItemWithTooltip ref={ref} {...props}>
        <RhombusItem
          icon={{ icon: ability.icon, icon2x: ability.icon2x }}
          size={26}
          borderSize={2}
        />
        {remainingDuration !== 'INFINITE' && remainingDuration !== 'COMPLETED' ? (
          <Time>
            <AnimatedSecondsCooldown
              animationName={effectGroup.id}
              cooldown={remainingDuration / 1000}
            />
          </Time>
        ) : null}
      </EffectItemWithTooltip>
    )
  },
)

const Wrapper = styled.div`
  margin-right: 3px;
`

const Time = styled.div`
  margin-top: 4px;
  text-align: center;
  font-family: Overpass;
  font-size: 12px;
  font-weight: 600;
`

const TooltipTitle = styled.div`
  font-weight: 600;
  color: #fff;
  margin-bottom: 4px;
`

const TooltipDescription = styled.div``
