import { useStore } from '~store'
import { Profile } from './Profile/Profile'
import { City } from './City/City'
import { Masteries } from './Masteries/Masteries'
import { Settings } from './Settings/Settings'
import { ChooseRace } from './ChooseRace/ChooseRace'
import { ChooseSide } from './ChooseSide/ChooseSide'

export const Fullscreen = () => {
  const [fullscreenId, setFullscreenId, currentCharacter] = useStore((state) => [
    state.fullscreenId,
    state.setFullscreenId,
    state.currentCharacter,
  ])

  if (!currentCharacter) {
    return null
  }

  switch (fullscreenId) {
    case 'profile':
      return <Profile characterId={currentCharacter.id} race={currentCharacter.race} />
    case 'cityMap':
      return <City />
    case 'masteries':
      return <Masteries onClose={() => setFullscreenId(null)} />
    case 'settings':
      return <Settings />
    case 'race':
      return <ChooseRace />
    case 'side':
      return <ChooseSide />
    default:
      return null
  }
}
