import styled from '@emotion/styled'
import { forwardRef } from 'react'
import type { HTMLAttributes, MouseEventHandler, PropsWithChildren } from 'react'

type Props = {
  size?: number
  borderSize?: number
  borderColor?: string
  icon?: string | { icon: string; icon2x: string }
  id?: string
  className?: string
} & HTMLAttributes<HTMLDivElement>

const calculateRotatedSize = (width: number, height: number, angle: number) => {
  const rad = (angle * Math.PI) / 180
  const sin = Math.sin(rad)
  const cos = Math.cos(rad)

  const newWidth = Math.round(Math.abs(width * cos) + Math.abs(height * sin))
  const newHeight = Math.round(Math.abs(width * sin) + Math.abs(height * cos))

  return { newWidth, newHeight }
}

export type Ref = HTMLDivElement

export const RhombusItem = forwardRef<Ref, PropsWithChildren<Props>>(
  (
    { icon, size = 18, borderSize = 1, borderColor, id, className, children, style, ...props },
    ref,
  ) => (
    <Wrapper size={size} id={id} className={className} {...props} ref={ref}>
      <Container size={size} borderSize={borderSize} borderColor={borderColor}>
        <IconContainer size={size} url={icon} />
        {children}
      </Container>
    </Wrapper>
  ),
)

const Wrapper = styled.div<{ size: number; onClick?: MouseEventHandler<HTMLDivElement> }>`
  position: relative;
  width: ${({ size }) => calculateRotatedSize(size, size, 45).newWidth}px;
  height: ${({ size }) => calculateRotatedSize(size, size, 45).newHeight}px;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  flex-shrink: 0;
`

const IconContainer = styled.div<{
  size: number
  url?: string | { icon: string; icon2x: string }
}>`
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: ${({ url }) => (url ? '#19051f' : '#131927')};

  &:before {
    content: '';
    position: absolute;
    left: -25%;
    top: -25%;
    transform: rotate(-45deg);
    height: ${({ size }) => (size === 24 ? 32 : size * 1.25)}px;
    width: ${({ size }) => (size === 24 ? 32 : size * 1.25)}px;
    background: ${({ url }) =>
        url
          ? typeof url === 'string'
            ? `url("${url}")`
            : `image-set(url("${url.icon}") 1x, url("${url.icon2x}") 2x)`
          : 'none'}
      left top no-repeat;
    background-size: ${({ size }) => (size === 24 ? 32 : size * 1.25)}px;
    display: ${({ url }) => (url ? 'block' : 'none')};
  }
`

const Container = styled.div<{ size: number; borderSize: number; borderColor?: string }>`
  z-index: 1;
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  padding: ${({ borderSize }) => borderSize}px;
  transform: rotate(45deg);
  transform-origin: left top;
  left: 50%;
  background: ${({ borderColor }) =>
    borderColor ||
    'linear-gradient(331deg, #808080 0%, #ada08a 30.73%, #d9d1bb 69.27%, #eae4cf 100%)'};
  box-shadow: 1px 3px 12px 0px rgb(0 0 0 / 37%);
  /* transition: background 0.2s; */

  > *:not(${IconContainer}) {
    transform: rotate(-45deg);
  }
`
