import type { Map } from 'maplibre-gl'
import { timer } from 'rxjs'
import type { CharacterEntity } from '../CharacterEntity'
import { AnimationMarker } from './AnimationMarker'
import type { MatchCharacterAbilityWithAnimation } from './CastedAbilityAnimation'
import { animationDurationMap, locationSpriteOffset } from '~utils/animation'
import type { Point } from '~shared-types'
import { convertMetersToPixels } from '~utils/map'
import { AbilityAnimationUI } from '../../components/animation/AbilityAnimationUI'

export class AttackLocationAnimation {
  constructor(
    map: Map,
    caster: CharacterEntity,
    target: CharacterEntity | Point,
    castedAbility: MatchCharacterAbilityWithAnimation,
  ) {
    const range =
      castedAbility.shape?.__typename === 'AbilityLocationShapeCircle'
        ? castedAbility.shape.radius * 2
        : 0
    const rangePx = Math.round(convertMetersToPixels(map, range))

    const attackComponent = (
      <AbilityAnimationUI
        side={caster.side}
        race={caster.race}
        range={rangePx + locationSpriteOffset}
        animationType="ATTACK_LOCATION"
        id={`${caster.id}${castedAbility.id}${Date.now()}ATTACK_LOCATION`}
      />
    )
    const attackMarker = new AnimationMarker(
      map,
      attackComponent,
      'firstName' in target ? target.position : target,
    )

    const subscription = timer(animationDurationMap.ATTACK_LOCATION * 1000).subscribe(() => {
      attackMarker.remove()
      subscription.unsubscribe()
    })
  }
}
