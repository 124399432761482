import 'reflect-metadata'
import styled from '@emotion/styled'
import type { PropsWithChildren, FC } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { Header } from '~components/Header/Header'
import { BottomMenu } from '~components/BottomMenu'
import Zone from './views/zone/Zone'
import { Auth } from './views/auth/Auth'
import { useDisableRightClick } from './hooks/useDisableRightClick'
import { GlobalStyles } from './GlobalStyles'
import { useSession } from './hooks/useSession'
import { Match } from './views/match/Match'
import { CreateCharacter } from './views/createCharacter/CreateCharacter'
import { ResetPassword } from './views/resetPassword/ResetPassword'
import { Fullscreen } from './views/fullscreens/Fullscreen'
import { Dialogs } from '~components/dialogs/Dialogs'

const App: FC = () => {
  useDisableRightClick()
  const { data, loading, error } = useSession()

  if (loading && !data) {
    return <div>Loading</div>
  }

  if (data?.session.character?.zoneId === undefined || error) {
    return (
      <>
        <GlobalStyles />
        <Root>
          <Routes>
            <Route path="/" element={<Auth />} />
            <Route path="/create-character" element={<CreateCharacter />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="*" element={<Navigate replace to="/" />} />
          </Routes>
        </Root>
      </>
    )
  }

  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route
          path="/"
          element={
            <WithHeaderAndMenu>
              {!data.session.matchAccess ? <Zone /> : <Navigate replace to="/match" />}
            </WithHeaderAndMenu>
          }
        />
        <Route
          path="/match"
          element={
            <WithHeaderAndMenu>
              {data.session.matchAccess ? <Match /> : <Navigate replace to="/" />}
            </WithHeaderAndMenu>
          }
        />
        <Route path="*" element={<Navigate replace to="/" />} />
      </Routes>
    </>
  )
}

const WithHeaderAndMenu: FC<PropsWithChildren> = ({ children }) => (
  <RootWithHeader>
    <Dialogs />
    <Fullscreen />
    <Header />
    <Main>
      {children}
      <BottomMenu />
    </Main>
  </RootWithHeader>
)

const Root = styled.div`
  width: 100vw;
  height: 100vh;
  user-select: none;
`

const RootWithHeader = styled(Root)`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-rows: 48px auto;
`
const Main = styled.main`
  position: relative;
`

export default App
