import { memo, useEffect, useMemo, useState } from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import { finalize, takeWhile, timer } from 'rxjs'
import type { MarkerAvatarFragment } from '~generated/graphql'
import type { LastingCrowdControlEffect } from '~generated/match-graphql'
import { CharacterTwilightRelation } from '~map-tools'
import type { Millisecond, Side, Race } from '~shared-types'
import type { CrowdControl } from '../../../views/match/model/statusEffectsGroups/CharacterStatusEffectsGroup'

import IconCharacterNotReachable from '~assets/icon-character-not-reachable.jpg'
import IconCharacterNotReachable2x from '~assets/icon-character-not-reachable_2x.jpg'
import { ReactComponent as IconSkull } from '~assets/icon-skull.svg'
import IconControlStun from '~assets/icon-control-stun.svg'
import { imageUrlMap } from '~constants/imageUrlMap'

type Props = {
  race?: Race
  side?: Side
  characterAvatar?: Partial<MarkerAvatarFragment>
  className?: string
  castedAbilityImageUrl?: string
  twilightRelation?: CharacterTwilightRelation
  crowdControls?: CrowdControl[]
  alive?: boolean
}

const controlImageMap: Partial<Record<LastingCrowdControlEffect, string>> = {
  STUN: IconControlStun,
  SILENCE: IconControlStun,
  ROOT: IconControlStun,
  TAUNT: IconControlStun,
  FEAR: IconControlStun,
  SLEEP: IconControlStun,
  KNOCKBACK: IconControlStun,
}

export const MarkerIconUI: FC<Props> = memo(
  ({
    side,
    characterAvatar,
    className,
    castedAbilityImageUrl,
    twilightRelation = CharacterTwilightRelation.REACHABLE,
    crowdControls = [],
    alive = true,
  }) => {
    const longestCC = useMemo(
      () =>
        crowdControls
          .filter((control) => control.type !== 'INSIGHT')
          .sort((a, b) => (b.endAt ?? 0) - (a.endAt ?? 0))
          .at(0),
      [crowdControls],
    )
    const [controlDuration, setControlDuration] = useState<
      { id: Millisecond; duration: Millisecond } | undefined
    >(
      longestCC?.endAt
        ? { id: longestCC.endAt, duration: longestCC.endAt - Date.now() }
        : undefined,
    )
    useEffect(() => {
      if (!longestCC?.endAt) {
        setControlDuration(undefined)
        return
      }
      const remainingDuration = longestCC.endAt - Date.now()
      if (remainingDuration <= 0) {
        return
      }

      const remainingSeconds = remainingDuration / 1000
      const fullSeconds = Math.floor(remainingSeconds)
      const millis = remainingDuration - fullSeconds * 1000
      timer(millis, 1000)
        .pipe(
          takeWhile(() => Date.now() < longestCC.endAt!),
          finalize(() =>
            setControlDuration((currentValue) =>
              currentValue?.id === longestCC.endAt ? undefined : currentValue,
            ),
          ),
        )
        .subscribe(() => {
          setControlDuration((currentValue) => {
            if (currentValue && currentValue.id === longestCC.endAt) {
              return { ...currentValue, duration: currentValue.duration - 1 }
            }
            return currentValue
          })
        })
      setControlDuration({
        id: longestCC.endAt,
        duration: Math.ceil(remainingSeconds),
      })
    }, [longestCC])

    return (
      <Icon className={className} twilightRelation={twilightRelation} side={side} alive={alive}>
        <Image
          overwriteSrc={castedAbilityImageUrl}
          src={characterAvatar?.headShot}
          src2x={characterAvatar?.headShot2x ?? characterAvatar?.headShot}
          twilightRelation={twilightRelation}
          alive={alive}
        >
          {!alive ? <IconSkull /> : null}
          {longestCC?.type !== undefined ? (
            <ControlIcon>
              <ControlIconCooldown>
                {controlDuration && controlDuration.duration > 0 ? controlDuration.duration : null}
              </ControlIconCooldown>
              <img src={controlImageMap.STUN} alt="control-effect" />
            </ControlIcon>
          ) : null}
        </Image>
      </Icon>
    )
  },
)

const Image = styled.div<{
  overwriteSrc?: string
  src?: string
  src2x?: string
  twilightRelation: CharacterTwilightRelation
  alive: boolean
}>`
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  width: 100%;
  height: 100%;
  position: relative;

  > * {
    z-index: 2;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${({ twilightRelation, overwriteSrc, src, src2x }) => {
        const image =
          twilightRelation === CharacterTwilightRelation.NOT_VISIBLE
            ? `image-set(url("${IconCharacterNotReachable}") 1x, url("${IconCharacterNotReachable2x}") 2x)`
            : overwriteSrc
            ? `url("${overwriteSrc}")`
            : src2x
            ? `image-set(url("${src}") 1x, url("${src2x}") 2x)`
            : src
            ? `url(${src})`
            : undefined
        return image
          ? `${image}, linear-gradient(to bottom, #131927, #2A3041)`
          : `image-set(url("${imageUrlMap.ImgFallbackCharacter.headShot}") 1x, url("${imageUrlMap.ImgFallbackCharacter.headShot2x}") 2x), linear-gradient(to bottom, #131927, #2A3041)`
      }}
      center no-repeat;
    background-size: contain;
    filter: ${({ twilightRelation, alive }) =>
      twilightRelation === CharacterTwilightRelation.VISIBLE || !alive
        ? 'grayscale(100%)'
        : 'none'};
  }

  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #131927;
    opacity: 0.5;
    background-size: contain;
    z-index: 1;
    display: ${({ twilightRelation, alive }) =>
      twilightRelation === CharacterTwilightRelation.ONE_BELOW_REACHABLE || !alive
        ? 'block'
        : 'none'};
  }
`

const Icon = styled.div<{
  frameSrc?: string
  twilightRelation: CharacterTwilightRelation
  side?: Side
  alive: boolean
}>`
  position: relative;
  z-index: 2;
  width: 72px;
  height: 72px;
  flex-shrink: 0;
  padding: 4px;
  border-radius: 50%;
  background: ${({ alive }) =>
    alive
      ? `linear-gradient(
    331deg,
    #808080 11.11%,
    #ada08a 33.94%,
    #d9d1bb 62.58%,
    #eae4cf 85.42%
  )`
      : '#646D85'};

  &:before {
    content: '';
    position: absolute;
    left: 4px;
    top: 4px;
    right: 4px;
    bottom: 4px;
    background: ${({ side }) =>
      side ? (side === 'DARKNESS' ? '#6B222F' : '#11337D') : 'transparent'};
    opacity: 0.5;
    z-index: 2;
    border-radius: 50%;
    display: ${({ twilightRelation }) =>
      twilightRelation === CharacterTwilightRelation.VISIBLE ? 'block' : 'none'};
  }

  &:after {
    content: '';
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #131927;
    opacity: 0.5;
    background-size: contain;
    z-index: 1;
    display: ${({ twilightRelation }) =>
      [CharacterTwilightRelation.VISIBLE, CharacterTwilightRelation.NOT_VISIBLE].includes(
        twilightRelation,
      )
        ? 'block'
        : 'none'};
  }
`

const ControlIcon = styled.div`
  padding: 3px;
  position: relative;

  > img {
    z-index: 2;
    position: relative;
    filter: drop-shadow(0 0 8px #0080ebbf) drop-shadow(0 0 24px #0080eb);
  }

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    background: #131927b0;
    filter: blur(4px);
  }
`

const ControlIconCooldown = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
  text-shadow: 0px 1px 2px #081f2dbf;
  z-index: 4;
  background: radial-gradient(circle, #00000085 0%, transparent 40%);
`
