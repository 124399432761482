import 'reflect-metadata'
import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Subject } from 'rxjs'
import type { Race, Second, Side } from '~shared-types'
import {
  animationDurationMap,
  animationIterationCountMap,
  animationSpriteCountMap,
  animationSpriteUrlMap,
  animationSizeMap,
  animationTransformMap,
} from '~utils/animation'
import { useValueFromObservable } from '~components/quest/useValueFromObservable'

type Props = {
  side: Side
  race: Race
  id: string
  sizeObservable: Subject<number>
  // motionDuration?: Second
}

export const AbilityChannelAnimationUI: FC<Props> = ({
  race,
  side,
  sizeObservable,
  id,
  // motionDuration,
}) => {
  const sprite = animationSpriteUrlMap[side]?.[race]?.CHANNEL
  const size = useValueFromObservable(sizeObservable)

  return (
    <Container height={size} width={animationSizeMap.CHANNEL}>
      <AnimationContainer
        duration={animationDurationMap.CHANNEL}
        // motionDuration={motionDuration}
        count={animationSpriteCountMap.CHANNEL}
        height={animationSizeMap.CHANNEL}
        width={size}
        spriteUrl={sprite}
        id={id}
        iterationCount={animationIterationCountMap.CHANNEL}
      />
    </Container>
  )
}

const Container = styled.div<{
  width: number
  height: number
}>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin-top: -${({ height }) => height}px;
  position: relative;
  pointer-events: none;
  overflow: hidden;
`

const minWidth = 250

const AnimationContainer = styled.div<{
  duration: Second
  count: number
  spriteUrl?: string
  id: string
  width?: number
  height?: number
  delay?: Second
  iterationCount: 'infinite' | number
  // motionDuration?: Second
}>`
  background: url(${({ spriteUrl }) => spriteUrl || ''}) no-repeat;
  animation-name: ${({ id }) => `ability-channel-animation-${id}`};
  animation-duration: ${({ duration }) => duration}s;
  animation-timing-function: ${({ count }) => `steps(${count})`};
  animation-iteration-count: ${({ iterationCount }) => iterationCount};
  animation-fill-mode: forwards;
  animation-delay: ${({ delay = 0 }) => delay}s, 0s;
  background-size: 1600px;
  background-position-y: center;
  background-repeat: repeat-x;
  opacity: 0;
  will-change: background-position-x;
  /* min-width: ${minWidth}px; */
  width: ${({ width = 0 }) => width}px;
  height: ${({ height = 0 }) => height}px;
  transition: opacity 0.1s;
  transform: rotate(90deg);
  position: absolute;
  bottom: 50%;
  margin-bottom: -${({ height = 0 }) => height / 2}px;
  left: 50%;
  margin-left: -${({ width = 0 }) => width / 2}px;

  @keyframes ${({ id }) => `ability-channel-animation-${id}`} {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      background-position-x: -${({ height = 0, count }) => height * count}px;
    }
  }
`

type SourceProps = {
  side: Side
  race: Race
  id: string
}

export const AbilityChannelSourceAnimationUI: FC<SourceProps> = ({ race, side, id }) => {
  const sprite = animationSpriteUrlMap[side]?.[race]?.CHANNEL_SOURCE

  return (
    <SourceContainer size={animationSizeMap.CHANNEL_SOURCE}>
      <SourceAnimationContainer
        duration={animationDurationMap.CHANNEL_SOURCE}
        countStart={4}
        count={4}
        size={animationSizeMap.CHANNEL_SOURCE}
        spriteUrl={sprite}
        id={id}
        iterationCount={animationIterationCountMap.CHANNEL_SOURCE}
        transform={animationTransformMap.CHANNEL_SOURCE}
      />
    </SourceContainer>
  )
}

const SourceContainer = styled.div<{
  size?: number
}>`
  width: ${({ size = 0 }) => size}px;
  height: ${({ size = 0 }) => size}px;
  position: relative;
  pointer-events: none;
`

const SourceAnimationContainer = styled.div<{
  duration: Second
  count: number
  countStart: number
  spriteUrl?: string
  id: string
  size?: number
  delay?: Second
  iterationCount: 'infinite' | number
  transform?: string
}>`
  background: url(${({ spriteUrl }) => spriteUrl || ''}) no-repeat;
  animation-name: ${({ id }) => `ability-animation-start-${id}, ability-animation-${id}`};
  animation-duration: ${({ duration }) => duration}s;
  animation-timing-function: ${({ count }) => `steps(${count})`};
  animation-iteration-count: 1, ${({ iterationCount }) => iterationCount};
  animation-fill-mode: forwards;
  animation-delay: 0s, ${({ duration = 0 }) => duration}s;
  background-size: ${({ count, countStart }) => (count + countStart) * 100}%;
  background-position-y: center;
  background-repeat: no-repeat;
  opacity: 0;
  will-change: background-position-x;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s;
  ${({ transform }) => transform && `transform: ${transform};`}

  @keyframes ${({ id }) => `ability-animation-start-${id}`} {
    0% {
      opacity: 1;
      background-position-x: 0;
    }

    100% {
      opacity: 1;
      background-position-x: -${({ size = 0, count }) => size * count}px;
    }
  }

  @keyframes ${({ id }) => `ability-animation-${id}`} {
    0% {
      opacity: 1;
      background-position-x: -${({ size = 0, countStart }) => size * countStart}px;
    }

    100% {
      opacity: 1;
      background-position-x: -${({ size = 0, count, countStart }) => size * (count + countStart)}px;
    }
  }
`
