import styled from '@emotion/styled'
import { AnimatedTimeCooldown } from '~components/AnimatedTimeCooldown'
import { imageUrlMap } from '~constants/imageUrlMap'
import type { Second } from '~shared-types'
import { useStore } from '~store'

export const TaxiTimer = () => {
  const [waitingTaxiCooldown] = useStore((state) => [state.waitingTaxiCooldown])

  if (!waitingTaxiCooldown) {
    return null
  }

  const size = 123
  const strokeWidth = 4
  const viewBox = `0 0 ${size} ${size}`
  const radius = (size - strokeWidth - 4) / 2
  const transform = `rotate(-90, ${size / 2}, ${size / 2})`
  return (
    <Container key={waitingTaxiCooldown}>
      <svg width={size + 2} height={size + 2} viewBox={viewBox}>
        <defs>
          <linearGradient id="progressGradient" gradientUnits="userSpaceOnUse">
            <stop stopColor="#597E81" />
            <stop offset="1" stopColor="#D3FAFF" />
          </linearGradient>
        </defs>
        <circle
          fill="none"
          stroke="#131927"
          cx={size / 2}
          cy={size / 2}
          r={(size - 8) / 2}
          strokeWidth={7}
        />
        <circle
          fill="none"
          stroke="#3C4860"
          cx={size / 2}
          cy={size / 2}
          r={(size - 2) / 2}
          strokeWidth={1}
        />
        <circle
          fill="none"
          stroke="#3C4860"
          cx={size / 2}
          cy={size / 2}
          r={(size - strokeWidth * 3) / 2 - 1}
          strokeWidth={1}
        />
        <ProgressCircle
          fill="none"
          stroke="url(#progressGradient)"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
          transform={transform}
          strokeDasharray={100}
          pathLength={100}
          strokeLinecap="round"
          cooldown={waitingTaxiCooldown}
        />
      </svg>
      <Icon />
      <TimerText cooldown={waitingTaxiCooldown} animationName="taxi" />
    </Container>
  )
}

const TimerText = styled(AnimatedTimeCooldown)`
  font-size: 14px;
  font-weight: 700;
  margin-top: 10px;
  color: #fff;
`

const Container = styled.div`
  position: fixed;
  bottom: 23px;
  right: 285px;
  z-index: 12;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Icon = styled.div`
  position: absolute;
  top: 11px;
  left: 11px;
  height: 103px;
  width: 103px;
  background: linear-gradient(331deg, #808080 0%, #ada08a 30.73%, #d9d1bb 69.27%, #eae4cf 100%);
  margin: 0 auto;
  margin-bottom: 27px;
  border-radius: 50%;

  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    left: 4px;
    right: 4px;
    top: 4px;
    bottom: 4px;
    background: image-set(
        url('${imageUrlMap.ImgTaxi['1x']}') 1x,
        url('${imageUrlMap.ImgTaxi['2x']}') 2x
      )
      no-repeat center;
    background-size: cover;
    border-radius: 50%;
  }
`

const ProgressCircle = styled.circle<{ cooldown: Second }>`
  stroke-dashoffset: 100;
  animation: taxiTimerProgressAnimation ${({ cooldown }) => cooldown}s linear forwards;

  @keyframes taxiTimerProgressAnimation {
    from {
      stroke-dashoffset: 100;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
`
