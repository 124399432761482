import styled from '@emotion/styled'
import { store } from '~store'
import withTooltip from './Tooltip/withTooltip'
import { t } from '../utils/i18n'

// import { ReactComponent as IconChat } from '~assets/icon-chat.svg'
import { ReactComponent as IconFriends } from '~assets/icon-friends.svg'
import { ReactComponent as IconNotifications } from '~assets/icon-notifications.svg'
import { ReactComponent as IconSettings } from '~assets/icon-settings.svg'
import BgBottomMenu from '~assets/bg-bottom-menu.png'
import BgBottomMenu2x from '~assets/bg-bottom-menu_2x.png'

export type BottomMenuId = 'chat' | 'friends' | 'notifications' | 'esc'

type MenuItem = {
  id: BottomMenuId
  icon: JSX.Element
  onClick?: () => void
  disabled?: boolean
}

const items: MenuItem[] = [
  // {
  //   id: 'chat',
  //   icon: <IconChat />,
  //   disabled: true,
  //   onClick: () => store.getState().setBottomMenuId('chat'),
  // },
  {
    id: 'friends',
    icon: <IconFriends />,
    disabled: true,
    onClick: () => store.getState().setBottomMenuId('friends'),
  },
  {
    id: 'notifications',
    icon: <IconNotifications />,
    disabled: true,
    onClick: () => store.getState().setBottomMenuId('notifications'),
  },
  {
    id: 'esc',
    icon: <IconSettings />,
    onClick: () => store.getState().setFullscreenId('esc'),
  },
]

export const BottomMenu = () => {
  const [bottomMenuId, fullscreenId] = store((state) => [state.bottomMenuId, state.fullscreenId])

  return (
    <Container>
      {items.map(({ id, icon, onClick, disabled }) => {
        const tooltipContent = t(`tooltip:bottomMenu.${id}`)
        const ItemWithTooltip =
          tooltipContent && !disabled
            ? withTooltip(Item, { type: 'text', content: tooltipContent })
            : Item

        return (
          <ItemWithTooltip
            key={id}
            active={bottomMenuId === id || fullscreenId === id}
            onClick={disabled ? undefined : onClick}
            disabled={disabled}
          >
            {icon}
          </ItemWithTooltip>
        )
      })}
    </Container>
  )
}

const Container = styled.div`
  height: 48px;
  max-width: 212px;
  position: fixed;
  z-index: 20;
  bottom: 0;
  left: 0;
  display: flex;
  grid-gap: 18px;
  align-items: center;
  padding-left: 24px;
  padding-top: 4px;
  background-image: image-set(url('${BgBottomMenu}') 1x, url('${BgBottomMenu2x}') 2x);
  background-repeat: no-repeat;
  background-size: auto 100%;
  padding-right: 36px;
  background-position-x: right;
`

const Item = styled.div<{ active?: boolean; disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  > svg {
    fill: ${({ disabled }) => (disabled ? '#63666c' : 'url(#menu-item-icon-gradient)')};
  }

  ${({ disabled }) =>
    !disabled &&
    `&:hover {
    > svg {
      fill: url(#menu-item-icon-gradient-hover);
    }
  }`}

  ${({ active }) =>
    active &&
    `
        > svg {
      fill: url(#menu-item-icon-gradient-hover);
        }
    `}
`
