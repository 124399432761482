import styled from '@emotion/styled'
import { useCallback, useEffect } from 'react'
import { delay, fromEvent, throttleTime } from 'rxjs'
import { useStore } from '~store'
import LogoLight from '~assets/logo-light.png'
import LogoLight2x from '~assets/logo-light_2x.png'
import { Dialog } from '../../Dialog'
import { Button } from '../../Button'
import type { Millisecond } from '~shared-types'
import { useKeepOnlineMutation } from '../../../generated/graphql'
import { t } from '../../../utils/i18n'

const sendEveryMs: Millisecond = 9000
const delayMs: Millisecond = 5000
const throttleTimeMs: Millisecond = sendEveryMs - delayMs

export const OfflineDialog = () => {
  const [offline] = useStore((state) => [state.offline])

  const [keepOnline] = useKeepOnlineMutation()
  const handleMouseMove = useCallback(async () => {
    if (!offline) {
      await keepOnline()
    }
  }, [offline, keepOnline])

  useEffect(() => {
    const mouseMove$ = fromEvent<MouseEvent>(document, 'mousemove')
    const subscription = mouseMove$
      .pipe(delay(delayMs), throttleTime(throttleTimeMs))
      .subscribe(() => handleMouseMove())

    return () => {
      subscription.unsubscribe()
    }
  }, [handleMouseMove])

  if (!offline) {
    return null
  }

  return (
    <Container>
      <StyledDialog relative>
        <img srcSet={`${LogoLight} 1x, ${LogoLight2x} 2x`} alt="logo" />
        <HighlightText>{t('dialog:offlineDialog.youAreOffline')}</HighlightText>
        <Text>{t('dialog:offlineDialog.backToGame')}</Text>
        <EnterButton onClick={() => window.location.reload()}>{t('button:play')}</EnterButton>
      </StyledDialog>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  background: rgba(19, 25, 39, 0.5);
  backdrop-filter: blur(8px);
`

const StyledDialog = styled(Dialog, {})`
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const EnterButton = styled(Button)`
  margin-top: 30px;
`

const HighlightText = styled.p`
  color: #fff;
  margin-top: 30px;
`

const Text = styled.div``
