import { EventEmitter2 } from 'eventemitter2'

export interface EventBus extends EventEmitter2 {
  emit<U extends Event['type'], T extends Omit<EventType<U>, 'type'>>(event: U, value: T): boolean
  emitAsync<
    U extends Event['type'],
    T extends Omit<EventType<U>, 'type'>,
    K extends ResponseType<U>,
  >(
    event: U,
    value: T,
  ): Promise<K[]>
  on<U extends Event['type'], T extends Omit<EventType<U>, 'type'>>(
    event: U,
    listener: (value: T) => void,
  ): this
}

interface ActionStarted {
  type: 'action.triggered'
}

export type Event = ActionStarted

export type CharacterFindResponse = {
  type: 'character.find'
}

export type Response = CharacterFindResponse

type FindByType<Union, Type> = Union extends { type: Type } ? Union : never
export type EventType<Type extends Event['type']> = FindByType<Event, Type>
export type ResponseType<Type extends Response['type'] | string> = FindByType<Response, Type>

export const eventBus = new EventEmitter2({ maxListeners: 10 }) as EventBus
