import styled from '@emotion/styled/macro'
import { useState } from 'react'
import type { Race } from '~generated/graphql'
import { Side } from '~generated/graphql'
import { useStore } from '~store'
import { RaceRibbon } from './RaceRibbon'
import { raceListBySide } from '~utils/character'
import { t } from '~utils/i18n'
import { RaceDetails } from './RaceDetails'

import BgLight from '~assets/bg-race-light.svg'
import BgDark from '~assets/bg-race-dark.svg'
import IconCornerDark from '~assets/icon-frame-corner-dark.svg'
import IconCornerLight from '~assets/icon-frame-corner-light.svg'
import BgSideLightSparks from '~assets/bg-side-light-sparks.png'
import BgSideLightSparks2x from '~assets/bg-side-light-sparks_2x.png'
import BgSideDarkSparks from '~assets/bg-side-dark-sparks.png'
import BgSideDarkSparks2x from '~assets/bg-side-dark-sparks_2x.png'

type Step = 'overview' | 'detail'

export const ChooseRace = () => {
  const [step, setStep] = useState<Step>('overview')
  const [selectedRaceIndex, setSelectedRaceIndex] = useState<number | null>(null)
  const [session] = useStore((state) => [state.session])

  if (!session?.character) {
    return null
  }

  const { side, gender } = session.character
  const races = raceListBySide[side]
  const selectedRace = selectedRaceIndex !== null ? races[selectedRaceIndex] : null

  const selectNextRace = () => {
    if (selectedRaceIndex === null) {
      return
    }
    setSelectedRaceIndex((prev) => {
      if (prev === null) {
        return null
      }
      return prev === races.length - 1 ? 0 : prev + 1
    })
  }

  const selectPrevRace = () => {
    if (selectedRaceIndex === null) {
      return
    }
    setSelectedRaceIndex((prev) => {
      if (prev === null) {
        return null
      }
      return prev === 0 ? races.length - 1 : prev - 1
    })
  }

  return (
    <Container side={side}>
      <Corners side={side}>
        <InnerCorners side={side} />
      </Corners>
      <Title>{t('chooseRace:chooseRace')}</Title>
      {step === 'detail' && selectedRace ? (
        <RaceDetails
          race={selectedRace}
          onClose={() => {
            setStep('overview')
            setSelectedRaceIndex(null)
          }}
          onNextArrow={selectNextRace}
          onPrevArrow={selectPrevRace}
        />
      ) : (
        <RaceList>
          {races.map((race, index) => {
            const raceTranslation = t('common:racesList')?.find(
              (translation) => translation.race === race,
            )
            return (
              <RaceItem
                key={race}
                race={race}
                onClick={() => {
                  setStep('detail')
                  setSelectedRaceIndex(index)
                }}
              >
                <RaceRibbon side={side} race={race}>
                  <Name>{raceTranslation?.[gender === 'MALE' ? 'nameMale' : 'nameFemale']}</Name>
                  <Description>{raceTranslation?.keyPoints}</Description>
                </RaceRibbon>
              </RaceItem>
            )
          })}
        </RaceList>
      )}
    </Container>
  )
}

const Container = styled.div<{ side?: Side }>`
  position: fixed;
  z-index: 19;
  background: #131927
    ${(props) =>
      props.side === 'LIGHT'
        ? `url(${BgLight}) no-repeat
    left bottom`
        : `url(${BgDark}) no-repeat right bottom`};
  background-size: cover;
  color: #646d85;
  height: 100vh;
  width: 100vw;
  padding: 48px 20px 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:after {
    content: '';
    position: absolute;
    pointer-events: none;
  }

  ${({ side }) =>
    side === 'DARKNESS'
      ? `
  &:after {
    bottom: 0;
    left: -210px;
    width: 482px;
    height: 482px;
    background: image-set(url('${BgSideDarkSparks}') 1x, url('${BgSideDarkSparks2x}') 2x) no-repeat
      bottom;
  }`
      : `
  &:after {
    top: 0;
    right: -43px;
    width: 338px;
    height: 491px;
    background: image-set(url('${BgSideLightSparks}') 1x, url('${BgSideLightSparks2x}') 2x) no-repeat
      top;
  }`}
`

const Title = styled.h1`
  font-size: 32px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 20px;
  text-align: center;
  color: #fff;
`

const RaceList = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 0;
  flex-grow: 1;
`

const Corners = styled.div<{ side: Side }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  opacity: 0.25;

  &::before,
  &:after {
    content: '';
    position: absolute;
    width: 76px;
    height: 76px;
    background: url(${({ side }) => (side === 'LIGHT' ? IconCornerLight : IconCornerDark)})
      no-repeat left top;
    background-size: contain;
  }

  &::before {
    top: 24px;
    left: 24px;
  }

  &::after {
    bottom: 24px;
    left: 24px;
    transform: scaleY(-1);
  }
`

const InnerCorners = styled.div<{ side: Side }>`
  position: absolute;
  width: 100%;
  height: 100%;

  &::before,
  &:after {
    content: '';
    position: absolute;
    width: 76px;
    height: 76px;
    background: url(${({ side }) => (side === 'LIGHT' ? IconCornerLight : IconCornerDark)})
      no-repeat left top;
    background-size: contain;
  }

  &::before {
    top: 24px;
    right: 24px;
    transform: scaleX(-1);
  }

  &::after {
    bottom: 24px;
    right: 24px;
    transform: scale(-1, -1);
  }
`

const RaceItem = styled.div<{ race: Race }>`
  position: relative;
  cursor: pointer;
  top: 0;
  transition: top 0.3s;

  &:hover {
    top: -24px;
  }
`

const Name = styled.div`
  font-size: 17px;
  letter-spacing: -0.3px;
  margin-bottom: 12px;
  color: #fff;
  font-weight: 600;
`

const Description = styled.div`
  text-align: center;
  font-size: 14px;
  color: #646d85;
  padding: 0px 10px;
`
