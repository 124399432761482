import { DateTime, Duration } from 'luxon'
import type { Subject, Subscription } from 'rxjs'
import { finalize, NEVER, timer } from 'rxjs'
import type { DateTimeIso, DurationIso, Millisecond, UUID } from '~shared-types'
import type {
  LastingStatsEffect,
  LastingCrowdControlEffect,
  LastingEffectCrowdControlChange,
  LastingEffectStatsChange,
  ShieldFragment,
} from '~generated/match-graphql'
import AbilityMock from '~assets/ability-mock.jpg'
import { AbilityManager } from '../AbilityManager'
import type { StatusEffectsGroupFragment } from './CharacterStatusEffectsGroups'

export type CrowdControl = {
  type: LastingCrowdControlEffect
  endAt: Millisecond | null
  abilityIcon: string
}

type StatChange = {
  statType: (typeof statMap)[keyof typeof statMap]
  value: number
}

const statMap = {
  INVISIBILITY_RANGE: 'invisibilityRange',
  PHYSICAL_POWER: 'physicalPower',
  MAGIC_POWER: 'magicPower',
  PHYSICAL_RESISTANCE: 'physicalResistance',
  MAGIC_RESISTANCE: 'magicResistance',
  MOVEMENT_SPEED: 'movementSpeed',
  CASTING_SPEED: 'castingSpeed',
} as const satisfies Partial<Record<LastingStatsEffect, string>>

export type StatusEffectChanges = {
  action: 'add' | 'remove'
  effect: CharacterStatusEffectsGroup
  statsChanges: StatChange[]
  shieldEffects: ShieldFragment[]
  crowdControls: CrowdControl[]
}

export class CharacterStatusEffectsGroup {
  private subscription: Subscription
  private remainingDuration: Millisecond | 'COMPLETED' | 'INFINITE'
  constructor(
    public effectsGroup: StatusEffectsGroupFragment,
    private changeSubject: Subject<StatusEffectChanges>,
  ) {
    this.remainingDuration = this.getRemainingDuration()

    const statsChanges: StatChange[] = []
    const crowdControls: CrowdControl[] = []
    const shieldEffects: { statusEffectId: UUID; value: number }[] = []

    if (effectsGroup.__typename === 'LastingStatusEffectsGroup') {
      const abilityManager = AbilityManager.getInstance()
      const ability = abilityManager.get(effectsGroup.causedByAbilityEffect.ability.id)
      crowdControls.push(
        ...effectsGroup.changes
          .filter(
            (change): change is LastingEffectCrowdControlChange =>
              change.__typename === 'LastingEffectCrowdControlChange',
          )
          .map((change) => ({
            type: change.controlType,
            abilityIcon: ability?.icon ?? AbilityMock,
            endAt:
              effectsGroup.duration !== 'P9999Y'
                ? DateTime.fromISO(effectsGroup.startAt)
                    .plus(Duration.fromISO(effectsGroup.duration))
                    .toMillis()
                : null,
          })),
      )

      statsChanges.push(
        ...effectsGroup.changes
          .filter(
            (change): change is LastingEffectStatsChange =>
              change.__typename === 'LastingEffectStatsChange',
          )
          .filter((change) =>
            [
              'INVISIBILITY_RANGE',
              'PHYSICAL_POWER',
              'MAGIC_POWER',
              'PHYSICAL_RESISTANCE',
              'MAGIC_RESISTANCE',
              'MOVEMENT_SPEED',
              'CASTING_SPEED',
            ].includes(change.statType),
          )
          .map(
            (change): StatChange => ({
              statType: statMap[change.statType as keyof typeof statMap],
              value: change.value,
            }),
          ),
      )

      shieldEffects.push(
        ...effectsGroup.changes
          .filter(
            (change): change is LastingEffectStatsChange =>
              change.__typename === 'LastingEffectStatsChange',
          )
          .filter((change) => ['SHIELD_HEALTH'].includes(change.statType))
          .map(
            (change): ShieldFragment => ({
              statusEffectId: effectsGroup.id,
              value: change.value,
            }),
          ),
      )
    }

    this.changeSubject.next({
      action: 'add',
      effect: this,
      statsChanges,
      shieldEffects,
      crowdControls: this.remainingDuration !== 'COMPLETED' ? crowdControls : [],
    })

    this.subscription = (
      this.remainingDuration === 'INFINITE'
        ? NEVER
        : timer(this.remainingDuration === 'COMPLETED' ? 0 : this.remainingDuration)
    )
      .pipe(
        finalize(() => {
          this.changeSubject.next({
            action: 'remove',
            effect: this,
            statsChanges,
            shieldEffects,
            crowdControls: this.remainingDuration !== 'COMPLETED' ? crowdControls : [],
          })
        }),
      )
      .subscribe()
  }

  static getRemainingDuration(effect: {
    startAt: DateTimeIso
    duration: DurationIso
  }): Millisecond | 'COMPLETED' | 'INFINITE' {
    if (effect.duration === 'P9999Y') {
      return 'INFINITE'
    }
    const duration = Duration.fromISO(effect.duration).toMillis()
    const remainingDuration = DateTime.fromISO(effect.startAt).diffNow().plus(duration).toMillis()
    return remainingDuration > 0 ? remainingDuration : 'COMPLETED'
  }

  private getRemainingDuration(): Millisecond | 'COMPLETED' | 'INFINITE' {
    return CharacterStatusEffectsGroup.getRemainingDuration(this.effectsGroup)
  }

  public cancel() {
    this.subscription.unsubscribe()
  }
}
