import type { FC } from 'react'
import styled from '@emotion/styled'
import type { Observable } from 'rxjs'
import type { Character } from '~generated/match-graphql'
import type { ActionMenuItem } from '~components/map/CharacterActionMenu'
import { CharacterActionMenu } from '~components/map/CharacterActionMenu'
import type { MarkerAvatarFragment } from '~generated/graphql'
import { CharacterTwilightRelation } from '~map-tools'
import type { TwilightLevel } from '../../../types'
import { HealthBar } from './HealthBar'
import { EnergyBar } from './EnergyBar'
import { usePopover } from '~hooks/usePopover'
import type { MainStats } from '../../CharacterPanel/CharacterPanelMainStats'
import { CharacterMarkerHealthChanges } from './CharacterMarkerHealthChanges'
import { CastProgress } from './CastProgress'
import type { CastAction } from '../../../views/match/model/SpellCast'
import { ShieldOrAbilityIcon } from './ShieldOrAbilitityIcon'
import { useValueFromObservable } from '../../quest/useValueFromObservable'
import { MarkerIcon } from './MarkerIcon'
import type { CrowdControl } from '../../../views/match/model/statusEffectsGroups/CharacterStatusEffectsGroup'
import type { Stats } from '../../../views/match/model/CharacterEntity'
import type { Side } from '~shared-types'

export type MarkerCharacter = Pick<
  Character,
  'id' | 'position' | 'firstName' | 'lastName' | 'race' | 'side' | 'alive'
> & {
  // TODO: add level&experience to match schema
  level: number
  experience: number
  stats: Pick<Stats, 'health' | 'energy' | 'healthMax' | 'energyMax' | 'shield'>
  avatar: MarkerAvatarFragment
  twilightLevel: TwilightLevel
}

type Props = {
  isCurrentCharacter: boolean
  character: MarkerCharacter
  aliveObservable: Observable<boolean>
  statsObservable: Observable<MainStats>
  spellCastObservable: Observable<CastAction | null>
  healthChangeObservable: Observable<{ health: number }>
  twilightRelationObservable: Observable<CharacterTwilightRelation>
  castedAbilityObservable?: Observable<{ icon: string }>
  crowdControlsObservable: Observable<CrowdControl[]>
  visibleObservable: Observable<boolean>
  areActionsVisibleObservable: Observable<boolean>
  menuItems?: ActionMenuItem[]
}

export const MapCharacterMarker: FC<Props> = ({
  character,
  menuItems,
  statsObservable,
  castedAbilityObservable,
  healthChangeObservable,
  spellCastObservable,
  twilightRelationObservable,
  aliveObservable,
  crowdControlsObservable,
  visibleObservable,
  areActionsVisibleObservable,
  isCurrentCharacter,
}) => {
  const visible = useValueFromObservable(visibleObservable)
  const { isPopoverOpen, popoverProps, parentProps } = usePopover()

  const twilightRelation = useValueFromObservable(twilightRelationObservable)

  const alive = useValueFromObservable(aliveObservable)
  if (!visible) {
    return null
  }

  const { id, race, firstName, lastName, side, avatar, level } = character

  const shouldShowPopover =
    alive &&
    !isCurrentCharacter &&
    [CharacterTwilightRelation.REACHABLE, CharacterTwilightRelation.VISIBLE].includes(
      twilightRelation,
    )

  return (
    <>
      <Container {...parentProps}>
        <MarkerContainer twilightRelation={twilightRelation} alive={alive}>
          <Title
            twilightRelation={twilightRelation}
            isCurrentCharacter={isCurrentCharacter}
            side={side}
          >
            <Name>
              {firstName} {lastName}
            </Name>
          </Title>
          {/* <MarkerHighlight race="WITCH" id={id} /> */}
          <MarkerIcon
            race={race}
            characterAvatar={avatar}
            twilightRelation={twilightRelation}
            crowdControlsObservable={crowdControlsObservable}
            castedAbilityObservable={castedAbilityObservable}
            side={side}
            alive={alive}
          />
          {[
            CharacterTwilightRelation.REACHABLE,
            CharacterTwilightRelation.ONE_BELOW_REACHABLE,
          ].includes(twilightRelation) && alive ? (
            <>
              {race !== 'HUMAN' ? (
                <EnergyBar id={id} side={side} statsObservable={statsObservable} />
              ) : null}
              <HealthBar id={id} race={race} statsObservable={statsObservable} />
            </>
          ) : null}

          {alive ? (
            <ShieldOrAbilityIcon
              spellCastObservable={spellCastObservable}
              twilightRelation={twilightRelation}
              areActionsVisibleObservable={areActionsVisibleObservable}
              level={level}
            />
          ) : null}
          <CastProgress spellCastObservable={spellCastObservable} />
          <CharacterMarkerHealthChanges healthChangeObservable={healthChangeObservable} />
        </MarkerContainer>
      </Container>
      {menuItems && isPopoverOpen && shouldShowPopover ? (
        <CharacterActionMenu items={menuItems} {...popoverProps} />
      ) : null}
    </>
  )
}

const Container = styled.div<{ range?: number }>`
  position: relative;
`

const MarkerContainer = styled.div<{ twilightRelation: CharacterTwilightRelation; alive: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 92px;
  height: 92px;
  color: #fff;
  font-size: 13px;
  font-weight: 600;

  &:after {
    content: '';
    position: absolute;
    top: 8px;
    left: 8px;
    right: 8px;
    bottom: 8px;
    border-radius: 50%;
    border: 2px solid #131927;
    display: ${({ twilightRelation, alive }) =>
      [CharacterTwilightRelation.ONE_BELOW_REACHABLE, CharacterTwilightRelation.REACHABLE].includes(
        twilightRelation,
      ) && alive
        ? 'block'
        : 'none'};
  }
`

const Title = styled.div<{
  twilightRelation: CharacterTwilightRelation
  isCurrentCharacter: boolean
  side: Side
}>`
  width: 130px;
  margin-left: -65px;
  left: 50%;
  text-align: center;
  top: -22px;
  position: absolute;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 600;
  user-select: none;
  pointer-events: none;
  z-index: 1;

  display: ${({ twilightRelation }) =>
    twilightRelation === CharacterTwilightRelation.NOT_VISIBLE ? 'none' : 'flex'};
  color: ${({ isCurrentCharacter, side, twilightRelation }) =>
    isCurrentCharacter
      ? '#fff'
      : twilightRelation === CharacterTwilightRelation.ONE_BELOW_REACHABLE
      ? '#A7AFC4'
      : twilightRelation === CharacterTwilightRelation.VISIBLE
      ? '#646D85'
      : side === 'LIGHT'
      ? '#20A9F6'
      : '#EA4357'};
`
const Name = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
`

// const Rank = styled.div<{ twilightRelation: CharacterTwilightRelation }>`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-shrink: 0;
//   width: 21px;
//   height: 28px;
//   background: url(${RankShield}) center no-repeat;
//   color: #131927;
//   font-size: 14px;
//   line-height: 15px;
//   font-weight: 700;
//   letter-spacing: -0.7px;
//   position: absolute;
//   z-index: 2;
//   bottom: -3px;
//   filter: drop-shadow(0 1px 3px #081f2dbc);

//   &:before {
//     content: '';
//     position: absolute;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background: url(${RankShieldOverlay}) center no-repeat;
//     background-size: contain;

//     display: ${({ twilightRelation }) =>
//       [CharacterTwilightRelation.ONE_BELOW_REACHABLE, CharacterTwilightRelation.REACHABLE].includes(
//         twilightRelation,
//       )
//         ? 'none'
//         : 'block'};
//   }
// `
