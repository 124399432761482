import type { GeoJSONSourceSpecification, LayerSpecification } from 'maplibre-gl'
import { Map, Marker } from 'maplibre-gl'
import { centroid } from '@turf/turf'
import { createRoot } from 'react-dom/client'
import styled from '@emotion/styled'
import type { Observable, Subscription } from 'rxjs'
import { filter, startWith, Subject, timer } from 'rxjs'
import { Duration } from 'luxon'
import { layersColorMap } from '~constants/layersColorMap'
import { styleTwilightMap } from '~constants/styleTwilightMap'
import { CITY_ZOOM } from '~constants/map'
import type {
  CityZoneFragment,
  RouteBetweenZonesOptionFragment,
  RouteBetweenZonesOptionsQuery,
  WaitRouteBetweenZonesMutation,
} from '~generated/graphql'
import {
  RouteBetweenZonesDocument,
  RouteBetweenZonesOptionsDocument,
  SessionDocument,
  Side,
  WaitRouteBetweenZonesDocument,
} from '~generated/graphql'
import { MarkerIcon } from '~components/map/MapCharacterMarker/MarkerIcon'
import { store } from '~store'
import type { TwilightLevel } from '~types'
import { client } from '~apolloClient'
import type { Point } from '~shared-types'
import { ZoneMap } from '../../../zone/model/ZoneMap'
import type { ZoneCharacter } from '../../../zone/model/ZoneCharacter'
import { LayerManager } from '../../../match/model/LayerManager'
import { ZoneDestinationMarker } from '../ZoneDestinationMarker'
import { imageUrlMap } from '~constants/imageUrlMap'

let hoveredPolygonId: string | number | null | undefined = null

const regionLabelPositionMap: Record<number, Point> = {
  1: [37.590211700027396, 55.75041498390823],
  2: [37.55660148894873, 55.761256924138564],
  3: [37.573299555599306, 55.728239858918045],
  4: [37.60284228890336, 55.77486531525571],
  5: [37.61064505658288, 55.73389514400279],
  6: [37.62917385554448, 55.78461707999361],
  7: [37.63730881109183, 55.729204256801495],
  9: [37.65957289995839, 55.779320127846375],
  12: [37.5104586176494, 55.79761057258684],
}

export class CityMap {
  private static instanceSubject: Subject<CityMap> = new Subject<CityMap>()
  protected static instance: CityMap | undefined
  public map: Map
  public marker: Marker | undefined
  public routeOptions: RouteBetweenZonesOptionFragment[] | undefined
  public currentCharacter: ZoneCharacter | undefined
  public layerManager: LayerManager
  public waitingCooldownSubscription?: Subscription

  protected constructor(regions?: CityZoneFragment[]) {
    this.map = new Map({
      container: 'city-map',
      style: styleTwilightMap[2],
      center: [37.61992, 55.751951],
      zoom: CITY_ZOOM,
      minZoom: CITY_ZOOM - 1,
      dragPan: false,
      dragRotate: false,
      keyboard: false,
      scrollZoom: false,
      doubleClickZoom: false,
      touchZoomRotate: false,
      touchPitch: false,
      pitchWithRotate: false,
      renderWorldCopies: false,
      validateStyle: false,
    })

    this.layerManager = new LayerManager(this.map)

    this.map.on('load', () => {
      const zoneMap = ZoneMap.getInstance()
      this.currentCharacter = zoneMap.getCurrentCharacter()

      this.addCurrentCharacterMarker()

      if (regions) {
        this.drawRegions(regions)
        this.map.fitBounds(
          [
            [37.499185, 55.805918],
            [37.735186, 55.707982],
          ],
          {
            padding: 5,
          },
        )
      }

      const { routeBetweenZones } = store.getState()
      if (routeBetweenZones?.path) {
        this.addDestinationMarker(routeBetweenZones.path[routeBetweenZones.path.length - 1])
        this.drawRoutePath(routeBetweenZones.path)
      }
    })

    this.map.on('mousemove', 'regions-fill-layer', (e) => {
      if (e.features && e.features.length > 0) {
        if (hoveredPolygonId !== null) {
          this.map.setFeatureState({ source: 'regions', id: hoveredPolygonId }, { hover: false })
        }
        hoveredPolygonId = e.features[0].id
        if (hoveredPolygonId !== store.getState().session?.character?.zoneId) {
          this.map.setFeatureState({ source: 'regions', id: hoveredPolygonId }, { hover: true })
        }
      }
    })

    this.map.on('mouseleave', 'regions-fill-layer', () => {
      if (hoveredPolygonId !== null) {
        this.map.setFeatureState({ source: 'regions', id: hoveredPolygonId }, { hover: false })
      }
      hoveredPolygonId = null
    })

    this.map.on('click', 'regions-fill-layer', async (e) => {
      if (e.features && e.features.length > 0) {
        const isCurrentZone = e.features[0].id === store.getState().session?.character?.zoneId
        if (isCurrentZone) {
          return
        }
        const region = e.features[0]
        this.clearElements()

        const { data } = await client.query<RouteBetweenZonesOptionsQuery>({
          query: RouteBetweenZonesOptionsDocument,
          variables: {
            destination: [e.lngLat.lng, e.lngLat.lat],
          },
        })

        const path = data?.routeBetweenZonesOptions[0].path
        if (!path) return
        this.routeOptions = data.routeBetweenZonesOptions
        this.drawRoutePath(path)
        this.addDestinationMarker([e.lngLat.lng, e.lngLat.lat], region.properties?.name)
      }
    })
  }

  public static getInstanceObservable(): Observable<CityMap> {
    return this.instanceSubject.pipe(
      startWith(this.instance),
      filter((instance) => !!instance),
    )
  }

  public static getInstance(regions?: CityZoneFragment[]): CityMap {
    if (!CityMap.instance) {
      CityMap.instance = new CityMap(regions)
      CityMap.instanceSubject.next(CityMap.instance)
    }

    return CityMap.instance
  }

  public static remove() {
    const cityMap = this.getInstance()
    cityMap.map.remove()
    delete CityMap.instance
  }

  private clearElements() {
    this.marker?.remove()
    store.getState().setRouteBetweenZones(null)
    this.routeOptions = undefined

    if (this.map?.getLayer('city-route-layer')) this.map.removeLayer('city-route-layer')
    if (this.map?.getSource('city-route-source')) this.map.removeSource('city-route-source')
  }

  private drawRoutePath(path: [number, number][]) {
    this.map.addSource('city-route-source', {
      type: 'geojson',
      lineMetrics: true,
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: path,
        },
      },
    })

    const side = store.getState().currentCharacter?.side

    this.map.addLayer({
      id: 'city-route-layer',
      type: 'line',
      source: 'city-route-source',
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-width': 3,
        'line-color': side === 'DARKNESS' ? '#D51F35' : '#0080EB',
        'line-gradient':
          side === 'DARKNESS'
            ? [
                'interpolate',
                ['linear'],
                ['line-progress'],
                0,
                '#6B222F',
                0.2,
                '#D51F35',
                0.4,
                '#F4673B',
                1,
                '#FFDD84',
              ]
            : ['interpolate', ['linear'], ['line-progress'], 0, '#0080EB', 1, '#5EF7F7'],
      },
    })
  }

  private startWaitingRoute = async () => {
    try {
      // TODO: mock one taxi option for now
      const selectedOption = this.routeOptions?.[this.routeOptions.length - 1]
      if (!selectedOption) return

      const result = await client.mutate<WaitRouteBetweenZonesMutation>({
        mutation: WaitRouteBetweenZonesDocument,
        variables: {
          destination: selectedOption.path[selectedOption.path.length - 1],
          transportMode: selectedOption.transportMode,
        },
      })

      if (!result.data?.waitRouteBetweenZones) return
      this.marker?.remove()
      this.waitingCooldownSubscription?.unsubscribe()

      store.getState().setRouteBetweenZones(result.data.waitRouteBetweenZones)
      const waitingTime = Duration.fromISO(result.data.waitRouteBetweenZones.waitingTime)
      const countdown = waitingTime.toMillis()
      store.getState().setWaitingTaxiCooldown(Math.round(waitingTime.as('seconds')))

      this.waitingCooldownSubscription = timer(countdown).subscribe(async () => {
        store.getState().setWaitingTaxiCooldown(null)
        store.getState().setFullscreenId(null)
        await client.mutate({
          mutation: RouteBetweenZonesDocument,
          refetchQueries: [SessionDocument],
        })
      })
    } catch (error) {
      console.log(error)
    }
  }

  private addDestinationMarker(position: Point, zoneName?: string) {
    // TODO: mock one taxi option for now
    const selectedOption = this.routeOptions?.[this.routeOptions.length - 1]
    if (!selectedOption) return

    const div = document.createElement('div')
    const root = createRoot(div)
    const component = (
      <ZoneDestinationMarker
        zoneName={zoneName}
        routeOption={selectedOption}
        onClick={this.startWaitingRoute}
      />
    )
    root.render(component)
    this.marker = new Marker({ element: div, className: 'region-marker' })
      .setLngLat(position)
      .addTo(this.map)

    this.marker.getElement().addEventListener('click', (e) => {
      e.stopPropagation()
    })
  }

  public addCurrentCharacterMarker() {
    if (!this.currentCharacter) return

    const div = document.createElement('div')
    const root = createRoot(div)
    const component = (
      <CurrentCharacterMarkerIcon
        characterAvatar={this.currentCharacter.avatar || imageUrlMap.ImgFallbackCharacter}
      />
    )
    root.render(component)
    new Marker({ element: div }).setLngLat(this.currentCharacter.position).addTo(this.map)
  }

  public setCenter(center: Point) {
    this.map.setCenter(center)
  }

  public setTwilight(twilight: TwilightLevel) {
    const twilightConfig = layersColorMap[twilight]

    Object.entries<object>(twilightConfig).forEach(([layer, style]) => {
      Object.entries(style).forEach(([property, value]) => {
        this.map.setPaintProperty(layer, property, value)
      })
    })
  }

  public drawRegions(regions: CityZoneFragment[]) {
    if (!regions?.length) {
      return
    }

    const source = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: regions.map((zone) => ({
          id: zone.id,
          type: 'Feature',
          properties: {
            id: zone.id,
            name: zone.name,
          },
          geometry: {
            type: 'Polygon',
            coordinates: [zone.boundaries],
          },
        })),
      },
    } as const satisfies GeoJSONSourceSpecification

    const layerLine = {
      id: 'regions-border-layer',
      type: 'line',
      source: 'regions',
      layout: {},
      paint: {
        'line-color': '#646D85',
        'line-width': 2,
        'line-opacity': 1,
      },
    } as const satisfies LayerSpecification

    const layerFill = {
      id: 'regions-fill-layer',
      type: 'fill',
      source: 'regions',
      layout: {},
      paint: {
        'fill-color': '#84AAAD',
        'fill-opacity': ['case', ['boolean', ['feature-state', 'hover'], false], 0.2, 0],
      },
    } as const satisfies LayerSpecification

    this.layerManager.createLayersWithSharedSource([layerLine, layerFill], source)

    source.data.features.forEach((region) => {
      const centroidPt = centroid(region.geometry)
      this.map.addSource(`label_${region.properties?.id}`, {
        type: 'geojson',
        data: regionLabelPositionMap[region.properties?.id]
          ? ({
              ...centroidPt,
              geometry: {
                ...centroidPt.geometry,
                coordinates: regionLabelPositionMap[region.properties?.id],
              },
            } satisfies GeoJSON.GeoJSON)
          : centroidPt,
      })
      this.map.addLayer({
        id: `label_style_${region.properties?.id}`,
        type: 'symbol',
        source: `label_${region.properties?.id}`,
        layout: {
          'text-field': region.properties?.name.split(' ')[0],
          'text-font': ['Overpass Semi Bold'],
        },
        paint: {
          'text-color': '#FFF',
        },
      })
    })
  }
}

const CurrentCharacterMarkerIcon = styled(MarkerIcon)`
  width: 36px;
  height: 36px;
  padding: 2px;
`
