import styled from '@emotion/styled'
import { useState } from 'react'
import type { FC } from 'react'
import { Duration } from 'luxon'
import { Button } from '~components/Button'
import { Dialog } from '~components/Dialog'
import { RhombusItem } from '~components/RhombusItem'
import type { MasteriesAbilityFragment } from '~generated/graphql'

type Props = {
  abilities: MasteriesAbilityFragment[]
  onSubmit: (abilityId: number) => void
}

export const DoubleAbilitySelectDialog: FC<Props> = ({ abilities, onSubmit }) => {
  const [selectedAbilityId, setSelectedAbilityId] = useState<number>(abilities[0].id)
  return (
    <StyledDialog withBackground>
      <Title>Выберите заклинание</Title>
      <AbilityList>
        {abilities.map(({ id, name, cost, cooldown, castTime, description, icon }) => (
          <AbilityItem
            active={selectedAbilityId === id}
            key={id}
            onClick={() => setSelectedAbilityId(id)}
          >
            <AbilityIcon icon={icon} size={48} borderSize={3} borderColor="#808080" />
            <AbilityTitle>{name}</AbilityTitle>
            <AbilityStats>
              <AbilityStat>
                <AbilityStatTitle>Необходимо энергии</AbilityStatTitle>
                <AbilityStatValue>{cost}</AbilityStatValue>
              </AbilityStat>
              <AbilityStat>
                <AbilityStatTitle>Время произнесения умения</AbilityStatTitle>
                <AbilityStatValue>{Duration.fromISO(castTime).as('seconds')} c</AbilityStatValue>
              </AbilityStat>
              <AbilityStat>
                <AbilityStatTitle>Время перезарядки умения</AbilityStatTitle>
                <AbilityStatValue>{Duration.fromISO(cooldown).as('seconds')} c</AbilityStatValue>
              </AbilityStat>
            </AbilityStats>
            <Description>{description}</Description>
          </AbilityItem>
        ))}
      </AbilityList>
      <ButtonContainer>
        <Button onClick={() => onSubmit(selectedAbilityId)}>Выбрать заклинание</Button>
      </ButtonContainer>
    </StyledDialog>
  )
}
const StyledDialog = styled(Dialog)`
  width: 828px;
  max-width: calc(100% - 40px);
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 72px;
`

const Title = styled.div`
  font-size: 32px;
  text-align: center;
  margin-bottom: 64px;
  color: #fff;
`

const AbilityList = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 24px;
`

const AbilityItem = styled.div<{ active?: boolean }>`
  position: relative;
  box-shadow: ${({ active }) => (active ? '0px 12px 48px 0px #080d13' : 'none')};
  background: linear-gradient(331deg, #808080 0%, #ada08a 30.73%, #d9d1bb 69.27%, #eae4cf 100%);
  padding: 24px;
  cursor: pointer;

  &:before {
    pointer-events: none;
    content: '';
    display: block;
    top: ${({ active }) => (!active ? '0' : '2px')};
    left: ${({ active }) => (!active ? '0' : '2px')};
    right: ${({ active }) => (!active ? '0' : '2px')};
    bottom: ${({ active }) => (!active ? '0' : '2px')};
    position: absolute;
    background: linear-gradient(180deg, #080c13 -13.69%, #131927 26.34%, #2a3041 100%);
  }

  > * {
    position: relative;
    z-index: 1;
  }
`

const AbilityTitle = styled.div`
  font-size: 20px;
  color: #fff;
  margin-top: 40px;
  font-weight: 600;
`

const AbilityIcon = styled(RhombusItem)``

const AbilityStats = styled.div`
  margin-top: 24px;
`

const AbilityStat = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #3c4860;
  padding: 5px 0;
  font-size: 14px;
`

const AbilityStatTitle = styled.div`
  color: #ada08a;
`

const AbilityStatValue = styled.div`
  color: #fff;
  font-weight: 600;
`

const Description = styled.div`
  font-size: 16px;
  margin-top: 20px;
`
