import type { Map } from 'maplibre-gl'
import { timer } from 'rxjs'
import type { CharacterEntity } from '../CharacterEntity'
import { AnimationMarker } from './AnimationMarker'
import type { MatchCharacterAbilityWithAnimation } from './CastedAbilityAnimation'
import type { Point, Second } from '~shared-types'
import { convertMetersToPixels } from '~utils/map'
import { angleBetweenTwoPoints, spatialDistance } from '~map-tools'
import { AbilityDirectionAreaAnimationUI } from '../../components/animation/AbilityDirectionAreaAnimationUI'

export class DirectionAreaAnimation {
  constructor(
    map: Map,
    caster: CharacterEntity,
    target: CharacterEntity | Point,
    castedAbility: MatchCharacterAbilityWithAnimation,
    motionDuration: Second = 1,
    motionDelay: Second = 0.5,
  ) {
    const angle = angleBetweenTwoPoints(
      caster.position,
      'firstName' in target ? target.position : target,
    )

    const distance = spatialDistance(
      caster.position,
      'firstName' in target ? target.position : target,
    )
    const widthPx = Math.round(convertMetersToPixels(map, distance))
    const height =
      castedAbility.shape?.__typename === 'AbilityDirectionShapeLine'
        ? castedAbility.shape.width
        : 0
    const heightPx = Math.round(convertMetersToPixels(map, height))
    const component = (
      <AbilityDirectionAreaAnimationUI
        side={caster.side}
        race={caster.race}
        width={widthPx}
        height={heightPx}
        id={`${caster.id}${castedAbility.id}${Date.now()}ATTACK_DIRECTION_AREA`}
        motionDelay={motionDelay}
      />
    )
    const marker = new AnimationMarker(map, component, caster.position, true, angle + 90)

    const motionSubscription = timer(motionDelay * 1000 + motionDuration * 1000 + 500).subscribe(
      () => {
        motionSubscription.unsubscribe()
        marker.remove()
      },
    )
  }
}
