import styled from '@emotion/styled/macro'
import { useLogin } from 'apps/client-app/src/views/auth/LoginForm/useLogin'
import { useState } from 'react'
import type { FC } from 'react'
import { useForm } from 'react-hook-form'
import type { LoginInput } from '~generated/graphql'
import { Input } from '~components/Input'
import { Button } from '~components/Button'
import { t } from '~utils/i18n'

type LoginInputs = {
  email: string
  password: string
  remember: boolean
}

type Props = {
  onForgotPassword?: () => void
}

export const LoginForm: FC<Props> = ({ onForgotPassword }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>()
  const [login, { error }] = useLogin()
  const [showError, setShowError] = useState<boolean>(false)

  const handleLoginSubmit = async (data: LoginInput) => {
    setShowError(true)
    await login({
      email: data.email,
      password: data.password,
    })
  }

  return (
    <Form onSubmit={handleSubmit(handleLoginSubmit)}>
      <Input
        register={register}
        name="email"
        rules={{ required: { value: true, message: t('form:requiredField')! } }}
        type="text"
        label={t('form:emailLabel')}
        placeholder={t('form:emailPlaceholder')}
        error={errors.email?.message}
        autoComplete="email"
        onChange={() => {
          setShowError(false)
        }}
      />

      <Input
        register={register}
        name="password"
        rules={{ required: { value: true, message: t('form:requiredField')! } }}
        type="password"
        label={t('form:passwordLabel')}
        placeholder={t('form:passwordPlaceholder')}
        error={errors.password?.message}
        autoComplete="current-password"
        onChange={() => {
          setShowError(false)
        }}
      />

      <FormRow>
        {/* <Checkbox
          register={register}
          name="remember"
          onChange={() => {
            setShowError(false)
          }}
        >
          {t('auth:login.rememberMe')}
        </Checkbox> */}
        {onForgotPassword ? (
          <TextLink onClick={onForgotPassword}>{t('auth:login.forgotPassword')}</TextLink>
        ) : null}
      </FormRow>

      {error && showError && <Error>{error.message}</Error>}
      <FormButton onClick={handleSubmit(handleLoginSubmit)}>{t('button:logIn')}</FormButton>
    </Form>
  )
}

const Form = styled.form`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const FormButton = styled(Button)`
  margin-top: 36px;
  min-width: 182px;
`

const Error = styled.div`
  width: 100%;
  text-align: center;
  color: #ea4357;
  font-size: 14px;
  margin-top: 20px;
`

const TextLink = styled.span`
  color: #ada08a;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  font-size: 14px;
  line-height: 15px;
`

const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
`
