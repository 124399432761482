import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Race, Side } from '~shared-types'
import { raceColorMap } from '~utils/character'

import { ReactComponent as BorderLine } from '~assets/img-direction-animation-border.svg'
import { ReactComponent as ArrowIcon } from '~assets/img-direction-animation-arrow.svg'

type Props = {
  side: Side
  race: Race
  width: number
  height: number
  className?: string
}

const arrowCountStep = 300

export const AbilityAreaDirection: FC<Props> = ({ race, side, width, height, className }) => {
  const arrowsCount = 1 + Math.floor(width / arrowCountStep)
  const arrowSize = Math.round((48 * height) / 240)
  return (
    <Container
      width={width}
      height={height}
      mainColor={raceColorMap[race].main}
      darkColor={raceColorMap[race].dark}
      className={className}
    >
      <TopBorderLine side={side} />
      <ArrowsLine count={arrowsCount}>
        {Array(arrowsCount).fill(<ArrowIcon height={arrowSize > 48 ? 48 : arrowSize} />)}
      </ArrowsLine>
      <BottomBorderLine side={side} />
    </Container>
  )
}

const StyledBorderLine = styled(BorderLine)<{ side: Side }>`
  --direction-animation-light-corner-display: ${({ side }) =>
    side === 'LIGHT' ? 'block' : 'none'};
  --direction-animation-dark-corner-display: ${({ side }) =>
    side === 'DARKNESS' ? 'block' : 'none'};

  width: auto;
  height: 33%;
  left: 0;
  z-index: 1;
`

const TopBorderLine = styled(StyledBorderLine)`
  position: absolute;
  top: 0;
`

const BottomBorderLine = styled(StyledBorderLine)`
  position: absolute;
  bottom: 0;
  transform: scaleY(-1);
`

const ArrowsLine = styled.div<{ count: number }>`
  position: absolute;
  top: 50%;
  left: 10%;
  right: 15%;
  transform: translateY(-50%);
  z-index: 2;
  display: flex;
  justify-content: ${({ count }) => (count > 1 ? 'space-between' : 'center')};

  &:before {
    content: '';
    position: absolute;
    height: 1px;
    left: 0;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    opacity: 0.75;
    background-image: linear-gradient(
      90deg,
      var(--direction-animation-dark-color) 1px,
      hsla(0, 0%, 100%, 0) 0
    );
    background-position: 0 0;
    background-size: 3px 1px;
    background-repeat: repeat-x;
  }
`

const Container = styled.div<{
  height: number
  width: number
  mainColor: string
  darkColor: string
}>`
  --direction-animation-main-color: ${({ mainColor }) => mainColor};
  --direction-animation-dark-color: ${({ darkColor }) => darkColor};

  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin-left: -${({ width }) => width}px;
  position: relative;
  pointer-events: none;
  overflow: hidden;
  mask-image: linear-gradient(-90deg, transparent 0, #000 15%);

  &:before {
    content: '';
    position: absolute;
    height: 50%;
    background: ${({ darkColor }) => darkColor};
    filter: blur(35px);
    left: 15%;
    top: 50%;
    right: 15%;
    transform: translateY(-50%);
    opacity: 0.35;
  }
`
