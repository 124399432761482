import styled from '@emotion/styled/macro'
import { Tab, VerticalTabs } from '~components/Tabs'
import { Row } from './Layout'
// import { HotkeysTab } from './HotkeysTab'
// import { SoundTab } from './SoundTab'
import { AccountTab } from './AccountTab'

// import IconMouse from '~assets/icon-mouse.svg'
// import IconSound from '~assets/icon-sound.svg'
import IconAccount from '~assets/icon-account.svg'
import { ReactComponent as IconClose } from '~assets/icon-close.svg'
import { useStore } from '~store'
import { t } from '~utils/i18n'

// const Footer = () => (
//   <ButtonContainer>
//     <Button outline onClick={() => {}}>
//       Восстановить по умолчанию
//     </Button>
//     <ActionButtonContainer>
//       <Button outline onClick={() => {}}>
//         Отменить
//       </Button>
//       <Button onClick={() => {}}>Применить</Button>
//     </ActionButtonContainer>
//   </ButtonContainer>
// )

export const Settings = () => {
  const [seFullscreenId] = useStore((state) => [state.setFullscreenId])
  return (
    <Container>
      <StyledIconClose onClick={() => seFullscreenId(null)} />
      <VerticalTabs>
        {/* <Tab id="keyboard" title="Управление" icon={IconMouse}>
          <Content>
            <HotkeysTab />
          </Content>
          <Footer />
        </Tab> */}
        {/* <Tab id="sound" title="Звук" icon={IconSound}>
          <Content>
            <SoundTab />
          </Content>
          <Footer />
        </Tab> */}
        <Tab id="account" title={t('settings:account.title') || 'Account'} icon={IconAccount}>
          <Content>
            <AccountTab />
          </Content>
        </Tab>
      </VerticalTabs>
    </Container>
  )
}

const Container = styled.div`
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: #131927;
  z-index: 10;
  display: flex;
  justify-content: center;

  ${Row} + ${Row} {
    margin-top: 50px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const ActionButtonContainer = styled.div`
  display: flex;

  > * + * {
    margin-left: 24px;
  }
`

const Content = styled.div`
  margin-bottom: 48px;
  flex: 1;
`

const StyledIconClose = styled(IconClose)`
  position: absolute;
  top: 36px;
  right: 36px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 1;
`
