import type { Placement } from '@floating-ui/react'
import {
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
} from '@floating-ui/react'
import { useState } from 'react'

export const usePopover = (options?: { isOpen?: boolean; placement?: Placement }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(options?.isOpen || false)
  const { refs, floatingStyles, context } = useFloating({
    placement: options?.placement || 'right',
    middleware: [offset(10), shift(), flip()],
    open: isPopoverOpen,
    onOpenChange: setIsPopoverOpen,
  })

  const click = useClick(context)
  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([click, dismiss])

  return {
    popoverProps: {
      ref: refs.setFloating,
      style: floatingStyles,
      ...getFloatingProps(),
    },
    parentProps: {
      ref: refs.setReference,
      ...getReferenceProps(),
    },
    isPopoverOpen,
    closePopover: () => setIsPopoverOpen(false),
  }
}
