import styled from '@emotion/styled'
import type { FC } from 'react'
import { t } from '~utils/i18n'

type Props = {
  level: number
  experience: number
  levelExperienceMax?: number | null
}

export const CharacterExperienceBar: FC<Props> = ({ level, experience, levelExperienceMax }) => (
  <>
    <ProgressContainer>
      <Level>Level {level}</Level>
      <Exp>
        {t('character:experience')} {experience}
        {levelExperienceMax ? ` / ${levelExperienceMax}` : ''}
      </Exp>
    </ProgressContainer>
    <LevelBar progress={levelExperienceMax ? (experience / levelExperienceMax) * 100 : 100} />
  </>
)

const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3px;
  max-width: 187px;
  margin-left: 14px;
  color: #646d85;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 400;
  margin-top: 13px;
`

const Level = styled.div`
  color: #ada08a;
`

const Exp = styled.div``

const LevelBar = styled.div<{ progress: number }>`
  width: 100%;
  max-width: 187px;
  height: 4px;
  margin-left: 14px;
  position: relative;
  background-color: #3c4860;
  margin-bottom: 12px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: ${({ progress }) => progress}%;
    height: 100%;
    background: linear-gradient(270deg, #d9d1bb 0%, #808080 100%);
  }
`
