import {
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  of,
  startWith,
  Subject,
  switchMap,
  throttleTime,
} from 'rxjs'
import { Map as MapLibreMap, NavigationControl } from 'maplibre-gl'
import { layersColorMap } from '~constants/layersColorMap'
import { ZOOM } from '~constants/map'
import { styleTwilightMap } from '~constants/styleTwilightMap'
import type { TwilightLevel } from '~types'
import type {
  MatchCharacterAbilityFragment as MatchCharacterAbility,
  MatchMapCharacterFragment as MatchMapCharacter,
} from '~generated/match-graphql'
import { matchStore } from '~store'
import { getCharacterTwilightRelation, spatialDistance } from '~map-tools'
import type { Point, UUID } from '~shared-types'
import { CharacterEntity } from './CharacterEntity'
import type { MapCharacterActionsManager } from './MapCharacterActionsManager'
import { MatchMinimap } from './MatchMinimap'
import { SelectedAbilityApplicationAreaEntity } from './SelectedAbilityApplicationAreaEntity'
import { Border } from './MatchBorder'
import { LayerManager } from './LayerManager'
import { actionPlanner } from './ActionPlanner'

export class MatchMap {
  protected static instance: MatchMap | undefined
  public map: MapLibreMap
  public minimap: MatchMinimap
  public characters: Map<string, CharacterEntity> = new Map()
  public selectedAbilityApplicationArea: SelectedAbilityApplicationAreaEntity
  public border: Border
  public layerManager: LayerManager
  public charactersChangeSubject: Subject<{
    character: CharacterEntity
    action: 'added' | 'removed'
  }>
  #startAt: string

  protected constructor() {
    this.map = new MapLibreMap({
      container: 'match-map',
      style: styleTwilightMap[0],
      center: [0, 0],
      zoom: ZOOM,
      dragRotate: false,
      // maxBounds
      // fitBoundsOptions?: FitBoundsOptions;
      keyboard: false,
      scrollZoom: false,
      doubleClickZoom: false,
      touchZoomRotate: false,
      touchPitch: false,
      pitchWithRotate: false,
      renderWorldCopies: false,
      validateStyle: false,
    })
    this.charactersChangeSubject = new Subject()

    this.selectedAbilityApplicationArea = new SelectedAbilityApplicationAreaEntity(this.map)

    this.layerManager = new LayerManager(this.map)
    this.minimap = new MatchMinimap(this)

    this.border = new Border(this.layerManager, this.minimap.layerManager, 'match')
    this.#startAt = ''

    this.map.on('load', () => {
      this.minimap?.minimap.setCenter(this.map.getCenter())
      this.map.on('move', () => {
        this.minimap?.updateBounds(this.map.getBounds())
      })

      this.map.addControl(new NavigationControl(), 'top-right')

      this.map.on('contextmenu', async (e) => {
        if (matchStore.getState().selectedAbility) {
          matchStore.getState().clearSelectedAbility()
        } else {
          const myCharacter = this.getCurrentCharacter()
          if (!myCharacter) {
            return
          }
          const point = [e.lngLat.lng, e.lngLat.lat] satisfies Point
          if (this.border.isInside(point)) {
            await actionPlanner.routeToCoordinate(point, myCharacter)
          }
        }
      })

      this.map.on('mousemove', (e) => {
        if (!matchStore.getState().selectedAbility?.targetUnitType) {
          this.selectedAbilityApplicationArea.updatePosition(e.lngLat)
        } else if (!matchStore.getState().selectedAbility) {
          this.selectedAbilityApplicationArea.hide()
        }
      })

      this.map.on('click', async (event) => {
        const { selectedAbility } = matchStore.getState()
        if (selectedAbility) {
          const myCharacter = this.getCurrentCharacter()
          if (!myCharacter) {
            return
          }
          if (
            selectedAbility.__typename === 'AbilityDirectionTargeted' ||
            selectedAbility.__typename === 'AbilityLocationTargeted'
          ) {
            const clickedCoord: Point = [event.lngLat.lng, event.lngLat.lat]

            await actionPlanner.castAbilityCoordinateTargeted(
              clickedCoord,
              selectedAbility,
              myCharacter,
            )
          }
        } else {
          matchStore.getState().setTargetCharacter(undefined)
        }
      })
    })

    // this.map.on('styledata', (data) => {
    //   if (!this.minimap) {
    //     return
    //   }
    //   this.minimap?.minimap.setStyle((data as any).style)
    // })
  }

  public get startAt() {
    return this.#startAt
  }

  public set startAt(value: string) {
    this.#startAt = value
  }

  public static getInstance(): MatchMap {
    if (!MatchMap.instance) {
      MatchMap.instance = new MatchMap()
    }

    return MatchMap.instance
  }

  public static remove() {
    const matchMap = this.getInstance()
    matchMap.map.remove()
    delete MatchMap.instance
  }

  public getCurrentCharacter() {
    return Array.from(this.characters.values()).find(({ isCurrentUser }) => isCurrentUser)
  }

  public addCharacter(
    character: MatchMapCharacter,
    abilities: MatchCharacterAbility[],
    actionsManager: MapCharacterActionsManager,
    isCurrentUser: boolean,
  ) {
    const mapCharacter = new CharacterEntity(
      this,
      character,
      actionsManager,
      abilities,
      isCurrentUser,
    )
    this.characters.set(character.id, mapCharacter)
    if (mapCharacter.isCurrentUser) {
      this.selectedAbilityApplicationArea.setCharacter(mapCharacter)
      mapCharacter.twilightLevelSubject.subscribe((twilightLevel) => {
        this.setTwilightMapStyle(twilightLevel)
      })
      mapCharacter.aliveSubject.subscribe((alive) => {
        if (!alive) {
          this.setTwilightMapStyle('death')
        }
      })
    }

    this.charactersChangeSubject.next({ character: mapCharacter, action: 'added' })

    if (mapCharacter.isCurrentUser) {
      this.characters.forEach((anotherCharacter) =>
        this.setRelationsToCurrentCharacter(mapCharacter, anotherCharacter),
      )
    } else {
      const currentCharacter = Array.from(this.characters.values()).find(
        ({ isCurrentUser: isCurrent }) => isCurrent,
      )
      if (currentCharacter) {
        this.setRelationsToCurrentCharacter(currentCharacter, mapCharacter)
      }
    }

    return mapCharacter
  }

  private setRelationsToCurrentCharacter(
    currentCharacter: CharacterEntity,
    anotherCharacter: CharacterEntity,
  ) {
    combineLatest([
      currentCharacter.twilightLevelSubject.pipe(startWith(currentCharacter.twilightLevel)),
      anotherCharacter.twilightLevelSubject.pipe(startWith(anotherCharacter.twilightLevel)),
    ])
      .pipe(
        map(([currentCharacterLevel, level]) =>
          getCharacterTwilightRelation(currentCharacterLevel, level),
        ),
        distinctUntilChanged(),
      )
      .subscribe((twilightRelation) => {
        anotherCharacter.twilightRelationSubject.next(twilightRelation)
      })

    const teamRelation = currentCharacter.teamId === anotherCharacter.teamId ? 'ALLY' : 'ENEMY'
    if (teamRelation === 'ENEMY') {
      anotherCharacter.statsSubject
        .pipe(
          map((stats) => stats.invisibilityRange),
          distinctUntilChanged(),
          switchMap((invisibilityRange) => {
            if (!invisibilityRange) {
              const visible = !invisibilityRange
              anotherCharacter.visibleSubject.next(visible)
              return of(null)
            }

            const aliveAllies$ = combineLatest(
              Array.from(this.characters.values())
                .filter((character) => character.teamId === currentCharacter.teamId)
                .map((character) =>
                  character.aliveSubject.pipe(
                    startWith(character.alive),
                    filter((alive) => alive),
                    map(() => character),
                  ),
                ),
            )

            const alliesPositions$ = aliveAllies$.pipe(
              switchMap((aliveAllies) =>
                combineLatest(
                  aliveAllies.map((character) =>
                    character.positionObservable.pipe(
                      startWith(character.position),
                      throttleTime(300, undefined, { leading: true, trailing: false }),
                    ),
                  ),
                ),
              ),
            )

            const enemyPosition$ = anotherCharacter.positionObservable.pipe(
              startWith(anotherCharacter.position),
              throttleTime(300, undefined, { leading: true, trailing: false }),
            )

            const enemyAlive$ = anotherCharacter.aliveSubject.pipe(
              startWith(anotherCharacter.alive),
            )

            return combineLatest([alliesPositions$, enemyPosition$, enemyAlive$]).pipe(
              map(([allyPositions, enemyPosition, enemyAlive]) => {
                if (!enemyAlive) {
                  return false
                }
                return allyPositions.every(
                  (allyPosition) =>
                    spatialDistance(allyPosition, enemyPosition) > invisibilityRange,
                )
              }),
            )
          }),
        )
        .subscribe((invisible) => {
          if (invisible !== null) {
            anotherCharacter.visibleSubject.next(!invisible)
          }
        })
    } else {
      const visible = true
      anotherCharacter.visibleSubject.next(visible)
    }

    if (teamRelation === 'ENEMY') {
      anotherCharacter.statusEffectsGroups.subject
        .pipe(
          map((effects) =>
            effects.some(
              (effect) =>
                effect.__typename === 'LastingStatusEffectsGroup' &&
                effect.causedByAbilityEffect.caster.id === currentCharacter.id,
            ),
          ),
          distinctUntilChanged(),
        )
        .subscribe((hasInsight) => {
          anotherCharacter.areActionsVisibleSubject.next(hasInsight)
        })
    } else {
      anotherCharacter.areActionsVisibleSubject.next(true)
    }
  }

  public removeCharacter(characterId: UUID) {
    const character = this.characters.get(characterId)
    if (character) {
      character.destroy()
      this.charactersChangeSubject.next({ character, action: 'removed' })
    }
  }

  public setCenter(center: Point) {
    this.map.setCenter(center)
  }

  private setTwilightMapStyle(twilight: TwilightLevel | 'death') {
    const twilightConfig = layersColorMap[twilight]
    Object.entries<object>(twilightConfig).forEach(([layer, style]) => {
      Object.entries(style).forEach(([property, value]) => {
        this.map.setPaintProperty(layer, property, value)
        this.minimap?.minimap.setPaintProperty(layer, property, value)
      })
    })
  }
}
