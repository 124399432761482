import styled from '@emotion/styled/macro'
import type { ReactElement } from 'react'
import React, { useState } from 'react'
import type { TabProps } from './Tab'

type Props = {
  children: ReactElement<TabProps> | ReactElement<TabProps>[]
  className?: string
}

export const VerticalTabs: React.FC<Props> = ({ className, children }) => {
  const childrenArray = Array.isArray(children) ? children : [children]
  const [selectedTabId, setSelectedTabId] = useState(childrenArray[0].props.id || undefined)

  return (
    <Container className={className}>
      <Header>
        {childrenArray.map((item, index) => {
          const active = item.props.id === selectedTabId
          return (
            <HeaderTab
              key={item.props.id}
              active={active}
              isFirst={index === 0}
              onClick={() => {
                item.props?.onChange?.()
                setSelectedTabId(item.props.id)
              }}
            >
              {item.props.icon ? <Icon src={item.props.icon} /> : null}
              {item.props.title}
            </HeaderTab>
          )
        })}
      </Header>
      <Content>{childrenArray.find((i) => i.props.id === selectedTabId) || null}</Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
`

const Header = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 48px 12px;
  width: 260px;
  background: linear-gradient(to bottom, #131927 0%, #2a3041 100%);
`

const HeaderTab = styled.div<{ active: boolean; isFirst: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  font-weight: 600;
  color: ${({ active }) => (active ? '#fff' : '#ADA08A')};
  background: ${({ active }) => (active ? '#131927' : 'transparent')};
  border-radius: 12px;
  padding: 10px 15px;
  height: 72px;
  transition: all 0.3s;
  white-space: nowrap;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    background: #131927;
  }
`

const Content = styled.div`
  flex: 1;
  padding: 86px 50px 70px;
  font-size: 14px;
  color: #646d85;
  display: flex;
  flex-direction: column;
`

const Icon = styled.div<{ src: string }>`
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #2a3041 url('${({ src }) => src}') no-repeat center;
`
