/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Map } from 'maplibre-gl'
import type { CharacterEntity } from '../CharacterEntity'
import type { MatchCharacterAnimatedAbilityFragment } from '~generated/match-graphql'
import { SingleUnitAnimation } from './SingleUnitAnimation'
import { AttackMeleeAnimation } from './AttackMeleeAnimation'
import type { AbilityAnimationType } from '~utils/animation'
import { PortalAnimation } from './PortalAnimation'

export type MatchCharacterAbilityWithAnimation = MatchCharacterAnimatedAbilityFragment & {
  effectAnimationType: AbilityAnimationType
}

export class EffectAnimation {
  constructor(
    map: Map,
    caster: CharacterEntity,
    target: CharacterEntity,
    castedAbility: MatchCharacterAnimatedAbilityFragment,
  ) {
    if (!this.isAbilityWithEffectAnimationType(castedAbility)) {
      return
    }

    if (castedAbility.effectAnimationType === 'PORTAL') {
      const portalAnimation = new PortalAnimation(map, caster, target.position)
      return
    }

    const singleUnitAnimationTypes: AbilityAnimationType[] = ['DEBUFF', 'HEAL', 'BUFF', 'DAMAGE']

    if (singleUnitAnimationTypes.includes(castedAbility.effectAnimationType)) {
      const singleUnitAnimation = new SingleUnitAnimation(
        map,
        caster,
        target,
        castedAbility.effectAnimationType,
      )
      return
    }

    if (castedAbility.effectAnimationType === 'ATTACK_MELEE') {
      const attackMeleeAnimation = new AttackMeleeAnimation(map, caster, target)
      return
    }

    const selfTarget = caster.id === target.id
    if (castedAbility.animationType === 'CHANNEL' && selfTarget) {
      const singleUnitAnimation = new SingleUnitAnimation(
        map,
        caster,
        target,
        castedAbility.effectAnimationType,
      )
    }
  }

  private isAbilityWithEffectAnimationType(
    castedAbility: MatchCharacterAnimatedAbilityFragment,
  ): castedAbility is MatchCharacterAbilityWithAnimation {
    return (castedAbility as MatchCharacterAbilityWithAnimation).effectAnimationType !== undefined
  }
}
