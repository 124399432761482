import { destination } from '@turf/turf'
import type { Map } from 'maplibre-gl'
import type { Point } from '~shared-types'

export const calculateRangeInPx = (map: Map, position: [number, number], rangeMeters: number) => {
  const centerPx = map.project(position)
  const dest = destination(position, rangeMeters, 0, {
    units: 'meters',
  })
  const edgePx = map.project(dest.geometry.coordinates as [number, number])
  return (centerPx.y - edgePx.y) * 2
}

export const convertMetersToPixels = (map: Map, meters: number) => {
  const mapCenter = [map.getCenter().lng, map.getCenter().lat] satisfies Point
  const centerPx = map.project(mapCenter)
  const dest = destination(mapCenter, meters, 0, {
    units: 'meters',
  })
  const edgePx = map.project([
    dest.geometry.coordinates[0],
    dest.geometry.coordinates[1],
  ] satisfies Point)

  return centerPx.y - edgePx.y
}
