import { useNavigate } from 'react-router-dom'
import type { CreateCharacterMutationVariables } from '~generated/graphql'
import {
  SessionDocument,
  useCreateCharacterMutation,
  useEnterGameMutation,
} from '~generated/graphql'

export const useCreateCharacter = () => {
  const navigate = useNavigate()
  const [enterGame] = useEnterGameMutation({
    refetchQueries: [SessionDocument],
  })
  const [createCharacter, query] = useCreateCharacterMutation({
    onError: (error) => {
      console.error(error)
    },
  })

  const createCharacterMutation = async (data: CreateCharacterMutationVariables) => {
    const createdCharacter = await createCharacter({
      variables: data,
    })
    await enterGame({
      variables: {
        characterId: createdCharacter.data?.createCharacter.id as string,
      },
    })
  }

  return [createCharacterMutation, query] as [typeof createCharacterMutation, typeof query]
}
