import 'reflect-metadata'
import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Race, Second, Side } from '~shared-types'
import { animationDurationMap } from '~utils/animation'
import { AbilityAreaLocation } from '../AbilityAreaLocation'

type Props = {
  side: Side
  race: Race
  id: string
  castTime?: Second
  range: number
}

export const AbilityLocationAreaAnimationUI: FC<Props> = ({ race, side, range, id, castTime }) => (
  <Container size={range}>
    <LocationArea
      race={race}
      side={side}
      radius={range / 2}
      duration={animationDurationMap.ATTACK_LOCATION_AREA}
      castTime={castTime || 0}
      id={id}
    />
  </Container>
)

const Container = styled.div<{
  size?: number
}>`
  width: ${({ size = 0 }) => size}px;
  height: ${({ size = 0 }) => size}px;
  position: relative;
  pointer-events: none;
`

const LocationArea = styled(AbilityAreaLocation)<{
  duration: Second
  castTime?: Second
  id: string
}>`
  will-change: transform, opacity;
  animation-name: ${({ id }) =>
    `ability-animation-location-area-${id}-rotate, ability-animation-location-area-${id}-active`};
  animation-duration: ${({ duration, castTime = 0 }) => `${castTime + duration}s, ${duration}s`};
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  animation-delay: 0s, ${({ castTime = 0 }) => castTime}s;
  opacity: 0.5;
  transition: opacity 0.5s;

  @keyframes ${({ id }) => `ability-animation-location-area-${id}-rotate`} {
    100% {
      transform: rotate(${({ castTime = 0, duration }) => (castTime + duration) * 10}deg);
    }
  }

  @keyframes ${({ id }) => `ability-animation-location-area-${id}-active`} {
    0% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
