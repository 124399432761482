import 'reflect-metadata'
import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Race, Second, Side } from '~shared-types'
import {
  animationDurationMap,
  animationIterationCountMap,
  animationSpriteCountMap,
  animationSpriteUrlMap,
} from '~utils/animation'

type Props = {
  side: Side
  race: Race
  id: string
  width: number
  height: number
  motionDuration: Second
  motionDelay: Second
}

export const AbilityAttackDirectionAnimationUI: FC<Props> = ({
  race,
  side,
  width,
  height,
  id,
  motionDelay,
  motionDuration,
}) => {
  const sprite = animationSpriteUrlMap[side]?.[race]?.ATTACK_DIRECTION

  return (
    <Container width={width} height={height}>
      <AnimationContainer
        duration={animationDurationMap.ATTACK_DIRECTION}
        motionDuration={motionDuration}
        count={animationSpriteCountMap.ATTACK_DIRECTION}
        width={Math.round(((height + 40) * 600) / 460)}
        height={height + 40}
        delay={motionDelay}
        spriteUrl={sprite}
        id={id}
        iterationCount={animationIterationCountMap.ATTACK_DIRECTION}
      />
    </Container>
  )
}

const Container = styled.div<{ width: number; height: number }>`
  width: ${({ width }) => width}px;
  height: ${({ height }) => height}px;
  margin-left: -${({ width }) => width}px;
  pointer-events: none;
  position: relative;
`

const AnimationContainer = styled.div<{
  duration: Second
  count: number
  spriteUrl?: string
  id: string
  width: number
  height: number
  delay?: Second
  iterationCount: 'infinite' | number
  motionDuration?: Second
}>`
  background: url(${({ spriteUrl }) => spriteUrl || ''}) no-repeat;
  background-size: ${({ count }) => count * 100}%;
  background-position-y: center;
  background-repeat: no-repeat;

  width: ${({ width = 0 }) => width}px;
  height: ${({ height = 0 }) => height}px;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -${({ height = 0 }) => height / 2}px;
  opacity: 0;

  animation-name: ${({ id }) => `ability-animation-${id}`},
    ${({ id }) => `ability-animation-${id}-move`};
  animation-duration: ${({ duration }) => duration}s, ${({ motionDuration }) => motionDuration}s;
  animation-timing-function: ${({ count }) => `steps(${count})`}, linear;
  animation-iteration-count: ${({ iterationCount }) => iterationCount}, 1;
  animation-fill-mode: forwards;
  animation-delay: ${({ delay = 0 }) => delay}s;
  will-change: background-position-x, transform;
  transform: scale(0.5);
  left: calc(100% - ${({ width = 0 }) => width / 2}px);

  @keyframes ${({ id }) => `ability-animation-${id}`} {
    0% {
      background-position-x: 0;
    }

    100% {
      background-position-x: -${({ width = 0, count }) => width * count}px;
    }
  }

  @keyframes ${({ id }) => `ability-animation-${id}-move`} {
    0% {
      opacity: 1;
      left: calc(100% - ${({ width = 0 }) => width / 2}px);
      transform: scale(0.5);
    }

    30% {
      transform: scale(1);
    }

    99% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      left: -${({ width = 0 }) => width / 2}px;
      transform: scale(1);
    }
  }
`
