import styled from '@emotion/styled'
import { forwardRef } from 'react'
import { Button } from '~components/Button'
import { MapPopover } from '~components/MapPopover'
import type { Side } from '~shared-types'
import { sideTextColorMap } from '~utils/character'
import { t } from '~utils/i18n'

export type OpenMatchTeam = {
  character: OpenMatchParticipant
  dominancePercent: number
  value: number
  side: Side
  participantsCount: number
}

type OpenMatchParticipant = {
  firstName: string
  lastName: string
}

type Props = {
  leftTeam: OpenMatchTeam
  rightTeam: OpenMatchTeam
  address: string | undefined
  onSubmit?: () => void
  onClose?: () => void
}

type Ref = HTMLDivElement

export const OpenMatchPopover = forwardRef<Ref, Props>(
  ({ leftTeam, rightTeam, address, onSubmit, onClose, ...props }, ref) => {
    const stats = [
      {
        label: t('popover:openMatch.participantsLabel') || '',
        value: (
          <TextContainer>
            <Text color={sideTextColorMap[leftTeam.side]}>{leftTeam.participantsCount}</Text> /
            <Text color={sideTextColorMap[rightTeam.side]}>{rightTeam.participantsCount}</Text>
          </TextContainer>
        ),
      },
      {
        label: t('popover:openMatch.participantsValueLabel') || '',
        value: (
          <TextContainer>
            <Text color={sideTextColorMap[leftTeam.side]}>{leftTeam.value}</Text> /
            <Text color={sideTextColorMap[rightTeam.side]}>{rightTeam.value}</Text>
          </TextContainer>
        ),
      },
      {
        label: t('popover:openMatch.forceRatioLabel') || '',
        value: (
          <TextContainer>
            <Text color={sideTextColorMap[leftTeam.side]}>
              {Math.trunc(leftTeam.dominancePercent)}
            </Text>
            /
            <Text color={sideTextColorMap[rightTeam.side]}>
              {Math.trunc(rightTeam.dominancePercent)}
            </Text>
          </TextContainer>
        ),
      },
    ]

    return (
      <MapPopover
        title={`${leftTeam.character.firstName} ${leftTeam.character.lastName} x ${rightTeam.character.firstName} ${rightTeam.character.lastName}`}
        address={address}
        stats={stats}
        onClose={onClose}
        buttons={
          onSubmit
            ? [
                <SubmitButton key="submit" onClick={onSubmit}>
                  {t('button:join')}
                </SubmitButton>,
              ]
            : undefined
        }
        ref={ref}
        {...props}
      />
    )
  },
)

const SubmitButton = styled(Button)`
  min-width: auto;
  flex-grow: 1;
`

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  color: #646d85;
  gap: 2px;
`

const Text = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`
