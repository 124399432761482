import { useRequestPasswordResetMutation } from '~generated/graphql'

export const useResetPassword = () =>
  useRequestPasswordResetMutation({
    onError: (error) => {
      console.error(error)
    },
    onCompleted: async (data) => {
      try {
        console.log(data)
      } catch (e) {
        console.log('useRequestPasswordResetMutation', e)
      }
    },
  })
