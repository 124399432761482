import type { LastingCrowdControlEffect } from '../../../../generated/match-graphql'

export class CrowdControlsService {
  static hasRouteControls(crowdControls: LastingCrowdControlEffect[]): boolean {
    const routeControls = [
      'ROOT',
      'STUN',
      'KNOCKBACK',
      'STASIS',
    ] satisfies LastingCrowdControlEffect[]
    return routeControls.some((routeControl) => crowdControls.includes(routeControl))
  }

  static hasCastControls(crowdControls: LastingCrowdControlEffect[]): boolean {
    const abilityControls = [
      'SILENCE',
      'STUN',
      'KNOCKBACK',
      'STASIS',
    ] satisfies LastingCrowdControlEffect[]
    return abilityControls.some((abilityControl) => crowdControls.includes(abilityControl))
  }
}
