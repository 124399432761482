import styled from '@emotion/styled/macro'
// import ArtefactIcon from '~assets/artefact-mock.png'
import { distinctUntilChanged, EMPTY, map } from 'rxjs'
import type { Observable } from 'rxjs'
import BgAbilitiesPanelArtefact from '~assets/bg-abilities-panel-artefact.svg'
import BgAbilitiesPanel from '~assets/bg-abilities-panel.svg'
import type { AbilityCooldown } from '~store'
import type { DurationIso, Int } from '~shared-types'
import { AbilitiesPanelItem } from './AbilitiesPanelItem'
import type { Side } from '../../generated/graphql'
import type {
  AbilityTargetType,
  MatchCharacterAbilityFragment,
} from '../../generated/match-graphql'
import type { AdvancedStats } from '../CharacterPanel/CharacterAdvancedStats'
import type { MainStats } from '../CharacterPanel/CharacterPanelMainStats'
import type { CrowdControl } from '../../views/match/model/statusEffectsGroups/CharacterStatusEffectsGroup'
import { useValueFromObservable } from '../quest/useValueFromObservable'
import { CrowdControlsService } from '../../views/match/model/statusEffectsGroups/crowdControls.service'

type PanelAbility = {
  id: number
  targetType?: AbilityTargetType
  name: string
  icon: string
  effectDescription: string
  cooldown: DurationIso
  range: { max: number }
}

type AbilitiesStore = {
  currentCharacter?: unknown
  selectedAbility?: MatchCharacterAbilityFragment
  abilitiesCooldown?: AbilityCooldown[]
  setAbilityRange?: (abilityRange?: Int) => void
}

type Props<T extends PanelAbility> = {
  abilities?: T[]
  statsObservable?: Observable<AdvancedStats & MainStats>
  crowdControlsObservable?: Observable<CrowdControl[]>
  defaultAbilitiesNumber?: number
  isView?: boolean
  side: Side
  onSelectAbility?: (ability: T) => void
  className?: string
  useAbilitiesStore: <U>(selector: (state: AbilitiesStore) => U) => U
}

export const AbilitiesPanel = <T extends PanelAbility>({
  abilities,
  statsObservable,
  crowdControlsObservable = EMPTY,
  defaultAbilitiesNumber = 7,
  side,
  onSelectAbility,
  isView = false,
  className,
  useAbilitiesStore,
}: Props<T>) => {
  const [selectedAbility, abilitiesCooldown, setAbilityRange] = useAbilitiesStore((state) => [
    state.selectedAbility,
    state.abilitiesCooldown,
    state.setAbilityRange,
  ])

  const isInSpellControl = useValueFromObservable(
    crowdControlsObservable.pipe(
      map((crowdControls) =>
        CrowdControlsService.hasCastControls(crowdControls.map((control) => control.type)),
      ),
      distinctUntilChanged(),
    ),
  )

  return (
    <Container className={className}>
      {/* <ArtefactContainer>
        <Image src={ArtefactIcon} />
      </ArtefactContainer> */}
      <AbilitiesPanelContainer hideArtefact>
        {Array.from({ length: defaultAbilitiesNumber }).map((_, index) => {
          const ability = abilities?.[index]
          const abilityCooldown = abilitiesCooldown?.find(
            (cooldown) => cooldown.abilityId === ability?.id,
          )
          return (
            <StyledAbilitiesPanelItem
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              isSelected={!!ability && selectedAbility?.id === ability?.id}
              isDisabled={isInSpellControl}
              side={side}
              ability={ability}
              statsObservable={statsObservable}
              abilityCooldown={abilityCooldown}
              onMouseLeave={
                isView
                  ? undefined
                  : () => {
                      if (!ability || selectedAbility) {
                        return
                      }
                      setAbilityRange?.(undefined)
                    }
              }
              onMouseEnter={
                isView
                  ? undefined
                  : () => {
                      if (!ability || selectedAbility) {
                        return
                      }
                      setAbilityRange?.(ability.range.max)
                    }
              }
              onClick={() => {
                if (abilityCooldown || !ability || isInSpellControl) {
                  return
                }
                onSelectAbility?.(ability)
              }}
            />
          )
        })}
      </AbilitiesPanelContainer>
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 5;
`

// const ArtefactContainer = styled.div`
//   width: 144px;
//   height: 144px;
//   position: relative;
//   z-index: 2;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   background: linear-gradient(
//     341deg,
//     #d9d1bb 12.73%,
//     #808080 32.52%,
//     #ada08a 53.74%,
//     #d9d1bb 72.39%,
//     #eae4cf 91.39%
//   );
//   border-radius: 50%;

//   &:before {
//     content: '';
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     box-shadow: 0px 0px 4px 4px rgba(19, 25, 39, 0.35) inset,
//       0px 0px 30px 24px rgba(19, 25, 39, 0.5) inset;
//     width: 128px;
//     height: 128px;
//     background: linear-gradient(143deg, #2a3041 14.29%, #3c4860 84.81%);
//     border-radius: 50%;
//   }
// `

// const Image = styled.img`
//   position: relative;
//   z-index: 2;
// `

const StyledAbilitiesPanelItem = styled(AbilitiesPanelItem)``

const AbilitiesPanelContainer = styled.div<{ hideArtefact: boolean }>`
  width: 471px;
  height: 156px;
  transform: translateY(16px);
  background: url(${({ hideArtefact }) =>
      hideArtefact ? BgAbilitiesPanel : BgAbilitiesPanelArtefact})
    right center no-repeat;
  background-size: contain;
  margin-left: ${({ hideArtefact }) => (hideArtefact ? '0' : '-70px')};
  padding-left: ${({ hideArtefact }) => (hideArtefact ? '93px' : '93px')};
  padding-top: 2px;
  position: relative;
  z-index: 1;
  display: flex;
`
