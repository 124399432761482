import { Marker } from 'maplibre-gl'
import { createRoot } from 'react-dom/client'
import type { Subscription } from 'rxjs'
import type { Side, MatchMapCharacterFragment as MatchMapCharacter } from '~generated/match-graphql'
import { MinimapCharacterMarker } from '~components/map/Minimap/MinimapCharacterMarker'
import type { Point } from '~shared-types'
import type { ZoneCharacter } from './ZoneCharacter'
import type { ZoneMinimap } from './ZoneMinimap'
import type { MapRoute } from '../../match/model/MapRoute'
import { imageUrlMap } from '~constants/imageUrlMap'

export class MinimapCharacter {
  public id: string
  public side: Side
  public position: MatchMapCharacter['position']
  #marker: Marker
  public isCurrentUser?: boolean
  private subscriptions: Subscription[]

  constructor(private minimapClass: ZoneMinimap, character: ZoneCharacter) {
    this.id = character.id
    this.side = character.side
    this.position = character.position!
    this.isCurrentUser = character.isCurrentUser

    const div = document.createElement('div')
    const root = createRoot(div)
    const component = (
      <MinimapCharacterMarker
        side={character.side}
        avatar={character.avatar || imageUrlMap.ImgFallbackCharacter}
      />
    )
    root.render(component)
    this.#marker = new Marker({ element: div, className: 'maplibregl-minimap-marker-character' })
    this.#marker.setLngLat(character.position!)
    if (character.display) {
      this.#marker.addTo(minimapClass.minimap)
    }
    const positionSubscription = character.positionSubject.subscribe((position) =>
      this.updatePosition(position),
    )
    const displaySubscription = character.displaySubject.subscribe((display) =>
      display ? minimapClass.layerManager.addMarker(this.#marker) : this.#marker.remove(),
    )
    this.subscriptions = [positionSubscription, displaySubscription]
  }

  destroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.#marker.remove()
  }

  updatePosition(position: Point) {
    this.#marker.setLngLat(position)
    if (this.isCurrentUser) {
      this.minimapClass.minimap.setCenter(position)
    }
  }
}
