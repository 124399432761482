export const layersColorMap = {
  0: {
    background: {
      'background-color': 'rgba(27, 36, 51, 1)',
    },
    landcover_wood: {
      'fill-color': 'rgba(32, 43, 61, 1)',
    },
    building_pattern: {
      'fill-opacity': 0,
    },
    building: {
      'fill-opacity': 1,
      'fill-color': 'rgba(32, 43, 61, 1)',
    },
    highway_minor: {
      'line-color': 'rgba(45, 56, 60, 1)',
    },
    highway_major_casing: {
      'line-color': {
        stops: [
          [8, 'rgba(0, 0, 0, 1)'],
          [13, 'rgba(45, 56, 60, 1)'],
        ],
      },
    },
    highway_major_inner: {
      'line-color': {
        stops: [
          [8, 'rgba(0, 0, 0, 1)'],
          [13, 'rgba(83, 83, 75, 1)'],
        ],
      },
    },
  },
  1: {
    background: {
      'background-color': 'rgba(22, 30, 43, 1)',
    },
    landcover_wood: {
      'fill-color': 'rgba(30, 35, 55, 1)',
    },
    building_pattern: {
      'fill-opacity': 0,
    },
    building: {
      'fill-opacity': 1,
      'fill-color': 'rgba(30, 35, 55, 1)',
    },
    highway_minor: {
      'line-color': 'rgba(34, 40, 62, 1)',
    },
    highway_major_casing: {
      'line-color': {
        stops: [
          [8, 'rgba(0, 0, 0, 1)'],
          [13, 'rgba(34, 40, 62, 1)'],
        ],
      },
    },
    highway_major_inner: {
      'line-color': {
        stops: [
          [8, 'rgba(0, 0, 0, 1)'],
          [13, 'rgba(51, 63, 87, 1)'],
        ],
      },
    },
  },
  2: {
    background: {
      'background-color': 'rgba(19, 25, 37, 1)',
    },
    landcover_wood: {
      'fill-color': 'rgba(25, 30, 45, 1)',
    },
    building_pattern: {
      'fill-opacity': 1,
    },
    building: {
      'fill-opacity': 0,
    },
    highway_minor: {
      'line-color': '#191E2D',
    },
    highway_major_casing: {
      'line-color': {
        stops: [
          [8, 'rgba(0, 0, 0, 1)'],
          [13, 'rgba(24, 30, 44, 1)'],
        ],
      },
    },
    highway_major_inner: {
      'line-color': {
        stops: [
          [8, 'rgba(0, 0, 0, 1)'],
          [13, 'rgba(46, 54, 77, 1)'],
        ],
      },
    },
  },
  3: {
    background: {
      'background-color': 'rgba(17, 21, 32, 1)',
    },
    landcover_wood: {
      'fill-color': 'rgba(25, 30, 45, 1)',
    },
    building_pattern: {
      'fill-opacity': 0,
    },
    building: {
      'fill-color': 'rgba(17, 21, 32, 1)',
      'fill-opacity': 1,
    },
    highway_minor: {
      'line-color': '#191E2D',
    },
    highway_major_casing: {
      'line-color': {
        stops: [
          [8, 'rgba(0, 0, 0, 1)'],
          [13, 'rgba(24, 30, 44, 1)'],
        ],
      },
    },
    highway_major_inner: {
      'line-color': 'transparent',
    },
  },
  death: {
    background: {
      'background-color': 'rgba(29, 34, 37, 1)',
    },
    landcover_wood: {
      'fill-color': 'rgba(39, 44, 48, 1)',
    },
    building_pattern: {
      'fill-opacity': 0,
    },
    building: {
      'fill-color': 'rgba(39, 44, 48, 1)',
      'fill-opacity': 1,
    },
    highway_minor: {
      'line-color': 'rgba(42, 50, 53, 1)',
    },
    highway_major_casing: {
      'line-color': {
        stops: [
          [8, 'rgba(0, 0, 0, 1)'],
          [13, 'rgba(42, 50, 53, 1)'],
        ],
      },
    },
    highway_major_inner: {
      'line-color': {
        stops: [
          [8, 'rgba(0, 0, 0, 1)'],
          [13, 'rgba(62, 73, 75, 1)'],
        ],
      },
    },
  },
} as const
