export const imageUrlMap = {
  ImgTaxi: {
    '1x': 'https://images.dozormoskva.com/xaOariPRR9hk5jeYD0S2BJdjMG_cN6J0Lo7FGHf_huM/size:103:103/q:80/czM6Ly9hcHAvaW1/nLXRheGkuanBn',
    '2x': 'https://images.dozormoskva.com/8IpStAIiEII-J_1I1QGYt_cQDH-eFrqF2Z6ICWYd2-E/size:206:206/q:80/czM6Ly9hcHAvaW1/nLXRheGkuanBn',
  },
  ImgFallbackCharacter: {
    headShot:
      'https://images.dozormoskva.com/vaGGB7vCCz6h12Ye410UV5Dkzo-G5v-wwOY3wHOElIk/pr:avatarHeadShot/czM6Ly9hcHAvaW1/nLWZhbGxiYWNrLWNoYXJhY3Rlci5wbmc',
    headShot2x:
      'https://images.dozormoskva.com/4-viA-nVaC3bAXaLJUNE_Pg6tVaqjtvvM8dRrAqdxAw/pr:avatarHeadShot2x/czM6Ly9hcHAvaW1/nLWZhbGxiYWNrLWNoYXJhY3Rlci5wbmc',
    halfBody:
      'https://images.dozormoskva.com/xT0nrQBckmzHy9_CJKabwIlDLoIEAWtPgyMHwT5Rk7c/pr:avatarHalfBody/czM6Ly9hcHAvaW1/nLWZhbGxiYWNrLWNoYXJhY3Rlci5wbmc',
    halfBody2x:
      'https://images.dozormoskva.com/bbzHzIOBZ5O0w3LVw1YuiwP0g2vepf8cFnlTozvE2ZQ/pr:avatarHalfBody2x/czM6Ly9hcHAvaW1/nLWZhbGxiYWNrLWNoYXJhY3Rlci5wbmc',
    fullBody:
      'https://images.dozormoskva.com/OWlj7911arCHxNZ_g6UUYtSLUQLzBi0m2dtHswk-oLQ/pr:avatarFullBody/czM6Ly9hcHAvaW1/nLWZhbGxiYWNrLWNoYXJhY3Rlci5wbmc',
    fullBody2x:
      'https://images.dozormoskva.com/V8XE-kNQeKK_m_bdmLIW61r5QEKQAEBhIqwMMotY2eo/pr:avatarFullBody2x/czM6Ly9hcHAvaW1/nLWZhbGxiYWNrLWNoYXJhY3Rlci5wbmc',
  },
} as const
