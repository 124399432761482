import type { Map as MaplibreMap } from 'maplibre-gl'
import { Marker } from 'maplibre-gl'
import { createRoot } from 'react-dom/client'
import { EMPTY, of } from 'rxjs'
import type { MarkerCharacter } from '~components/map/MapCharacterMarker/MapCharacterMarker'
import { MapCharacterMarker } from '~components/map/MapCharacterMarker/MapCharacterMarker'
import { store } from '~store'
import type { ZoneCharacter } from './ZoneCharacter'
import type { LayerManager } from '../../match/model/LayerManager'
import { Race } from '~generated/graphql'
import { CharacterTwilightRelation } from '~map-tools'
import { t } from '~utils/i18n'

const getMarkerCharacterFromClass = (character: ZoneCharacter): MarkerCharacter => ({
  id: character.id,
  firstName: character.firstName,
  lastName: character.lastName,
  race: character.race,
  side: character.side,
  stats: {
    ...character.stats,
    shield: 0,
  },
  position: character.position!,
  alive: true,
  twilightLevel: 0,
  avatar: character.avatar,
  level: character.level,
  experience: character.experience,
})

export class ZoneCharacterFrame {
  #marker: Marker

  constructor(
    private map: MaplibreMap,
    public character: ZoneCharacter,
    public layerManager: LayerManager,
  ) {
    const markerCharacter: MarkerCharacter = getMarkerCharacterFromClass(character)
    const menuItems = [
      {
        label: t('popover:characterMarker.attack') || '',
        onClick: async () => {
          store.getState().setOpenMatchWaitDialog({
            isAttacker: true,
          })

          try {
            await character.attack()
          } catch (e) {
            store.getState().hideOpenMatchWaitDialog()
          }
        },
      },
    ]
    const div = document.createElement('div')
    const root = createRoot(div)
    const component = (
      <MapCharacterMarker
        isCurrentCharacter={character.isCurrentUser ?? false}
        character={markerCharacter}
        statsObservable={character.statsSubject}
        menuItems={character.race !== 'HUMAN' ? menuItems : undefined}
        healthChangeObservable={EMPTY}
        spellCastObservable={EMPTY}
        twilightRelationObservable={of(CharacterTwilightRelation.REACHABLE)}
        crowdControlsObservable={EMPTY}
        visibleObservable={of(true)}
        aliveObservable={of(true)}
        areActionsVisibleObservable={of(character.isCurrentUser ?? false)}
      />
    )
    root.render(component)
    this.#marker = new Marker({ element: div, className: 'maplibregl-marker-character' })
    this.#marker.setLngLat(markerCharacter.position)
    if (character.display) {
      this.#marker.addTo(this.map)
    }

    this.character.positionSubject.subscribe((position) => this.#marker.setLngLat(position))

    this.character.displaySubject.subscribe((display) =>
      display ? this.layerManager.addMarker(this.#marker) : this.#marker.remove(),
    )
  }

  get marker() {
    return this.#marker
  }
}
