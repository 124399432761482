
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Ability": [
      "AbilityDirectionTargeted",
      "AbilityLocationTargeted",
      "AbilityUnitTargeted"
    ],
    "AbilityCastActionTarget": [
      "Character",
      "Coordinate"
    ],
    "AbilityDirectionShapeUnion": [
      "AbilityDirectionShapeArc",
      "AbilityDirectionShapeLine"
    ],
    "AbilityLocationShapeUnion": [
      "AbilityLocationShapeCircle",
      "AbilityLocationShapeRectangle"
    ],
    "AbilityUnitShapeUnion": [
      "AbilityDirectionShapeArc",
      "AbilityDirectionShapeLine",
      "AbilityLocationShapeCircle",
      "AbilityLocationShapeRectangle"
    ],
    "Action": [
      "AbilityCastAction",
      "AbilityChannelAction",
      "EnRouteAction"
    ],
    "LastingEffectChange": [
      "LastingEffectCrowdControlChange",
      "LastingEffectCrowdControlImmuneChange",
      "LastingEffectStatsChange"
    ],
    "StatusEffectsGroup": [
      "LastingStatusEffectsGroup",
      "PeriodicStatusEffectsGroup"
    ]
  }
};
      export default result;
    