import { TWILIGHT_ABILITY_IDS } from '~constants/map'
import { AbilitiesPanel } from '~components/AbilitiesPanel/AbilitiesPanel'
import { useMatchStore } from '~store'
import type { MatchCharacterAbilityFragment } from '~generated/match-graphql'
import { MatchMap } from '../model/MatchMap'
import { actionPlanner } from '../model/ActionPlanner'

export const MatchAbilitiesPanel = () => {
  const [currentCharacter, setSelectedAbility, selectedAbility, clearSelectedAbility] =
    useMatchStore((state) => [
      state.currentCharacter,
      state.setSelectedAbility,
      state.selectedAbility,
      state.clearSelectedAbility,
    ])

  const handleSelectAbility = async (ability: MatchCharacterAbilityFragment) => {
    if (!currentCharacter) {
      return
    }
    if (selectedAbility === ability) {
      clearSelectedAbility()
      return
    }

    if (ability.targetUnitType === 'SELF') {
      await actionPlanner.castAbilitySelfTargeted(ability, currentCharacter)
      return
    }
    setSelectedAbility(ability)

    if (
      (ability.targetType === 'LOCATION' || ability.targetType === 'DIRECTION') &&
      !ability.targetUnitType
    ) {
      MatchMap.getInstance().selectedAbilityApplicationArea.show(ability)
    }
  }

  const abilities = currentCharacter?.abilities.filter(
    ({ id }) => !TWILIGHT_ABILITY_IDS.includes(id),
  )

  return (
    <AbilitiesPanel
      abilities={abilities}
      onSelectAbility={handleSelectAbility}
      statsObservable={currentCharacter?.statsSubject}
      crowdControlsObservable={currentCharacter?.statusEffectsGroups.crowdControlsSubject}
      side={currentCharacter?.side ?? 'NEUTRAL'}
      useAbilitiesStore={useMatchStore}
    />
  )
}
