import styled from '@emotion/styled/macro'
import { DateTime } from 'luxon'
import type { ChangeEvent, FC, KeyboardEvent as ReactKeyboardEvent, RefObject } from 'react'
import { memo, useEffect, useRef, useState } from 'react'
import type { Message as MessageGql, Side } from '~generated/graphql'
import { useStore } from '~store'
import { ReactComponent as SubmitIcon } from '~assets/icon-chat-submit.svg'
import type { InfiniteListRef } from './InfiniteList'
import { InfiniteList } from './InfiniteList'
import { sideTextColorMap } from '~utils/character'
import type { UUID } from '~shared-types'

type Message = Pick<MessageGql, 'id' | 'content' | 'createdAt' | 'chatRoomId' | 'characterId'> & {
  character?: {
    id: UUID
    firstName: string
    lastName: string
    side: Side
  } | null
}

type Props = {
  listRef: RefObject<InfiniteListRef>
  messages: Message[]
  charactersMap: Map<
    string,
    {
      id: UUID
      firstName: string
      lastName: string
      side: Side
    }
  >
  onSendMessage: (message: string) => Promise<void>
  onFetchMore: () => Promise<void>
} & ({ matchId: UUID; teamId: UUID } | { matchId?: undefined; teamId?: undefined })

export const ChatUI: FC<Props> = memo(
  ({ listRef, onSendMessage, onFetchMore, messages, charactersMap, ...props }) => {
    const [session] = useStore((state) => [state.session])
    const [active, setActive] = useState(false)
    // const listRef = useRef<InfiniteListRef>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    // const shouldFetchMore = useRef(true)
    const [inputMessage, setInputMessage] = useState('')

    // useEffect(() => {
    //   listRef.current?.scrollToBottom()
    // }, [sendPrivateMessageData])

    // useEffect(() => {
    //   if (data) {
    //     // list component is not mounted yet, so we need to wait for it to mount
    //     setTimeout(() => {
    //       listRef.current?.scrollToBottom()
    //     }, 0)
    //   }
    // }, [data])

    const handlePageKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        inputRef.current?.focus()
        setActive(true)
      }
    }

    const handleInputKeyUp = async (e: ReactKeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        e.stopPropagation()
        e.preventDefault()
        if (inputMessage !== '') {
          await onSendMessage(inputMessage)
          setInputMessage('')
        } else {
          inputRef.current?.blur()
          setActive(false)
          listRef.current?.scrollToBottom()
        }
      }
    }

    useEffect(() => {
      document.addEventListener('keyup', handlePageKeyUp)

      return () => {
        document.removeEventListener('keyup', handlePageKeyUp)
      }
    }, [])

    return (
      <Container active={active}>
        <MessageList showShadow={active} ref={listRef} fetchMore={onFetchMore}>
          {messages.map((message) => {
            if (!session?.characterId) return null

            const { id, chatRoomId, characterId, createdAt, content } = message

            const isPrivate = chatRoomId.includes(session.characterId)
            if (isPrivate) {
              const isMeRecipient = characterId !== session.characterId
              const characterIds = chatRoomId.match(/[a-f0-9\\-]{36}/g) || []
              const anotherCharacterId = characterIds.find(
                (charId) => charId !== session.characterId,
              )!
              const character = charactersMap.get(anotherCharacterId)
              if (!character) {
                return null
              }
              return (
                <MessageItem key={id} isPrivate>
                  <Time>[{DateTime.fromISO(createdAt).toFormat('HH:mm')}]</Time>
                  <Text>{`${isMeRecipient ? '[From]' : '[To]'} ${character.firstName} ${
                    character.lastName
                  }: `}</Text>
                  <Text>{content}</Text>
                </MessageItem>
              )
            }

            let prefix
            if (props.matchId) {
              const { matchId } = props
              prefix = chatRoomId.includes(matchId) ? '[All]' : '[Ally]'
            }

            const character = message.character || charactersMap.get(characterId)
            const isMe = characterId === session.characterId
            if (!character) {
              return null
            }
            return (
              <MessageItem key={id} side={character.side} self={isMe}>
                <Time>[{DateTime.fromISO(createdAt).toFormat('HH:mm')}]</Time>
                <Name>{`${prefix ? `${prefix} ` : ''}${character.firstName} ${
                  character.lastName
                }: `}</Name>
                <Text>{message.content}</Text>
              </MessageItem>
            )
          })}
        </MessageList>
        <InputContainer show={active}>
          <Input
            type="text"
            ref={inputRef}
            onKeyUp={handleInputKeyUp}
            value={inputMessage}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setInputMessage(e.target.value)}
          />
          <SubmitButton onClick={() => onSendMessage(inputMessage)} />
        </InputContainer>
      </Container>
    )
  },
)

const Input = styled.input`
  border-radius: 19px;
  border: 1px solid #3c4860;
  background: #131927;
  outline: none;
  color: #fff;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 12px;
  padding-right: 30px;
  height: 32px;
  box-sizing: border-box;
  width: 100%;
`
const SubmitButton = styled(SubmitIcon)`
  position: absolute;
  right: 11px;
  top: 8px;
  cursor: pointer;
  fill: #3c4860;

  &:hover {
    fill: #ada08a;
  }
`
const InputContainer = styled.div<{ show: boolean }>`
  margin: 12px 12px 0;
  position: relative;
  opacity: ${({ show }) => (show ? '1' : '0')};
  pointer-events: ${({ show }) => (show ? 'auto' : 'none')};
`

const MessageList = styled(InfiniteList)`
  padding: 19px 24px 0;
  flex: 1;
  user-select: text;
`

const Container = styled.div<{ active: boolean }>`
  position: absolute;
  bottom: 52px;
  z-index: 20;
  left: 0;
  height: 260px;
  width: 395px;
  color: #a7afc4;
  font-size: 14px;
  line-height: 18px;
  padding: 0 0 12px;
  border-radius: 12px;
  overflow: hidden;
  background: ${(props) =>
    props.active
      ? 'linear-gradient(89deg, #131927 0.9%, rgba(42, 48, 65, 0.50) 99.42%)'
      : 'transparent'};
  display: flex;
  flex-direction: column;
  pointer-events: ${(props) => (props.active ? 'auto' : 'none')};
`

const Item = styled.div`
  &:not(:first-of-type) {
    margin-top: 10px;
  }
`

const Name = styled.div`
  font-weight: 600;
  margin-right: 5px;
  display: inline;
`

const Text = styled.div`
  display: inline;
`

const Time = styled.div`
  color: #646d85;
  margin-right: 5px;
  display: inline-block;
  font-size: 13px;
  font-family: 'Source Code Pro', monospace;
`

const MessageItem = styled(Item)<{ side?: Side; self?: boolean; isPrivate?: boolean }>`
  justify-self: ${(props) => (props.self ? 'flex-end' : 'flex-start')};
  color: ${({ self, isPrivate }) => (isPrivate ? '#725F9A' : self ? '#fff' : '#a7afc4')};

  ${Name} {
    color: ${({ side }) => (side ? sideTextColorMap[side] : '#fff')};
  }
`

// const NotificationItem = styled(Item)`
//   color: #ada08a;
//   font-weight: 600;
// `
