import { useEffect } from 'react'
import {
  usePrivateMessageReceivedSubscription,
  useGroupMessageReceivedSubscription,
} from '~generated/graphql'
import { useChatStore, useMatchStore } from '~store'

export const useMatchChatSubscription = ({
  scrollToBottom,
}: {
  scrollToBottom?: (skipIfScrolled?: boolean) => void
}) => {
  const [me] = useMatchStore((state) => [state.currentCharacter])

  const [addGeneralMessage] = useChatStore((state) => [state.addGeneralMessage])

  const { data: teamMessageData } = useGroupMessageReceivedSubscription({
    skip: !me?.teamId,
    variables: {
      groupId: me?.teamId ?? '',
    },
    onData: (data) => {
      if (!data.data?.data?.groupMessageReceived) return
      addGeneralMessage(data.data.data.groupMessageReceived)
    },
  })

  const { data: privateMessageData } = usePrivateMessageReceivedSubscription({
    onData: (data) => {
      if (!data.data?.data?.privateMessageReceived) return
      addGeneralMessage(data.data.data.privateMessageReceived)
    },
  })

  useEffect(() => {
    const isCurrentCharacterMessage = teamMessageData?.groupMessageReceived.characterId === me?.id
    scrollToBottom?.(!isCurrentCharacterMessage)
  }, [teamMessageData])

  useEffect(() => {
    scrollToBottom?.(true)
  }, [privateMessageData])
}
