import styled from '@emotion/styled'
import type { FC } from 'react'
import { Fragment } from 'react'
import { t } from '~utils/i18n'
import { sideColorMap, raceColorMap } from '~utils/character'
import type { MasteryInfoFragment, Race, Side } from '~generated/graphql'
import withTooltip from '~components/Tooltip/withTooltip'

import { ReactComponent as IconEnergy } from '~assets/icon-energy-tooltip.svg'
import { ReactComponent as IconHealth } from '~assets/icon-health-tooltip.svg'
import { ReactComponent as IconPointsBaloon } from '~assets/icon-mastery-points.svg'
import IconPhysicalPower from '~assets/icon-phys-power.svg'
import IconMagicPower from '~assets/icon-magic-power.svg'
import IconMagicResistance from '~assets/icon-magic-resistance.svg'
import IconPhysicalResistance from '~assets/icon-phys-resistance.svg'
import IconMovementSpeed from '~assets/icon-movement-speed.svg'
import IconBarBadge from '~assets/masteries-bar-badge.svg'

type Props = {
  race: Race
  side: Side
  availablePoints: number
  pointsLeft: number
  pointsRight: number
  isLeftBarDisabled: boolean
  isRightBarDisabled: boolean
  statsPerLevelLeft?: MasteryInfoFragment['statsPerLevel']
  statsPerLevelRight?: MasteryInfoFragment['statsPerLevel']
  masteryLeftType?: MasteryInfoFragment['type']
  masteryRightType?: MasteryInfoFragment['type']
}

const sectionPartHeight = 15
const sectionDividerHeight = 2

const calculateProgressHeight = (currentPoints: number) => {
  if (currentPoints === 0) return `0px`

  if (currentPoints % 3 === 0) {
    return `${(currentPoints / 12) * 100}%`
  }

  const badgeHeight = 18
  const sectionNumber = Math.floor(currentPoints / 3)
  const sectionDividerCount = currentPoints - Math.floor(currentPoints / 3)

  return `
    ${
      sectionPartHeight * currentPoints +
      badgeHeight / 2 +
      badgeHeight * sectionNumber +
      sectionDividerCount * sectionDividerHeight
    }px
  `
}

const maxPoints = 12
const pointsPerSection = 3
const sectionsCount = maxPoints / pointsPerSection

export const MasteriesBar: FC<Props> = ({
  race,
  side,
  availablePoints,
  pointsLeft,
  pointsRight,
  isLeftBarDisabled,
  isRightBarDisabled,
  statsPerLevelLeft,
  statsPerLevelRight,
  masteryLeftType,
  masteryRightType,
}) => (
  <Container>
    <PointsBaloon>
      <PointsBaloonText>
        <PointsBaloonTextNumber>{availablePoints}</PointsBaloonTextNumber>
        {t('masteries:point.point', { count: availablePoints })}
      </PointsBaloonText>
      <StyledIconPointsBaloon side={side} />
    </PointsBaloon>
    <Bar>
      <BarProgressContainer>
        <BarProgress
          side={side}
          progress={pointsLeft !== undefined ? calculateProgressHeight(pointsLeft) : undefined}
        />
        <BarProgress
          side={side}
          progress={pointsRight !== undefined ? calculateProgressHeight(pointsRight) : undefined}
        />
      </BarProgressContainer>
      {Array.from({ length: sectionsCount }).map((_, index) => {
        const revertIndex = sectionsCount - index
        const number = revertIndex * pointsPerSection
        const nextNumber = (revertIndex - 1) * pointsPerSection
        const last = index === sectionsCount - 1

        const leftMasteryName =
          t('masteries:masteries')?.find((matery) => matery.mastery === masteryLeftType)?.name ??
          masteryLeftType
        const rightMasteryName =
          t('masteries:masteries')?.find((matery) => matery.mastery === masteryRightType)?.name ??
          masteryLeftType

        const [BarSectionLeftWithTooltip, BarSectionRightWithTooltip] = (
          ['left', 'right'] as const
        ).map((placement) => {
          const masteryName = placement === 'left' ? leftMasteryName : rightMasteryName
          const statsPerLevel = placement === 'left' ? statsPerLevelLeft : statsPerLevelRight

          return withTooltip(
            BarSection,
            {
              type: 'text',
              content: (
                <>
                  <div>{t('masteries:statsIncreaseText', { masteryName })}</div>

                  {statsPerLevel?.health ? (
                    <StatsText>
                      + {statsPerLevel.health} <StyledIconHealth race={race || 'HUMAN'} />{' '}
                      {t('character:stats.health')}
                    </StatsText>
                  ) : null}
                  {statsPerLevel?.energy ? (
                    <StatsText>
                      + {statsPerLevel.energy} <StyledIconEnergy side={side || 'NEUTRAL'} />{' '}
                      {t('character:stats.energy')}
                    </StatsText>
                  ) : null}
                  {statsPerLevel?.physicalPower ? (
                    <StatsText>
                      + {statsPerLevel.physicalPower} <img src={IconPhysicalPower} alt="" />{' '}
                      {t('character:stats.physicalPower')}
                    </StatsText>
                  ) : null}
                  {statsPerLevel?.magicPower ? (
                    <StatsText>
                      + {statsPerLevel.magicPower} <img src={IconMagicPower} alt="" />{' '}
                      {t('character:stats.magicPower')}
                    </StatsText>
                  ) : null}
                  {statsPerLevel?.physicalResistance ? (
                    <StatsText>
                      + {statsPerLevel.physicalResistance}{' '}
                      <img src={IconPhysicalResistance} alt="" />{' '}
                      {t('character:stats.physicalResistance')}
                    </StatsText>
                  ) : null}
                  {statsPerLevel?.magicResistance ? (
                    <StatsText>
                      + {statsPerLevel.magicResistance} <img src={IconMagicResistance} alt="" />{' '}
                      {t('character:stats.magicResistance')}
                    </StatsText>
                  ) : null}
                  {statsPerLevel?.movementSpeed ? (
                    <StatsText>
                      + {statsPerLevel.movementSpeed} <img src={IconMovementSpeed} alt="" />{' '}
                      {t('character:stats.movementSpeed')}
                    </StatsText>
                  ) : null}
                  {statsPerLevel?.criticalStrikeChance ? (
                    <StatsText>
                      + {statsPerLevel.criticalStrikeChance}{' '}
                      {t('character:stats.criticalStrikeChance')}
                    </StatsText>
                  ) : null}
                </>
              ),
            },
            {
              placement,
              maxWidth: 210,
              offset: 15,
            },
          )
        })

        return (
          // eslint-disable-next-line react/no-array-index-key
          <Fragment key={index}>
            <BarBadge>{number}</BarBadge>
            <BarSectionContainer>
              <BarSectionLeftWithTooltip side={side} disabled={isLeftBarDisabled} />
              <BarSectionRightWithTooltip side={side} disabled={isRightBarDisabled} />
            </BarSectionContainer>
            {last ? <BarBadge>{nextNumber}</BarBadge> : null}
          </Fragment>
        )
      })}
    </Bar>
  </Container>
)

const Container = styled.div`
  height: 100%;
  display: flex;
  position: relative;
  margin-top: 87px;
  align-items: flex-end;
`

const BarSectionContainer = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
`

const BarSection = styled.div<{ progress?: string; side?: Side; disabled: boolean }>`
  width: 8px;
  height: 49px;
  background: ${({ side, disabled }) =>
    disabled
      ? `repeating-linear-gradient(
    #2A3041 0px,
    #2A3041 15px,
    #131927 15px,
    #131927 17px,
    #2A3041 17px,
    #2A3041 32px,
    #131927 32px,
    #131927 34px
  );`
      : side === 'LIGHT'
      ? `repeating-linear-gradient(
    #11337D 0px,
    #11337D 15px,
    #122A5F 15px,
    #122A5F 17px,
    #11337D 17px,
    #11337D 32px,
    #122A5F 32px,
    #122A5F 34px
  );`
      : `repeating-linear-gradient(
    #6b222f 0px,
    #6b222f 15px,
    #4c1f2c 15px,
    #4c1f2c 17px,
    #6b222f 17px,
    #6b222f 32px,
    #4c1f2c 32px,
    #4c1f2c 34px
  )`};
  position: relative;

  &:not(:first-of-type) {
    margin-left: 4px;
  }
`

const BarProgressContainer = styled.div`
  position: absolute;
  top: 9px;
  bottom: 9px;
  width: 20px;
  display: flex;
  align-items: flex-end;
  z-index: 2;
  pointer-events: none;
`

const BarProgress = styled.div<{ progress?: string; side: Side }>`
  width: 8px;
  transition: height 0.3s ease-in;
  height: ${({ progress }) => progress || 0};
  background: ${({ side }) =>
    `linear-gradient(180deg, ${sideColorMap[side].light} -25%, ${sideColorMap[side].medium} 25.12%, ${sideColorMap[side].dark} 62.5%)`};
  box-shadow: ${({
    side,
  }) => `0px 0px 24px 0px ${sideColorMap[side].dark}, 0px 0px 8px 0px ${sideColorMap[side].dark}bf,
      0px 1px 3px 0px rgba(255, 255, 255, 0.75) inset`};

  &:last-of-type {
    margin-left: 4px;
  }
`

const Bar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const BarBadge = styled.div`
  position: relative;
  z-index: 2;
  width: 48px;
  height: 18px;
  padding: 2px 0;
  background: url(${IconBarBadge}) no-repeat;
  background-size: cover;
  color: #ada08a;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  user-select: none;
  box-shadow: 0 -5px 5px -5px rgba(8, 31, 45, 0.75), 0 5px 5px -5px rgba(8, 31, 45, 0.75);
`

const PointsBaloon = styled.div`
  z-index: 1;
  top: -104px;
  left: 50%;
  margin-left: -36px;
  position: absolute;
`

const StyledIconPointsBaloon = styled(IconPointsBaloon)<{ side?: Side }>`
  fill: url(#${({ side }) =>
    side === 'LIGHT' ? 'fill-baloon-light-side' : 'fill-baloon-dark-side'});
  stroke: ${({ side }) => (side === 'LIGHT' ? '#11337D' : '#6b222f')};
`

const PointsBaloonText = styled.div`
  color: #ada08a;
  font-size: 14px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-bottom: 4px;
`

const PointsBaloonTextNumber = styled.div`
  font-size: 24px;
  font-weight: 600;
  line-height: 21px;
`

const StyledIconEnergy = styled(IconEnergy)<{ side: Side }>`
  --icon-energy-tooltip-gradient1: ${({ side }) => sideColorMap[side].light};
  --icon-energy-tooltip-gradient2: ${({ side }) => sideColorMap[side].medium};
  --icon-energy-tooltip-gradient3: ${({ side }) => sideColorMap[side].dark};
`

const StyledIconHealth = styled(IconHealth)<{ race: Race }>`
  --icon-health-tooltip-gradient1: ${({ race }) => raceColorMap[race].main};
  --icon-health-tooltip-gradient2: ${({ race }) => raceColorMap[race].dark};
`

const StatsText = styled.div`
  display: flex;

  img {
    margin-right: 2px;
  }
`
