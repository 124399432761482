import { keyframes, css } from '@emotion/react'
import styled from '@emotion/styled'
import type { Second } from '~shared-types'

const timeCooldownSecondDecimalAnimation = (animationName: string) => keyframes`
  from {
    ${`--cooldownSecondDecimal${animationName}: 0;`}
    content: '0.' ${`counter(cooldownSecondDecimal${animationName})`};
  }
  to {
    ${`--cooldownSecondDecimal${animationName}: 10;`}
    content: '0.' ${`counter(cooldownSecondDecimal${animationName})`};
  }
`

const timeCooldownSecondsAnimation = (cooldown: Second, animationName: string) => keyframes`
  from {
    ${`--cooldownSeconds${animationName}: 1;`}
  }
  to {
    ${`--cooldownSeconds${animationName}: ${Math.floor(cooldown)};`}
    
  }
`

const timeCooldownAnimation = (cooldown: Second, animationName: string) => css`
  animation-name: ${timeCooldownSecondsAnimation(cooldown, animationName)},
    ${timeCooldownSecondDecimalAnimation(animationName)};
  animation-duration: ${Math.floor(cooldown) - 1}s, 1s;
  animation-iteration-count: 1;
  animation-direction: reverse;
  animation-timing-function: steps(${Math.floor(cooldown - 1)}, end), steps(10, end);
  animation-delay: ${cooldown - Math.floor(cooldown)}s, ${cooldown - 1}s;
  animation-fill-mode: forwards;
`

export const AnimatedSecondsCooldown = styled.div<{ cooldown: Second; animationName: string }>`
  position: relative;

  @property ${({ animationName }) => `--cooldownSecondDecimal${animationName}`} {
    syntax: '<integer>';
    initial-value: 9;
    inherits: false;
  }

  @property ${({ animationName }) => `--cooldownSeconds${animationName}`} {
    syntax: '<integer>';
    initial-value: ${({ cooldown }) => Math.floor(cooldown)};
    inherits: false;
  }

  &::after {
    ${({ animationName, cooldown }) => timeCooldownAnimation(cooldown, animationName)};
    ${({ animationName }) => `
      counter-reset: cooldownSeconds${animationName} var(--cooldownSeconds${animationName}) cooldownSecondDecimal${animationName} var(--cooldownSecondDecimal${animationName});
      content: ${`counter(cooldownSeconds${animationName})`};
    `}
  }
`
