import styled from '@emotion/styled'
import type { Observable } from 'rxjs'
import AbilityItemButtonKeyBg from '~assets/ability-item-button-key-bg.svg'
import { RhombusItem } from '~components/RhombusItem'
import type { Side } from '../../generated/graphql'
import type { AbilityCooldown } from '../../store'
import { AbilityTooltip } from '../Tooltip/AbilityTooltip'
import withTooltip from '~components/Tooltip/withTooltip'
import { AnimatedSecondsCooldown } from '../AnimatedSecondsCooldown'
import type { AdvancedStats } from '../CharacterPanel/CharacterAdvancedStats'

type PanelAbility = {
  id: number
  name: string
  icon: string
  effectDescription: string
}

type Props<T extends PanelAbility> = {
  ability?: T
  statsObservable?: Observable<AdvancedStats>
  isSelected: boolean
  isDisabled: boolean
  side: Side
  abilityCooldown?: AbilityCooldown
  onClick?: () => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export const AbilitiesPanelItem = <T extends PanelAbility>({
  ability,
  statsObservable,
  isSelected = false,
  isDisabled = false,
  side,
  abilityCooldown,
  onClick,
  onMouseEnter,
  onMouseLeave,
}: Props<T>) => {
  const empty = !ability

  const HoverWithTooltip = ability
    ? withTooltip(Hover, {
        type: 'ability',
        ability,
        statsObservable,
      })
    : Hover

  return (
    <StyledRhombusItem
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      icon={ability && ability.icon}
      onClick={ability ? onClick : undefined}
      size={48}
      borderSize={3}
      borderColor={empty ? '#3C4860' : isDisabled ? '#808080' : undefined}
      isSelected={isSelected}
      isDisabled={!empty && isDisabled}
      side={side}
      isInCooldown={Boolean(abilityCooldown)}
    >
      <HoverWithTooltip />
      {abilityCooldown && (
        <CooldownContainer>
          <CooldownProgress
            progress={abilityCooldown.remainingCooldown / abilityCooldown.duration}
            cooldown={abilityCooldown.remainingCooldown / 1000}
            side={side}
          />
          <CooldownValue
            cooldown={abilityCooldown.remainingCooldown / 1000}
            animationName={String(abilityCooldown.abilityId)}
          />
        </CooldownContainer>
      )}
      {/* <ButtonKey active={!empty}>W</ButtonKey> */}
    </StyledRhombusItem>
  )
}

const Hover = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
`

const colorsMap = {
  DARKNESS: {
    border: '#F4673B',
    shadow: '#d51f35',
  },
  LIGHT: {
    border: '#3fb8f1',
    shadow: '#0080EB',
  },
  NEUTRAL: {
    border: '#EFEFEF',
    shadow: '#EFEFEF',
  },
} as const satisfies Record<Side, { border: string; shadow: string }>

const StyledTooltip = styled(AbilityTooltip)``

const StyledRhombusItem = styled(RhombusItem)<{
  isSelected: boolean
  isDisabled: boolean
  side: Side
  isInCooldown: boolean
}>`
  position: relative;

  > div:first-of-type {
    z-index: 1;
    position: relative;
    box-shadow: 1px 4px 12px 0px rgb(0 0 0 / 60%)
      ${({ isSelected, side }) =>
        isSelected &&
        `, 0px 0px 36px 0px ${`${colorsMap[side].shadow}80`}, 0px 0px 12px 0px ${`${colorsMap[side].shadow}bf`}`};
    ${({ isSelected, side }) => isSelected && `outline: 1px solid ${colorsMap[side].border}bf;`}

    ${({ isInCooldown }) =>
      isInCooldown &&
      `> div:first-of-type {
      filter: grayscale(0.6);
    }`}
  }

  &:not(:first-of-type) {
    margin-left: -30px;
  }

  &:nth-of-type(2n) {
    margin-top: 40px;
  }

  ${({ isDisabled }) =>
    isDisabled
      ? `
      > div > div:before {
    filter: grayscale(50%);
    background-color: #00000075;
    background-blend-mode: saturation;
  }`
      : null}
`

const CooldownContainer = styled.div`
  position: absolute;
  left: 3px;
  top: 3px;
  right: 3px;
  bottom: 3px;
  overflow: hidden;
  transform: rotate(0) !important;
  display: grid;
  place-items: center;
  z-index: 2;
`

const cooldownColorMap = {
  DARKNESS: '#8f1726',
  LIGHT: '#004987',
  NEUTRAL: '#676666',
}

const CooldownProgress = styled.div<{ side: Side; cooldown: number; progress: number }>`
  width: calc(100% * ${Math.sqrt(2)});
  height: calc(100% * ${Math.sqrt(2)});
  border-radius: 50%;
  opacity: 0.5;
  background: ${({ side }) => cooldownColorMap[side]};
  background-image: linear-gradient(to right, transparent 50%, #000 0);
  transform: rotate(-45deg);
  position: absolute;

  @keyframes spin {
    to {
      transform: rotate(0.5turn);
    }
  }

  @keyframes bg {
    50% {
      background: #000;
    }
  }

  &::before {
    content: '';
    display: block;
    margin-left: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 50%;
    background-color: inherit;
    transform-origin: left;
    animation: ${({ cooldown }) => `spin ${cooldown / 2}s linear 2, bg ${cooldown}s step-end 1`};
  }
`

const CooldownValue = styled(AnimatedSecondsCooldown)`
  position: absolute;
  color: #fff;
  transform: rotate(-45deg);
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
  font-size: 15px;
  line-height: 25px;
`

const ButtonKey = styled.div<{ active: boolean }>`
  position: absolute;
  left: 50%;
  margin-left: 6px;
  bottom: -2px;
  z-index: 2;
  width: 26px;
  height: 14px;
  line-height: 14px;
  background: ${({ active }) =>
    active ? `url(${AbilityItemButtonKeyBg}) center no-repeat` : '#3C4860'};
  text-align: center;
  font-family: Overpass;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: -0.6px;
  color: #131927;
  pointer-events: none;
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%);
  user-select: none;
`
