import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Race, Side } from '~shared-types'
import {
  attackLocationAreaSpriteIndexMap,
  commonAnimationSpriteUrlMap,
  locationSpriteOffset,
} from '~utils/animation'

type Props = {
  radius: number
  race?: Race
  side?: Side
  className?: string
}
export const AbilityAreaLocation: FC<Props> = ({
  radius,
  race = 'MAGICIAN',
  side = 'DARKNESS',
  className,
}) => (
  <Container
    race={race}
    side={side}
    size={radius * 2 + locationSpriteOffset}
    className={className}
  />
)

const Container = styled.div<{
  race: Race
  side: Side
  image?: string
  size: number
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -${({ size }) => size / 2}px;
  margin-left: -${({ size }) => size / 2}px;
  background: url(${commonAnimationSpriteUrlMap.ATTACK_LOCATION_AREA}) no-repeat;
  background-size: 1000%;
  background-position-x: ${({ race, side, size }) =>
    size ? -size * (attackLocationAreaSpriteIndexMap[side]?.[race] || 0) : 0}px;
`
