import styled from '@emotion/styled'
import { useMemo } from 'react'
import type { FC } from 'react'
import { useMatchStore } from '~store'
import type { MatchMapCharacterFragment, Side } from '~generated/match-graphql'
import { MatchInfoPanelTimer } from './MatchInfoPanelTimer'
import { MatchInfoPanelFps } from './MatchInfoPanelFps'
import { sideColorMap } from '~utils/character'

import IconPlayers from '~assets/icon-match-stats-players.svg'
import IconWin from '~assets/icon-match-stats-win.svg'

type TeamStats = {
  side: Side
  value: number
  count: number
  alive: number
}

const participantsToTeamStats: (participants: MatchMapCharacterFragment[]) => TeamStats = (
  participants,
) =>
  participants.reduce(
    (acc, character) => ({
      side: character.side,
      value: acc.value + character.value,
      count: acc.count + 1,
      alive: acc.alive + (character.alive ? 1 : 0),
    }),
    {
      side: participants[0]?.side,
      value: 0,
      count: 0,
      alive: 0,
    },
  )

export const MatchInfoPanel: FC = () => {
  const [currentCharacter, characters, startAt] = useMatchStore((state) => [
    state.currentCharacter,
    state.characters,
    state.startAt,
  ])

  const leftTeam = useMemo(
    () =>
      participantsToTeamStats(
        characters.filter((character) => character.teamId === currentCharacter?.teamId),
      ),
    [characters, currentCharacter],
  )

  const rightTeam = useMemo(
    () =>
      participantsToTeamStats(
        characters.filter((character) => character.teamId !== currentCharacter?.teamId),
      ),
    [characters, currentCharacter],
  )

  const leftTeamWinRate =
    Math.trunc((leftTeam.value / (rightTeam.value + leftTeam.value)) * 100) || 0
  const rightTeamWinRate =
    Math.trunc((rightTeam.value / (rightTeam.value + leftTeam.value)) * 100) || 0

  return (
    <Container>
      <StatsContainer>
        <Stats>
          <StatsItem>
            <img src={IconPlayers} alt="players" /> {leftTeam.alive} / {leftTeam.count}
          </StatsItem>
          <StatsItem>
            <img src={IconWin} alt="winRate" />
            {leftTeamWinRate}%
          </StatsItem>
        </Stats>
        <MatchInfoPanelTimer startAt={startAt} />
        <Stats>
          <StatsItem>
            <img src={IconWin} alt="winRate" />
            {rightTeamWinRate}%
          </StatsItem>
          <StatsItem>
            <img src={IconPlayers} alt="players" /> {rightTeam.alive} / {rightTeam.count}
          </StatsItem>
        </Stats>
      </StatsContainer>
      <Balance
        leftSide={leftTeam.side}
        rightSide={rightTeam.side}
        left={leftTeamWinRate}
        right={rightTeamWinRate}
      />
      <MatchInfoPanelFps />
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  z-index: 5;
  width: 305px;
  top: calc(33px + 48px);
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  color: #a7afc4;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StatsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  font-weight: 600;
`

const Stats = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
`

const StatsItem = styled.div`
  display: flex;
  align-items: center;
`

const Balance = styled.div<{ left: number; right: number; leftSide: Side; rightSide: Side }>`
  width: 100%;
  display: flex;
  gap: 4px;
  margin-bottom: 18px;

  &:before,
  &:after {
    content: '';
    height: 4px;
    border-radius: 4px;
  }

  &:before {
    width: ${({ left }) => left}%;
    background: ${({ leftSide = 'NEUTRAL' }) =>
      `linear-gradient(180deg, ${sideColorMap[leftSide].light} -25%, ${sideColorMap[leftSide].medium} 25.12%, ${sideColorMap[leftSide].dark} 62.5%)`};
    box-shadow: ${({
      leftSide = 'NEUTRAL',
    }) => `0px 0px 24px 0px ${sideColorMap[leftSide].dark}, 0px 0px 8px 0px ${sideColorMap[leftSide].dark}bf,
      0px 1px 3px 0px rgba(255, 255, 255, 0.75) inset`};
  }

  &:after {
    width: ${({ right }) => right}%;
    background: ${({ rightSide = 'NEUTRAL' }) =>
      `linear-gradient(180deg, ${sideColorMap[rightSide].light} -25%, ${sideColorMap[rightSide].medium} 25.12%, ${sideColorMap[rightSide].dark} 62.5%)`};
    box-shadow: ${({
      rightSide = 'NEUTRAL',
    }) => `0px 0px 24px 0px ${sideColorMap[rightSide].dark}, 0px 0px 8px 0px ${sideColorMap[rightSide].dark}bf,
      0px 1px 3px 0px rgba(255, 255, 255, 0.75) inset`};
  }
`
