import styled from '@emotion/styled'
import { memo } from 'react'
import type { FC } from 'react'

type Props = {
  value: number
  shift: number
}
export const CharacterMarkerHealthChange: FC<Props> = memo<Props>(({ value, shift }) => {
  const xShift = shift * 200 - 100
  const yShift = Math.sqrt(10000 - xShift ** 2)
  return (
    <HealthValue type={value > 0 ? 'heal' : 'damage'} xShift={xShift} yShift={yShift}>
      {Math.abs(value)}
    </HealthValue>
  )
})

const HealthValue = styled.div<{ type: 'damage' | 'heal'; xShift: number; yShift: number }>`
  --xShift: ${({ xShift }) => xShift};
  --yShift: ${({ yShift }) => yShift};
  position: absolute;
  left: 50%;
  top: 50%;
  color: ${({ type }) => (type === 'damage' ? '#e8e3d3' : '#299051')};
  transform: translate(-50%, -50%);
  animation: characterMarketHealthChangeFlightPosition 3s forwards,
    characterMarketHealthChangeFontSize 3s linear;
  animation-timing-function: cubic-bezier(0.35, 0.57, 0.55, 0.77);
  will-change: transform, font-size;
  z-index: 100;
`
