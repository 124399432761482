import 'reflect-metadata'
import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Race, Second, Side } from '~shared-types'
import type { AbilityAnimationType } from '~utils/animation'
import {
  commonAnimationSpriteUrlMap,
  animationDurationMap,
  animationIterationCountMap,
  animationSpriteCountMap,
  animationSpriteUrlMap,
  animationSizeMap,
  animationTransformMap,
} from '~utils/animation'

type Props = {
  side: Side
  race: Race
  animationType: AbilityAnimationType
  id: string
  range?: number
  transform?: string
}

export const AbilityAnimationUI: FC<Props> = ({
  race,
  side,
  range,
  animationType: type,
  id,
  transform,
}) => {
  const sprite =
    type === 'PORTAL'
      ? commonAnimationSpriteUrlMap.PORTAL
      : animationSpriteUrlMap[side]?.[race]?.[type]

  if (type === 'ATTACK_MELEE_SPLASH') {
    return (
      <Container size={range || animationSizeMap[type]}>
        <SplashContainer
          size={animationSizeMap.ATTACK_MELEE_SPLASH || 0}
          race={race}
          side={side}
          id={id}
          duration={animationDurationMap.ATTACK_MELEE_SPLASH || 0}
          iterationCount={animationIterationCountMap.ATTACK_MELEE_SPLASH || 0}
          spriteUrl={animationSpriteUrlMap[side]?.[race]?.ATTACK_MELEE_SPLASH}
          transform={transform}
        />
      </Container>
    )
  }

  return (
    <Container size={range || animationSizeMap[type]}>
      <AnimationContainer
        duration={animationDurationMap[type] || 0}
        count={animationSpriteCountMap[type] || 0}
        size={range || animationSizeMap[type]}
        spriteUrl={sprite}
        id={id}
        iterationCount={animationIterationCountMap[type] || 0}
        transform={transform || animationTransformMap[type]}
      />
    </Container>
  )
}

const Container = styled.div<{
  size?: number
}>`
  width: ${({ size = 0 }) => size}px;
  height: ${({ size = 0 }) => size}px;
  position: relative;
  pointer-events: none;
`

const AnimationContainer = styled.div<{
  duration: Second
  count: number
  spriteUrl?: string
  id: string
  size?: number
  delay?: Second
  iterationCount: 'infinite' | number
  transform?: string
}>`
  background: url(${({ spriteUrl }) => spriteUrl || ''}) no-repeat;
  animation-name: ${({ id }) => `ability-animation-${id}`};
  animation-duration: ${({ duration }) => duration}s;
  animation-timing-function: ${({ count }) => `steps(${count})`};
  animation-iteration-count: ${({ iterationCount }) => iterationCount};
  animation-fill-mode: forwards;
  animation-delay: ${({ delay = 0 }) => delay}s;
  background-size: ${({ count }) => count * 100}%;
  background-position-y: center;
  background-repeat: no-repeat;
  opacity: 0;
  will-change: background-position-x;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s;
  ${({ transform }) => transform && `transform: ${transform};`}

  @keyframes ${({ id }) => `ability-animation-${id}`} {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      background-position-x: -${({ size = 0, count }) => size * count}px;
    }
  }
`

const SplashContainer = styled.div<{
  race: Race
  side: Side
  size: number
  id: string
  spriteUrl?: string
  duration: number
  delay?: number
  iterationCount: 'infinite' | number
  transform?: string
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: url(${({ spriteUrl }) => spriteUrl}) no-repeat;
  background-size: 300%;
  background-position-x: ${({ size }) => -size * Math.floor(Math.random() * 3)}px;
  will-change: opacity;
  animation-name: ${({ id }) => `ability-animation-splash-${id}`};
  animation-duration: ${({ duration }) => duration}s;
  animation-timing-function: linear;
  animation-iteration-count: ${({ iterationCount }) => iterationCount};
  animation-fill-mode: forwards;
  animation-delay: ${({ delay = 0 }) => delay}s;
  transform: ${({ transform }) => transform || 'none'};

  @keyframes ${({ id }) => `ability-animation-splash-${id}`} {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
