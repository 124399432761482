import type { LngLat, Map } from 'maplibre-gl'
import { Marker } from 'maplibre-gl'
import { createRoot } from 'react-dom/client'
import { angleBetweenTwoPoints } from '~map-tools'
import type { Point } from '~shared-types'
import type { MatchCharacterAbilityFragment } from '~generated/match-graphql'
import { AbilityAreaLocation } from '../components/AbilityAreaLocation'
import { convertMetersToPixels } from '~utils/map'
import { AbilityAreaDirection } from '../components/AbilityAreaDirection'
import type { CharacterEntity } from './CharacterEntity'
import { matchStore } from '~store'

export class SelectedAbilityApplicationAreaEntity {
  private marker?: Marker
  private type?: 'Location' | 'Direction'
  private character?: CharacterEntity

  constructor(private map: Map) {
    matchStore.subscribe(
      (state) => state.selectedAbility,
      () => this.hide(),
    )
  }

  public setCharacter(character: CharacterEntity) {
    this.character = character
  }

  public updatePosition(lngLat: LngLat) {
    if (!this.marker) {
      return
    }
    if (this.type === 'Location') {
      this.marker.setLngLat(lngLat)
    }
    if (this.type === 'Direction' && this.character) {
      const destination = [lngLat.lng, lngLat.lat] satisfies Point
      // const source = [this.marker.getLngLat().lng, this.marker.getLngLat().lat] satisfies Point

      const angle = angleBetweenTwoPoints(this.character.position, destination)
      this.marker.setLngLat(this.character.position)
      this.marker.setRotation(angle + 90)
    }
  }

  public hide() {
    this.marker?.remove()
  }

  public show(ability: MatchCharacterAbilityFragment) {
    this.hide()

    if (ability.shape?.__typename === 'AbilityLocationShapeCircle') {
      this.showLocationArea(ability)
    }

    if (ability?.shape?.__typename === 'AbilityDirectionShapeLine') {
      this.showDirectionArea(ability)
    }
  }

  private showLocationArea(ability: MatchCharacterAbilityFragment) {
    if (ability.shape?.__typename !== 'AbilityLocationShapeCircle') {
      return
    }
    const div = document.createElement('div')
    const root = createRoot(div)
    const component = (
      <AbilityAreaLocation
        race={this.character?.race}
        side={this.character?.side}
        radius={convertMetersToPixels(this.map, ability.shape.radius)}
      />
    )
    root.render(component)
    this.marker = new Marker({ element: div })
    this.marker.setLngLat([0, 0])
    this.marker.addTo(this.map)
    this.type = 'Location'
  }

  private showDirectionArea(ability: MatchCharacterAbilityFragment) {
    if (ability.shape?.__typename !== 'AbilityDirectionShapeLine') {
      return
    }
    if (!this.character) {
      return
    }

    const div = document.createElement('div')
    const root = createRoot(div)
    const component = (
      <AbilityAreaDirection
        race={this.character.race}
        side={this.character.side}
        width={convertMetersToPixels(this.map, ability.range.max)}
        height={convertMetersToPixels(this.map, ability.shape.width)}
      />
    )
    root.render(component)
    const marker = new Marker({ element: div })
    this.marker = marker
    this.marker.setLngLat([0, 0])
    this.character.positionObservable.subscribe(
      (value) => marker.getLngLat().lat !== 0 && marker.setLngLat(value),
    )
    this.marker.addTo(this.map)
    this.type = 'Direction'
  }
}
