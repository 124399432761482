import type { FeatureCollection } from '@turf/turf'
import type { GeoJSONSourceSpecification, LayerSpecification } from 'maplibre-gl'
import type { Feature } from 'geojson'
import type { Path, Point } from '~shared-types'
import { createBoundingBoxWithOffset, isPointInPolygon } from '../../../../../../libs/map-tools/src'
import type { LayerManager } from './LayerManager'

interface IBorder {
  update(props: { path?: Path; colors: BorderColors }): void
  isInside(point: Point): boolean
}

type BorderColors = { stroke: string; fill: string }

export class Border implements IBorder {
  private path: Path
  constructor(
    private layerManager: LayerManager,
    private miniMapLayerManager: LayerManager,
    private type: 'zone' | 'zone-open-match' | 'match',
    private id?: string,
    private colors?: BorderColors,
  ) {
    this.path = []
  }

  get strokeLayerId(): string {
    return `border-layer-stroke-${this.id}`
  }

  get strokeSourceId(): string {
    return `border-source-stroke-${this.id}`
  }

  get fillLayerId(): string {
    return `border-layer-fill-${this.id}`
  }

  get fillSourceId(): string {
    return `border-layer-fill-${this.id}`
  }

  public update({ path, colors }: { path?: Path; colors?: BorderColors }) {
    if (colors) {
      this.colors = colors
    }
    if (path) {
      this.path = path
      if (this.type === 'match' || this.type === 'zone') {
        this.layerManager.setMapBounds(createBoundingBoxWithOffset(this.path, 200))
      }
    }
    this.draw()
  }

  public clear() {
    this.layerManager.removeLayer(this.strokeLayerId)
    this.layerManager.removeSource(this.strokeSourceId)
    this.layerManager.removeLayer(this.fillLayerId)
    this.layerManager.removeSource(this.fillSourceId)
    this.miniMapLayerManager.removeLayer(this.strokeLayerId)
    this.miniMapLayerManager.removeSource(this.strokeSourceId)
    this.miniMapLayerManager.removeLayer(this.fillLayerId)
    this.miniMapLayerManager.removeSource(this.fillSourceId)
  }

  public isInside(point: Point): boolean {
    if (this.path.length === 0) {
      return true
    }
    return isPointInPolygon(point, this.path)
  }

  private draw() {
    const features = [
      {
        type: 'Feature',
        properties: {
          id: 'MatchBorder',
          name: 'MatchBorder',
        },
        geometry: {
          type: 'Polygon',
          coordinates: [this.path],
        },
      },
    ] as const satisfies FeatureCollection['features']

    const source = {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features,
      },
    } as const satisfies GeoJSONSourceSpecification

    const layer = {
      id: this.strokeLayerId,
      type: 'line',
      source: this.strokeSourceId,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': this.type === 'zone-open-match' ? this.colors?.stroke ?? '#f00' : '#7B959E',
        'line-width': this.type === 'zone-open-match' ? 2 : 4,
      },
    } as const satisfies LayerSpecification
    this.layerManager.createLayerWithSource(layer, source)

    const minimapLayer = {
      id: this.strokeLayerId,
      type: 'line',
      source: this.strokeSourceId,
      layout: {
        'line-join': 'round',
        'line-cap': 'round',
      },
      paint: {
        'line-color': this.type === 'zone-open-match' ? this.colors?.stroke ?? '#f00' : '#7B959E',
        'line-width': 2,
      },
    } as const satisfies LayerSpecification

    this.miniMapLayerManager.createLayerWithSource(minimapLayer, source)

    this.drawShadedArea(
      this.type === 'zone' || this.type === 'match' ? 'behindBorder' : 'insideBorder',
    )
  }

  private drawShadedArea(fillType: 'insideBorder' | 'behindBorder') {
    const largeInvertedPolygon = {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates:
          fillType === 'behindBorder'
            ? [
                [
                  [-180, -90],
                  [-180, 90],
                  [180, 90],
                  [180, -90],
                  [-180, -90],
                ],
                this.path,
              ]
            : [this.path],
      },
    } as const satisfies Feature

    const source = {
      type: 'geojson',
      data: largeInvertedPolygon,
    } as const satisfies GeoJSONSourceSpecification

    const layer = {
      id: this.fillLayerId,
      type: 'fill',
      source: this.fillSourceId,
      layout: {},
      paint: {
        'fill-color': this.type === 'zone-open-match' ? this.colors?.fill ?? '#f00' : '#000',
        'fill-opacity': 0.4,
      },
    } as const satisfies LayerSpecification
    this.layerManager.createLayerWithSource(layer, source, 'afterBase')

    const minimapLayer = {
      id: this.fillLayerId,
      type: 'fill',
      source: this.fillSourceId,
      layout: {},
      paint: {
        'fill-color': this.type === 'zone-open-match' ? this.colors?.fill ?? '#f00' : '#000',
        'fill-opacity': 0.5,
      },
    } as const satisfies LayerSpecification

    this.miniMapLayerManager.createLayerWithSource(minimapLayer, source, 'afterBase')
  }
}
