import { useStore } from '~store'
import { useOpenMatchesQuery } from '~generated/graphql'
import { ZoneMap } from '../model/ZoneMap'

export const useOpenMatches = () => {
  const [session] = useStore((state) => [state.session])

  return useOpenMatchesQuery({
    skip: !session?.characterId || session?.character?.zoneId === undefined,
    // fetchPolicy: 'network-only',
    variables: {
      zoneId: session?.character?.zoneId as number,
    },
    onError: (error) => {
      console.error(error)
    },
    onCompleted: async (data) => {
      try {
        const map = ZoneMap.getInstance()
        map.openMatchManager.setOpenMatches(data.openMatches)
      } catch (e) {
        console.log('useOpenMatchesQuery', e)
      }
    },
  })
}
