import type { Subscription } from 'rxjs'
import { BehaviorSubject, of, Subject, timer } from 'rxjs'
import { StartOpenMatchDocument } from '~generated/graphql'
import type { Character, Race, Side, ZoneMapCharacterFragment } from '~generated/graphql'
import { client } from '~apolloClient'
import type { Point } from '~shared-types'
import { ZoneCharacterFrame } from './ZoneCharacterFrame'
import type { ZoneMap } from './ZoneMap'
import { RouteManager } from './RouteManager'

type ZoneMapCharacter = ZoneMapCharacterFragment & Pick<Character, 'levelExperienceMax'>

export class ZoneCharacter {
  public firstName: string
  public id: string
  public lastName: string
  #race: Race
  public raceSubject: Subject<Race>
  #side: Side
  public sideSubject: Subject<Side>
  public stats: ZoneMapCharacter['stats']
  public statsSubject: BehaviorSubject<ZoneMapCharacter['stats']>
  #position: Point
  public positionSubject: Subject<Point>
  #display: boolean
  public displaySubject: Subject<boolean>
  public characterFrame: ZoneCharacterFrame
  // public effects: Effect[] = []
  public isCurrentUser?: boolean
  public zone: ZoneMapCharacter['zone']
  public abilities: ZoneMapCharacter['abilities']
  public routeManager: RouteManager
  public avatar: ZoneMapCharacter['avatar']
  public level: number
  public experience: number
  public levelExperienceMax?: number | null

  public spellCastSubscription?: Subscription

  constructor(public mapClass: ZoneMap, character: ZoneMapCharacter, isCurrentUser?: boolean) {
    this.sideSubject = new Subject()
    this.raceSubject = new Subject()
    this.id = character.id
    this.firstName = character.firstName
    this.lastName = character.lastName
    this.#race = character.race
    this.#side = character.side
    this.positionSubject = new Subject()
    // this.twilightLevel = character.twilightLevel as TwilightLevel
    this.stats = character.stats
    this.statsSubject = new BehaviorSubject(this.stats)
    this.#position = character.position ?? [0, 0]
    this.#display = true
    this.displaySubject = new Subject()
    this.abilities = character.abilities
    this.isCurrentUser = isCurrentUser
    this.zone = character.zone
    this.avatar = character.avatar
    this.level = character.level
    this.experience = character.experience
    this.levelExperienceMax = character.levelExperienceMax
    this.routeManager = new RouteManager(
      this.id,
      this.isCurrentUser ?? false,
      { map: mapClass.layerManager, minimap: mapClass.minimap.layerManager },
      (newPosition) => {
        this.position = newPosition
      },
      of(this.isCurrentUser ?? false),
    )
    this.characterFrame = new ZoneCharacterFrame(mapClass.map, this, mapClass.layerManager)
  }

  destroy() {
    this.displaySubject.complete()
    this.characterFrame.marker.remove()
  }

  public joinedMatch() {
    this.display = false
  }

  public leftMatch() {
    this.display = true
  }

  get display(): boolean {
    return this.#display
  }

  set display(value: boolean) {
    this.#display = value
    this.displaySubject.next(this.#display)
  }

  get position(): Point {
    return this.#position
  }

  set position(point: Point) {
    this.#position = point
    this.positionSubject.next(this.#position)
  }

  get side(): Side {
    return this.#side
  }

  set side(side: Side) {
    this.#side = side
    this.sideSubject.next(this.#side)
  }

  get race(): Race {
    return this.#race
  }

  set race(race: Race) {
    this.#race = race
    this.raceSubject.next(this.#race)
  }

  withDelay = (startAt: number, activity: () => void) => {
    const delayMillis = startAt - Date.now()

    timer(delayMillis).subscribe(() => {
      activity()
    })
  }

  public async attack() {
    await client.mutate({
      mutation: StartOpenMatchDocument,
      variables: {
        attackedCharacterId: this.id,
      },
    })
  }
}
