import styled from '@emotion/styled/macro'
import { forwardRef } from 'react'

import TwilightButtonBase from '~assets/twilight-button-base.svg'
import BgTwilightButtonActive from '~assets/bg-twilight-button-active.png'
import BgTwilightButtonActive2x from '~assets/bg-twilight-button-active_2x.png'

type Props = {
  bottom?: number
  top?: number
  right?: number
  left?: number
  active?: boolean
  shiftVertical?: number
  shiftHorizontal?: number
  onClick?: () => void
  disabled?: boolean
  Icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined
    }
  >
}

type Ref = HTMLDivElement

export const TwilightButton = forwardRef<Ref, Props>(
  ({ active, onClick, Icon, disabled = false, ...props }, ref) => (
    <Container active={active} {...props}>
      <Button ref={ref} active={active} onClick={onClick} disabled={disabled}>
        <StyledIcon as={Icon} disabled={disabled} />
      </Button>
    </Container>
  ),
)

const Container = styled.div<Omit<Props, 'onClick' | 'Icon' | 'level'>>`
  position: absolute;
  background: url(${TwilightButtonBase}) center no-repeat;
  width: 69px;
  height: 67px;
  ${(props) => props.top && `top: ${props.top}px;`}
  ${(props) => props.bottom && `bottom: ${props.bottom}px;`}
    ${(props) => props.right && `right: ${props.right}px;`}
    ${(props) => props.left && `left: ${props.left}px;`}
    z-index: 3;
  transform-origin: center;
  transition: 0.2s;
  transform: scale(0.75);

  ${(props) =>
    props.active &&
    `   
     transform: scale(1) ${props.shiftVertical ? `translateY(${props.shiftVertical}px)` : ''} ${
      props.shiftHorizontal ? `translateX(${props.shiftHorizontal}px)` : ''
    };
  `}
`

const StyledIcon = styled.div<{ disabled: boolean }>`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 9px;
  left: 9px;
  fill: ${({ disabled }) => (disabled ? '#597E81' : 'url(#twilightButtonGradient)')};
`

const Button = styled.div<{ active?: boolean; disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  width: 38px;
  height: 38px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 14px;
  background: ${({ disabled }) =>
    disabled
      ? '#30444F'
      : `radial-gradient(
      61.81% 63.07% at 40.79% 31.58%,
      #eae4cf 0%,
      #d9d1bb 22.71%,
      #ada08a 63.85%,
      #808080 100%
    ),
    linear-gradient(322deg, #3d2924 -3.47%, #ada08a 86.38%)`};

  ${({ disabled }) =>
    !disabled &&
    `&:hover {
      &:before {
        opacity: 1;
      }

      ${StyledIcon} {
        fill: url(#twilightButtonGradientActive);
      }
    }`}

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: image-set(
        url('${BgTwilightButtonActive}') 1x,
        url('${BgTwilightButtonActive2x}') 2x
      )
      center no-repeat;
    opacity: 0;
    transition: 0.2s;
  }

  ${(props) =>
    props.active &&
    `   
        &:before {
        opacity: 1;
        }
        ${StyledIcon} {
        fill: url(#twilightButtonGradientActive);
        }
    `}
`
