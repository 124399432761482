import styled from '@emotion/styled'
import type { FC } from 'react'
import { raceSvgColorMap, sideColorMap } from 'apps/client-app/src/utils/character'
import { from } from 'rxjs'
import { ReactComponent as BgPodiumProfile } from '~assets/bg-podium-profile.svg'
import type { Race, Side } from '~generated/graphql'
import { useProfileInfoQuery } from '~generated/graphql'
import BgLightLeft from '~assets/bg-profile-light-left.svg'
import BgLightRight from '~assets/bg-profile-light-right.svg'
import BgDarkLeft from '~assets/bg-profile-dark-left.svg'
import BgDarkRight from '~assets/bg-profile-dark-right.svg'
import { ProfileCharacterPanel } from './ProfileCharacterPanel'
import { ProfileMasteriesInfo } from './ProfileMasteriesInfo'
import { ProfileTabs } from './ProfileTabs'
import { useStore } from '~store'

type Props = {
  characterId: string
  race: Race
}

export const Profile: FC<Props> = ({ characterId, race }) => {
  const [setFullscreenId] = useStore((state) => [state.setFullscreenId])
  const { data } = useProfileInfoQuery({
    variables: {
      isCurrentCharacter: true,
      characterId,
      race,
    },
  })

  if (!data?.character) {
    return null
  }

  const {
    stats,
    side,
    activeStatusEffects,
    level,
    experience,
    firstName,
    lastName,
    learnedMasteries,
    avatar,
    gender,
    levelExperienceMax,
  } = data.character

  const firstMasteryLearned = learnedMasteries.find((m) => m.masteryId === data.masteries[0]?.id)
  const secondMasteryLearned = learnedMasteries.find((m) => m.masteryId === data.masteries[1]?.id)

  return (
    <Container side={side}>
      <Content>
        <CharacterContainer>
          <ImageContainer>
            <StyledBgPodiumProfile
              raceсolor={raceSvgColorMap[race]}
              sideсolor={sideColorMap[side]}
            />
            {avatar ? (
              <img srcSet={`${avatar?.fullBody} 1x, ${avatar?.fullBody2x} 2x`} alt="character" />
            ) : null}
          </ImageContainer>
          <ProfileCharacterPanel
            side={side}
            race={race}
            firstName={firstName}
            lastName={lastName}
            level={level}
            experience={experience}
            levelExperienceMax={levelExperienceMax}
            statsObservable={from([stats])}
            activeStatusEffects={activeStatusEffects}
          />
          <ProfileMasteriesInfo
            disabled={race === 'HUMAN'}
            firstMasteryLearned={firstMasteryLearned}
            firstMasteryType={data.masteries[0]?.type}
            secondMasteryLearned={secondMasteryLearned}
            secondMasteryType={data.masteries[1]?.type}
            onOpenMasteries={() => setFullscreenId('masteries')}
          />
        </CharacterContainer>
        <InfoContainer>
          <ProfileTabs race={race} gender={gender} />
        </InfoContainer>
      </Content>
    </Container>
  )
}

const Container = styled.div<{ side: Side }>`
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: url(${({ side }) => (side === 'LIGHT' ? BgLightLeft : BgDarkLeft)}) no-repeat top left,
    url(${({ side }) => (side === 'LIGHT' ? BgLightRight : BgDarkRight)}) no-repeat bottom right,
    #131927;
  z-index: 10;
  display: flex;
  padding: 0 20px;
  justify-content: center;
`

const Content = styled.div`
  display: flex;
  width: 100%;
  max-width: 1570px;
  height: 100%;
`

const CharacterContainer = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 50%;
  position: relative;
  z-index: 1;
`

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-basis: 50%;
  padding: 4% 0px 30px 80px;
`

const ImageContainer = styled.div`
  height: calc(100% - 120px);
  position: relative;

  img {
    position: relative;
    z-index: 1;
    height: 100%;
    width: auto;
  }
`

const StyledBgPodiumProfile = styled(BgPodiumProfile)<{
  raceсolor: { main: string; dark: string }
  sideсolor: {
    light: string
    medium: string
    dark: string
  }
}>`
  position: absolute;
  z-index: 0;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 120%;
  pointer-events: none;

  .bg-podium-dark-stop-color {
    stop-color: ${({ raceсolor }) => raceсolor.dark};
  }

  .bg-podium-main-stop-color {
    stop-color: ${({ raceсolor }) => raceсolor.main};
  }

  .bg-podium-side-light-stop-color {
    stop-color: ${({ sideсolor }) => sideсolor.light};
  }

  .bg-podium-side-middle-stop-color {
    stop-color: ${({ sideсolor }) => sideсolor.medium};
  }

  .bg-podium-side-dark-stop-color {
    stop-color: ${({ sideсolor }) => sideсolor.dark};
  }
`
