import { memo, useMemo } from 'react'
import type { FC } from 'react'
import type { Observable } from 'rxjs'
import { concat, delay, mergeMap, of, scan } from 'rxjs'
import { useValueFromObservable } from '../../quest/useValueFromObservable'
import { CharacterMarkerHealthChange } from './CharacterMarkerHealthChange'

type Props = {
  healthChangeObservable: Observable<{ health: number }>
}
export const CharacterMarkerHealthChanges: FC<Props> = memo<Props>(({ healthChangeObservable }) => {
  const observable = useMemo(
    () =>
      healthChangeObservable.pipe(
        mergeMap((healthChange) =>
          concat(
            of({ healthChange: healthChange.health, action: 'add' }),
            of({ healthChange: healthChange.health, action: 'remove' }).pipe(delay(3000)),
          ),
        ),
        scan(
          (
            acc: { healthChange: number; shift: number; date: number }[],
            { healthChange, action },
          ) => {
            if (action === 'add') {
              acc.push({ healthChange, shift: Math.random(), date: Date.now() })
            } else {
              acc.shift()
            }
            return [...acc]
          },
          [],
        ),
      ),
    [healthChangeObservable],
  )
  const healthChanges = useValueFromObservable(observable)
  if (!healthChanges) {
    return null
  }

  return (
    <>
      {healthChanges.map(({ healthChange, date, shift }) => (
        <CharacterMarkerHealthChange shift={shift} value={healthChange} key={date} />
      ))}
    </>
  )
})
