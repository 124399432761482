import {
  buffer as turfBuffer,
  bbox as turfBbox,
  bboxPolygon as turfBboxPolygon,
  booleanPointInPolygon,
  polygon as turfPolygon,
  distance as turfDistance,
  destination as turfDestination,
  bearing as turfBearing,
  circle as turfCircle,
} from '@turf/turf'
import type {
  Meter,
  Millisecond,
  Point,
  Polygon,
  Route,
  From0To100,
  Path,
  Degree,
} from '~shared-types'

// alternative algorithm point in point
// npm point-in-polygon
// https://www.algorithms-and-technologies.com/point_in_polygon/javascript
export const isPointInPolygon = (point: Point, polygon: Polygon): boolean => {
  if (polygon.length === 0) {
    return false
  }
  if (polygon.at(0) !== polygon.at(-1)) {
    return booleanPointInPolygon(point, turfPolygon([[...polygon, polygon[0]]]))
  }
  return booleanPointInPolygon(point, turfPolygon([polygon]))
}

export const isPointInCircle = (point: Point, circle: { center: Point; radius: Meter }): boolean =>
  booleanPointInPolygon(point, turfCircle(circle.center, circle.radius, { units: 'meters' }))

export const euclideanDistance = (pointA: Point, pointB: Point): number => {
  const deltaX = pointB[0] - pointA[0]
  const deltaY = pointB[1] - pointA[1]

  // Euclidean distance formula: sqrt((x2 - x1)^2 + (y2 - y1)^2)
  return Math.sqrt(deltaX ** 2 + deltaY ** 2)
}

export const spatialDistance = (pointA: Point, pointB: Point): Meter =>
  turfDistance(pointA, pointB) * 1000

export const getProgress = (
  action: { startAt: Millisecond; duration: Millisecond },
  timestamp: Millisecond,
): From0To100 => {
  const passedDuration = timestamp - action.startAt
  if (passedDuration < 0) {
    return 0
  }
  if (passedDuration > action.duration) {
    return 100
  }
  const currentProgress = (passedDuration / action.duration) * 100
  return currentProgress
}

export const getRemainingPath = (path: Path, progress: From0To100): Path | null => {
  if (progress <= 0) {
    return path
  }
  if (progress >= 100) {
    return null
  }
  const distances: number[] = []

  for (let i = 0; i < path.length - 1; i += 1) {
    distances.push(euclideanDistance(path[i], path[i + 1]))
  }
  const totalDistance = distances.reduce((a, b) => a + b)

  const passedDistance = (totalDistance * progress) / 100
  let currentPathIndex = 0
  let currentPathProgress = 0
  for (let i = 0, accumulatedDistance = 0; i < distances.length; i += 1) {
    accumulatedDistance += distances[i]
    currentPathIndex = i
    if (accumulatedDistance > passedDistance) {
      currentPathProgress = (distances[i] - (accumulatedDistance - passedDistance)) / distances[i]
      break
    }
    currentPathProgress = 1
  }
  const currentPathStartPoint = path[currentPathIndex]
  const currentPathEndPoint = path[currentPathIndex + 1]

  const currentPoint: Point = [
    Number(
      (
        currentPathStartPoint[0] +
        (currentPathEndPoint[0] - currentPathStartPoint[0]) * currentPathProgress
      ).toFixed(6),
    ),
    Number(
      (
        currentPathStartPoint[1] +
        (currentPathEndPoint[1] - currentPathStartPoint[1]) * currentPathProgress
      ).toFixed(6),
    ),
  ]

  const remainingPath = [currentPoint, ...path.slice(currentPathIndex + 1)]
  return remainingPath
}

export const getRemainingRoute = (
  route: Omit<Route, 'distance' | 'startAt'>,
  progress: From0To100,
): Omit<Route, 'distance' | 'startAt'> | null => {
  const remainingPath = getRemainingPath(route.path, progress)
  if (!remainingPath) {
    return null
  }
  const remainingRoute: Omit<Route, 'distance' | 'startAt'> = {
    duration: (route.duration * (100 - progress)) / 100,
    source: remainingPath[0],
    destination: route.destination,
    path: remainingPath,
  }

  return remainingRoute
}

export const getPointOnTheRoute = (
  route: Omit<Route, 'distance' | 'startAt'>,
  progress: From0To100,
): Point => {
  const remainingRoute = getRemainingRoute(route, progress)

  return remainingRoute?.source ?? route.path[route.path.length - 1]
}

export const getRandomPointInCircle = (center: Point, radius: Meter): Point => {
  const angle = Math.random() * 360
  // to not have most of the points close to center, radius should be corrected
  // https://stackoverflow.com/questions/5837572/generate-a-random-point-within-a-circle-uniformly
  const u = Math.random() + Math.random()
  const correctedRadius = (u > 1 ? 2 - u : u) * radius

  const {
    geometry: {
      coordinates: [lng, lat],
    },
  } = turfDestination(center, correctedRadius, angle, { units: 'meters' })

  return [lng, lat]
}

// Normalize the bearing to ensure it is within the range of -180 to 180 degrees
const normalizeBearing = (bearing: Degree) => ((bearing + 180) % 360) - 180

export const getPointInDirection = (
  source: Point,
  vector: [Point, Point],
  distance: Meter,
  rotation?: Degree,
): Point => {
  const bearing = turfBearing(vector[0], vector[1])
  const adjustedBearing = rotation ? normalizeBearing(bearing + rotation) : bearing
  const {
    geometry: {
      coordinates: [lng, lat],
    },
  } = turfDestination(source, distance, adjustedBearing, { units: 'meters' })
  return [lng, lat]
}

export const rectangleBetweenTwoPoints = (
  source: Point,
  destination: Point,
  width: Meter,
): Polygon => {
  const bearing = turfBearing(source, destination)
  const {
    geometry: {
      coordinates: [sourceLeftLng, sourceLeftLat],
    },
  } = turfDestination(source, width / 2, bearing - 90, { units: 'meters' })
  const {
    geometry: {
      coordinates: [sourceRightLng, sourceRightLat],
    },
  } = turfDestination(source, width / 2, bearing + 90, { units: 'meters' })
  const {
    geometry: {
      coordinates: [destinationLeftLng, destinationLeftLat],
    },
  } = turfDestination(destination, width / 2, bearing - 90, { units: 'meters' })
  const {
    geometry: {
      coordinates: [destinationRightLng, destinationRightLat],
    },
  } = turfDestination(destination, width / 2, bearing + 90, { units: 'meters' })
  return [
    [sourceLeftLng, sourceLeftLat],
    [sourceRightLng, sourceRightLat],
    [destinationRightLng, destinationRightLat],
    [destinationLeftLng, destinationLeftLat],
  ]
}

export const angleBetweenTwoPoints = (source: Point, destination: Point): number => {
  const bearing = turfBearing(source, destination)
  return bearing
}

export const createBoundingBoxWithOffset = (
  polygon: Polygon,
  offset: Meter = 0,
): [Point, Point] => {
  const polygonFeature = turfPolygon([polygon])
  const bbox = turfBbox(polygonFeature)
  const expandedBbox = turfBboxPolygon(bbox)
  const buffered = turfBuffer(expandedBbox, offset, { units: 'meters' })
  const bufferedBbox = turfBbox(buffered)
  const southwestCoordinate = [bufferedBbox[0], bufferedBbox[1]] satisfies Point
  const northeastCoordinate = [bufferedBbox[2], bufferedBbox[3]] satisfies Point
  return [southwestCoordinate, northeastCoordinate]
}

export const prettifyPolygon = (polygon: Polygon): Polygon => {
  const polygonFeature = turfPolygon([polygon])
  const buffered = turfBuffer(polygonFeature, -1, { units: 'meters' })
  const bufferedExpanded = turfBuffer(buffered, 15, { units: 'meters' })
  return bufferedExpanded.geometry.coordinates.flatMap((coordinate) =>
    coordinate.map((coord) => [coord[0], coord[1]] satisfies Point),
  )
}

export function arePointsEqual(p1: Point, p2: Point): boolean {
  return p1[0] === p2[0] && p1[1] === p2[1]
}

export function removePolygonTails(points: Polygon): Point[] {
  const prettyPolygon = [...points]

  while (
    prettyPolygon.at(0) &&
    prettyPolygon.at(-1) &&
    arePointsEqual(prettyPolygon.at(0)!, prettyPolygon.at(-1)!)
  ) {
    prettyPolygon.shift()
    prettyPolygon.pop()
  }
  for (let i = 0; i < prettyPolygon.length - 2; i += 1) {
    const currentPoint = prettyPolygon[i]
    const nextPoint = prettyPolygon[i + 1]
    const afterNextPoint = prettyPolygon[i + 2]
    if (arePointsEqual(currentPoint, nextPoint)) {
      prettyPolygon.splice(i, 2)
      if (i > 0) {
        i -= 2
      }
    }
    if (arePointsEqual(currentPoint, afterNextPoint)) {
      prettyPolygon.splice(i, 3)
      if (i > 0) {
        i -= 2
      }
    }
  }

  return [...prettyPolygon, prettyPolygon[0]]
}
