import styled from '@emotion/styled'
import type { FC } from 'react'
import { useForm } from 'react-hook-form'
import { Button } from '~components/Button'
import { Dialog } from '~components/Dialog'
import { Input } from '~components/Input'
import { SettingsUserDocument, useChangeEmailMutation } from '~generated/graphql'
import { isValidEmail } from '~validation'
import { t } from '~utils/i18n'

type Props = {
  email: string
  onClose: () => void
  onEmailChange: (email: string) => void
}

type ChangeEmailInput = {
  email: string
}

export const ChangeEmailDialog: FC<Props> = ({ email, onClose, onEmailChange }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ChangeEmailInput>({
    defaultValues: {
      email,
    },
  })

  const [changeEmail, { error }] = useChangeEmailMutation({
    refetchQueries: [SettingsUserDocument],
    onError: (requestError) => {
      console.error(requestError)
    },
    onCompleted: (_, options) => {
      onEmailChange(options?.variables?.email)
      onClose()
    },
  })
  const handleChangeEmailSubmit = async (formData: { email: string }) => {
    await changeEmail({ variables: { email: formData.email } })
  }

  return (
    <StyledDialog withBackground onClose={onClose}>
      <Title>{t('settings:account.changeEmail.title')}</Title>
      <Form>
        <Input
          register={register}
          name="email"
          rules={{
            required: { value: true, message: t('form:requiredField')! },
            validate: (value) =>
              isValidEmail(value) ? undefined : t('form:ruleEmailMustBeCorrect'),
          }}
          type="text"
          label={t('form:emailPlaceholder')}
          error={errors.email?.message}
          autoComplete="email"
        />
        {error && <Error>{error.message}</Error>}
        <ButtonContainer>
          <StyledButton onClick={onClose} outline>
            {t('button:cancel')}
          </StyledButton>
          <StyledButton onClick={handleSubmit(handleChangeEmailSubmit)}>
            {t('button:submit')}
          </StyledButton>
        </ButtonContainer>
      </Form>
    </StyledDialog>
  )
}

const Error = styled.div`
  width: 100%;
  text-align: center;
  color: #ea4357;
  font-size: 14px;
  margin-top: 20px;
`

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 32px;
  line-height: 37px;
  font-weight: 600;
  margin-top: 40px;
  margin-bottom: 55px;
`

const StyledDialog = styled(Dialog)`
  width: 445px;
`

const Form = styled.form``

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 64px;
`

const StyledButton = styled(Button)`
  min-width: 165px;
`
