import { useMatchStore } from '~store'
import { TargetCharacterPanel } from '~components/CharacterPanel/CharacterPanel'

export const TargetMatchCharacterPanel = () => {
  const [character] = useMatchStore((state) => [state.targetCharacter])

  if (!character) {
    return
  }

  return <TargetCharacterPanel character={character} />
}
