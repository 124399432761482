import styled from '@emotion/styled'
import type { FC } from 'react'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import type { LoginInput } from '~generated/graphql'
import { Input } from '~components/Input'
import { Checkbox } from '~components/Checkbox'
import { Button } from '~components/Button'
import { useSignup } from './useSignup'
import { t } from '~utils/i18n'

type SignupInputs = {
  email: string
  password: string
  repeatPassword: string
  agree: boolean
}

type Props = {
  onForgotPassword?: () => void
}

export const SignupForm: FC<Props> = () => {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<SignupInputs>()
  const [createUser, { error }] = useSignup()
  const [showError, setShowError] = useState<boolean>(false)

  const handleLoginSubmit = async (data: LoginInput) => {
    setShowError(true)
    await createUser({
      email: data.email,
      password: data.password,
    })
  }

  return (
    <Form onSubmit={handleSubmit(handleLoginSubmit)}>
      <Input
        register={register}
        name="email"
        rules={{ required: { value: true, message: t('form:requiredField')! } }}
        type="text"
        label={t('form:emailLabel')}
        placeholder={t('form:emailPlaceholder')}
        error={errors.email?.message}
        autoComplete="email"
        onChange={() => {
          setShowError(false)
        }}
      />

      <InputHalf
        register={register}
        name="password"
        rules={{ required: { value: true, message: t('form:requiredField')! } }}
        type="password"
        label={t('form:passwordLabel')}
        placeholder={t('form:passwordPlaceholder')}
        error={errors.password?.message}
        autoComplete="current-password"
        onChange={() => {
          setShowError(false)
        }}
      />

      <InputHalf
        register={register}
        name="repeatPassword"
        rules={{
          required: { value: true, message: t('form:requiredField')! },
          validate: (value: string) =>
            value === getValues('password') || t('form:rulePasswordsMustMatch')!,
        }}
        type="password"
        label={t('auth:createAccount.confirmPasswordLabel')}
        placeholder={t('form:passwordPlaceholder')}
        error={errors.repeatPassword?.message}
        autoComplete="repeat-password"
        onChange={() => {
          setShowError(false)
        }}
      />

      <FormRow>
        <Checkbox
          register={register}
          name="agree"
          error={errors.agree?.message}
          rules={{ required: { value: true, message: t('form:requiredField')! } }}
          onChange={() => {
            setShowError(false)
          }}
        >
          {t('auth:createAccount.agreeWithTermsAndCondition')}{' '}
          <Link to="/" target="_blank">
            {t('auth:createAccount.termsAndConditionLink')}
          </Link>
        </Checkbox>
      </FormRow>

      {error && showError && <Error>{error.message}</Error>}
      <FormButton onClick={handleSubmit(handleLoginSubmit)}>{t('button:signUp')}</FormButton>
    </Form>
  )
}

const Form = styled.form`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
`

const FormButton = styled(Button)`
  margin-top: 36px;
  height: 48px;
  font-size: 16px;
`

const Error = styled.div`
  width: 100%;
  text-align: center;
  color: #ea4357;
  font-size: 14px;
  margin-top: 20px;
`

const FormRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 24px;
`

const InputHalf = styled(Input<SignupInputs>)`
  width: calc(50% - 12px);

  & + & {
    margin-left: 24px;
  }
`
