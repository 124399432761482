import type { Observable, Subscription } from 'rxjs'
import { timer } from 'rxjs'
import type { Point, UUID } from '~shared-types'
import type { RouteData } from './Route'
import { Route } from './Route'
import { MapRoute } from '../../match/model/MapRoute'
import type { LayerManager } from '../../match/model/LayerManager'

export class RouteManager {
  public mapRoute?: MapRoute
  private minimapRoute?: MapRoute
  public isCurrentUser?: boolean
  #route?: Route
  #displayRoute: boolean

  constructor(
    private characterId: UUID,
    isCurrentUser: boolean,
    private layerManagers: { map: LayerManager; minimap: LayerManager },
    private updatePosition: (position: Point) => void,
    private areActionsVisible: Observable<boolean>,
  ) {
    this.isCurrentUser = isCurrentUser
    this.#displayRoute = isCurrentUser ?? false
    areActionsVisible.subscribe((visibility) => {
      this.displayRoute = visibility
    })
  }

  get displayRoute(): boolean {
    return this.#displayRoute
  }

  set displayRoute(value: boolean) {
    this.#displayRoute = value
    if (value && !this.mapRoute) {
      this.addMapRoute()
    }
    if (!value && this.mapRoute) {
      this.removeMapRoute()
    }
  }

  private get route(): Route | undefined {
    return this.#route
  }

  private set route(route: Route | undefined) {
    this.#route = route
    if (!route) {
      this.removeMapRoute()
      return
    }
    if (this.displayRoute) {
      this.addMapRoute()
    } else {
      this.removeMapRoute()
    }
  }

  private addMapRoute() {
    if (!this.#route) {
      return
    }
    this.mapRoute = new MapRoute(this.characterId, this.#route.path, this.layerManagers.map, 'map')
    if (this.isCurrentUser) {
      this.minimapRoute = new MapRoute(
        this.characterId,
        this.#route.path,
        this.layerManagers.minimap,
        'minimap',
      )
    }
  }

  private removeMapRoute() {
    this.mapRoute?.clearRoute()
    this.mapRoute = undefined

    this.minimapRoute?.clearRoute()
    this.mapRoute = undefined
  }

  public addRoute(routeData: RouteData) {
    this.withDelay(routeData.startAt, () => {
      if (this.route) {
        this.route.cancel()
      }
      const route = new Route(
        this.characterId,
        routeData,
        ({ position, progress }) => {
          this.updatePosition(position)
          this.mapRoute?.updateProgress(progress)
          this.minimapRoute?.updateProgress(progress)
        },
        () => {
          this.route = undefined
        },
      )
      this.route = route
    })
  }

  public removeRoute() {
    if (this.route) {
      this.route.cancel()
    }
  }

  withDelay = (startAt: number, activity: () => void) => {
    const delayMillis = startAt - Date.now()

    timer(delayMillis).subscribe(() => {
      activity()
    })
  }
}
