import { useMemo } from 'react'
import type { FC } from 'react'
import { map, distinctUntilChanged, of } from 'rxjs'
import { MatchNotificationUI } from './MatchNotificationUI'
import { useMatchStore } from '../../../store/matchStore'
import { useValueFromObservable } from '../../../components/quest/useValueFromObservable'
import { CrowdControlsService } from '../model/statusEffectsGroups/crowdControls.service'
import { t } from '~utils/i18n'

export const MatchNotification: FC = () => {
  const [myCharacter] = useMatchStore((state) => [state.currentCharacter])

  const controlNotificationObservable = useMemo(
    () =>
      (myCharacter?.statusEffectsGroups.crowdControlsSubject ?? of([])).pipe(
        map((controls) => {
          const crowdControls = controls.map(({ type }) => type)
          if (
            CrowdControlsService.hasCastControls(crowdControls) &&
            CrowdControlsService.hasRouteControls(crowdControls)
          ) {
            return t('notification:match.canNotMoveOrUseAbilities')
          }
          if (CrowdControlsService.hasCastControls(crowdControls)) {
            return t('notification:match.canNotUseAbilities')
          }
          if (CrowdControlsService.hasRouteControls(crowdControls)) {
            return t('notification:match.canNotMove')
          }
          return undefined
        }),
        distinctUntilChanged(),
      ),
    [myCharacter?.statusEffectsGroups.crowdControlsSubject],
  )
  const controlNotification = useValueFromObservable(controlNotificationObservable)
  if (!controlNotification) {
    return null
  }
  return (
    <MatchNotificationUI side={myCharacter?.side ?? 'NEUTRAL'}>
      {controlNotification}
    </MatchNotificationUI>
  )
}
