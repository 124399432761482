import { useEffect, useState } from 'react'
import type { Observable } from 'rxjs'

function get<T>(observable: Observable<T>): T {
  let value
  observable.subscribe((val) => (value = val)).unsubscribe()
  return value as T
}

export function useValueFromObservable<T>(observable: Observable<T>) {
  const [value, setValue] = useState<T>(() => get(observable))

  useEffect(() => {
    const subscription = observable.subscribe((receivedValue) => setValue(receivedValue))
    return function cleanup() {
      subscription.unsubscribe()
    }
  }, [observable])

  return value
}
