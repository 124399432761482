import styled from '@emotion/styled'
import type { FC, PropsWithChildren } from 'react'

type Props = {
  onClick?: () => void
  className?: string
  outline?: boolean
  disabled?: boolean
}

export const Button: FC<PropsWithChildren<Props>> = ({
  children,
  onClick,
  className,
  outline = false,
  disabled = false,
}) => (
  <Container onClick={onClick} className={className} outline={outline} disabled={disabled}>
    <Text>{children}</Text>
  </Container>
)

const Container = styled.div<{ outline: boolean; disabled: boolean }>`
  user-select: none;
  height: 48px;
  cursor: pointer;
  border-radius: 36px;
  min-width: 213px;
  padding: 2px;
  font-size: 16px;
  font-weight: 600;
  position: relative;
  transition: 0.1s ease-in;
  background: linear-gradient(331deg, #808080 0%, #ada08a 30.73%, #d9d1bb 69.27%, #eae4cf 100%);
  color: ${({ outline }) => (outline ? '#ADA08A' : '#3d2924;')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.6' : '1')};

  &:before {
    content: '';
    z-index: 1;
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    border-radius: 36px;
    transition: opacity 0.1s ease-in;
    background: linear-gradient(
      338deg,
      #597e81 9.27%,
      #597e81 36.26%,
      #84aaad 70.12%,
      #d3faff 97.12%
    );
    opacity: 0;
  }

  &:after {
    content: '';
    z-index: 1;
    position: absolute;
    left: 3px;
    right: 3px;
    top: 3px;
    bottom: 3px;
    border-radius: 36px;
    transition: opacity 0.1s ease-in;
    background: ${({ outline }) => (outline ? '#2A3041' : '#30444f')};
    opacity: ${({ outline }) => (outline ? 1 : 0)};
  }

  &:hover {
    ${({ outline }) =>
      outline
        ? `
        background: linear-gradient(
      338deg,
      #597e81 9.27%,
      #597e81 36.26%,
      #84aaad 70.12%,
      #d3faff 97.12%
    );
    color: #84AAAD;
    `
        : `&:before {
      opacity: 1;
    }
    color: #30444f;`}
  }

  &:active {
    ${({ outline }) =>
      outline
        ? `
        color: #30444F;
          &:after {
            background: #131927;
          }
        `
        : `
    &:after {
      opacity: 1;
    }
    color: #587d80;
    `}
  }
`

const Text = styled.div`
  text-align: center;
  height: 100%;
  position: relative;
  z-index: 2;
  border: 1px solid #131927;
  border-radius: 36px;
  display: grid;
  place-items: center;
  padding: 0 30px;
`

export const MediumButton = styled(Button)`
  height: 65px;
  min-width: 225px;
  font-size: 22px;
  padding: 3px;

  &:before,
  &:after {
    left: 5px;
    right: 5px;
    top: 5px;
    bottom: 5px;
  }

  ${Text} {
    border: 2px solid #131927;
  }
`

export const BigButton = styled(Button)`
  height: 72px;
  min-width: 244px;
  font-size: 24px;
  padding: 4px;

  &:before,
  &:after {
    left: 6px;
    right: 6px;
    top: 6px;
    bottom: 6px;
  }

  ${Text} {
    border: 2px solid #131927;
  }
`

export const IconButton = styled(Button)`
  min-width: 48px;

  &:hover,
  &:active {
    --icon-button-gradient-top: #d3faff;
    --icon-button-gradient-middle: #84aaad;
    --icon-button-gradient-bottom: #597e81;
  }

  ${Text} {
    padding: 1px 0 0;
  }
`
