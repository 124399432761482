import type { Map } from 'maplibre-gl'
import { BehaviorSubject, throttleTime, timer } from 'rxjs'
import { angleBetweenTwoPoints, spatialDistance } from '~map-tools'
import type { Second } from '~shared-types'
import { convertMetersToPixels } from '~utils/map'
import type { CharacterEntity } from '../CharacterEntity'
import { AbilityAnimationUI } from '../../components/animation/AbilityAnimationUI'
import { AbilityAttackRangedAnimationUI } from '../../components/animation/AbilityAttackRangedAnimationUI'
import { animationDurationMap } from '~utils/animation'
import { AnimationMarker } from './AnimationMarker'

export class AttackRangedAnimation {
  public markerAnimationSizeSubject: BehaviorSubject<number>

  constructor(map: Map, caster: CharacterEntity, target: CharacterEntity) {
    this.markerAnimationSizeSubject = new BehaviorSubject(0)

    // bullet animation
    const size = convertMetersToPixels(map, spatialDistance(caster.position, target.position))
    const motionDuration: Second = 1
    this.markerAnimationSizeSubject.next(size)
    const bulletMarkerComponent = (
      <AbilityAttackRangedAnimationUI
        side={caster.side}
        race={caster.race}
        id={`${caster.id}${target.id}${Date.now()}ATTACK_RANGED`}
        sizeObservable={this.markerAnimationSizeSubject}
        motionDuration={motionDuration}
      />
    )
    const angle = angleBetweenTwoPoints(caster.position, target.position)
    const bulletMarker = new AnimationMarker(
      map,
      bulletMarkerComponent,
      caster.position,
      true,
      angle,
    )

    // source animation
    const sourceMarkerComponent = (
      <AbilityAnimationUI
        side={caster.side}
        race={caster.race}
        animationType="ATTACK_RANGED_SOURCE"
        id={`${caster.id}${target.id}ATTACK_RANGED_SOURCE`}
      />
    )
    const sourceMarker = new AnimationMarker(
      map,
      sourceMarkerComponent,
      caster.position,
      false,
      angle,
    )
    const sourceSubscription = timer(animationDurationMap.ATTACK_RANGED_SOURCE * 1000).subscribe(
      () => {
        sourceMarker.remove()
        sourceSubscription.unsubscribe()
      },
    )

    // target animation
    let targetMarker: AnimationMarker | undefined
    const motionSubscription = timer(motionDuration * 1000).subscribe(() => {
      motionSubscription.unsubscribe()
      bulletMarker.remove()
      const targetMarkerComponent = (
        <AbilityAnimationUI
          side={caster.side}
          race={caster.race}
          animationType="ATTACK_RANGED_TARGET_VAR1"
          id={`${caster.id}${target.id}ATTACK_RANGED_TARGET_VAR1`}
        />
      )
      targetMarker = new AnimationMarker(
        map,
        targetMarkerComponent,
        target.position,
        false,
        (angle + 180) % 360,
      )

      const targetSubscription = timer(
        animationDurationMap.ATTACK_RANGED_TARGET_VAR1 * 1000,
      ).subscribe(() => {
        targetMarker?.remove()
        targetSubscription.unsubscribe()
        positionSubscription.unsubscribe()
      })
    })

    const positionSubscription = target.positionObservable
      .pipe(throttleTime(50))
      .subscribe((position) => {
        const angleUpdated = angleBetweenTwoPoints(caster.position, position)
        bulletMarker.setRotation(angleUpdated)
        const newSize = convertMetersToPixels(map, spatialDistance(caster.position, position))
        this.markerAnimationSizeSubject.next(newSize)

        targetMarker?.setLngLat(position)
        targetMarker?.setRotation((angleUpdated + 180) % 360)
      })
  }
}
