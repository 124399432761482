import styled from '@emotion/styled/macro'
import { BigButton } from '~components/Button'
import { raceListBySide } from '~utils/character'
import { SessionDocument, Side, useSelectCharacterSideMutation } from '~generated/graphql'
import { RaceIconFrame } from '../ChooseRace/RaceIconFrame'
import { useStore } from '~store'
import { t } from '~utils/i18n'
import { ZoneMap } from '../../zone/model/ZoneMap'

import BgSideLight from '~assets/bg-side-light.svg'
import BgSideDark from '~assets/bg-side-dark.svg'
import IconSideDark from '~assets/icon-side-dark.svg'
import IconSideLight from '~assets/icon-side-light.svg'
import BgSideLightSparks from '~assets/bg-side-light-sparks.png'
import BgSideLightSparks2x from '~assets/bg-side-light-sparks_2x.png'
import BgSideDarkSparks from '~assets/bg-side-dark-sparks.png'
import BgSideDarkSparks2x from '~assets/bg-side-dark-sparks_2x.png'

export const ChooseSide = () => {
  const [session, setFullscreenId] = useStore((state) => [state.session, state.setFullscreenId])

  const [sendSelectSide] = useSelectCharacterSideMutation({
    refetchQueries: [SessionDocument],
    onCompleted: (data) => {
      const character = ZoneMap.getInstance().getCurrentCharacter()
      if (character) {
        character.side = data.selectCharacterSide.side
      }
      setFullscreenId(null)
    },
    onError: (error) => {
      console.error('Can not select side', error)
    },
  })

  if (!session?.character) {
    return null
  }

  const { gender } = session.character
  return (
    <Container>
      <LightSide>
        <Content>
          <Title>{t('chooseSide:chooseLight')}</Title>
          <Description>{t('chooseSide:lightDescription')}</Description>
          <Classes>
            <Subtitle>{t('chooseSide:availableRaces')}</Subtitle>
            <ClassesList>
              {raceListBySide.LIGHT.map((race) => (
                <ClassItem key={race}>
                  <RaceIconFrame race={race} />
                  <ClassName>
                    {
                      t('common:racesList')?.find((raceObj) => raceObj.race === race)?.[
                        gender === 'MALE' ? 'nameMale' : 'nameFemale'
                      ]
                    }
                  </ClassName>
                </ClassItem>
              ))}
            </ClassesList>
          </Classes>
          <ButtonContainer>
            <SelectButton onClick={() => sendSelectSide({ variables: { side: 'LIGHT' } })}>
              {t('button:choose')}
            </SelectButton>
          </ButtonContainer>
        </Content>
      </LightSide>
      <DarkSide>
        <Content>
          <Title>{t('chooseSide:chooseDarkness')}</Title>
          <Description>{t('chooseSide:darknessDescription')}</Description>
          <Classes>
            <Subtitle>{t('chooseSide:availableRaces')}</Subtitle>
            <ClassesList>
              {raceListBySide.DARKNESS.map((race) => (
                <ClassItem key={race}>
                  <RaceIconFrame race={race} />
                  <ClassName>
                    {
                      t('common:racesList')?.find((raceObj) => raceObj.race === race)?.[
                        gender === 'MALE' ? 'nameMale' : 'nameFemale'
                      ]
                    }
                  </ClassName>
                </ClassItem>
              ))}
            </ClassesList>
          </Classes>
          <ButtonContainer>
            <SelectButton onClick={() => sendSelectSide({ variables: { side: 'DARKNESS' } })}>
              {t('button:choose')}
            </SelectButton>
          </ButtonContainer>
        </Content>
      </DarkSide>
    </Container>
  )
}

const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;

  justify-content: center;
  align-items: flex-end;
  flex: 1;
`

const SelectButton = styled(BigButton)`
  opacity: 0;
`

const Content = styled.div`
  padding-top: calc(100vh / 6);
  padding-bottom: 72px;
  width: 450px;
  height: 100%;
  display: flex;
  flex-direction: column;
`

const SideContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-start;
  width: calc(50vw + 59px);
  transition: width 0.3s ease;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;

  &:has(+ div:hover) {
    width: calc(50vw - 32px);
  }

  &:hover {
    width: calc(50vw + 150px);

    ${SelectButton} {
      opacity: 1;
    }

    + div {
      width: calc(50vw - 32px);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    margin-top: -240px;
    pointer-events: none;
    width: 480px;
    height: 480px;
  }
`

const LightSide = styled(SideContainer)`
  left: 0;
  background: #131927 url(${BgSideLight}) no-repeat left center;
  background-size: cover;
  justify-content: flex-start;

  &:before {
    left: calc((100vw - 480px) / 2);
    background: url(${IconSideLight}) no-repeat left center;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 3px;
    width: 338px;
    height: 491px;
    background: image-set(url('${BgSideLightSparks}') 1x, url('${BgSideLightSparks2x}') 2x)
      no-repeat top;
  }

  ${Content} {
    margin-left: calc((100vw - 1240px) / 2);
  }
`

const DarkSide = styled(SideContainer)`
  right: 0;
  clip-path: polygon(118px 0, 100% 0px, 100% 100%, 0px 100%);
  background: #131927 url(${BgSideDark}) no-repeat right center;
  background-size: cover;
  justify-content: flex-end;

  &:before {
    right: calc((100vw - 480px) / 2);
    background: url(${IconSideDark}) no-repeat;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: -166px;
    width: 482px;
    height: 482px;
    background: image-set(url('${BgSideDarkSparks}') 1x, url('${BgSideDarkSparks2x}') 2x) no-repeat
      bottom;
  }

  ${Content} {
    margin-right: calc((100vw - 1240px) / 2);
  }
`

const Container = styled.div`
  position: fixed;
  z-index: 19;
  background: #131927;
  color: #646d85;
  height: 100vh;
  width: 100vw;

  &:before {
    content: '';
    top: 0;
    left: 50%;
    margin-left: -59px;
    position: absolute;
    width: 118px;
    height: calc(100% + 20px);
    background: radial-gradient(#ada08a, #808080, #2a3041);
    pointer-events: none;
    z-index: 11;
    transition: margin-left 0.3s ease;
    clip-path: polygon(118px 0px, 112px 0px, 0px 100vh, 6px 100vh, 118px 0px);
  }

  &:has(> ${LightSide}:hover):before {
    margin-left: 32px;
  }

  &:has(> ${DarkSide}:hover):before {
    margin-left: -150px;
  }
`

const Title = styled.h2`
  font-size: 60px;
  font-weight: 400;
  color: #fff;
  margin: 0;
  margin-bottom: 20px;
`

const Description = styled.div`
  font-size: 16px;
  height: 170px;
  line-height: 23px;
`

const Classes = styled.div`
  margin-top: 30px;
`

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 38px;
  color: #fff;
`

const ClassesList = styled.div`
  display: flex;
  align-items: flex-start;
  text-align: center;
`

const ClassItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  & + & {
    margin-left: 10px;
  }
`

const ClassName = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin-top: 12px;
`
