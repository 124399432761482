import styled from '@emotion/styled'
import type { ReactNode } from 'react'
import { forwardRef, useMemo } from 'react'
import { Duration } from 'luxon'
import type { Observable } from 'rxjs'
import { distinctUntilChanged, of } from 'rxjs'
import type { Side, AbilityCostType } from '~generated/graphql'
import type { Race, DurationIso, Second } from '~shared-types'
import { useStore } from '~store'
import { t } from '~utils/i18n'
import { raceColorMap, sideColorMap } from '~utils/character'
import type { AdvancedStats } from '../CharacterPanel/CharacterAdvancedStats'
import { useValueFromObservable } from '../quest/useValueFromObservable'

import { ReactComponent as IconEnergy } from '~assets/icon-energy-tooltip.svg'
import { ReactComponent as IconHealth } from '~assets/icon-health-tooltip.svg'
import IconPhysicalPower from '~assets/icon-phys-power.svg'
import IconMagicPower from '~assets/icon-magic-power.svg'

export type AbilityTooltipProps = {
  description?: string
  castTime?: DurationIso
  channelTime?: DurationIso
  cooldown?: DurationIso
  costType?: AbilityCostType
  cost?: number
  range?: { max: number }
  name?: string
  className?: string
  style?: React.CSSProperties
  statsObservable?: Observable<AdvancedStats>
  effectDescription?: string
}

function formatFloatNumber(value: number): string {
  return value.toFixed(2).replace(/\.?0+$/, '')
}

export function replacePlaceholdersForAbilityEffectDescription(
  description: string,
  stats: Pick<AdvancedStats, 'physicalPower' | 'magicPower'>,
) {
  const regex = /{{([^}]+)}}/g // Match anything inside double curly braces
  const parts: ReactNode[] = []
  let lastIndex = 0
  let match: RegExpExecArray | null

  for (match = regex.exec(description); match !== null; match = regex.exec(description)) {
    const [fullMatch, content] = match
    const { index } = match

    // Push the text before the match
    parts.push(description.slice(lastIndex, index))

    const params: {
      BASE?: string
      PH?: string
      PH_FLOAT?: string
      MA: string
      MA_FLOAT: string
      COLOR: 'PH' | 'MA'
    } = Object.fromEntries(
      content.split(';').map((part) => part.split(':').map((string, i) => string.trim())),
    )

    const physicalPowerMultiplier = Number(params.PH_FLOAT ?? params.PH ?? 0)
    const magicPowerMultiplier = Number(params.MA_FLOAT ?? params.MA ?? 0)
    const totalValue =
      Number(params.BASE ?? 0) +
      physicalPowerMultiplier * stats.physicalPower +
      magicPowerMultiplier * stats.magicPower

    const formattedValue =
      params.PH_FLOAT ?? params.MA_FLOAT ? formatFloatNumber(totalValue) : Math.floor(totalValue)

    let color: 'Default' | 'Physical' | 'Magic' = 'Default'
    if (params.COLOR === 'PH') {
      color = 'Physical'
    }
    if (params.COLOR === 'MA') {
      color = 'Magic'
    }

    // Push the formatted result with the appropriate color and icons
    parts.push(
      <HighlightValue key={index} type={color}>
        {formattedValue}
        {physicalPowerMultiplier > 0 && <img src={IconPhysicalPower} alt="Physical Power" />}
        {magicPowerMultiplier > 0 && <img src={IconMagicPower} alt="Magic Power" />}
      </HighlightValue>,
    )

    lastIndex = index + fullMatch.length
  }

  // Push the remaining text after the last match
  parts.push(description.slice(lastIndex))
  return parts
}

function getCastTime(duration: DurationIso, stats?: AdvancedStats): string {
  const defaultCastTime = Duration.fromISO(duration).as('seconds')

  if (stats) {
    const castTimeMultiplier = 100 / stats.castingSpeed
    return formatCastTime(defaultCastTime * castTimeMultiplier)
  }
  return formatCastTime(defaultCastTime)
}

function formatCastTime(castTime: Second): string {
  const roundedCastTime = Math.round(castTime * 10) / 10

  if (roundedCastTime % 1 === 0) {
    return roundedCastTime.toString()
  }

  return roundedCastTime.toFixed(1)
}

type Ref = HTMLDivElement

export const AbilityTooltip = forwardRef<Ref, AbilityTooltipProps>(
  (
    {
      name,
      cost,
      cooldown,
      costType,
      description,
      castTime,
      range,
      className,
      style,
      channelTime,
      statsObservable,
      effectDescription,
    },
    ref,
  ) => {
    const [session] = useStore((state) => [state.session])
    const advancedStatsObservable = useMemo(
      () =>
        statsObservable
          ? statsObservable.pipe(
              distinctUntilChanged(
                (prev, cur) =>
                  !(
                    prev.physicalPower !== cur.physicalPower ||
                    prev.magicPower !== cur.magicPower ||
                    prev.castingSpeed !== cur.castingSpeed
                  ),
              ),
            )
          : of(undefined),
      [statsObservable],
    )
    const stats = useValueFromObservable(advancedStatsObservable)

    return (
      <TooltipContainer className={className} ref={ref} style={style}>
        {name ? (
          <Header>
            <Title>{name}</Title>
            <Key>
              {/* [W] */}
              {range?.max && range.max > 0 ? `${range.max} m` : null}
            </Key>
          </Header>
        ) : null}
        <Stats>
          <div>
            {channelTime ? (
              <div>
                {t('tooltip:ability.channeled')} /{' '}
                <b>
                  {Duration.fromISO(channelTime).as('seconds')}
                  {t('common:units.seconds')}
                </b>{' '}
                {t('tooltip:ability.channel')}
              </div>
            ) : null}
            {castTime ? (
              <>
                <b>
                  {getCastTime(castTime, stats)}
                  {t('common:units.seconds')}
                </b>{' '}
                {t('tooltip:ability.cast')} /{' '}
              </>
            ) : null}
            {cooldown ? (
              <>
                <b>
                  {Duration.fromISO(cooldown).as('seconds')}
                  {t('common:units.seconds')}
                </b>{' '}
                {t('tooltip:ability.cooldown')}
              </>
            ) : null}
          </div>
          {cost ? (
            <Cost>
              {costType === 'HEALTH' ? (
                <StyledIconHealth race={session?.character?.race || 'HUMAN'} />
              ) : (
                <StyledIconEnergy side={session?.character?.side || 'NEUTRAL'} />
              )}{' '}
              {cost}
            </Cost>
          ) : null}
        </Stats>
        {description && !(effectDescription && stats) ? (
          <Description>{description}</Description>
        ) : null}
        {effectDescription && stats ? (
          <Description>
            {replacePlaceholdersForAbilityEffectDescription(effectDescription, stats)}
          </Description>
        ) : null}
      </TooltipContainer>
    )
  },
)

const TooltipContainer = styled.div`
  background: linear-gradient(to bottom, #131927, #2a3041);
  border-radius: 12px;
  padding: 18px 24px;
  color: #646d85;
  font-size: 14px;
  width: 370px;
  z-index: 100;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
`

const HighlightValue = styled.div<{ type?: 'Physical' | 'Magic' | 'Default' }>`
  display: inline-flex;
  color: ${({ type }) =>
    type === 'Default' ? '#d4d4d4' : type === 'Physical' ? '#f7c46c' : '#6f8ff5'};
`

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #646d85;
  margin-bottom: 8px;
  padding: 5px 0;

  b {
    color: #fff;
  }
`

const Title = styled.div`
  color: #fff;
  font-size: 18px;
`

const Key = styled.div`
  /* font-size: 18px; */
  font-size: 14px;
`

const Cost = styled.div`
  color: #fff;
  display: flex;
  align-items: center;
`

const Description = styled.div`
  b {
    color: #ada08a;
  }
`

const StyledIconEnergy = styled(IconEnergy)<{ side: Side }>`
  --icon-energy-tooltip-gradient1: ${({ side }) => sideColorMap[side].light};
  --icon-energy-tooltip-gradient2: ${({ side }) => sideColorMap[side].medium};
  --icon-energy-tooltip-gradient3: ${({ side }) => sideColorMap[side].dark};
`

const StyledIconHealth = styled(IconHealth)<{ race: Race }>`
  --icon-health-tooltip-gradient1: ${({ race }) => raceColorMap[race].main};
  --icon-health-tooltip-gradient2: ${({ race }) => raceColorMap[race].dark};
`
