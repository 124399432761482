import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Race, Second, Side } from '~shared-types'

import { AbilityAreaDirection } from '../AbilityAreaDirection'

type Props = {
  side: Side
  race: Race
  id: string
  width: number
  height: number
  motionDelay: Second
}

export const AbilityDirectionAreaAnimationUI: FC<Props> = ({
  race,
  side,
  width,
  height,
  id,
  motionDelay,
}) => (
  <AnimatedAbilityAreaDirection
    race={race}
    side={side}
    width={width}
    height={height}
    id={id}
    duration={motionDelay}
  />
)

const AnimatedAbilityAreaDirection = styled(AbilityAreaDirection)<{
  id: string
  duration: Second
}>`
  animation-name: ${({ id }) => `ability-direction-area-animation-${id}`};
  animation-duration: ${({ duration }) => duration}s;
  animation-fill-mode: forwards;
  @keyframes ${({ id }) => `ability-direction-area-animation-${id}`} {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0.7;
    }
  }
  > div {
    animation-name: ${({ id }) => `ability-direction-area-arrows-animation-${id}`};
    animation-duration: ${({ duration }) => duration}s;
    animation-fill-mode: forwards;
    @keyframes ${({ id }) => `ability-direction-area-arrows-animation-${id}`} {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }
`
