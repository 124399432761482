import styled from '@emotion/styled'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { Button } from '~components/Button'
import { AbilitiesPanel } from '~components/AbilitiesPanel/AbilitiesPanel'
import type { MasteryType } from '~generated/graphql'
import {
  CharactersDocument,
  MasteriesDocument,
  Race,
  Side,
  useLearnMasteriesMutation,
  useMasteriesQuery,
} from '~generated/graphql'
import { isTruthy } from '~utils'
import { t } from '~utils/i18n'
import { raceColorMap } from '~utils/character'
import { store, useStore } from '~store'
import { TWILIGHT_ABILITY_IDS } from '~constants/map'
import type { MasteryRef, MasteryUnsavedChanges } from './Mastery'
import { Mastery } from './Mastery'
import { MasteriesBar } from './MasteriesBar'

import BgLightLeft from '~assets/bg-masteries-light-left.svg'
import BgLightRight from '~assets/bg-masteries-light-right.svg'
import BgDarkLeft from '~assets/bg-masteries-dark-left.svg'
import BgDarkRight from '~assets/bg-masteries-dark-right.svg'
import { ReactComponent as IconClose } from '~assets/icon-close.svg'
import { ReactComponent as IconFrameCorner } from '~assets/icon-masteries-frame-corner.svg'
import { ReactComponent as IconFrameCenter } from '~assets/icon-masteries-frame-center.svg'

type Props = {
  onClose?: () => void
}

export const Masteries: FC<Props> = ({ onClose }) => {
  const leftMasteryRef = useRef<MasteryRef>(null)
  const rightMasteryRef = useRef<MasteryRef>(null)
  const [currentCharacter] = store((state) => [state.currentCharacter])
  const [leftMasteryUnsavedChanges, setLeftMasteryUnsavedChanges] =
    useState<MasteryUnsavedChanges>()
  const [rightMasteryUnsavedChanges, setRightMasteryUnsavedChanges] =
    useState<MasteryUnsavedChanges>()

  const { data } = useMasteriesQuery({
    skip: !currentCharacter,
    variables: {
      race: currentCharacter?.race || 'HUMAN',
      characterId: currentCharacter?.id as string,
    },
    onCompleted() {
      setLeftMasteryUnsavedChanges(undefined)
      setRightMasteryUnsavedChanges(undefined)
    },
    notifyOnNetworkStatusChange: true,
  })

  const learnedMasteries = data?.character?.learnedMasteries || []
  const leftMasteryLearned = learnedMasteries.find((m) => m.masteryId === data?.masteries[0]?.id)
  const rightMasteryLearned = learnedMasteries.find((m) => m.masteryId === data?.masteries[1]?.id)

  const [learnMasteryMutation] = useLearnMasteriesMutation({
    refetchQueries: [MasteriesDocument, CharactersDocument],
  })

  const saveChanges = async (update?: MasteryUnsavedChanges) => {
    const input = update
      ? [update]
      : [leftMasteryUnsavedChanges, rightMasteryUnsavedChanges]
          .filter(isTruthy)
          .filter(
            ({ levelIncrease, chosenAbilitiesIds }) => levelIncrease || chosenAbilitiesIds.length,
          )
    await learnMasteryMutation({
      variables: {
        input,
      },
    })
  }

  if (!currentCharacter) return null

  const color = raceColorMap[currentCharacter.race].main
  const { side, race } = currentCharacter

  const availablePoints =
    (data?.character?.masteryPoints.available || 0) -
    (leftMasteryUnsavedChanges?.levelIncrease || 0) -
    (rightMasteryUnsavedChanges?.levelIncrease || 0)

  const points1 = (leftMasteryLearned?.level || 0) + (leftMasteryUnsavedChanges?.levelIncrease || 0)
  const points2 =
    (rightMasteryLearned?.level || 0) + (rightMasteryUnsavedChanges?.levelIncrease || 0)

  const buttonDisabled = !(
    leftMasteryUnsavedChanges?.levelIncrease ||
    leftMasteryUnsavedChanges?.chosenAbilitiesIds.length ||
    rightMasteryUnsavedChanges?.levelIncrease ||
    rightMasteryUnsavedChanges?.chosenAbilitiesIds.length
  )

  const abilities = (data?.character?.abilities || []).filter(
    ({ id }) => !TWILIGHT_ABILITY_IDS.includes(id),
  )

  return (
    <Container side={side}>
      <Frame borderColor={color}>
        <Background>
          <StyledIconFrameCenter fill={color} />
          <StyledIconFrameCorner position="left-top" fill={color} />
          <StyledIconFrameCorner position="left-bottom" fill={color} />
          <StyledIconFrameCorner position="right-top" fill={color} />
          <StyledIconFrameCorner position="right-bottom" fill={color} />
        </Background>

        <MasteriesContainer>
          <Mastery
            ref={leftMasteryRef}
            direction="left"
            masteryInfo={data?.masteries[0]}
            learnedMasteryInfo={data?.character?.learnedMasteries.find(
              (m) => m.masteryId === data?.masteries[0]?.id,
            )}
            availablePoints={availablePoints}
            unsavedChanges={leftMasteryUnsavedChanges}
            setUnsavedChanges={setLeftMasteryUnsavedChanges}
            characterLevel={data?.character?.level}
            onSaveChanges={saveChanges}
          />
          <MasteriesBar
            availablePoints={availablePoints}
            pointsLeft={points1}
            pointsRight={points2}
            masteryLeftType={data?.masteries[0].type}
            masteryRightType={data?.masteries[1].type}
            statsPerLevelLeft={data?.masteries[0].statsPerLevel}
            statsPerLevelRight={data?.masteries[1].statsPerLevel}
            side={side}
            race={race}
            isLeftBarDisabled={!leftMasteryLearned}
            isRightBarDisabled={!rightMasteryLearned}
          />
          <Mastery
            ref={rightMasteryRef}
            direction="right"
            masteryInfo={data?.masteries[1]}
            learnedMasteryInfo={data?.character?.learnedMasteries.find(
              (m) => m.masteryId === data?.masteries[1]?.id,
            )}
            availablePoints={availablePoints}
            unsavedChanges={rightMasteryUnsavedChanges}
            setUnsavedChanges={setRightMasteryUnsavedChanges}
            characterLevel={data?.character?.level}
            onSaveChanges={saveChanges}
          />
        </MasteriesContainer>
        <ButtonListContainer>
          {rightMasteryLearned || leftMasteryLearned ? (
            <ApplyButton onClick={() => saveChanges()} disabled={!!buttonDisabled}>
              {t('button:save')}
            </ApplyButton>
          ) : null}
        </ButtonListContainer>
      </Frame>
      <AbilitiesPanel
        abilities={abilities}
        statsObservable={currentCharacter?.statsSubject}
        isView
        side={currentCharacter.side}
        useAbilitiesStore={useStore}
      />
      <CloseButton onClick={onClose}>
        <IconClose />
      </CloseButton>
    </Container>
  )
}

const Container = styled.div<{ side?: Side }>`
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: url(${({ side }) => (side === 'LIGHT' ? BgLightLeft : BgDarkLeft)}) no-repeat top left,
    url(${({ side }) => (side === 'LIGHT' ? BgLightRight : BgDarkRight)}) no-repeat bottom right,
    #131927;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px 160px;
`

const Frame = styled.div<{ borderColor: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 1200px;
  border: ${({ borderColor }) => `2px solid ${borderColor}26`};
  padding: 50px 50px 45px;
`

const Background = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  display: grid;
  place-items: center;
`

const StyledIconFrameCorner = styled(IconFrameCorner)<{
  position: 'left-top' | 'left-bottom' | 'right-top' | 'right-bottom'
}>`
  position: absolute;
  pointer-events: none;
  top: ${({ position }) => (position.includes('top') ? '2px' : 'auto')};
  bottom: ${({ position }) => (position.includes('bottom') ? '2px' : 'auto')};
  left: ${({ position }) => (position.includes('left') ? '2px' : 'auto')};
  right: ${({ position }) => (position.includes('right') ? '2px' : 'auto')};
  transform: ${({ position }) =>
    `scaleX(${position.includes('left') ? '1' : '-1'}) scaleY(${
      position.includes('top') ? '1' : '-1'
    })`};
  fill-opacity: 0.15;
`

const StyledIconFrameCenter = styled(IconFrameCenter)`
  fill-opacity: 0.15;
`

const MasteriesContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
`

const ButtonListContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 48px;
`

const ApplyButton = styled(Button)`
  min-width: 134px;
`

const CloseButton = styled.div`
  position: absolute;
  top: 36px;
  right: 36px;
  cursor: pointer;
`
