import styled from '@emotion/styled'
import type { FC } from 'react'
import { ReactComponent as IconHint } from '~assets/icon-hint.svg'
import withTooltip from '~components/Tooltip/withTooltip'

type Props = {
  tooltipContent?: string
}

export const Hint: FC<Props> = ({ tooltipContent }) => {
  const ContainerWithTooltip = tooltipContent
    ? withTooltip(
        Container,
        { type: 'text', content: tooltipContent },
        { placement: 'right', maxWidth: 200 },
      )
    : Container

  return (
    <ContainerWithTooltip>
      <IconHint />
    </ContainerWithTooltip>
  )
}

const Container = styled.div`
  display: inline-flex;
  margin-left: 4px;
  position: relative;
  top: 3px;
  cursor: pointer;
`
