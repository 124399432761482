import { memo, useEffect, useRef } from 'react'
import type { FC } from 'react'
import { useCharacterQuestsQuery } from '~generated/graphql'
import { QuestDialog } from './QuestDialog'
import { Quest } from './quest'
import type { Int } from '~shared-types'

export const QuestDialogManager: FC = memo(() => {
  const { data } = useCharacterQuestsQuery()

  const questsRef = useRef<Map<Int, Quest>>(new Map())

  useEffect(
    () => () => {
      questsRef.current = new Map()
    },
    [],
  )

  if (!data?.character?.quests.length) {
    return null
  }

  return data?.character?.quests
    .filter((questData) => questData.status !== 'COMPLETED')
    .map((questData) => {
      let quest = questsRef.current.get(questData.id)
      if (!quest) {
        quest = new Quest(questData.id, questData.story, questData.state ?? undefined)
        questsRef.current.set(questData.id, quest)
      }

      return <QuestDialog quest={quest} key={questData.id} />
    })
})

export default QuestDialogManager
