import type { ReactElement } from 'react'
import type { Side, Race } from '~generated/graphql'
import { ReactComponent as IconClairvoyant } from '~assets/icon-clairvoyant.svg'
import { ReactComponent as IconWitch } from '~assets/icon-witch.svg'
import { ReactComponent as IconMagician } from '~assets/icon-magician.svg'
import { ReactComponent as IconWerewolf } from '~assets/icon-werewolf.svg'
import { ReactComponent as IconVampire } from '~assets/icon-vampire.svg'
import { ReactComponent as IconShapeshifterDog } from '~assets/icon-shapeshifter-dog.svg'
import type { Character, UnitType } from '~generated/match-graphql'
import { AbilityTargetType } from '~generated/match-graphql'

export const sideTextColorMap: Record<Side, string> = {
  LIGHT: '#20A9F6',
  DARKNESS: '#EA4357',
  NEUTRAL: '#EAE4CF',
}

export const sideColorMap: Record<
  Side,
  {
    light: string
    medium: string
    dark: string
  }
> = {
  LIGHT: {
    light: '#9DFFF9',
    medium: '#5EF7F7',
    dark: '#0080EB',
  },
  DARKNESS: {
    light: '#FFD700',
    medium: '#F4673B',
    dark: '#D51F35',
  },
  NEUTRAL: {
    light: '#EAE4CF',
    medium: '#B4B1A7',
    dark: '#808080',
  },
}

export const raceColorMap: Record<
  Race,
  {
    main: string
    dark: string
  }
> = {
  CLAIRVOYANT: {
    main: '#FFF468',
    dark: '#906507',
  },
  WITCH: {
    main: '#2FBC5E',
    dark: '#136744',
  },
  MAGICIAN: {
    main: '#33C6B0',
    dark: '#026574',
  },
  VAMPIRE: {
    main: '#9A69EA',
    dark: '#341C7B',
  },
  WEREWOLF: {
    main: '#FFA500',
    dark: '#8B4513',
  },
  SHAPESHIFTER_CAT_LIKE: {
    main: '#F48CBA',
    dark: '#8F156B',
  },
  SHAPESHIFTER_DOG_LIKE: {
    main: '#FFA500',
    dark: '#8B4513',
  },
  HUMAN: {
    main: '#EAE4CF',
    dark: '#808080',
  },
}

export const raceSvgColorMap: Record<
  Race,
  {
    main: string
    dark: string
  }
> = {
  CLAIRVOYANT: {
    main: '#C5C556',
    dark: '#6C5A00',
  },
  HUMAN: {
    main: '#6e6d73',
    dark: '#6e6d73',
  },
  MAGICIAN: {
    main: '#33C6B0',
    dark: '#026574',
  },
  SHAPESHIFTER_DOG_LIKE: {
    main: '#725F9A',
    dark: '#322262',
  },
  SHAPESHIFTER_CAT_LIKE: {
    main: '#725F9A',
    dark: '#322262',
  },
  WEREWOLF: {
    main: '#B36A40',
    dark: '#6A1D1D',
  },
  VAMPIRE: {
    main: '#8064BC',
    dark: '#362078',
  },
  WITCH: {
    main: '#2FBC5E',
    dark: '#136744',
  },
}

export const raceIconMap: Record<Race, ReactElement> = {
  CLAIRVOYANT: <IconClairvoyant />,
  MAGICIAN: <IconMagician />,
  WITCH: <IconWitch />,
  WEREWOLF: <IconWerewolf />,
  VAMPIRE: <IconVampire />,
  SHAPESHIFTER_DOG_LIKE: <IconShapeshifterDog />,
  SHAPESHIFTER_CAT_LIKE: <IconShapeshifterDog />,
  // eslint-disable-next-line react/jsx-no-useless-fragment
  HUMAN: <></>,
}

export const raceListBySide: Record<Side, Race[]> = {
  LIGHT: ['SHAPESHIFTER_DOG_LIKE', 'SHAPESHIFTER_CAT_LIKE', 'MAGICIAN', 'CLAIRVOYANT', 'WITCH'],
  DARKNESS: ['VAMPIRE', 'WEREWOLF', 'MAGICIAN', 'CLAIRVOYANT', 'WITCH'],
  NEUTRAL: [],
}

export const raceHealthIconShadowMap: Record<Race, string> = {
  CLAIRVOYANT: 'drop-shadow(0px 1px 1px rgba(197, 197, 86, 0.75))',
  HUMAN: 'drop-shadow(0px 1px 1px rgba(255, 255, 255, 0.75))',
  MAGICIAN: 'drop-shadow(0px 1px 1px rgba(51, 198, 176, 0.75))',
  SHAPESHIFTER_DOG_LIKE: 'drop-shadow(0px 1px 1px rgba(235, 152, 55, 0.75))',
  SHAPESHIFTER_CAT_LIKE: 'drop-shadow(0px 1px 1px rgba(235, 152, 55, 0.75))',
  WEREWOLF: 'drop-shadow(0px 1px 1px rgba(179, 106, 64, 0.75))',
  VAMPIRE: 'drop-shadow(0px 1px 1px rgba(114, 95, 154, 0.75))',
  WITCH: 'drop-shadow(0px 1px 1px rgba(47, 188, 94, 0.75))',
}

export const getEnergyClipPath = (width: number) => {
  const value = `100% 0%,
4.326% 0%,
4.326% 0%,
3.968% 10.309%,
3.6% 20.6%,
3.222% 30.853%,
2.829% 41.05%,
2.419% 51.172%,
1.988% 61.2%,
1.534% 71.115%,
1.053% 80.9%,
0.543% 90.534%,
0% 100%,
95.182% 100%,
96.426% 78.842%,
96.426% 78.842%,
96.853% 71.409%,
97.264% 63.867%,
97.66% 56.218%,
98.041% 48.466%,
98.407% 40.616%,
98.757% 32.67%,
99.091% 24.632%,
99.41% 16.505%,
99.713% 8.293%,
100% 0%`
    .split(',\n')
    .map((point) => {
      const [x, y] = point.split(` `)
      const xValue = parseFloat(x.slice(0, -1))

      // 100% 0% || 0% 100%,
      if (xValue === 100 || xValue === 0) {
        return `${x} ${y}`
      }

      if (xValue < 50) {
        const newX = (xValue / 100) * width
        return `${newX}px ${y}`
      }

      const newX = (1 - xValue / 100) * width
      return `calc(100% - ${newX}px) ${y}`
    })
    .join(', ')

  return `polygon(${value})`
}

export const getHealthClipPath = (width: number) => {
  const value = `100% 0%,
2.226% 0%,
2.226% 0%,
2.096% 11.044%,
1.95% 21.711%,
1.789% 32.055%,
1.614% 42.132%,
1.423% 51.995%,
1.218% 61.698%,
0.999% 71.296%,
0.766% 80.843%,
0.52% 90.393%,
0.26% 100%,
97.378% 100%,
97.378% 100%,
97.759% 90.703%,
98.109% 81.5%,
98.43% 72.297%,
98.723% 63%,
98.991% 53.516%,
99.234% 43.75%,
99.455% 33.609%,
99.655% 23%,
99.836% 11.828%,
100% 0%`
    .split(',\n')
    .map((point) => {
      const [x, y] = point.split(` `)
      const xValue = parseFloat(x.slice(0, -1))

      // 100% 0% || 0% 100%,
      if (xValue === 100 || xValue === 0) {
        return `${x} ${y}`
      }

      if (xValue < 50) {
        const newX = (xValue / 100) * width
        return `${newX}px ${y}`
      }

      const newX = (1 - xValue / 100) * width
      return `calc(100% - ${newX}px) ${y}`
    })
    .join(', ')

  return `polygon(${value})`
}

export const getSafeProgress = (progress: number, min: number) =>
  progress < min && progress > 0 ? min - (1 - progress / min) : progress

export const isPossibleTarget = (
  targetUnitType: UnitType,
  target: Pick<Character, 'id' | 'teamId'>,
  caster: Pick<Character, 'id' | 'teamId'>,
) => {
  const isInSameTeam = target.teamId === caster.teamId
  let isTarget = false
  switch (targetUnitType) {
    case 'SELF':
      isTarget = target.id === caster.id
      break
    case 'ENEMY':
      isTarget = !isInSameTeam
      break
    case 'SELF_ALLY':
      isTarget = isInSameTeam || target.id === caster.id
      break
    case 'ALLY':
      isTarget = isInSameTeam
      break
    default:
      break
  }

  return isTarget
}
