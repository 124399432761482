import type { GeoJSONSource, LngLatBounds, Map as MaplibreMap } from 'maplibre-gl'
import { Map as MapLibreMap } from 'maplibre-gl'
import { OVERVIEW_DIFFERENCE, OVERVIEW_MAX_ZOOM, OVERVIEW_MIN_ZOOM } from '~constants/map'
import type { ZoneMap } from './ZoneMap'
import { MinimapCharacter } from './MinimapCharacter'
import { LayerManager } from '../../match/model/LayerManager'

const calcMinimapZoom = (parentZoom: number) =>
  Math.min(Math.max(parentZoom - OVERVIEW_DIFFERENCE, OVERVIEW_MIN_ZOOM), OVERVIEW_MAX_ZOOM)

const boundsSourceId = 'minimap-rect-source'

export class ZoneMinimap {
  public minimap: MaplibreMap
  public characters: Map<string, MinimapCharacter> = new Map()
  public layerManager: LayerManager

  constructor(public parentMap: ZoneMap, parentMapZoom: number, mapStyle: string) {
    this.minimap = new MapLibreMap({
      container: 'minimap',
      style: mapStyle,
      center: [0, 0],
      zoom: calcMinimapZoom(parentMapZoom),
      interactive: false,
      attributionControl: false,
    })

    this.layerManager = new LayerManager(this.minimap)

    this.parentMap.characters.forEach((character) => {
      this.characters.set(character.id, new MinimapCharacter(this, character))
    })
    this.parentMap.charactersChangeSubject.subscribe(({ character, action }) => {
      if (action === 'added' && !this.characters.has(character.id)) {
        this.characters.set(character.id, new MinimapCharacter(this, character))
      }
      if (action === 'removed' && this.characters.has(character.id)) {
        const minimapCharacter = this.characters.get(character.id)
        minimapCharacter?.destroy()
        this.characters.delete(character.id)
      }
    })
    this.minimap.on('load', () => {
      this.minimap.addSource(boundsSourceId, {
        type: 'geojson',
        data: {
          type: 'Feature',
          id: 'minimap-rect-source-feature',
          geometry: {
            type: 'Polygon',
            coordinates: [],
          },
        } as any,
      })

      this.minimap.addLayer({
        id: 'minimap-rect-layer',
        type: 'line',
        source: 'minimap-rect-source',
        layout: {},
        paint: {
          'line-color': '#D9D1BB',
          'line-width': 1,
        },
      })

      // update minimap border
      this.updateBounds(this.parentMap.map.getBounds())
    })
  }

  public calculateBounds(parentMapBounds: LngLatBounds) {
    const ne = [parentMapBounds._ne.lng, parentMapBounds._ne.lat]
    const se = [parentMapBounds._ne.lng, parentMapBounds._sw.lat]
    const sw = [parentMapBounds._sw.lng, parentMapBounds._sw.lat]
    const nw = [parentMapBounds._sw.lng, parentMapBounds._ne.lat]
    const bounds = [ne, se, sw, nw, ne]

    return bounds
  }

  public updateBounds(parentMapBounds: LngLatBounds) {
    const minimapRectSource = this.minimap.getSource(boundsSourceId) as GeoJSONSource | undefined
    if (!minimapRectSource) {
      return
    }

    const bounds = this.calculateBounds(parentMapBounds)

    minimapRectSource.setData({
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [bounds],
      },
      properties: {},
    })
  }
}
