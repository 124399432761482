import { Map as MapLibreMap, NavigationControl } from 'maplibre-gl'
import { Subject } from 'rxjs'
import { layersColorMap } from '~constants/layersColorMap'
import { ZOOM } from '~constants/map'
import type { TwilightLevel } from '~types'
import { styleTwilightMap } from '~constants/styleTwilightMap'
import { RouteWithinZoneDocument } from '~generated/graphql'
import type { Character, ZoneMapCharacterFragment as ZoneMapCharacter } from '~generated/graphql'
import { store } from '~store'
import { client } from '~apolloClient'
import type { Point, UUID } from '~shared-types'
import { ZoneMinimap } from './ZoneMinimap'
import { ZoneCharacter } from './ZoneCharacter'
import { Border } from '../../match/model/MatchBorder'
import { LayerManager } from '../../match/model/LayerManager'
import type { OpenMatchCharacterSubject } from './OpenMatchManager'
import { OpenMatchManager } from './OpenMatchManager'

export class ZoneMap {
  protected static instance: ZoneMap | undefined
  public map: MapLibreMap
  public minimap: ZoneMinimap
  public characters: Map<string, ZoneCharacter> = new Map()
  public charactersChangeSubject: Subject<{ character: ZoneCharacter; action: 'added' | 'removed' }>
  public layerManager: LayerManager
  public border: Border
  public openMatchManager: OpenMatchManager

  protected constructor() {
    const mapStyle = styleTwilightMap[0]
    this.map = new MapLibreMap({
      container: 'zone-map',
      style: mapStyle,
      center: [0, 0],
      zoom: ZOOM,
      dragRotate: false,
      // maxBounds
      // fitBoundsOptions?: FitBoundsOptions;
      keyboard: false,
      scrollZoom: false,
      doubleClickZoom: false,
      touchZoomRotate: false,
      touchPitch: false,
      pitchWithRotate: false,
      renderWorldCopies: false,
      validateStyle: false,
    })

    this.charactersChangeSubject = new Subject()
    this.layerManager = new LayerManager(this.map)
    this.minimap = new ZoneMinimap(this, ZOOM, mapStyle)

    this.border = new Border(this.layerManager, this.minimap.layerManager, 'zone')
    const characterStatusChangeSubject: OpenMatchCharacterSubject = new Subject()
    characterStatusChangeSubject.subscribe((newStatus) => {
      const character = this.characters.get(newStatus.characterId)
      if (!character) {
        return
      }
      if (newStatus.status === 'joinedMatch') {
        character.joinedMatch()
        return
      }
      character.leftMatch()
    })

    this.openMatchManager = new OpenMatchManager(
      this.layerManager,
      this.minimap.layerManager,
      characterStatusChangeSubject,
    )

    this.map.on('load', () => {
      const features = this.map.queryRenderedFeatures(
        this.map.project([55.74219330021862, 37.73775112003928]),
      )

      this.map.on('move', () => {
        this.minimap?.updateBounds(this.map.getBounds())
      })

      this.map.addControl(new NavigationControl(), 'top-right')

      this.map.on('contextmenu', async (e) => {
        await client.mutate({
          mutation: RouteWithinZoneDocument,
          variables: {
            destination: [e.lngLat.lng, e.lngLat.lat],
          },
        })
      })
    })
  }

  public static getInstance(): ZoneMap {
    if (!ZoneMap.instance) {
      ZoneMap.instance = new ZoneMap()
    }

    return ZoneMap.instance
  }

  public static remove() {
    if (ZoneMap.instance) {
      const zoneMap = this.getInstance()
      zoneMap.map.remove()
      // TODO: clear routes/actions
      //   Uncaught TypeError: this.style is undefined (when user moves on map and match appears)
      // getSource map.ts:2036
      // setNewSourceData LayerManager.tsx:114
      // updateProgressThrottled MapRoute.ts:76
      // MapRoute MapRoute.ts:54
      // RxJS 10
      // updateProgress MapRoute.ts:58
      // route RouteManager.ts:74
      // subscription Route.ts:45
      // RxJS 4

      delete ZoneMap.instance
    }
  }

  public clear() {
    this.characters.forEach((character) => {
      this.removeCharacter(character.id)
    })
    this.characters.clear()
  }

  public initMap(
    characters: ZoneMapCharacter[],
    currentCharacterAdditionalFields?: Partial<Character>,
  ) {
    this.clear()
    characters.forEach((ch) => {
      const isCurrentUser = ch.id === store.getState().session?.characterId
      this.addCharacter({ ...ch, ...currentCharacterAdditionalFields }, isCurrentUser)

      if (isCurrentUser) {
        this.setCenter(ch.position!)
      }
    })
    this.minimap?.minimap.setCenter(this.map.getCenter())

    return this.characters
  }

  public addCharacter(character: ZoneMapCharacter, isCurrentUser: boolean) {
    const zoneCharacter = new ZoneCharacter(this, character, isCurrentUser)
    if (this.openMatchManager.isInMatch(zoneCharacter.id)) {
      zoneCharacter.joinedMatch()
    }
    this.characters.set(character.id, zoneCharacter)
    this.charactersChangeSubject.next({ character: zoneCharacter, action: 'added' })
  }

  public removeCharacter(characterId: UUID) {
    const character = this.characters.get(characterId)
    if (character) {
      this.charactersChangeSubject.next({ character, action: 'removed' })
      character.destroy()
    }
  }

  public getCurrentCharacter() {
    return this.characters.get(store.getState().session?.characterId ?? '')
  }

  public setCenter(center: Point) {
    this.map.setCenter(center)
  }

  public setTwilight(twilight: TwilightLevel) {
    const twilightConfig = layersColorMap[twilight]

    Object.entries<object>(twilightConfig).forEach(([layer, style]) => {
      Object.entries(style).forEach(([property, value]) => {
        this.map.setPaintProperty(layer, property, value)
      })
    })
  }
}
