import styled from '@emotion/styled/macro'
import type { FC, PropsWithChildren } from 'react'
import type { Race } from '~generated/graphql'
import {
  SessionDocument,
  useSelectCharacterRaceMutation,
  useSelectRaceMasteriesQuery,
} from '~generated/graphql'
import { BigButton } from '~components/Button'
import { useStore } from '~store'
import { RaceRibbon } from './RaceRibbon'
import { raceColorMap } from '~utils/character'
import { t } from '~utils/i18n'
import { ActiveAbility, DoubleAbility } from '../../../components/Ability/Ability'
import { ZoneMap } from '../../zone/model/ZoneMap'

import { ReactComponent as IconClose } from '~assets/icon-close.svg'
import { ReactComponent as IconArrow } from '~assets/icon-arrow.svg'

type Props = {
  race: Race
  onNextArrow: () => void
  onPrevArrow: () => void
  onClose: () => void
}

export const RaceDetails: FC<PropsWithChildren<Props>> = ({
  race,
  onPrevArrow,
  onNextArrow,
  onClose,
}) => {
  const [session, setFullscreenId] = useStore((state) => [state.session, state.setFullscreenId])

  const [sendSelectRace] = useSelectCharacterRaceMutation({
    refetchQueries: [SessionDocument],
    onCompleted: (data) => {
      const character = ZoneMap.getInstance().getCurrentCharacter()
      if (character) {
        character.race = data.selectCharacterRace.race
      }
      setFullscreenId(null)
    },
    onError: (error) => {
      console.error('Can not select side', error)
    },
  })

  const { data } = useSelectRaceMasteriesQuery({ variables: { race } })

  if (!session?.character) {
    return null
  }

  const { side, gender } = session.character

  const raceMasteries = data?.masteries
    .filter((mastery) => mastery.race === race)
    .map((mastery) => ({
      mastery,
      translations: t('masteries:masteries')?.find(
        (translation) => translation.mastery === mastery.type,
      ),
    }))

  const raceTranslation = t('common:racesList')?.find((raceObj) => raceObj.race === race)

  return (
    <>
      <DetailContainer>
        <LeftArrow onClick={onPrevArrow} />
        <RightArrow onClick={onNextArrow} />
        <StyledIconClose onClick={onClose} />
        <RaceRibbon side={side} race={race} active>
          <Name>{raceTranslation?.[gender === 'FEMALE' ? 'nameFemale' : 'nameMale']}</Name>
          <Description>{raceTranslation?.keyPoints}</Description>
        </RaceRibbon>
        <TableContainer>
          <Table color={raceColorMap[race].dark}>
            {raceMasteries?.map(({ mastery, translations }) => (
              <Mastery key={mastery.id}>
                <MasteryTitle color={raceColorMap[race].dark}>{translations?.name}</MasteryTitle>
                <MasteryContent>
                  <MasteryProps>
                    <PropItem>
                      <PropName>{t('chooseRace:role')}</PropName>
                      <PropValue>{translations?.role}</PropValue>
                    </PropItem>
                    <PropItem>
                      <PropName>{t('chooseRace:damage')}</PropName>
                      <PropValue>{translations?.damage}</PropValue>
                    </PropItem>
                    <PropItem>
                      <PropName>{t('chooseRace:range')}</PropName>
                      <PropValue>{translations?.range}</PropValue>
                    </PropItem>
                  </MasteryProps>
                  <MasteryDescription>{translations?.description}</MasteryDescription>
                  <AbilitiesContainer>
                    {mastery.tiers.flatMap((tier) =>
                      tier.abilitySlots.map((slot) =>
                        slot.optionalAbilityId ? (
                          <DoubleAbility
                            ability1={{ ...slot.ability! }}
                            ability2={{ ...slot.optionalAbility! }}
                            key={slot.abilityId}
                          />
                        ) : (
                          <ActiveAbility
                            icon={slot.ability?.icon ?? ''}
                            name={slot.ability?.name}
                            description={slot.ability?.description}
                            key={slot.abilityId}
                          />
                        ),
                      ),
                    )}
                  </AbilitiesContainer>
                </MasteryContent>
              </Mastery>
            ))}
          </Table>
        </TableContainer>
      </DetailContainer>
      <ButtonContainer>
        <BigButton onClick={() => sendSelectRace({ variables: { race } })}>
          {t('button:choose')}
        </BigButton>
      </ButtonContainer>
    </>
  )
}

const Name = styled.div`
  font-size: 17px;
  letter-spacing: -0.3px;
  margin-bottom: 12px;
  color: #fff;
  font-weight: 600;
`

const Description = styled.div`
  text-align: center;
  font-size: 14px;
  color: #646d85;
  padding: 0px 10px;
`

const DetailContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-grow: 1;
`

const Mastery = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
`

const Table = styled.div<{ color: string }>`
  display: flex;
  width: 1000px;
  position: relative;
  left: -17px;
  border: 10px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: ${({ color }) => `linear-gradient(to right, transparent, ${color}59)`};

  > ${Mastery}:first-of-type {
    border-right: 2px solid ${({ color }) => color}26;
  }
`

const MasteryTitle = styled.div<{ color: string }>`
  font-size: 20px;
  font-weight: 600;
  color: #ada08a;
  width: 210px;
  height: 48px;
  background: ${({ color }) => `linear-gradient(to right, transparent, ${color}59)`};
  display: flex;
  align-items: center;
  padding-left: 20px;
  justify-content: center;
  clip-path: polygon(0 0, 100% 0px, calc(100% - 8px) 100%, 0px 100%);
`

const MasteryDescription = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #646d85;
  margin-bottom: 30px;
  min-height: 96px;
  flex-grow: 1;
`

const MasteryContent = styled.div`
  padding: 30px 44px;
  display: flex;
  flex-direction: column;
  flex: 1;
`

const MasteryProps = styled.div`
  display: flex;
  margin-bottom: 30px;
`

const PropItem = styled.div`
  color: #646d85;
  flex: 1;
  font-size: 16px;
  padding: 12px 0;

  & + & {
    border-left: 1px solid #2a3041;
    padding-left: 24px;
  }
`

const PropName = styled.div`
  margin-bottom: 4px;
`

const PropValue = styled.div`
  color: #fff;
  font-weight: 600;
`

const AbilitiesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`

const StyledIconClose = styled(IconClose)`
  position: absolute;
  top: 72px;
  right: 72px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 1;
`

const LeftArrow = styled(IconArrow)`
  position: absolute;
  user-select: none;
  top: 50%;
  left: 72px;
  transform: translateY(-50%) rotate(180deg);
  cursor: pointer;
  z-index: 1;
  transition: left 0.3s;

  &:hover {
    left: 65px;
  }
`

const RightArrow = styled(IconArrow)`
  position: absolute;
  user-select: none;
  top: 50%;
  right: 72px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 1;
  transition: right 0.3s;

  &:hover {
    right: 65px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: -52px;
`

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
`
