import { useLazyQuery } from '@apollo/client'
import { SessionDocument } from '~generated/graphql'
import { useNavigate } from 'react-router-dom'
import { useMatchStore } from '../store/matchStore'
import { useStore } from '../store/store'

export const useLazySession = () => {
  const [setSession] = useStore((state) => [state.setSession])
  const [setWsClient] = useMatchStore((state) => [state.setWsClient])
  const navigate = useNavigate()
  return useLazyQuery(SessionDocument, {
    fetchPolicy: 'network-only',
    onError: () => {
      navigate('/')
    },
    onCompleted: async (newData) => {
      if (!newData.session) {
        navigate('/')
        return
      }

      setSession(newData.session)

      if (newData.session.matchAccess) {
        navigate('/match')
        setWsClient(newData.session.matchAccess)
      }
    },
  })
}
