import { useMemo } from 'react'
import type { FC } from 'react'
import type { Observable } from 'rxjs'
import { distinctUntilChanged } from 'rxjs'
import type { Side } from '~shared-types'
import { useValueFromObservable } from '../../quest/useValueFromObservable'
import type { MainStats } from '../../CharacterPanel/CharacterPanelMainStats'
import { EnergyBarSvg } from './EnergyBarSvg'

type Props = {
  id: string
  side: Side
  statsObservable: Observable<MainStats>
}

export const EnergyBar: FC<Props> = ({ id, side, statsObservable }) => {
  const energyObservable = useMemo(
    () =>
      statsObservable.pipe(
        distinctUntilChanged(
          (prev, cur) => !(prev.energy !== cur.energy || prev.energyMax !== cur.energyMax),
        ),
      ),
    [statsObservable],
  )
  const { energy, energyMax } = useValueFromObservable(energyObservable)

  const progress = energy / energyMax

  return <EnergyBarSvg side={side} progress={progress} id={id} />
}
