import { DateTime, Duration } from 'luxon'
import type { Point } from '~shared-types'
import type { MatchCharacterAbilityFragment } from '~generated/match-graphql'
import {
  useAbilityEffectSubscription,
  useCancelledActionSubscription,
  useCharacterDiedSubscription,
  useEnRouteActionSubscription,
  useMatchEndedSubscription,
  usePeriodicStatusEffectsGroupTickSubscription,
  useAbilityCastActionSubscription,
  useAbilityChannelActionSubscription,
  useCharacterJoinedMatchSubscription,
  useAbilityCooldownSubscription,
  useAbilityCastedSubscription,
} from '~generated/match-graphql'
import { useMatchStore } from '../../store/matchStore'
import { useLazySession } from '../../hooks/useLazySession'
import { MatchMap } from './model/MatchMap'
import { MapCharacterActionsManager } from './model/MapCharacterActionsManager'
import { CastedAbilityAnimation } from './model/animation/CastedAbilityAnimation'
import type { CharacterEntity } from './model/CharacterEntity'
import { AbilityManager } from './model/AbilityManager'
import { useStore } from '~store'
import { useKeepOnlineMutation } from '../../generated/graphql'
import { EffectAnimation } from './model/animation/EffectAnimation'
import { TickDamageAnimation } from './model/animation/TickDamageAnimation'

export const MatchSubscriptions = () => {
  const [wsClient, clearMatchStore, setAbilityCooldown, addCharacter, updateCharacter] =
    useMatchStore((state) => [
      state.wsClient,
      state.clearMatchStore,
      state.setAbilityCooldown,
      state.addCharacter,
      state.updateCharacter,
    ])
  const [setOpenMatchFinishedDialog, hideOpenMatchFinishedDialog] = useStore((state) => [
    state.setOpenMatchFinishedDialog,
    state.hideOpenMatchFinishedDialog,
  ])
  const [fetchSession] = useLazySession()
  const [setOnline] = useKeepOnlineMutation()

  useEnRouteActionSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: (data) => {
      const result = data.data.data?.enRouteAction
      if (result?.character.id && result?.route) {
        const actionsManager = MapCharacterActionsManager.getInstance()
        actionsManager.addAction(result)
      }
    },
  })

  useAbilityCastActionSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: (data) => {
      const result = data.data.data?.abilityCastAction
      if (result?.character.id && result?.ability) {
        const actionsManager = MapCharacterActionsManager.getInstance()
        actionsManager.addAction(result)

        // apply effects on caster
        const { characters } = MatchMap.getInstance()
        const casterCharacter = characters.get(result.character?.id)
        if (result.character && casterCharacter) {
          casterCharacter.stats = { ...casterCharacter.stats, ...result.character.stats }
        }
      }
    },
  })

  useCharacterJoinedMatchSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: (data) => {
      const character = data.data.data?.characterJoinedMatch

      if (character) {
        const map = MatchMap.getInstance()
        const actionManager = MapCharacterActionsManager.getInstance()
        const abilityManager = AbilityManager.getInstance()
        const actionsManager = MapCharacterActionsManager.getInstance()

        const characterAbilities = character.abilities
          .map((ability) => abilityManager.get(ability.id))
          .filter((ability): ability is MatchCharacterAbilityFragment => Boolean(ability))
        map.addCharacter(character, characterAbilities, actionManager, false)
        addCharacter(character)

        try {
          character.actions.forEach((action) => {
            if (action.__typename === 'EnRouteAction' && action.route) {
              actionsManager.addAction(action)
            }
            if (action.__typename === 'AbilityCastAction') {
              actionsManager.addAction(action)
            }
            if (action.__typename === 'AbilityChannelAction') {
              actionsManager.addAction(action)
            }
          })
        } catch (error) {
          console.log('Failed to set existing actions on init')
        }
      }
    },
  })

  useAbilityChannelActionSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: (data) => {
      const result = data.data.data?.abilityChannelAction
      if (result?.character.id && result?.ability) {
        const actionsManager = MapCharacterActionsManager.getInstance()
        actionsManager.addAction(result)
      }
    },
  })

  useCancelledActionSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: (data) => {
      const cancelledAction = data.data.data?.cancelledAction
      if (cancelledAction?.characterId) {
        const actionsManager = MapCharacterActionsManager.getInstance()
        actionsManager.cancelAction(cancelledAction.id, cancelledAction.characterId)
      }
    },
  })

  useAbilityEffectSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: (data) => {
      const { characters, map } = MatchMap.getInstance()
      const abilityEffect = data.data.data?.abilityEffect

      if (!abilityEffect) return

      abilityEffect.instantEffects.forEach((effect) => {
        const affectedCharacter = characters.get(effect.character.id)

        if (affectedCharacter) {
          affectedCharacter.applyInstantEffect(effect)
        }
      })
      abilityEffect.periodicStatusEffectsGroups.forEach((effect) => {
        const affectedCharacter = characters.get(effect.character.id)

        if (affectedCharacter) {
          affectedCharacter.addPeriodicEffect(effect)
        }
      })
      abilityEffect.lastingStatusEffectsGroups.forEach((effect) => {
        const affectedCharacter = characters.get(effect.character.id)

        if (affectedCharacter) {
          affectedCharacter.applyLastingEffect(effect)
        }
      })

      abilityEffect.cancelledStatusEffectsGroupIds.forEach((cancelledEffectId) => {
        characters.forEach((character) =>
          character.statusEffectsGroups.removeById(cancelledEffectId),
        )
      })

      const caster = characters.get(abilityEffect.caster.id)

      const affectedCharacters = (
        abilityEffect.instantEffects ||
        abilityEffect.periodicStatusEffectsGroups ||
        abilityEffect.lastingStatusEffectsGroups
      ).map((effect) => characters.get(effect.character.id))

      affectedCharacters.forEach((character) => {
        if (character && caster) {
          const effectAnimation = new EffectAnimation(map, caster, character, abilityEffect.ability)
        }
      })
    },
  })

  useAbilityCastedSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: (data) => {
      const abilityCasted = data.data.data?.abilityCasted
      if (!abilityCasted) {
        return
      }
      // cancel status effects
      const { characters, map } = MatchMap.getInstance()
      abilityCasted.cancelledStatusEffectsGroupIds.forEach((cancelledEffectId) => {
        characters.forEach((character) =>
          character.statusEffectsGroups.removeById(cancelledEffectId),
        )
      })

      // show animation
      const abilityCastAction = abilityCasted
        ? MapCharacterActionsManager.getInstance().getActionById(abilityCasted.abilityCastActionId)
        : undefined

      if (!abilityCastAction) return

      const caster = characters.get(abilityCastAction.character.id)

      const ability =
        abilityCastAction?.__typename === 'AbilityCastAction'
          ? caster?.abilities.find(({ id }) => id === abilityCastAction?.ability.id)
          : undefined

      if (ability && caster) {
        caster.abilityCastedSubject.next(ability)
      }

      if (caster && abilityCasted) {
        let target: CharacterEntity | Point | undefined
        if (abilityCasted.target.__typename === 'Character') {
          target = characters.get(abilityCasted.target.id)
        }
        if (abilityCasted.target.__typename === 'Coordinate') {
          // if (ability?.__typename === 'AbilityDirectionTargeted') {
          //   const [maxDistance] = [
          //     ...result.instantEffects,
          //     ...result.periodicStatusEffectsGroups,
          //     ...result.lastingStatusEffectsGroups,
          //   ]
          //     .map((effect) => spatialDistance(caster.position, effect.character.position))
          //     .sort((distanceA, distanceB) => distanceB - distanceA)
          //   const destination = [abilityCasted.target.lng, abilityCasted.target.lat] satisfies Point

          //   target = maxDistance
          //     ? getPointInDirection(caster.position, [caster.position, destination], maxDistance)
          //     : destination
          // } else {

          // }
          target = [abilityCasted.target.lng, abilityCasted.target.lat] satisfies Point
        }
        if (target && ability) {
          const abilityAnimation = new CastedAbilityAnimation(map, caster, target, ability)
        }
      }
    },
  })

  useCharacterDiedSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: (data) => {
      const result = data.data.data?.characterDied
      if (result?.character.id) {
        const { characters } = MatchMap.getInstance()
        const character = characters.get(result.character.id)
        updateCharacter(result.character.id, { alive: false })

        if (character) {
          character.alive = false
        }
      }
    },
  })

  useMatchEndedSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: async ({ data }) => {
      const result = data.data?.matchEnded
      if (result?.id) {
        const endMatch = async () => {
          await setOnline()
          await fetchSession()
          MatchMap.remove()
          MapCharacterActionsManager.remove()
          clearMatchStore()
          hideOpenMatchFinishedDialog()
        }

        const currentCharacter = MatchMap.getInstance().getCurrentCharacter()

        setOpenMatchFinishedDialog({
          result: currentCharacter?.teamId === result.winnerTeamId ? 'win' : 'defeat',
          side: currentCharacter?.side || 'NEUTRAL',
          endMatch,
        })
      }
    },
  })

  usePeriodicStatusEffectsGroupTickSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: (data) => {
      const { characters, map } = MatchMap.getInstance()
      const result = data.data.data?.periodicStatusEffectsGroupTick

      if (!result) return

      const { character, causedByAbilityEffect, changes } = result

      const affectedCharacter = characters.get(character?.id)
      const caster = characters.get(causedByAbilityEffect.caster.id)

      if (affectedCharacter) {
        affectedCharacter.periodicStatusEffectsGroupTick(result)
      }

      if (affectedCharacter && caster && caster.id !== affectedCharacter.id && changes.health) {
        const tickDamageAnimation = new TickDamageAnimation(map, caster, affectedCharacter)
      }
    },
  })

  useAbilityCooldownSubscription({
    skip: !wsClient,
    client: wsClient,
    onData: (data) => {
      const result = data.data.data?.abilityCooldown
      if (result) {
        setAbilityCooldown({
          abilityId: result.abilityId,
          duration: Duration.fromISO(result.duration).toMillis(),
          startAt: DateTime.fromISO(result.startAt).toMillis(),
        })
      }
    },
  })

  return null
}
