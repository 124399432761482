import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Observable } from 'rxjs'
import { CastProgressUI } from '../../CastProgress'
import type { CastAction } from '../../../views/match/model/SpellCast'
import { useValueFromObservable } from '../../quest/useValueFromObservable'

type Props = {
  spellCastObservable: Observable<CastAction | null>
}

export const CastProgress: FC<Props> = ({ spellCastObservable }) => {
  const spellCast = useValueFromObservable(spellCastObservable)
  if (!spellCast) {
    return null
  }
  return <StyledCastProgress castAction={spellCast} compact />
}

const StyledCastProgress = styled(CastProgressUI)`
  position: absolute;
  bottom: -10px;
  z-index: 2;
  width: 70px;
`
