import { useEffect } from 'react'
import { useStore } from '~store'
import { useCharactersQuery } from '~generated/graphql'
import { ZoneMap } from '../model/ZoneMap'
import { useOpenMatches } from './useOpenMatches'

export const useZoneInit = () => {
  const [session, setCurrentCharacter, setCharacters] = useStore((state) => [
    state.session,
    state.setCurrentCharacter,
    state.setCharacters,
  ])

  useEffect(
    () => () => {
      ZoneMap.remove()
    },
    [],
  )

  useOpenMatches()

  useCharactersQuery({
    skip: !session?.characterId || session?.character?.zoneId === undefined,
    fetchPolicy: 'cache-and-network',
    variables: {
      currentCharacterId: session?.character?.id as string,
      zoneId: session?.character?.zoneId as number,
    },
    onError: (error) => {
      console.error(error)
    },
    onCompleted: async (data) => {
      try {
        const map = ZoneMap.getInstance()
        if (data.zone?.boundaries) {
          map.border.update({ path: data.zone.boundaries })
        }
        const characters = map.initMap(data.characters, {
          levelExperienceMax: data.character?.levelExperienceMax || undefined,
        })

        setCharacters(characters)

        // set current character
        const currentCharacter = characters.get(session?.characterId as string)
        if (currentCharacter?.id) {
          setCurrentCharacter(currentCharacter)
        }
      } catch (e) {
        console.log('useCharactersQuery', e)
      }
    },
  })
}
