import { DateTime, Duration } from 'luxon'
import { styleTwilightMap } from '~constants/styleTwilightMap'
import type { MatchCharacterAbilityFragment } from '~generated/match-graphql'
import { useMatchQuery } from '~generated/match-graphql'
import { useStore, useMatchStore } from '~store'
import { calculateRangeInPx } from '~utils/map'
import { MatchMap } from '../model/MatchMap'
import { MapCharacterActionsManager } from '../model/MapCharacterActionsManager'
import { AbilityManager } from '../model/AbilityManager'
import type { CharacterEntity } from '../model/CharacterEntity'

export const useMatchInit = () => {
  const [session, setOpenMatchWaitDialog] = useStore((state) => [
    state.session,
    state.setOpenMatchWaitDialog,
  ])

  const [
    wsClient,
    setCurrentCharacter,
    setAbilityCooldown,
    setMeterToPixel,
    setCharacters,
    setStartAt,
    setCastAction,
  ] = useMatchStore((state) => [
    state.wsClient,
    state.setCurrentCharacter,
    state.setAbilityCooldown,
    state.setMeterToPixel,
    state.setCharacters,
    state.setStartAt,
    state.setCastAction,
  ])

  return useMatchQuery({
    skip: !wsClient || !session?.characterId,
    client: wsClient,
    onError: (error) => {
      console.error(error)
    },
    onCompleted: (data) => {
      try {
        const { characters, border } = data.match
        const matchMap = MatchMap.getInstance()
        const actionManager = MapCharacterActionsManager.getInstance()
        const abilityManager = AbilityManager.getInstance()
        const actionsManager = MapCharacterActionsManager.getInstance()
        abilityManager.setAbilities(data.abilities)
        matchMap.border.update({ path: border })
        setCharacters(characters)
        setStartAt(DateTime.fromISO(data.match.startAt).toMillis())

        // NOTE: if match is less than 30 sec, show waiting dialog
        const isMatchLessThat30Sec =
          Math.abs(DateTime.fromISO(data.match.startAt).diffNow().as('seconds')) < 30
        if (isMatchLessThat30Sec) {
          setOpenMatchWaitDialog({ matchStartAt: data.match.startAt })
        }

        let currentCharacter: CharacterEntity | undefined
        characters.forEach(async (character) => {
          const characterAbilities = character.abilities
            .map((ability) => abilityManager.get(ability.id))
            .filter((ability): ability is MatchCharacterAbilityFragment => Boolean(ability))
          const isCurrentUser = character.id === session?.characterId
          const mapCharacter = matchMap.addCharacter(
            character,
            characterAbilities,
            actionManager,
            isCurrentUser,
          )

          if (isCurrentUser) {
            currentCharacter = mapCharacter
          }

          if (isCurrentUser && setAbilityCooldown) {
            character.abilityCooldowns.forEach(({ abilityId, duration, startAt }) =>
              setAbilityCooldown({
                abilityId,
                duration: Duration.fromISO(duration).toMillis(),
                startAt: DateTime.fromISO(startAt).toMillis(),
              }),
            )
          }

          try {
            character.actions.forEach((action) => {
              if (action.__typename === 'EnRouteAction' && action.route) {
                actionsManager.addAction(action)
              }
              if (action.__typename === 'AbilityCastAction') {
                actionsManager.addAction(action)
              }
              if (action.__typename === 'AbilityChannelAction') {
                actionsManager.addAction(action)
              }
            })
          } catch (error) {
            console.log('Failed to set existing actions on init')
          }
        })

        if (currentCharacter) {
          setMeterToPixel(calculateRangeInPx(matchMap.map, currentCharacter.position, 1))
          setCurrentCharacter(currentCharacter)
          matchMap.setCenter(currentCharacter?.position)
          matchMap.map.setStyle(styleTwilightMap[currentCharacter.twilightLevel || 0])
          matchMap.minimap?.minimap.setStyle(styleTwilightMap[currentCharacter.twilightLevel || 0])
          currentCharacter.spellCastSubject.subscribe((spellCast) => {
            // const { castAction: currentCastAction } = matchStore.getState()
            // NOTE: this prevents canceling channeling action when cast action finished after channeling starts
            // if (spellCast.id === currentCastAction?.id) {
            // setCastAction( undefined)
            // }
            setCastAction(spellCast ?? undefined)
          })
        }
      } catch (e) {
        console.error(e)
      }
    },
  })
}
