import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'
import type { ChatMessageFragment as Message } from '~generated/graphql'
import { sortByDate } from '~utils/messages'

type State = {
  messages: Message[]
}

type Actions = {
  addGeneralMessage: (message: Message) => void
  addGeneralMessages: (messages: Message[]) => void
  setGeneralMessages: (messages: Message[]) => void
}

type Store = State & Actions

// TODO: optimise performance https://github.com/pmndrs/zustand#transient-updates-for-often-occurring-state-changes
export const chatStore = create(
  subscribeWithSelector<Store>((set, get) => ({
    messages: [],
    addGeneralMessage: (message: Message) =>
      set({
        messages: [...get().messages, message].sort(sortByDate),
      }),
    addGeneralMessages: (messages: Message[]) =>
      set({
        messages: [...get().messages, ...messages].sort(sortByDate),
      }),
    setGeneralMessages: (messages: Message[]) =>
      set({
        messages: messages.sort(sortByDate),
      }),
  })),
)

export const useChatStore = <U>(selector: (state: Store) => U) => chatStore(useShallow(selector))
