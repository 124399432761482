import { useMatchStore } from '~store'
import { CharacterPanel } from '~components/CharacterPanel/CharacterPanel'

export const MatchCharacterPanel = () => {
  const [character] = useMatchStore((state) => [state.currentCharacter])

  if (!character) {
    return
  }

  return <CharacterPanel character={character} />
}
