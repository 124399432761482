import type { Map } from 'maplibre-gl'
import { throttleTime, timer } from 'rxjs'
import type { CharacterEntity } from '../CharacterEntity'
import { AbilityAnimationUI } from '../../components/animation/AbilityAnimationUI'
import { AnimationMarker } from './AnimationMarker'
import type { AbilityAnimationType } from '~utils/animation'
import { animationDurationMap } from '~utils/animation'

export class SingleUnitAnimation {
  constructor(
    map: Map,
    caster: CharacterEntity,
    target: CharacterEntity,
    animationType: AbilityAnimationType,
  ) {
    const component = (
      <AbilityAnimationUI
        animationType={animationType}
        side={caster.side}
        race={caster.race}
        id={`${caster.id}${target.id}${Date.now()}${animationType}`}
      />
    )
    const marker = new AnimationMarker(map, component, target.position)

    const positionSubscription = target.positionObservable
      .pipe(throttleTime(50))
      .subscribe((position) => {
        marker.setLngLat(position)
      })

    const subscription = timer(animationDurationMap[animationType] * 1000).subscribe(() => {
      marker.remove()
      positionSubscription.unsubscribe()
      subscription.unsubscribe()
    })
  }
}
