import type { ChatMessageFragment as Message } from '~generated/graphql'

export const sortByDate = (a: Message, b: Message) => {
  const aDate = new Date(a.createdAt)
  const bDate = new Date(b.createdAt)
  return aDate.getTime() - bDate.getTime()
}

export type InputMessage = {
  message: string
} & ({ characterId: string } | { zoneId: string } | { matchId: string } | { teamId: string })

export const parseGeneralMessage = (
  str: string,
  characters: { firstName: string; lastName: string; id: string }[],
) => {
  const characterNames = characters.map(({ firstName, lastName, id }) => ({
    name: `${firstName} ${lastName}`,
    nameWithoutWhitespace: `${firstName}${lastName}`,
    id,
  }))
  const regexUsername = /^@(\w+\s\w+)(.*)/
  const regexUsernameWithoutWhitespace = /^@(\w+)(.*)/
  const match = str.match(regexUsername)

  let characterNameIndex = characterNames.findIndex(({ name }) => name === match?.[1])
  let message = str

  if (characterNameIndex === -1) {
    const matchWithoutWhitespace = str.match(regexUsernameWithoutWhitespace)
    characterNameIndex = characterNames.findIndex(
      ({ nameWithoutWhitespace }) => nameWithoutWhitespace === matchWithoutWhitespace?.[1],
    )
    if (characterNameIndex === -1) {
      if (str.startsWith('@')) {
        return undefined
      }
      return { message: str }
    }
    message = matchWithoutWhitespace?.[2].slice(1) || ''
  } else {
    message = match?.[2].slice(1) || ''
  }

  return { message, recipientId: characterNames[characterNameIndex].id }
}
