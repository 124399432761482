import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Observable } from 'rxjs'
import { CharacterExperienceBar } from '~components/CharacterPanel/CharacterExperienceBar'
import { CharacterPanelMainStats } from '~components/CharacterPanel/CharacterPanelMainStats'
import { EffectItem } from '~components/CharacterPanel/EffectItem'
// import { RankShield } from '~components/RankShield'
import type { CharacterInfoActiveStatusEffectFragment, Race, Side } from '~generated/graphql'
import type { StatsFragment } from '~generated/match-graphql'
import { AbilityManager } from '../../match/model/AbilityManager'

type Props = {
  firstName: string
  lastName: string
  level: number
  experience: number
  levelExperienceMax?: number | null
  statsObservable?: Observable<Pick<StatsFragment, 'energy' | 'health' | 'energyMax' | 'healthMax'>>
  race: Race
  side: Side
  activeStatusEffects: CharacterInfoActiveStatusEffectFragment[]
}

export const ProfileCharacterPanel: FC<Props> = ({
  firstName,
  lastName,
  level,
  experience,
  levelExperienceMax,
  race,
  side,
  statsObservable,
  activeStatusEffects,
}) => (
  <Container>
    <NameContainer>
      <Name>
        {firstName} {lastName}
      </Name>
      {/* <RankShield>{6}</RankShield> */}
    </NameContainer>
    <CharacterExperienceBar
      level={level}
      experience={experience}
      levelExperienceMax={levelExperienceMax}
    />
    {statsObservable ? (
      <CharacterPanelMainStats statsObservable={statsObservable} race={race} side={side} />
    ) : null}
    <EffectsContainer>
      {activeStatusEffects
        ?.map((effect) => ({
          ...effect,
          ability: effect.statusEffect?.ability?.id
            ? AbilityManager.getInstance().get(effect.statusEffect?.ability?.id)
            : undefined,
        }))
        .filter((effect) => effect.ability)
        .map((effect) => (
          <EffectItem
            key={effect.effectId}
            effectGroup={{ ...effect, id: String(effect.effectId), statusEffects: [] }}
            ability={effect.ability!}
          />
        ))}
    </EffectsContainer>
  </Container>
)

const Container = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 30%;
  right: calc(50% + 150px);
`

const EffectsContainer = styled.div`
  display: flex;
  margin-top: 10px;
`

const Name = styled.div`
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  margin-right: 8px;
`

const NameContainer = styled.div`
  display: flex;
  margin-bottom: 20px;
  margin-left: 10px;
`
