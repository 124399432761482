import { Marker } from 'maplibre-gl'
import { createRoot } from 'react-dom/client'
import { startWith } from 'rxjs'
import type { MarkerCharacter } from '~components/map/MapCharacterMarker/MapCharacterMarker'
import { MapCharacterMarker } from '~components/map/MapCharacterMarker/MapCharacterMarker'
import { matchStore } from '~store'
import type { CharacterEntity } from './CharacterEntity'
import type { LayerManager } from './LayerManager'
import { MarkerAbilityRange } from '../../../components/map/MapCharacterMarker/MarkerAbilityRange'
import { actionPlanner } from './ActionPlanner'
import type { MatchMap } from './MatchMap'
import { isPossibleTarget } from '~utils/character'

const getMarkerCharacterFromClass = (character: CharacterEntity): MarkerCharacter => ({
  id: character.id,
  firstName: character.firstName,
  lastName: character.lastName,
  race: character.race,
  side: character.side,
  stats: character.stats,
  position: character.position,
  alive: character.alive,
  twilightLevel: character.twilightLevel,
  avatar: character.avatar,
  level: character.level,
  experience: 0,
})

export class MatchCharacterFrame {
  #marker: Marker

  constructor(
    private mapClass: MatchMap,
    public mapCharacter: CharacterEntity,
    public layerManager: LayerManager,
  ) {
    const character: MarkerCharacter = getMarkerCharacterFromClass(mapCharacter)
    const div = document.createElement('div')
    const root = createRoot(div)
    const showRange = mapCharacter.isCurrentUser
    const component = (
      <div style={{ position: 'relative' }}>
        <MapCharacterMarker
          character={character}
          isCurrentCharacter={mapCharacter.isCurrentUser ?? false}
          statsObservable={mapCharacter.statsSubject}
          healthChangeObservable={mapCharacter.effectChanges}
          spellCastObservable={mapCharacter.spellCastSubject}
          twilightRelationObservable={mapCharacter.twilightRelationSubject}
          aliveObservable={mapCharacter.aliveSubject.pipe(startWith(mapCharacter.alive))}
          castedAbilityObservable={mapCharacter.abilityCastedSubject}
          visibleObservable={mapCharacter.visibleSubject}
          crowdControlsObservable={mapCharacter.statusEffectsGroups.crowdControlsSubject}
          areActionsVisibleObservable={mapCharacter.areActionsVisibleSubject}
        />

        {showRange ? (
          <MarkerAbilityRange useAbilityRangeStore={matchStore} race={character.race} />
        ) : null}
      </div>
    )
    root.render(component)
    this.#marker = new Marker({ element: div, className: 'maplibregl-marker-character' })
    this.#marker.setLngLat(character.position)
    this.layerManager.addMarker(this.#marker)
    // this.mapCharacter.crowdControlsSubject.subscribe((newCrowdControls) => {
    //   // this.ref.current?.setCrowdControls(
    //   //   newCrowdControls.map((crowdControl) => ({
    //   //     type: crowdControl.crownControlType,
    //   //     duration: crowdControl.duration,
    //   //     startAt: crowdControl.startAt,
    //   //     controlAbilityImageUrl: crowdControl.ability.icon,
    //   //   })),
    //   // )
    // })

    this.#marker.getElement().addEventListener('mouseenter', () => {
      const { selectedAbility, currentCharacter } = matchStore.getState()

      if (
        currentCharacter &&
        selectedAbility &&
        (selectedAbility.targetType === 'LOCATION' || selectedAbility.targetType === 'DIRECTION') &&
        selectedAbility.targetUnitType
      ) {
        const canBeTarget = isPossibleTarget(
          selectedAbility.targetUnitType,
          this.mapCharacter,
          currentCharacter,
        )

        if (canBeTarget) {
          const { selectedAbilityApplicationArea } = this.mapClass
          selectedAbilityApplicationArea.show(selectedAbility)
          selectedAbilityApplicationArea.updatePosition(this.#marker.getLngLat())
        }
      }
    })

    this.#marker.getElement().addEventListener('click', async (e) => {
      const { selectedAbility, currentCharacter, setTargetCharacter } = matchStore.getState()
      if (selectedAbility) {
        e.stopPropagation()
        if (!currentCharacter) {
          return
        }

        await actionPlanner.castAbilityUnitTargeted(
          this.mapCharacter,
          selectedAbility,
          currentCharacter,
        )
      }
      // click on map
      else if (currentCharacter?.id !== character.id) {
        e.stopPropagation()
        setTargetCharacter(this.mapCharacter)
      }
    })

    mapCharacter.positionObservable.subscribe((position) => this.#marker.setLngLat(position))
  }

  get marker() {
    return this.#marker
  }
}
