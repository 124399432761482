export enum CharacterTwilightAvailability {
  REACHABLE,
  VISIBLE,
  NOT_VISIBLE,
}

export const getCharacterTwilightAvailability = (
  characterTwilightLevel: number,
  anotherCharacterTwilightLevel: number,
): CharacterTwilightAvailability => {
  if (
    characterTwilightLevel === anotherCharacterTwilightLevel ||
    characterTwilightLevel === anotherCharacterTwilightLevel + 1
  ) {
    return CharacterTwilightAvailability.REACHABLE
  }
  if (characterTwilightLevel > anotherCharacterTwilightLevel) {
    return CharacterTwilightAvailability.VISIBLE
  }
  return CharacterTwilightAvailability.NOT_VISIBLE
}

export enum CharacterTwilightRelation {
  ONE_BELOW_REACHABLE,
  REACHABLE,
  VISIBLE,
  NOT_VISIBLE,
}

export const getCharacterTwilightRelation = (
  characterTwilightLevel: number,
  anotherCharacterTwilightLevel: number,
): CharacterTwilightRelation => {
  if (characterTwilightLevel === anotherCharacterTwilightLevel) {
    return CharacterTwilightRelation.REACHABLE
  }
  if (characterTwilightLevel === anotherCharacterTwilightLevel + 1) {
    return CharacterTwilightRelation.ONE_BELOW_REACHABLE
  }
  if (characterTwilightLevel > anotherCharacterTwilightLevel) {
    return CharacterTwilightRelation.VISIBLE
  }
  return CharacterTwilightRelation.NOT_VISIBLE
}
