import styled from '@emotion/styled'
import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import type { PropsWithChildren, UIEvent } from 'react'

type Props = {
  className?: string
  showShadow?: boolean
  fetchMore?: () => Promise<void>
}

type ShadowPosition = 'top' | 'bottom' | 'both'

export type InfiniteListRef = {
  scrollToBottom: (skipIfScrolled?: boolean) => void
}

export const InfiniteList = forwardRef<InfiniteListRef, PropsWithChildren<Props>>(
  ({ children, className, showShadow, fetchMore }, ref) => {
    const [shadowPosition, setShadowPosition] = useState<ShadowPosition | null>(null)
    const scroll = useRef<HTMLDivElement>(null)
    const scrollHeight = useRef(0)
    const lastElem = useRef<HTMLDivElement>(null)

    const handleScroll = async (event: UIEvent<HTMLDivElement>) => {
      event.stopPropagation()
      const { scrollTop, clientHeight } = event.currentTarget
      const isTop = scrollTop === 0
      scrollHeight.current = event.currentTarget.scrollHeight

      if (scrollHeight.current <= clientHeight || !showShadow) {
        setShadowPosition(null)
      } else {
        const isBottom = clientHeight === scrollHeight.current - scrollTop
        const isBetween = scrollTop > 0 && clientHeight < scrollHeight.current - scrollTop

        setShadowPosition(isBetween ? 'both' : isTop ? 'bottom' : isBottom ? 'top' : null)
      }

      if (isTop && fetchMore) {
        await fetchMore?.()
        // save scroll position after new messages are loaded on top
        if (scrollHeight.current && lastElem.current && scroll.current) {
          scroll.current.scrollTop += scroll.current.scrollHeight - scrollHeight.current
        }
      }
    }

    useImperativeHandle(
      ref,
      () => ({
        scrollToBottom(skipIfScrolled) {
          if (!scroll.current) return
          const { clientHeight, scrollTop } = scroll.current
          const isBottom =
            scrollHeight.current === 0 || clientHeight === scrollHeight.current - scrollTop
          if (skipIfScrolled && !isBottom) return
          lastElem.current?.scrollIntoView(false)
        },
      }),
      [],
    )

    return (
      <Container shadowPosition={shadowPosition} showShadow={showShadow}>
        <ScrollContainer className={className} onScroll={handleScroll} ref={scroll}>
          {/* <div ref={topElement} /> */}
          {children}
          <div ref={lastElem} />
        </ScrollContainer>
      </Container>
    )
  },
)

const Container = styled.div<{ shadowPosition: ShadowPosition | null; showShadow?: boolean }>`
  position: relative;
  display: flex;
  overflow: hidden;
  flex: 1;

  &:before,
  &:after {
    content: '';
    left: 0;
    right: 0;
    z-index: 1;
    position: absolute;
    height: 30px;
    pointer-events: none;
  }

  &:before {
    top: 0;
    background: linear-gradient(0deg, rgba(19, 25, 39, 0) 0%, rgba(19, 25, 39, 0.4) 100%);
    opacity: ${({ showShadow, shadowPosition }) =>
      !showShadow ? 0 : shadowPosition === 'top' || shadowPosition === 'both' ? 1 : 0};
  }

  &:after {
    bottom: 0;
    background: linear-gradient(180deg, rgba(19, 25, 39, 0) 0%, rgba(19, 25, 39, 0.4) 100%);
    opacity: ${({ showShadow, shadowPosition }) =>
      !showShadow ? 0 : shadowPosition === 'bottom' || shadowPosition === 'both' ? 1 : 0};
  }
`

const ScrollContainer = styled.div`
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
`
