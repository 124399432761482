import styled from '@emotion/styled'
import { Duration } from 'luxon'
import type { FC } from 'react'
import { useState, useEffect } from 'react'
import { interval } from 'rxjs'
import type { Millisecond } from '~shared-types'

type Props = {
  startedAt?: Millisecond
  className?: string
}

export const OpenMatchMarkerTimer: FC<Props> = ({ startedAt = 0, className }) => {
  const [time, setTime] = useState<number>(0)

  useEffect(() => {
    if (!startedAt) return
    const timer$ = interval(1000).subscribe(() => {
      setTime(() => Date.now() - startedAt + 1000)
    })

    return () => timer$.unsubscribe()
  }, [startedAt])

  return <Container className={className}>{Duration.fromMillis(time).toFormat('mm:ss')}</Container>
}

const Container = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-shadow: 0 0px 5px #000000;
`
