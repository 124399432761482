import styled from '@emotion/styled/macro'
import IconError from '~assets/icon-error.svg'
import IconSuccess from '~assets/icon-success.svg'

export const TextLink = styled.span`
  color: #ada08a;
  cursor: pointer;
  font-weight: 600;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin: 0;
  }

  p + p {
    margin-top: 8px;
  }
`

export const Row = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-column-gap: 100px;

  ${({ columns }) =>
    columns === 1 &&
    `
  ${Column} + ${Column} {
    margin-top: 50px;
  } `}
`

export const Title = styled.h4`
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin: 0;
  padding-bottom: 14px;
  margin-bottom: 36px;
  border-bottom: 1px solid #3c4860;
`

export const StatusText = styled.span<{ status?: 'success' | 'error' }>`
  color: ${({ status }) => (status === 'success' ? '#646D85' : '#EA4357')};
  font-size: 14px;
  line-height: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    position: relative;
    display: ${({ status }) => (status ? 'block' : 'none')};
    width: 16px;
    height: 16px;
    background: url('${({ status }) => (status === 'error' ? IconError : IconSuccess)}') center
      no-repeat;
    margin-right: 6px;
    flex-shrink: 0;
  }
`
