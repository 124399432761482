import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import type { FC } from 'react'
import type { Character } from '~generated/graphql'

type Props = { avatar?: Pick<Character['avatar'], 'fullBody' | 'fullBody2x'> }

export const CharacterImage: FC<Props> = ({ avatar }) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (!avatar?.fullBody) return

    const img = new Image()
    img.src = avatar?.fullBody
    img.onload = () => {
      setLoaded(true)
    }
  }, [avatar?.fullBody])
  if (!avatar) return null

  return <Container avatar={avatar} loaded={loaded} />
}

const Container = styled.div<{
  avatar?: { fullBody: string; fullBody2x: string }
  loaded: boolean
}>`
  width: 100%;
  height: 620px;
  background: ${({ avatar }) =>
      `image-set(url("${avatar?.fullBody}") 1x, url("${avatar?.fullBody2x}") 2x)`}
    center no-repeat;
  background-size: auto 100%;
  position: absolute;
  top: 160px;
  z-index: -1;
  clip-path: polygon(0 0, 100% 0, 100% 60%, 0 60%);
  transition: opacity 0.2s;
  opacity: ${({ loaded }) => (loaded ? 1 : 0)};
`
