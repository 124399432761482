import type { Map } from 'maplibre-gl'
import { timer } from 'rxjs'
import type { CharacterEntity } from '../CharacterEntity'
import { AnimationMarker } from './AnimationMarker'
import { animationDurationMap } from '~utils/animation'
import { AbilityPortalAnimationUI } from '../../components/animation/AbilityPortalAnimationUI'
import type { Point } from '~shared-types'

export class PortalAnimation {
  constructor(map: Map, caster: CharacterEntity, target: Point) {
    const component = <AbilityPortalAnimationUI id={`${caster.id}${Date.now()}PORTAL`} />
    const marker = new AnimationMarker(map, component, target, true)

    const subscription = timer(animationDurationMap.PORTAL * 1000).subscribe(() => {
      marker.remove()
      subscription.unsubscribe()
    })
  }
}
