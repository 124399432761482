import { useNavigate } from 'react-router-dom'
import { useEnterGameMutation, useSessionQuery } from '../generated/graphql'
import { useMatchStore, useStore } from '../store'
import { useEnterGame } from './useEnterGame'

export const useSession = () => {
  const [setSession] = useStore((state) => [state.setSession])
  const [setWsClient] = useMatchStore((state) => [state.setWsClient])
  const navigate = useNavigate()

  const [enterGame] = useEnterGameMutation()

  return useSessionQuery({
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    onError: () => {
      console.log('error')
    },
    onCompleted: async (newData) => {
      if (!newData.session) {
        navigate('/')
        return
      }

      if (newData.session.characterId && newData.session.character?.onlineStatus !== 'ONLINE') {
        await enterGame({ variables: { characterId: newData.session.characterId } })
      }

      setSession(newData.session)

      if (newData.session.matchAccess) {
        navigate('/match')
        setWsClient(newData.session.matchAccess)
      }
    },
  })
}
