import type { Map } from 'maplibre-gl'
import type { Subscription } from 'rxjs'
import { BehaviorSubject, throttleTime, timer } from 'rxjs'
import { Duration } from 'luxon'
import { eventBus } from 'apps/client-app/src/eventBus'
import { angleBetweenTwoPoints, spatialDistance } from '~map-tools'
import { convertMetersToPixels } from '~utils/map'
import type { CharacterEntity } from '../CharacterEntity'
import { AnimationMarker } from './AnimationMarker'
import {
  AbilityChannelAnimationUI,
  AbilityChannelSourceAnimationUI,
} from '../../components/animation/AbilityChannelAnimationUI'
import type { DurationIso } from '~shared-types'
import { matchStore } from '~store'

export class ChannelAnimation {
  public markerAnimationSizeSubject: BehaviorSubject<number>
  public markers: AnimationMarker[]
  public subscriptions: Subscription[]

  constructor(
    map: Map,
    caster: CharacterEntity,
    target: CharacterEntity,
    channelTime?: DurationIso,
  ) {
    eventBus.on('action.triggered', () => {
      if (matchStore.getState().currentCharacter?.id === caster.id) {
        this.cancel()
      }
    })

    this.markerAnimationSizeSubject = new BehaviorSubject(0)
    this.markers = []
    this.subscriptions = []

    // channel animation
    const size = convertMetersToPixels(map, spatialDistance(caster.position, target.position))
    this.markerAnimationSizeSubject.next(size)
    const channelComponent = (
      <AbilityChannelAnimationUI
        side={caster.side}
        race={caster.race}
        id={`${caster.id}${target.id}${Date.now()}CHANNEL`}
        sizeObservable={this.markerAnimationSizeSubject}
      />
    )
    const angle = angleBetweenTwoPoints(caster.position, target.position)
    const channelMarker = new AnimationMarker(map, channelComponent, caster.position, true, angle)
    this.markers.push(channelMarker)

    // source animation
    const sourceMarkerComponent = (
      <AbilityChannelSourceAnimationUI
        side={caster.side}
        race={caster.race}
        id={`${caster.id}${target.id}CHANNEL_SOURCE`}
      />
    )
    const sourceMarker = new AnimationMarker(
      map,
      sourceMarkerComponent,
      caster.position,
      false,
      angle,
    )
    this.markers.push(sourceMarker)

    const targetMarkerComponent = (
      <AbilityChannelSourceAnimationUI
        side={caster.side}
        race={caster.race}
        id={`${caster.id}${target.id}CHANNEL_TARGET`}
      />
    )
    const targetMarker = new AnimationMarker(
      map,
      targetMarkerComponent,
      target.position,
      false,
      (angle + 180) % 360,
    )
    this.markers.push(targetMarker)

    this.subscriptions.push(
      target.positionObservable.pipe(throttleTime(50)).subscribe((position) => {
        const angleUpdated = angleBetweenTwoPoints(caster.position, position)
        channelMarker.setRotation(angleUpdated)
        const newSize = convertMetersToPixels(map, spatialDistance(caster.position, position))
        this.markerAnimationSizeSubject.next(newSize)

        targetMarker?.setLngLat(position)
        targetMarker?.setRotation((angleUpdated + 180) % 360)
        sourceMarker?.setRotation(angleUpdated)
      }),
    )

    this.subscriptions.push(
      caster.positionObservable.pipe(throttleTime(50)).subscribe((position) => {
        const angleUpdated = angleBetweenTwoPoints(position, target.position)
        channelMarker.setRotation(angleUpdated)
        channelMarker.setLngLat(position)
        const newSize = convertMetersToPixels(map, spatialDistance(position, target.position))
        this.markerAnimationSizeSubject.next(newSize)

        sourceMarker?.setLngLat(position)
        sourceMarker?.setRotation(angleUpdated)
        targetMarker?.setRotation((angleUpdated + 180) % 360)
      }),
    )

    const channelTimeMillis = channelTime ? Duration.fromISO(channelTime).as('milliseconds') : 0
    const positionSubscription = timer(channelTimeMillis).subscribe(() => {
      positionSubscription.unsubscribe()
      this.markers.forEach((marker) => marker.remove())
    })
  }

  cancel() {
    this.markers.forEach((marker) => marker.remove())
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
  }
}
