import { css, Global, useTheme } from '@emotion/react'

import './maplibre-gl.css'

export const GlobalStyles = () => (
  // const theme = useTheme()

  <Global
    styles={css(`
        @import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;500;600;700;800&family=Roboto:ital,wght@0,400;0,700;1,300&display=swap');

        body {
          margin: 0;
          line-height: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          position: fixed;
          height: 100%;
          width: 100%;
          font-family: 'Overpass', sans-serif;
          background: #000;
        }

        a, button{
          outline: none;
          font-family: 'Overpass', sans-serif;
        }

        #root {
          height: 100%;
        }

        * {
          box-sizing: border-box;

          &:before, &:after {
            box-sizing: border-box;
          }
        }

        svg {
          transition: 0.2s ease-in;
          display: block;
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover, 
        input:-webkit-autofill:focus, 
        input:-webkit-autofill:active{
            -webkit-text-fill-color: #ffffff;
            transition: background-color 5000s ease-in-out 0s;
        }

        .region-marker > svg {
          top: -23px;
          position: relative;
        }

        @keyframes characterMarketHealthChangeFlightPosition {
          0% {
            transform: translate(-50%, -50%);
          }
          100% {
            transform: translateX(calc(-50% + 50px * var(--xShift) / 100))
              translateY(calc(-50% + -50px * var(--yShift) / 100));
          }
        }

        @keyframes characterMarketHealthChangeFontSize {
          0% {
            font-size: 30px;
          }
          20% {
            font-size: 45px;
          }
          70% {
            font-size: 45px;
          }
          100% {
            font-size: 14px;
          }
        }

        @keyframes spellCastProgress {
          from {
            width: var(--progress);
          }
          to {
            width: 100%;
          }
        }

        @keyframes spellChannelProgress {
          from {
            width: calc(100% - var(--progress));
          }
          to {
            width: 0%;
          }
        }
    `)}
  />
)
