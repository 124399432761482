import { EscDialog } from './EscDialog/EscDialog'
import { FinishedMatchDialog } from './FinishedMatchDialog/FinishedMatchDialog'
import { OfflineDialog } from './OfflineDialog/OfflineDialog'
import { OpenMatchWaitDialog } from './OpenMatchWaitDialog/OpenMatchWaitDialog'

export const Dialogs = () => (
  <>
    <EscDialog />
    <OpenMatchWaitDialog />
    <OfflineDialog />
    <FinishedMatchDialog />
  </>
)
