import styled from '@emotion/styled'
import type { FC } from 'react'
import { Button } from '~components/Button'
import { t } from '~utils/i18n'
import { Side, useSessionQuery } from '~generated/graphql'
import { store } from '~store'

import ImgAttackCircle from '~assets/img-attack-circle.png'
import ImgAttackCircle2x from '~assets/img-attack-circle_2x.png'
import ImgFinishMatchFrame from '~assets/img-finish-match-frame.png'
import ImgFinishMatchFrame2x from '~assets/img-finish-match-frame_2x.png'
import ImgFinishMatchCrownLight from '~assets/img-finish-match-crown-light.png'
import ImgFinishMatchCrownLight2x from '~assets/img-finish-match-crown-light_2x.png'
import ImgFinishMatchCrownDark from '~assets/img-finish-match-crown-dark.png'
import ImgFinishMatchCrownDark2x from '~assets/img-finish-match-crown-dark_2x.png'
import ImgFinishMatchTextWin from '~assets/img-finish-match-text-win.png'
import ImgFinishMatchTextWin2x from '~assets/img-finish-match-text-win_2x.png'
import ImgFinishMatchTextDefeat from '~assets/img-finish-match-text-defeat.png'
import ImgFinishMatchTextDefeat2x from '~assets/img-finish-match-text-defeat_2x.png'

import ImgFinishMatchGlassWinLight from '~assets/img-finish-match-glass-win-light.png'
import ImgFinishMatchGlassWinLight2x from '~assets/img-finish-match-glass-win-light_2x.png'
import ImgFinishMatchGlassWinDark from '~assets/img-finish-match-glass-win-dark.png'
import ImgFinishMatchGlassWinDark2x from '~assets/img-finish-match-glass-win-dark_2x.png'
import ImgFinishMatchGlassDefeat from '~assets/img-finish-match-glass-defeat.png'
import ImgFinishMatchGlassDefeat2x from '~assets/img-finish-match-glass-defeat_2x.png'

export const FinishedMatchDialog: FC = () => {
  const [openMatchFinishedDialog] = store((state) => [state.openMatchFinishedDialog])
  const { data } = useSessionQuery({
    fetchPolicy: 'network-only',
    pollInterval: 1000,
    skip: !openMatchFinishedDialog,
    skipPollAttempt: () => !data?.session.matchAccess || !openMatchFinishedDialog,
  })

  if (!openMatchFinishedDialog) {
    return null
  }

  const { result, side, endMatch } = openMatchFinishedDialog

  const frameGlassImage =
    result === 'win'
      ? side === 'LIGHT'
        ? ImgFinishMatchGlassWinLight
        : ImgFinishMatchGlassWinDark
      : ImgFinishMatchGlassDefeat

  const frameGlassImage2x =
    result === 'win'
      ? side === 'LIGHT'
        ? ImgFinishMatchGlassWinLight2x
        : ImgFinishMatchGlassWinDark2x
      : ImgFinishMatchGlassDefeat2x

  const crownImage = side === 'LIGHT' ? ImgFinishMatchCrownLight : ImgFinishMatchCrownDark
  const crownImage2x = side === 'LIGHT' ? ImgFinishMatchCrownLight2x : ImgFinishMatchCrownDark2x

  const isContinueButtonVisible = true //! data?.session?.matchAccess

  return (
    <Container>
      <Frame>
        <RotatingCircle />
        <GlassImage result={result} srcSet={`${frameGlassImage} 1x, ${frameGlassImage2x} 2x`} />
        <CrownImage srcSet={`${crownImage} 1x, ${crownImage2x} 2x`} alt="crown" />
        {result === 'win' ? (
          <img
            srcSet={`${ImgFinishMatchTextWin} 1x, ${ImgFinishMatchTextWin2x} 2x`}
            alt="winText"
          />
        ) : (
          <img
            srcSet={`${ImgFinishMatchTextDefeat} 1x, ${ImgFinishMatchTextDefeat2x} 2x`}
            alt="defeatText"
          />
        )}
      </Frame>
      <ContinueButton
        outline
        onClick={isContinueButtonVisible ? endMatch : undefined}
        hidden={!isContinueButtonVisible}
      >
        {t('button:continue')}
      </ContinueButton>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #131927bf;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  backdrop-filter: blur(8px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 108px;
`

const RotatingCircle = styled.div`
  width: 570px;
  height: 570px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -285px;
  margin-top: -285px;
  z-index: -2;
  background: image-set(url('${ImgAttackCircle}') 1x, url('${ImgAttackCircle2x}') 2x) center
    no-repeat;
  pointer-events: none;
  animation: spin 60s linear infinite;
  will-change: transform;

  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }
`

const Frame = styled.div`
  width: 642px;
  height: 256px;
  background: image-set(url('${ImgFinishMatchFrame}') 1x, url('${ImgFinishMatchFrame2x}') 2x) center
    no-repeat;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
  position: relative;
`

const ContinueButton = styled(Button)<{ hidden: boolean }>`
  margin-top: 60px;
  min-width: 182px;
  opacity: 0;
  animation: ${({ hidden }) => (hidden ? 'none' : 'fade-in 1s forwards')};

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 100;
    }
  }
`

const CrownImage = styled.img`
  position: absolute;
  bottom: calc(100% - 10px);
`

const GlassImage = styled.img<{ result: 'win' | 'defeat' }>`
  position: absolute;
  z-index: -1;
  top: ${({ result }) => (result === 'defeat' ? '0' : '10px')};
  left: 50%;
  transform: translateX(-50%);
`
