import ruabilities from './ru/abilities.json';
import ruauth from './ru/auth.json';
import rubackendError from './ru/backendError.json';
import rubutton from './ru/button.json';
import rucharacter from './ru/character.json';
import ruchooseRace from './ru/chooseRace.json';
import ruchooseSide from './ru/chooseSide.json';
import rucommon from './ru/common.json';
import rudialog from './ru/dialog.json';
import ruform from './ru/form.json';
import rulanding from './ru/landing.json';
import rumasteries from './ru/masteries.json';
import runews from './ru/news.json';
import runotification from './ru/notification.json';
import rupopover from './ru/popover.json';
import ruprofile from './ru/profile.json';
import ruquestLocations from './ru/questLocations.json';
import ruquests from './ru/quests.json';
import rusettings from './ru/settings.json';
import rutooltip from './ru/tooltip.json';
import ruzones from './ru/zones.json';
import enabilities from './en/abilities.json';
import enauth from './en/auth.json';
import enbackendError from './en/backendError.json';
import enbutton from './en/button.json';
import encharacter from './en/character.json';
import enchooseRace from './en/chooseRace.json';
import enchooseSide from './en/chooseSide.json';
import encommon from './en/common.json';
import endialog from './en/dialog.json';
import enform from './en/form.json';
import enlanding from './en/landing.json';
import enmasteries from './en/masteries.json';
import ennews from './en/news.json';
import ennotification from './en/notification.json';
import enpopover from './en/popover.json';
import enprofile from './en/profile.json';
import ensettings from './en/settings.json';
import entooltip from './en/tooltip.json';
type LocalesType = { abilities: typeof ruabilities } & 
{ auth: typeof ruauth } & 
{ backendError: typeof rubackendError } & 
{ button: typeof rubutton } & 
{ character: typeof rucharacter } & 
{ chooseRace: typeof ruchooseRace } & 
{ chooseSide: typeof ruchooseSide } & 
{ common: typeof rucommon } & 
{ dialog: typeof rudialog } & 
{ form: typeof ruform } & 
{ landing: typeof rulanding } & 
{ masteries: typeof rumasteries } & 
{ news: typeof runews } & 
{ notification: typeof runotification } & 
{ popover: typeof rupopover } & 
{ profile: typeof ruprofile } & 
{ questLocations: typeof ruquestLocations } & 
{ quests: typeof ruquests } & 
{ settings: typeof rusettings } & 
{ tooltip: typeof rutooltip } & 
{ zones: typeof ruzones } & 
{ abilities: typeof enabilities } & 
{ auth: typeof enauth } & 
{ backendError: typeof enbackendError } & 
{ button: typeof enbutton } & 
{ character: typeof encharacter } & 
{ chooseRace: typeof enchooseRace } & 
{ chooseSide: typeof enchooseSide } & 
{ common: typeof encommon } & 
{ dialog: typeof endialog } & 
{ form: typeof enform } & 
{ landing: typeof enlanding } & 
{ masteries: typeof enmasteries } & 
{ news: typeof ennews } & 
{ notification: typeof ennotification } & 
{ popover: typeof enpopover } & 
{ profile: typeof enprofile } & 
{ settings: typeof ensettings } & 
{ tooltip: typeof entooltip };
export const languages = ['ru', 'en'];
type ArrayElementType<T> = T extends readonly (infer U)[] ? U : never;
export type Language = ArrayElementType<typeof languages>;
export const namespaces = ['abilities', 'auth', 'backendError', 'button', 'character', 'chooseRace', 'chooseSide', 'common', 'dialog', 'form', 'landing', 'masteries', 'news', 'notification', 'popover', 'profile', 'questLocations', 'quests', 'settings', 'tooltip', 'zones'];
export type Translations = {
  'abilities:abilities': LocalesType['abilities']['abilities']
  'auth:passwordChangeTitle': LocalesType['auth']['passwordChangeTitle']
  'auth:userDetailsBlock.title': LocalesType['auth']['userDetailsBlock']['title']
  'auth:userDetailsBlock.emailIsNotConfirmed': LocalesType['auth']['userDetailsBlock']['emailIsNotConfirmed']
  'auth:userDetailsBlock.emailIsVerified': LocalesType['auth']['userDetailsBlock']['emailIsVerified']
  'auth:login.title': LocalesType['auth']['login']['title']
  'auth:login.dontHaveAccount': LocalesType['auth']['login']['dontHaveAccount']
  'auth:login.forgotPassword': LocalesType['auth']['login']['forgotPassword']
  'auth:login.rememberMe': LocalesType['auth']['login']['rememberMe']
  'auth:createAccount.title': LocalesType['auth']['createAccount']['title']
  'auth:createAccount.confirmPasswordLabel': LocalesType['auth']['createAccount']['confirmPasswordLabel']
  'auth:createAccount.agreeWithTermsAndCondition': LocalesType['auth']['createAccount']['agreeWithTermsAndCondition']
  'auth:createAccount.termsAndConditionLink': LocalesType['auth']['createAccount']['termsAndConditionLink']
  'auth:passwordRecovery.title': LocalesType['auth']['passwordRecovery']['title']
  'auth:passwordRecovery.content': LocalesType['auth']['passwordRecovery']['content']
  'auth:passwordRecovery.newPassword': LocalesType['auth']['passwordRecovery']['newPassword']
  'auth:passwordRecovery.confirmPassword': LocalesType['auth']['passwordRecovery']['confirmPassword']
  'auth:passwordRecoveryRequest.title': LocalesType['auth']['passwordRecoveryRequest']['title']
  'auth:passwordRecoveryRequest.placeYourEmail': LocalesType['auth']['passwordRecoveryRequest']['placeYourEmail']
  'auth:passwordRecoveryRequest.emailWasSent': LocalesType['auth']['passwordRecoveryRequest']['emailWasSent']
  'auth:alreadyHaveAccount': LocalesType['auth']['alreadyHaveAccount']
  'backendError:general.somethingWentWrong': LocalesType['backendError']['general']['somethingWentWrong']
  'backendError:general.notValidEmail': LocalesType['backendError']['general']['notValidEmail']
  'backendError:general.characterNotFound': LocalesType['backendError']['general']['characterNotFound']
  'backendError:auth.userNotFound': LocalesType['backendError']['auth']['userNotFound']
  'backendError:auth.emailIsAlreadyConfirmed': LocalesType['backendError']['auth']['emailIsAlreadyConfirmed']
  'backendError:auth.nextTryInSeconds': LocalesType['backendError']['auth']['nextTryInSeconds']
  'backendError:auth.incorrectConfirmationCode': LocalesType['backendError']['auth']['incorrectConfirmationCode']
  'backendError:auth.expiredConfirmationCode': LocalesType['backendError']['auth']['expiredConfirmationCode']
  'backendError:auth.expiredPasswordResetLink': LocalesType['backendError']['auth']['expiredPasswordResetLink']
  'backendError:auth.newEmailMustBeDifferent': LocalesType['backendError']['auth']['newEmailMustBeDifferent']
  'backendError:auth.emailIsAlreadyTaken': LocalesType['backendError']['auth']['emailIsAlreadyTaken']
  'backendError:auth.wrongCredentials': LocalesType['backendError']['auth']['wrongCredentials']
  'backendError:character.lastAndFirstNameTaken': LocalesType['backendError']['character']['lastAndFirstNameTaken']
  'button:save': LocalesType['button']['save']
  'button:cancel': LocalesType['button']['cancel']
  'button:confirm': LocalesType['button']['confirm']
  'button:go': LocalesType['button']['go']
  'button:continue': LocalesType['button']['continue']
  'button:continueQuest': LocalesType['button']['continueQuest']
  'button:submit': LocalesType['button']['submit']
  'button:play': LocalesType['button']['play']
  'button:signUp': LocalesType['button']['signUp']
  'button:logIn': LocalesType['button']['logIn']
  'button:logOut': LocalesType['button']['logOut']
  'button:choose': LocalesType['button']['choose']
  'button:unlock': LocalesType['button']['unlock']
  'button:enter': LocalesType['button']['enter']
  'button:join': LocalesType['button']['join']
  'button:orderTaxi': LocalesType['button']['orderTaxi']
  'character:level': LocalesType['character']['level_other']
  'character:experience': LocalesType['character']['experience']
  'character:firstName': LocalesType['character']['firstName']
  'character:firstNamePlaceholder': LocalesType['character']['firstNamePlaceholder']
  'character:lastName': LocalesType['character']['lastName']
  'character:male': LocalesType['character']['male']
  'character:female': LocalesType['character']['female']
  'character:lastNamePlaceholder': LocalesType['character']['lastNamePlaceholder']
  'character:createCharacter.title': LocalesType['character']['createCharacter']['title']
  'character:createCharacter.characterName': LocalesType['character']['createCharacter']['characterName']
  'character:createCharacter.characterGender': LocalesType['character']['createCharacter']['characterGender']
  'character:createCharacter.button': LocalesType['character']['createCharacter']['button']
  'character:stats.health': LocalesType['character']['stats']['health']
  'character:stats.energy': LocalesType['character']['stats']['energy']
  'character:stats.physicalPower': LocalesType['character']['stats']['physicalPower']
  'character:stats.magicPower': LocalesType['character']['stats']['magicPower']
  'character:stats.physicalResistance': LocalesType['character']['stats']['physicalResistance']
  'character:stats.magicResistance': LocalesType['character']['stats']['magicResistance']
  'character:stats.physicalPenetration': LocalesType['character']['stats']['physicalPenetration']
  'character:stats.magicPenetration': LocalesType['character']['stats']['magicPenetration']
  'character:stats.criticalStrikeChance': LocalesType['character']['stats']['criticalStrikeChance']
  'character:stats.criticalStrikeDamage': LocalesType['character']['stats']['criticalStrikeDamage']
  'character:stats.movementSpeed': LocalesType['character']['stats']['movementSpeed']
  'chooseRace:chooseRace': LocalesType['chooseRace']['chooseRace']
  'chooseRace:role': LocalesType['chooseRace']['role']
  'chooseRace:damage': LocalesType['chooseRace']['damage']
  'chooseRace:range': LocalesType['chooseRace']['range']
  'chooseSide:chooseLight': LocalesType['chooseSide']['chooseLight']
  'chooseSide:chooseDarkness': LocalesType['chooseSide']['chooseDarkness']
  'chooseSide:lightDescription': LocalesType['chooseSide']['lightDescription']
  'chooseSide:darknessDescription': LocalesType['chooseSide']['darknessDescription']
  'chooseSide:availableRaces': LocalesType['chooseSide']['availableRaces']
  'common:racesList': LocalesType['common']['racesList']
  'common:units.seconds': LocalesType['common']['units']['seconds']
  'common:units.m': LocalesType['common']['units']['m']
  'common:units.km': LocalesType['common']['units']['km']
  'dialog:offlineDialog.youAreOffline': LocalesType['dialog']['offlineDialog']['youAreOffline']
  'dialog:offlineDialog.backToGame': LocalesType['dialog']['offlineDialog']['backToGame']
  'dialog:openMatchWaitDialog.waitToBeginText': LocalesType['dialog']['openMatchWaitDialog']['waitToBeginText']
  'dialog:openMatchWaitDialog.youWereAttackedText': LocalesType['dialog']['openMatchWaitDialog']['youWereAttackedText']
  'dialog:openMatchWaitDialog.youAttackedText': LocalesType['dialog']['openMatchWaitDialog']['youAttackedText']
  'dialog:escDialog.support': LocalesType['dialog']['escDialog']['support']
  'dialog:escDialog.settings': LocalesType['dialog']['escDialog']['settings']
  'form:requiredField': LocalesType['form']['requiredField']
  'form:emailLabel': LocalesType['form']['emailLabel']
  'form:emailPlaceholder': LocalesType['form']['emailPlaceholder']
  'form:passwordLabel': LocalesType['form']['passwordLabel']
  'form:passwordPlaceholder': LocalesType['form']['passwordPlaceholder']
  'form:ruleEmailMustBeCorrect': LocalesType['form']['ruleEmailMustBeCorrect']
  'form:rulePasswordsMustMatch': LocalesType['form']['rulePasswordsMustMatch']
  'form:minLength': LocalesType['form']['minLength']
  'form:maxLength': LocalesType['form']['maxLength']
  'landing:header.menu': LocalesType['landing']['header']['menu']
  'landing:header.playButtonLabel': LocalesType['landing']['header']['playButtonLabel']
  'landing:hero.logoLabel': LocalesType['landing']['hero']['logoLabel']
  'landing:hero.title': LocalesType['landing']['hero']['title']
  'landing:hero.content': LocalesType['landing']['hero']['content']
  'landing:hero.playButtonLabel': LocalesType['landing']['hero']['playButtonLabel']
  'landing:news.title': LocalesType['landing']['news']['title']
  'landing:news.allNewsLinkLabel': LocalesType['landing']['news']['allNewsLinkLabel']
  'landing:side.lightTitle': LocalesType['landing']['side']['lightTitle']
  'landing:side.lightDescription': LocalesType['landing']['side']['lightDescription']
  'landing:side.darknessTitle': LocalesType['landing']['side']['darknessTitle']
  'landing:side.darknessDescription': LocalesType['landing']['side']['darknessDescription']
  'landing:races.title': LocalesType['landing']['races']['title']
  'landing:races.roleLabel': LocalesType['landing']['races']['roleLabel']
  'landing:races.damageLabel': LocalesType['landing']['races']['damageLabel']
  'landing:races.rangeLabel': LocalesType['landing']['races']['rangeLabel']
  'landing:races.racesList': LocalesType['landing']['races']['racesList']
  'landing:universe.title': LocalesType['landing']['universe']['title']
  'landing:universe.booksTitle': LocalesType['landing']['universe']['booksTitle']
  'landing:universe.filmsTitle': LocalesType['landing']['universe']['filmsTitle']
  'landing:universe.books': LocalesType['landing']['universe']['books']
  'landing:universe.films': LocalesType['landing']['universe']['films']
  'landing:cta.title': LocalesType['landing']['cta']['title']
  'landing:cta.playButtonLabel': LocalesType['landing']['cta']['playButtonLabel']
  'landing:footer.copyright': LocalesType['landing']['footer']['copyright']
  'landing:footer.links': LocalesType['landing']['footer']['links']
  'masteries:masteries': LocalesType['masteries']['masteries']
  'masteries:point.point': LocalesType['masteries']['point']['point_other']
  'masteries:statsIncreaseText': LocalesType['masteries']['statsIncreaseText']
  'masteries:notLearned': LocalesType['masteries']['notLearned']
  'news:news': LocalesType['news']['news']
  'notification:match.canNotUseAbilities': LocalesType['notification']['match']['canNotUseAbilities']
  'notification:match.canNotMove': LocalesType['notification']['match']['canNotMove']
  'notification:match.canNotMoveOrUseAbilities': LocalesType['notification']['match']['canNotMoveOrUseAbilities']
  'popover:openMatch.participantsLabel': LocalesType['popover']['openMatch']['participantsLabel']
  'popover:openMatch.participantsValueLabel': LocalesType['popover']['openMatch']['participantsValueLabel']
  'popover:openMatch.forceRatioLabel': LocalesType['popover']['openMatch']['forceRatioLabel']
  'popover:cityMapRoute.waitingTimeLabel': LocalesType['popover']['cityMapRoute']['waitingTimeLabel']
  'popover:cityMapRoute.distanceLabel': LocalesType['popover']['cityMapRoute']['distanceLabel']
  'popover:characterMarker.attack': LocalesType['popover']['characterMarker']['attack']
  'profile:title': LocalesType['profile']['title']
  'profile:raceLabel': LocalesType['profile']['raceLabel']
  'profile:matchesPlayedLabel': LocalesType['profile']['matchesPlayedLabel']
  'profile:matchesWinnedLabel': LocalesType['profile']['matchesWinnedLabel']
  'profile:masteriesLinkText': LocalesType['profile']['masteriesLinkText']
  'profile:masteriesDisabledText': LocalesType['profile']['masteriesDisabledText']
  'questLocations:questLocations': LocalesType['questLocations']['questLocations']
  'quests:quests': LocalesType['quests']['quests']
  'settings:account.title': LocalesType['settings']['account']['title']
  'settings:account.userData': LocalesType['settings']['account']['userData']
  'settings:account.emailIsConfirmed': LocalesType['settings']['account']['emailIsConfirmed']
  'settings:account.emailIsNotConfirmed': LocalesType['settings']['account']['emailIsNotConfirmed']
  'settings:account.changeEmail.title': LocalesType['settings']['account']['changeEmail']['title']
  'settings:account.confirmEmail.title': LocalesType['settings']['account']['confirmEmail']['title']
  'settings:account.confirmEmail.content': LocalesType['settings']['account']['confirmEmail']['content']
  'settings:account.confirmEmail.haveCodeAlready': LocalesType['settings']['account']['confirmEmail']['haveCodeAlready']
  'settings:account.confirmEmail.emailIsSent': LocalesType['settings']['account']['confirmEmail']['emailIsSent']
  'settings:account.confirmEmail.nextTryIn': LocalesType['settings']['account']['confirmEmail']['nextTryIn']
  'settings:account.confirmEmail.reRequest': LocalesType['settings']['account']['confirmEmail']['reRequest']
  'settings:account.confirmEmail.confirmationCode': LocalesType['settings']['account']['confirmEmail']['confirmationCode']
  'tooltip:mainMenu.profile': LocalesType['tooltip']['mainMenu']['profile']
  'tooltip:mainMenu.cityMap': LocalesType['tooltip']['mainMenu']['cityMap']
  'tooltip:bottomMenu.chat': LocalesType['tooltip']['bottomMenu']['chat']
  'tooltip:bottomMenu.friends': LocalesType['tooltip']['bottomMenu']['friends']
  'tooltip:bottomMenu.notifications': LocalesType['tooltip']['bottomMenu']['notifications']
  'tooltip:bottomMenu.esc': LocalesType['tooltip']['bottomMenu']['esc']
  'tooltip:twilight.goToReality': LocalesType['tooltip']['twilight']['goToReality']
  'tooltip:twilight.goTo': LocalesType['tooltip']['twilight']['goTo']
  'tooltip:twilight.disabledText': LocalesType['tooltip']['twilight']['disabledText']
  'tooltip:ability.cooldown': LocalesType['tooltip']['ability']['cooldown']
  'tooltip:ability.cast': LocalesType['tooltip']['ability']['cast']
  'tooltip:ability.channel': LocalesType['tooltip']['ability']['channel']
  'tooltip:ability.channeled': LocalesType['tooltip']['ability']['channeled']
  'tooltip:stats.physicalPower': LocalesType['tooltip']['stats']['physicalPower']
  'tooltip:stats.physicalResistance': LocalesType['tooltip']['stats']['physicalResistance']
  'tooltip:stats.castingSpeed': LocalesType['tooltip']['stats']['castingSpeed']
  'tooltip:stats.magicPower': LocalesType['tooltip']['stats']['magicPower']
  'tooltip:stats.magicResistance': LocalesType['tooltip']['stats']['magicResistance']
  'tooltip:stats.movementSpeed': LocalesType['tooltip']['stats']['movementSpeed']
  'zones:zones': LocalesType['zones']['zones']
};