import { create } from 'zustand'
import { subscribeWithSelector } from 'zustand/middleware'
import { useShallow } from 'zustand/react/shallow'
import type { BottomMenuId } from '~components/BottomMenu'
import type { TwilightLevel } from '../types'
import type {
  Character,
  RouteBetweenZonesOptionFragment,
  SessionFragment,
  Side,
} from '../generated/graphql'
import type { ZoneCharacter } from '../views/zone/model/ZoneCharacter'
import type { Second } from '~shared-types'

export type FullscreenId =
  | 'masteries'
  | 'profile'
  | 'settings'
  | 'cityMap'
  | 'race'
  | 'side'
  | 'esc'

type OpenMatchWaitDialog = {
  character?: Pick<Character, 'firstName' | 'lastName' | 'level'> & {
    avatar: Pick<Character['avatar'], 'fullBody' | 'fullBody2x'>
  }
  isAttacker?: boolean
  matchStartAt?: string
}

type OpenMatchFinishedDialog = {
  result: 'win' | 'defeat'
  side: Side
  endMatch?: () => void
}

type State = {
  session: Partial<SessionFragment> | null
  currentCharacter: ZoneCharacter | null
  twilightLevel: TwilightLevel
  headerTitle: string
  bottomMenuId: BottomMenuId | null
  fullscreenId: FullscreenId | null
  characters: Map<string, ZoneCharacter>
  cityRouteOptions: RouteBetweenZonesOptionFragment[] | null
  routeBetweenZones: RouteBetweenZonesOptionFragment | null
  waitingTaxiCooldown: Second | null
  offline: boolean | null
  openMatchWaitDialog: OpenMatchWaitDialog | null
  openMatchFinishedDialog: OpenMatchFinishedDialog | null
}

type Actions = {
  setSession: (session: Partial<SessionFragment>) => void
  setCurrentCharacter: (character: ZoneCharacter) => void
  setTwilightLevel: (twilightLevel: TwilightLevel) => void
  setHeaderTitle: (headerTitle: string) => void
  setBottomMenuId: (bottomMenuId: BottomMenuId | null) => void
  setFullscreenId: (id: FullscreenId | null) => void
  setCharacters: (characters: Map<string, ZoneCharacter>) => void
  setCityRouteOptions: (cityRouteOptions: RouteBetweenZonesOptionFragment[] | null) => void
  setRouteBetweenZones: (routeBetweenZones: RouteBetweenZonesOptionFragment | null) => void
  setWaitingTaxiCooldown: (time: Second | null) => void
  setOffline: () => void
  clearStore: () => void
  setOpenMatchWaitDialog: (openMatchWaitDialog: OpenMatchWaitDialog) => void
  hideOpenMatchWaitDialog: () => void
  setOpenMatchFinishedDialog: (openMatchFinishedDialog: OpenMatchFinishedDialog) => void
  hideOpenMatchFinishedDialog: () => void
}

type Store = State & Actions

// TODO: optimise performance https://github.com/pmndrs/zustand#transient-updates-for-often-occurring-state-changes
export const store = create(
  subscribeWithSelector<Store>((set, get) => ({
    bottomMenuId: null,
    fullscreenId: null,
    offline: null,
    setBottomMenuId: (bottomMenuId: BottomMenuId | null) =>
      set({
        bottomMenuId: bottomMenuId === get().bottomMenuId ? null : bottomMenuId,
      }),
    setFullscreenId: (id: FullscreenId | null) =>
      set({
        fullscreenId: id === get().fullscreenId ? null : id,
      }),
    session: null,
    setSession: (session: Partial<SessionFragment>) => set({ session }),
    currentCharacter: null,
    setCurrentCharacter: (currentCharacter: ZoneCharacter) =>
      set({ currentCharacter, headerTitle: currentCharacter.zone?.name }),
    twilightLevel: 0,
    setTwilightLevel: (twilightLevel: TwilightLevel) => set({ twilightLevel }),
    headerTitle: '',
    setHeaderTitle: (headerTitle: string) => set({ headerTitle }),
    characters: new Map(),
    setCharacters: (characters: Map<string, ZoneCharacter>) => set({ characters }),
    cityRouteOptions: null,
    setCityRouteOptions: (cityRouteOptions: RouteBetweenZonesOptionFragment[] | null) =>
      set({ cityRouteOptions }),
    routeBetweenZones: null,
    setRouteBetweenZones: (routeBetweenZones: RouteBetweenZonesOptionFragment | null) =>
      set({ routeBetweenZones }),
    waitingTaxiCooldown: null,
    setWaitingTaxiCooldown: (value: Second | null) => set({ waitingTaxiCooldown: value }),
    setOffline: () => set({ offline: true }),
    clearStore: () =>
      set({
        session: null,
        currentCharacter: null,
        characters: new Map(),
        cityRouteOptions: null,
        routeBetweenZones: null,
        waitingTaxiCooldown: null,
        bottomMenuId: null,
        fullscreenId: null,
        twilightLevel: 0,
        headerTitle: '',
      }),
    openMatchWaitDialog: null,
    setOpenMatchWaitDialog: (openMatchWaitDialog: OpenMatchWaitDialog | null) => {
      // NOTE: prevent setting matchstart if dialog is already open
      if (get().openMatchWaitDialog && openMatchWaitDialog?.matchStartAt) {
        return
      }

      set({
        openMatchWaitDialog: {
          ...get().openMatchWaitDialog,
          ...openMatchWaitDialog,
        },
      })
    },
    hideOpenMatchWaitDialog: () => set({ openMatchWaitDialog: null }),
    openMatchFinishedDialog: null,
    setOpenMatchFinishedDialog: (openMatchFinishedDialog: OpenMatchFinishedDialog) =>
      set({
        openMatchFinishedDialog: {
          ...get().openMatchFinishedDialog,
          ...openMatchFinishedDialog,
        },
      }),
    hideOpenMatchFinishedDialog: () => set({ openMatchFinishedDialog: null }),
  })),
)

export const useStore = <U>(selector: (state: Store) => U) => store(useShallow(selector))
