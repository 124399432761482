import styled from '@emotion/styled'
import { useMemo } from 'react'
import type { FC } from 'react'
import { distinctUntilChanged } from 'rxjs'
import type { Observable } from 'rxjs'
import type { Race, Side } from '~generated/graphql'
import { EnergyBar, HealthBar } from './Bar'
import { useValueFromObservable } from '../quest/useValueFromObservable'

import BgStatsWrapper from '~assets/bg-stats-wrapper.png'
import BgStatsWrapper2x from '~assets/bg-stats-wrapper_2x.png'
import BgStatsWrapperSingle from '~assets/bg-stats-wrapper-single.png'
import BgStatsWrapperSingle2x from '~assets/bg-stats-wrapper-single_2x.png'

export type MainStats = {
  health: number
  healthMax: number
  energy: number
  energyMax: number
  shield?: number
}

type Props = {
  race: Race
  side: Side
  statsObservable: Observable<MainStats>
}

export const CharacterPanelMainStats: FC<Props> = ({ race, side, statsObservable }) => {
  const healthAndEnergyObservable = useMemo(
    () =>
      statsObservable.pipe(
        distinctUntilChanged(
          (prev, cur) =>
            !(
              prev.energy !== cur.energy ||
              prev.energyMax !== cur.energyMax ||
              prev.health !== cur.health ||
              prev.healthMax !== cur.healthMax ||
              prev.shield !== cur.shield
            ),
        ),
      ),
    [statsObservable],
  )
  const stats = useValueFromObservable(healthAndEnergyObservable)

  const { energy, health, healthMax = 300, energyMax = 300, shield } = stats

  const maxValue = Math.max(health + (shield ?? 0), healthMax)
  const healthProgress = health / maxValue
  const shieldProgress = (shield ? health + shield : 0) / maxValue
  const sectorsCount = maxValue / 250

  if (race === 'HUMAN') {
    return (
      <MainStatsSingleContainer>
        {health !== undefined ? (
          <HealthBar
            race={race}
            side={side}
            sectorsCount={sectorsCount}
            healthProgress={healthProgress * 100}
            shieldProgress={shieldProgress * 100}
            value={Math.round(health)}
          />
        ) : null}
      </MainStatsSingleContainer>
    )
  }

  return (
    <MainStatsContainer>
      {health !== undefined ? (
        <HealthBar
          race={race}
          side={side}
          sectorsCount={sectorsCount}
          healthProgress={healthProgress * 100}
          shieldProgress={shieldProgress * 100}
          value={Math.round(health)}
        />
      ) : null}
      {energy !== undefined ? (
        <EnergyBar side={side} progress={(energy / energyMax) * 100} value={Math.round(energy)} />
      ) : null}
    </MainStatsContainer>
  )
}

const MainStatsContainer = styled.div`
  position: relative;
  width: 219px;
  height: 52px;
  background: image-set(url('${BgStatsWrapper}') 1x, url('${BgStatsWrapper2x}') 2x) no-repeat;
  left: -8px;
  padding-top: 7px;
  padding-left: 14px;
`

const MainStatsSingleContainer = styled.div`
  position: relative;
  width: 219px;
  height: 28px;
  background: image-set(url('${BgStatsWrapperSingle}') 1x, url('${BgStatsWrapperSingle2x}') 2x)
    no-repeat;
  left: -8px;
  padding-top: 7px;
  padding-left: 2px;
  margin-left: 12px;
`
