import type { FC } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import { useForm } from 'react-hook-form'
import { BigButton, Button } from '~components/Button'
import { Dialog } from '~components/Dialog'
import { LoginForm } from './LoginForm/LoginForm'
import { SignupForm } from './SignupForm/SignupForm'
import { Input } from '../../components/Input'
import { useResetPassword } from './useResetPassword'
import { isValidEmail } from '~validation'
import { t } from '~utils/i18n'

import BgHome from '~assets/bg-home.jpg'
import BgHome2x from '~assets/bg-home_2x.jpg'
import Logo from '~assets/logo-main.png'
import Logo2x from '~assets/logo-main_2x.png'
import LogoLight from '~assets/logo-light.png'
import LogoLight2x from '~assets/logo-light_2x.png'
import IconSupport from '~assets/icon-support.svg'
import IconWiki from '~assets/icon-wiki.svg'
import IconForum from '~assets/icon-forum.svg'
import { ReactComponent as IconSteam } from '~assets/icon-steam.svg'

type LoginInputs = {
  email: string
}

export const Auth: FC = () => {
  const [state, setState] = useState<
    'login' | 'signup' | 'restore-password' | 'restore-password-requested' | null
  >(null)

  const handleEnterGameClick = async () => {
    setState('login')
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginInputs>()

  const [requestPasswordReset, { error }] = useResetPassword()

  const [showError, setShowError] = useState<boolean>(false)

  const handleRequestPasswordResetSubmit = async (formData: { email: string }) => {
    setShowError(true)

    const { data } = await requestPasswordReset({
      variables: { email: formData.email },
    })

    if (data?.requestPasswordReset.__typename === 'PasswordResetInitiated') {
      setState('restore-password-requested')
    }
  }

  return (
    <Container>
      <Menu>
        <MenuLink to="/" target="_blank">
          <img src={IconSupport} alt="support" />
          Поддержка
        </MenuLink>
        <MenuLink to="/" target="_blank">
          <img src={IconWiki} alt="wiki" />
          Об игре
        </MenuLink>
        <MenuLink to="/" target="_blank">
          <img src={IconForum} alt="forum" />
          Форум
        </MenuLink>
      </Menu>
      <SocialMenu>
        <SocialLink to="/" target="_blank">
          <IconSteam />
        </SocialLink>
      </SocialMenu>
      <Main overlay={state !== null}>
        {state === 'login' ? (
          <LoginDialog onClose={() => setState(null)} relative>
            <FormLogo srcSet={`${LogoLight} 1x, ${LogoLight2x} 2x`} alt="logo" />
            <Title>{t('auth:login.title')}</Title>
            <LoginForm onForgotPassword={() => setState('restore-password')} />
            <Navigation>
              {t('auth:login.dontHaveAccount')}{' '}
              <TextLink onClick={() => setState('signup')}> {t('button:signUp')}</TextLink>
            </Navigation>
          </LoginDialog>
        ) : state === 'signup' ? (
          <SignupDialog onClose={() => setState(null)} relative>
            <FormLogo srcSet={`${LogoLight} 1x, ${LogoLight2x} 2x`} alt="logo" />
            <Title>{t('auth:createAccount.title')}</Title>
            <SignupForm />
            <Navigation>
              {t('auth:alreadyHaveAccount')}{' '}
              <TextLink onClick={() => setState('login')}>{t('button:logIn')}</TextLink>
            </Navigation>
          </SignupDialog>
        ) : state === 'restore-password' ? (
          <LoginDialog onClose={() => setState(null)} relative>
            <FormLogo srcSet={`${LogoLight} 1x, ${LogoLight2x} 2x`} alt="logo" />
            <Title>{t('auth:passwordRecoveryRequest.title')}</Title>
            <Content>{t('auth:passwordRecoveryRequest.placeYourEmail')}</Content>
            <Form onSubmit={handleSubmit(handleRequestPasswordResetSubmit)}>
              <Input
                register={register}
                name="email"
                rules={{
                  required: { value: true, message: t('form:requiredField')! },
                  validate: (value) =>
                    isValidEmail(value) ? undefined : t('form:ruleEmailMustBeCorrect'),
                }}
                type="text"
                label={t('form:emailLabel')}
                placeholder={t('form:emailPlaceholder')}
                error={errors.email?.message}
                autoComplete="email"
              />

              {error && showError && <Error>{error.message}</Error>}
              <FormButton onClick={handleSubmit(handleRequestPasswordResetSubmit)}>
                {t('button:submit')}
              </FormButton>
            </Form>
            <Navigation>
              {t('auth:alreadyHaveAccount')}{' '}
              <TextLink onClick={() => setState('login')}>{t('button:logIn')}</TextLink>
            </Navigation>
          </LoginDialog>
        ) : state === 'restore-password-requested' ? (
          <LoginDialog onClose={() => setState(null)} relative>
            <FormLogo srcSet={`${LogoLight} 1x, ${LogoLight2x} 2x`} alt="logo" />
            <Title>{t('auth:passwordRecoveryRequest.title')}</Title>
            <Content>{t('auth:passwordRecoveryRequest.emailWasSent')}</Content>
            <Navigation>
              {t('auth:alreadyHaveAccount')}{' '}
              <TextLink onClick={() => setState('login')}>{t('button:logIn')}</TextLink>
            </Navigation>
          </LoginDialog>
        ) : (
          <>
            <img srcSet={`${Logo} 1x, ${Logo2x} 2x`} alt="logo" />
            <EnterButton onClick={handleEnterGameClick}>{t('button:logIn')}</EnterButton>
          </>
        )}
      </Main>
      <MapCopyright>
        Map data from ©{' '}
        <a href="https://www.openstreetmap.org/copyright" target="_blank" rel="noreferrer">
          OpenStreetMap
        </a>
      </MapCopyright>
    </Container>
  )
}

const FormButton = styled(Button)`
  margin-top: 36px;
  min-width: 182px;
`

const Form = styled.form`
  width: 100%;
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

const Error = styled.div`
  width: 100%;
  text-align: center;
  color: #ea4357;
  font-size: 14px;
  margin-top: 20px;
`

const FormLogo = styled.img`
  margin: auto;
`

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  background: image-set(url(${BgHome}) 1x, url(${BgHome2x}) 2x) no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
`

const Main = styled.div<{ overlay?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  flex-grow: 1;

  &:before {
    content: '';
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #131927;
    transition: opacity 0.2s;
    opacity: ${({ overlay }) => (overlay ? 0.75 : 0)};
    z-index: 0;
    pointer-events: none;
  }
`

const Menu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 48px;
  right: 46px;
  z-index: 1;
`

const MenuLink = styled(Link)`
  display: flex;
  flex-direction: column;
  color: #fff;
  text-decoration: none;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.16px;

  & + & {
    margin-top: 30px;
  }

  img {
    margin-bottom: 8px;
  }
`

const SocialMenu = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 48px;
  right: 55px;
  z-index: 1;
`

const SocialLink = styled(Link)`
  display: grid;
  place-items: center;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  background: linear-gradient(178deg, #131927 1.95%, rgba(42, 48, 65, 0.5) 98.21%);

  & + & {
    margin-top: 12px;
  }
`

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  margin-top: 60px;
`

const LoginDialog = styled(Dialog)`
  width: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const SignupDialog = styled(Dialog)`
  width: 608px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Navigation = styled.div`
  text-align: center;
  margin-top: 28px;
  font-size: 16px;
`

const TextLink = styled.span`
  color: #ada08a;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
`

const EnterButton = styled(BigButton)`
  margin-top: 60px;
`

const MapCopyright = styled.div`
  color: #646d85;
  font-size: 14px;

  a {
    color: #ada08a;
    cursor: pointer;
    font-weight: 600;
    text-decoration: none;
  }
`

const Content = styled.div`
  text-align: center;
  margin-top: 8px;
`
