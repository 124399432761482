import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Int, Race } from '~shared-types'
import { raceSvgColorMap } from '~utils/character'

type AbilityRangeStore = {
  abilityRange?: Int
}

type Props = {
  race: Race
  useAbilityRangeStore: <U>(selector: (state: AbilityRangeStore) => U) => U
}

export const MarkerAbilityRange: FC<Props> = ({ useAbilityRangeStore, race }) => {
  const [abilityRange] = useAbilityRangeStore((state) => [state.abilityRange])
  if (!abilityRange) {
    return null
  }
  return <Container range={abilityRange} race={race} />
}

const Container = styled.div<{ range: number; race: Race }>`
  pointer-events: none;
  position: absolute;
  width: ${({ range }) => range || 0}px;
  height: ${({ range }) => range || 0}px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 50%;
  border: 2px solid ${({ race }) => raceSvgColorMap[race].main}63;
  background: radial-gradient(
    55.5% 55.5% at 50% 50%,
    ${({ race }) => raceSvgColorMap[race].dark}30 0%,
    transparent 100%
  );
`
