import { DateTime, Duration } from 'luxon'
import { store, useStore } from '~store'
import {
  OnlineStatus,
  useCharacterEnteredZoneSubscription,
  useCharacterLeftZoneSubscription,
  useOpenMatchCharacterLazyQuery,
  useOpenMatchSubscription,
  useRouteUpdatedSubscription,
} from '~generated/graphql'
import { useLazySession } from '~hooks/useLazySession'
import { ZoneMap } from '../model/ZoneMap'

export const useZoneSubscriptions = () => {
  const [session, setOffline] = useStore((state) => [state.session, state.setOffline])
  const [fetchSession] = useLazySession()

  useRouteUpdatedSubscription({
    skip: !session?.character?.zoneId,
    variables: {
      zoneId: session?.character?.zoneId || 0,
    },
    onData: (data) => {
      const characterRouteUpdated = data.data.data?.characterRouteUpdated
      if (!session || !characterRouteUpdated) return
      const map = ZoneMap.getInstance()
      const mapCharacter = map.characters.get(characterRouteUpdated.characterId)
      const { route } = characterRouteUpdated
      if (route) {
        const routeInfo = {
          ...route,
          startAt: DateTime.fromISO(route.startAt).toMillis(),
          duration: Duration.fromISO(route.duration).toMillis(),
        }
        mapCharacter?.routeManager.addRoute(routeInfo)
      }
    },
    onError: (error) => {
      console.error('Route updated error', error)
    },
  })

  const [queryCharacter] = useOpenMatchCharacterLazyQuery()

  useOpenMatchSubscription({
    skip: !session?.characterId || !session?.character?.zoneId,
    variables: {
      zoneId: session?.character?.zoneId || 0,
    },
    onData: async ({ data }) => {
      if (!session?.characterId || !data.data?.openMatch) return

      const { participants, leftCharacterId, rightCharacterId } = data.data.openMatch

      const isCurrentCharacterMatch =
        participants.findIndex(({ characterId }) => characterId === session.characterId) !== -1

      if (isCurrentCharacterMatch) {
        await fetchSession()
        const isCurrentCharacterInitiator = session.characterId === leftCharacterId
        const isCurrentCharacterAttacked = session.characterId === rightCharacterId

        if (isCurrentCharacterInitiator) {
          const attackedCharacter = await queryCharacter({ variables: { id: rightCharacterId } })
          if (attackedCharacter.data?.character) {
            store
              .getState()
              .setOpenMatchWaitDialog({ character: attackedCharacter.data?.character })
          }
        } else if (isCurrentCharacterAttacked) {
          store.getState().setOpenMatchWaitDialog({
            isAttacker: false,
          })
          const attackerCharacter = await queryCharacter({ variables: { id: leftCharacterId } })
          if (attackerCharacter.data?.character) {
            store
              .getState()
              .setOpenMatchWaitDialog({ character: attackerCharacter.data?.character })
          }
        }
      } else {
        const map = ZoneMap.getInstance()
        map.openMatchManager.updateMatch(data.data?.openMatch)
      }
    },
  })

  useCharacterEnteredZoneSubscription({
    skip: !session?.characterId || !session?.character?.zoneId,
    variables: {
      zoneId: session?.character?.zoneId || 0,
    },
    onData: async ({ data }) => {
      const map = ZoneMap.getInstance()
      if (!data.data?.characterEnteredZone) {
        return
      }
      map.addCharacter(
        data.data.characterEnteredZone,
        session?.characterId === data.data.characterEnteredZone.id,
      )
    },
  })

  useCharacterLeftZoneSubscription({
    skip: !session?.characterId || !session?.character?.zoneId,
    variables: {
      zoneId: session?.character?.zoneId || 0,
    },
    onData: async ({ data }) => {
      const map = ZoneMap.getInstance()
      if (!data.data?.characterLeftZone) {
        return
      }
      map.removeCharacter(data.data.characterLeftZone.id)

      if (
        data.data.characterLeftZone.id === session?.characterId &&
        data.data.characterLeftZone.onlineStatus === 'OFFLINE'
      ) {
        setOffline()
      }
    },
  })
}
