/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/no-array-index-key */
import styled from '@emotion/styled'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { raceColorMap } from 'apps/client-app/src/utils/character'
import { ReactComponent as IconLock } from '~assets/icon-lock.svg'
import { store } from '~store'
import type {
  LearnedMasteryInfoFragment,
  MasteriesAbilityFragment,
  MasteriesQuery,
  MasteryInfoFragment,
  MasteryType,
  Race,
} from '~generated/graphql'
import { Button } from '~components/Button'
import { PointsSlider } from './PointsSlider'
import { DoubleAbilitySelectDialog } from './DoubleAbilitySelectDialog'
import { ActiveAbility, DoubleAbility } from '~components/Ability/Ability'
import { t } from '~utils/i18n'

const masteriesLevelColor = {
  VAMPIRE: '#362078',
  SHAPESHIFTER_DOG_LIKE: '#973F0C',
  WEREWOLF: '#973F0C',
  SHAPESHIFTER_CAT_LIKE: '#8F156B',
  MAGICIAN: '#026574',
  CLAIRVOYANT: '#906507',
  WITCH: '#136744',
  HUMAN: '#fff',
}

const sectionPartHeight = 15
const sectionDividerHeight = 2

const getAbilityByCurrentMasteryTier = (mastery: MasteriesQuery['masteries'][0], level: number) => {
  const currentTier = mastery.tiers.find((tier) => tier.requiredLevel === level)
  if (!currentTier) return
  return currentTier.abilitySlots[0]
}

const calculateProgressHeight = (currentPoints: number) => {
  if (currentPoints === 0) return `0px`

  if (currentPoints % 3 === 0) {
    return `${(currentPoints / 12) * 100}%`
  }

  const badgeHeight = 18
  const sectionNumber = Math.floor(currentPoints / 3)
  const sectionDividerCount = currentPoints - Math.floor(currentPoints / 3)

  return `
    ${
      sectionPartHeight * currentPoints +
      badgeHeight / 2 +
      badgeHeight * sectionNumber +
      sectionDividerCount * sectionDividerHeight
    }px
  `
}

const columnsCount = 3

export type MasteryUnsavedChanges = {
  masteryType: MasteryType
  chosenAbilitiesIds: number[]
  levelIncrease: number
}

type Props = {
  className?: string
  masteryInfo?: MasteryInfoFragment
  learnedMasteryInfo?: LearnedMasteryInfoFragment
  availablePoints: number
  direction: 'left' | 'right'
  unsavedChanges?: MasteryUnsavedChanges
  setUnsavedChanges: (unsavedChanges: MasteryUnsavedChanges) => void
  characterLevel?: number
  onSaveChanges: (unsavedChanges: MasteryUnsavedChanges) => void
}

export type MasteryRef = {
  clearUpdates: () => void
}

export const Mastery = forwardRef<MasteryRef, Props>(
  (
    {
      className,
      availablePoints,
      masteryInfo,
      learnedMasteryInfo,
      direction,
      unsavedChanges: updates,
      setUnsavedChanges,
      characterLevel,
      onSaveChanges,
    },
    ref,
  ) => {
    const [currentCharacter] = store((state) => [state.currentCharacter])
    const [doubleAbilityToSelect, setDoubleAbilityToSelect] =
      useState<[MasteriesAbilityFragment, MasteriesAbilityFragment]>()
    const learnedMasteryInfoRef = useRef<LearnedMasteryInfoFragment | undefined>()

    const clearUpdates = useCallback(() => {
      if (masteryInfo) {
        setUnsavedChanges({
          masteryType: masteryInfo.type,
          chosenAbilitiesIds: [],
          levelIncrease: 0,
        })
      }
    }, [])

    useImperativeHandle(ref, () => ({
      clearUpdates,
    }))

    useEffect(() => {
      if (
        learnedMasteryInfo &&
        JSON.stringify(learnedMasteryInfo) !== JSON.stringify(learnedMasteryInfoRef.current)
      ) {
        clearUpdates()
        learnedMasteryInfoRef.current = learnedMasteryInfo
      }
    }, [learnedMasteryInfo])

    if (!currentCharacter || !masteryInfo) return null

    const tiers = [...(masteryInfo.tiers || [])].sort((a, b) => a.requiredLevel - b.requiredLevel)

    const race = currentCharacter?.race || 'HUMAN'

    const isDisabled = !learnedMasteryInfo && !updates
    const points = (learnedMasteryInfo?.level || 0) + (updates?.levelIncrease || 0)
    const isUnlockButtonDisabled = !!characterLevel && characterLevel < 10
    const maxLevel = Math.max(...tiers.map((tier) => tier.requiredLevel))
    const canIncrease = availablePoints > 0 && points < maxLevel
    const canDecrease = (updates?.levelIncrease ?? 0) > 0

    const handleUnlockButtonClick = () => {
      const unlockedAbility = getAbilityByCurrentMasteryTier(masteryInfo, points)

      if (unlockedAbility && unlockedAbility.ability && unlockedAbility.optionalAbility) {
        setDoubleAbilityToSelect([unlockedAbility.ability, unlockedAbility.optionalAbility])
      } else {
        onSaveChanges({
          masteryType: masteryInfo.type,
          levelIncrease: 0,
          chosenAbilitiesIds: [],
        })
      }
    }

    const handleSelectDoubleAbility = (abilityId: number) => {
      const newChosenAbilitiesIds = [...(updates?.chosenAbilitiesIds || []), abilityId]
      const newUpdates = updates
        ? {
            ...updates,
            chosenAbilitiesIds: newChosenAbilitiesIds,
          }
        : {
            masteryType: masteryInfo.type,
            levelIncrease: 0,
            chosenAbilitiesIds: newChosenAbilitiesIds,
          }

      if (!updates) {
        onSaveChanges(newUpdates)
      } else {
        setUnsavedChanges(newUpdates)
      }

      setDoubleAbilityToSelect(undefined)
    }

    const handlePointsIncrease = () => {
      if (!canIncrease) {
        return
      }

      const newUpdates = updates
        ? { ...updates, levelIncrease: updates.levelIncrease + 1 }
        : { masteryType: masteryInfo.type, levelIncrease: 1, chosenAbilitiesIds: [] }

      const unlockedAbility = getAbilityByCurrentMasteryTier(masteryInfo, points + 1)

      if (unlockedAbility && unlockedAbility.ability && unlockedAbility.optionalAbility) {
        setDoubleAbilityToSelect([unlockedAbility.ability, unlockedAbility.optionalAbility])
      }

      setUnsavedChanges(newUpdates)
    }

    const handlePointsDecrease = () => {
      if (!canDecrease) {
        return
      }
      if (!updates) {
        return
      }

      const levelIncrease = updates.levelIncrease - 1

      const unlockedAbility = getAbilityByCurrentMasteryTier(masteryInfo, points)

      let newChosenAbilitiesIds = [...updates.chosenAbilitiesIds]

      if (unlockedAbility && unlockedAbility.ability && unlockedAbility.optionalAbility) {
        if (updates.chosenAbilitiesIds.includes(unlockedAbility.ability.id)) {
          newChosenAbilitiesIds = newChosenAbilitiesIds.filter(
            (id) => id !== unlockedAbility.ability?.id,
          )
        } else if (updates.chosenAbilitiesIds.includes(unlockedAbility.optionalAbility.id)) {
          newChosenAbilitiesIds = newChosenAbilitiesIds.filter(
            (id) => id !== unlockedAbility.optionalAbility?.id,
          )
        }
      }

      setUnsavedChanges({
        ...updates,
        chosenAbilitiesIds: newChosenAbilitiesIds,
        levelIncrease: levelIncrease > 0 ? levelIncrease : 0,
      })
    }

    return (
      <Container className={className} direction={direction}>
        <Title disabled={isDisabled}>
          {isDisabled && <IconLock />}{' '}
          {t('masteries:masteries')?.find((matery) => matery.mastery === masteryInfo.type)?.name ??
            masteryInfo.type}
        </Title>
        <Table
          disabled={isDisabled}
          direction={direction}
          progressHeight={points !== undefined ? calculateProgressHeight(points) : undefined}
          race={race}
        >
          <RowsContainer>
            {tiers.map((tier) => (
              <Row
                direction={direction}
                key={tier.requiredLevel}
                active={points !== undefined && points > tier.requiredLevel}
                race={race}
              >
                {Array.from({ length: columnsCount }).map((_, slotIndex) => {
                  const ability = tier.abilitySlots.find((a) => a.column === slotIndex + 1)
                  if (ability?.optionalAbility) {
                    const learnedMasteryAbility = learnedMasteryInfo?.abilityIds.includes(
                      ability?.optionalAbility?.id,
                    )
                      ? ability?.optionalAbility
                      : learnedMasteryInfo?.abilityIds.includes(ability?.abilityId)
                      ? ability?.ability
                      : undefined

                    if (learnedMasteryAbility) {
                      return (
                        <ActiveAbility
                          key={learnedMasteryAbility.id}
                          {...learnedMasteryAbility}
                          disabled={isDisabled}
                          inactive={
                            (learnedMasteryInfo?.level || 0) + (updates?.levelIncrease || 0) <
                            tier.requiredLevel
                          }
                        />
                      )
                    }

                    const updatedMasteriesAbility = updates?.chosenAbilitiesIds.includes(
                      ability?.optionalAbility?.id,
                    )
                      ? ability?.optionalAbility
                      : updates?.chosenAbilitiesIds.includes(ability?.abilityId)
                      ? ability?.ability
                      : undefined

                    if (updatedMasteriesAbility) {
                      return (
                        <ActiveAbility
                          key={updatedMasteriesAbility.id}
                          {...updatedMasteriesAbility}
                          disabled={isDisabled}
                          inactive={
                            (learnedMasteryInfo?.level || 0) + (updates?.levelIncrease || 0) <
                            tier.requiredLevel
                          }
                        />
                      )
                    }

                    return (
                      <DoubleAbility
                        key={ability.abilityId}
                        ability1={ability.ability!}
                        ability2={ability.optionalAbility!}
                        inactive={
                          (learnedMasteryInfo?.level || 0) + (updates?.levelIncrease || 0) <
                          tier.requiredLevel
                        }
                      />
                    )
                  }

                  if (ability?.ability) {
                    return (
                      <ActiveAbility
                        key={ability.abilityId}
                        {...ability.ability}
                        disabled={isDisabled}
                        inactive={
                          (learnedMasteryInfo?.level || 0) + (updates?.levelIncrease || 0) <
                          tier.requiredLevel
                        }
                      />
                    )
                  }

                  return <EmptySlot key={`${tier.requiredLevel} ${slotIndex}`} />
                })}
              </Row>
            ))}
          </RowsContainer>
          {learnedMasteryInfo ? (
            <PointsSlider
              direction={direction}
              position={points !== undefined ? calculateProgressHeight(points) : undefined}
              isIncreaseDisabled={!canIncrease}
              value={updates?.levelIncrease || 0}
              onIncrease={handlePointsIncrease}
              isDecreaseDisabled={!canDecrease}
              onDecrease={handlePointsDecrease}
            />
          ) : null}
        </Table>
        <ButtonContainer direction={direction}>
          {isDisabled ? (
            <UnlockButton
              onClick={() => handleUnlockButtonClick()}
              disabled={isUnlockButtonDisabled}
            >
              {t('button:unlock')}
            </UnlockButton>
          ) : null}
        </ButtonContainer>

        {doubleAbilityToSelect ? (
          <DoubleAbilitySelectDialog
            abilities={doubleAbilityToSelect}
            onSubmit={handleSelectDoubleAbility}
          />
        ) : null}
      </Container>
    )
  },
)

// const PassiveAbility = styled.div<{ icon: string }>`
//   width: 56px;
//   height: 56px;
//   border-radius: 50%;
//   border: 3px solid #808080;
//   background: url(${({ icon }) => icon}) center no-repeat;
//   background-size: 90%;
//   margin: 6px;
//   position: relative;
// `

const EmptySlot = styled.div`
  width: 68px;
  height: 68px;
`

const Row = styled.div<{ direction: 'left' | 'right'; active: boolean; race: Race }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  height: 67px;

  ${({ direction }) =>
    direction === 'left'
      ? `
    padding-left: 77px;
    padding-right: 70px;`
      : `
    padding-right: 77px;
    padding-left: 70px;`}

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    top: 50%;
    z-index: 0;
    transition: background 0.3s ease-in;
    opacity: ${({ active }) => (active ? '0.6' : '1')};
    background: ${({ active, race, direction }) =>
      active
        ? `linear-gradient(to ${direction}, ${raceColorMap[race].main} 0%, ${raceColorMap[race].dark} 90%, rgba(0, 0, 0, 0) 100%)`
        : `linear-gradient(to ${direction}, #2a3041 0%, #2a3041 90%, rgba(0, 0, 0, 0) 100%)`};
  }
`

const RowsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  ${Row}:first-of-type {
    margin-bottom: -33px;
  }
  ${Row}:last-of-type {
    margin-top: -33px;
  }
`

const Table = styled.div<{
  direction: 'left' | 'right'
  progressHeight?: string
  race: Race
  disabled: boolean
}>`
  position: relative;
  margin-bottom: 8px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: ${({ disabled }) => (disabled ? 'none' : 'block')};
    transition: height 0.3s ease-in;
    height: ${({ progressHeight }) => progressHeight || 0};
    background: ${({ race, direction }) =>
      `linear-gradient(to ${direction}, transparent 50%, #131927 100%), linear-gradient(to bottom, ${masteriesLevelColor[race]}26 0%, ${masteriesLevelColor[race]}0d 100%)`};
  }

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 0;
    z-index: 1;
    display: ${({ disabled }) => (disabled ? 'none' : 'block')};
    transition: bottom 0.3s ease-in;
    bottom: ${({ progressHeight }) => progressHeight};
    margin-bottom: -1px;
    border-top: 2px dashed
      ${({ race, progressHeight }) =>
        progressHeight !== undefined ? `${raceColorMap[race].main}cc` : 'transparent'};
  }
`

const Container = styled.div<{ direction: 'left' | 'right' }>`
  flex: 1;
  height: 100%;
  ${({ direction }) => (direction === 'left' ? 'margin-right: -14px;' : 'margin-left: -14px;')}
`
const Title = styled.div<{ disabled?: boolean }>`
  color: ${({ disabled }) => (disabled ? '#646D85' : '#fff')};
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: baseline;
  margin-bottom: 54px;

  > svg {
    margin-right: 10px;
    height: 22px;
  }
`

const ButtonContainer = styled.div<{ direction: 'left' | 'right' }>`
  margin-top: 52px;
  display: flex;
  height: 0;

  justify-content: ${({ direction }) => (direction === 'left' ? 'flex-start' : 'flex-end')};
`

const UnlockButton = styled(Button)`
  min-width: 134px;
`
