import styled from '@emotion/styled'
import type { UseFormRegister, FieldValues, Path, RegisterOptions } from 'react-hook-form'
import type { ChangeEventHandler, InputHTMLAttributes } from 'react'
import type { Gender } from '~generated/graphql'
import { t } from '~utils/i18n'

import IconGenderFemaleActive from '~assets/icon-gender-female-active.svg'
import IconGenderMaleActive from '~assets/icon-gender-male-active.svg'
import IconGenderFemale from '~assets/icon-gender-female.svg'
import IconGenderMale from '~assets/icon-gender-male.svg'
import BgTwilightButtonActive from '~assets/bg-twilight-button-active.png'
import BgTwilightButtonActive2x from '~assets/bg-twilight-button-active_2x.png'

type Props<T extends FieldValues> = InputHTMLAttributes<HTMLInputElement> & {
  register: UseFormRegister<T>
  name: Path<T>
  rules?: RegisterOptions
  className?: string
  error?: string
  gender: Gender
  onChange?: ChangeEventHandler<HTMLInputElement>
}

const genderLabelMap: Record<Gender, string> = {
  FEMALE: t('character:female')!,
  MALE: t('character:male')!,
}

export const GenderRadioButton = <T extends FieldValues>({
  name,
  gender,
  checked,
  register,
  onChange: onInputChange,
}: Props<T>) => {
  const { onChange } = register(name)

  return (
    <Label gender={gender}>
      <Input
        type="radio"
        checked={checked}
        value={gender}
        {...register(name)}
        onChange={async (e) => {
          await onChange(e)
          onInputChange?.(e)
        }}
      />
      <Button gender={gender}>
        <Icon gender={gender} />
      </Button>
      {genderLabelMap[gender]}
    </Label>
  )
}

const Button = styled.div<{ gender: Gender }>`
  position: relative;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  margin-right: 16px;
  padding: 2px;
  display: grid;
  place-items: center;
  background: radial-gradient(
      61.81% 63.07% at 40.79% 31.58%,
      #eae4cf 0%,
      #d9d1bb 22.71%,
      #ada08a 63.85%,
      #808080 100%
    ),
    linear-gradient(322deg, #3d2924 -3.47%, #ada08a 86.38%);

  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    background: #2a3041;
    border-radius: 50%;
    border: 1px solid #131927;
    transition: 0.2s;
  }

  &:before {
    content: '';
    position: absolute;
    left: -4px;
    top: -4px;
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    background: image-set(
        url('${BgTwilightButtonActive}') 1x,
        url('${BgTwilightButtonActive2x}') 2x
      )
      center no-repeat;
    border: 1px solid #131927;
    border-radius: 50%;
    background-size: cover;
    opacity: 0;
    transition: 0.2s;
    z-index: 1;
  }
`

const Icon = styled.div<{ gender: Gender }>`
  position: relative;
  z-index: 1;
  width: 26px;
  height: 26px;
  background: url(${({ gender }) => (gender === 'FEMALE' ? IconGenderFemale : IconGenderMale)})
    center no-repeat;
`

const Label = styled.label<{ gender: Gender }>`
  cursor: pointer;
  position: relative;
  display: flex;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  align-items: center;

  > input:checked ~ ${Button}:before {
    opacity: 1;
  }

  > input:checked ~ ${Button} > ${Icon} {
    background: url(${({ gender }) =>
        gender === 'FEMALE' ? IconGenderFemaleActive : IconGenderMaleActive})
      center no-repeat;
  }
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`
