import styled from '@emotion/styled/macro'
import type { FC } from 'react'
import type { Race } from '~generated/graphql'
import { raceColorMap, raceIconMap } from '../../../utils/character'

type Props = {
  race: Race
  active?: boolean
  className?: string
}

export const RaceIconFrame: FC<Props> = ({ race, active = false, className }) => {
  const id = race.toLowerCase()
  const color = raceColorMap[race].main
  return (
    <Container className={className}>
      <Svg
        id={id}
        active={active}
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_f_794_22048)">
          <path d="M40 4L76 40L40 76L3.99999 40L40 4Z" fill="#0A0D14" />
        </g>
        <path d="M40 2L76 38L40 74L3.99999 38L40 2Z" fill="url(#paint0_linear_794_22048)" />
        <HoverPath id="icon-frame-hover-path" d="M40 6L72 38L40 70L7.99999 38L40 6Z" />
        <path d="M14 34L18 38L14 42L10 38L14 34Z" fill="url(#paint2_linear_794_22048)" />
        <path d="M66 34L70 38L66 42L62 38L66 34Z" fill="url(#paint3_linear_794_22048)" />
        <path d="M40 8L44 12L40 16L36 12L40 8Z" fill="url(#paint4_linear_794_22048)" />
        <path d="M40 60L44 64L40 68L36 64L40 60Z" fill="url(#paint5_linear_794_22048)" />
        <defs>
          <filter
            id="filter0_f_794_22048"
            x="0"
            y="0"
            width="80"
            height="80"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="2" result="effect1_foregroundBlur_794_22048" />
          </filter>
          <linearGradient
            id="paint0_linear_794_22048"
            x1="40"
            y1="57.5"
            x2="40"
            y2="2.00001"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#808080" />
            <stop offset="0.252154" stopColor="#ADA08A" />
            <stop offset="0.692708" stopColor="#EAE4CF" />
            <stop offset="1" stopColor="#FEFFBF" />
          </linearGradient>
          <linearGradient
            id={`paint_icon_frame_hover_${id}`}
            x1="40"
            y1="38"
            x2="40"
            y2="70"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#131927" />
            <stop offset="1" stopColor={color} />
          </linearGradient>
          <linearGradient
            id="paint2_linear_794_22048"
            x1="14"
            y1="42"
            x2="16.0772"
            y2="34.5816"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#808080" />
            <stop offset="0.307292" stopColor="#ADA08A" />
            <stop offset="0.692708" stopColor="#D9D1BB" />
            <stop offset="1" stopColor="#EAE4CF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_794_22048"
            x1="66"
            y1="42"
            x2="68.0772"
            y2="34.5816"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#808080" />
            <stop offset="0.307292" stopColor="#ADA08A" />
            <stop offset="0.692708" stopColor="#D9D1BB" />
            <stop offset="1" stopColor="#EAE4CF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_794_22048"
            x1="40"
            y1="16"
            x2="42.0772"
            y2="8.5816"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#808080" />
            <stop offset="0.307292" stopColor="#ADA08A" />
            <stop offset="0.692708" stopColor="#D9D1BB" />
            <stop offset="1" stopColor="#EAE4CF" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_794_22048"
            x1="40"
            y1="68"
            x2="42.0772"
            y2="60.5816"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#808080" />
            <stop offset="0.307292" stopColor="#ADA08A" />
            <stop offset="0.692708" stopColor="#D9D1BB" />
            <stop offset="1" stopColor="#EAE4CF" />
          </linearGradient>
        </defs>
      </Svg>
      <IconContainer>{raceIconMap[race]} </IconContainer>
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  width: 80px;
  height: 80px;
`

const IconContainer = styled.div`
  position: absolute;
  top: calc(50% - 1px);
  left: 50%;
  transform: translate(-50%, -50%);

  svg {
    width: 26px;
  }
`

const HoverPath = styled.path`
  fill: #131927;
`

const Svg = styled.svg<{ id: string; active: boolean }>`
  position: relative;
  transition: all 0.3s;

  ${({ active, id }) =>
    active
      ? `
    ${HoverPath} {
      fill: url(#paint_icon_frame_hover_${id});
    }`
      : ''}
`
