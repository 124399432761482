import styled from '@emotion/styled'
import type { FC } from 'react'
import withTooltip from '~components/Tooltip/withTooltip'
import { t } from '~utils/i18n'
import type { MasteryType } from '~generated/graphql'

import IconMasteriesLink from '~assets/icon-masteries-link.svg'
import IconMasteriesLinkDisabled from '~assets/icon-masteries-link-disabled.svg'
import IconAccept from '~assets/icon-accept.svg'
import IconLock from '~assets/icon-lock.svg'

type Props = {
  disabled?: boolean
  firstMasteryLearned?: {
    level: number
  }
  firstMasteryType: MasteryType
  secondMasteryLearned?: {
    level: number
  }
  secondMasteryType: MasteryType
  onOpenMasteries: () => void
}

export const ProfileMasteriesInfo: FC<Props> = ({
  disabled,
  firstMasteryLearned,
  firstMasteryType,
  secondMasteryType,
  secondMasteryLearned,
  onOpenMasteries,
}) => {
  const tooltipContent = t('profile:masteriesDisabledText')
  const MasteriesLinkWithTooltip =
    tooltipContent && disabled
      ? withTooltip(MasteriesLink, {
          type: 'text',
          content: tooltipContent,
        })
      : MasteriesLink

  return (
    <Container>
      {!disabled ? (
        <>
          <SecondMastery>
            <MasteryIcon
              src={secondMasteryLearned ? IconAccept : IconLock}
              disabled={!secondMasteryLearned}
            />
            <MasteryInfo>
              <MasteryName disabled={!secondMasteryLearned}>
                {t('masteries:masteries')?.find(({ mastery }) => mastery === secondMasteryType)
                  ?.name ?? secondMasteryType}
              </MasteryName>
              <MasteryPoints disabled={!secondMasteryLearned}>
                {secondMasteryLearned ? (
                  <>
                    {secondMasteryLearned.level}{' '}
                    {t('masteries:point.point', { count: secondMasteryLearned.level })}
                  </>
                ) : (
                  t('masteries:notLearned')
                )}
              </MasteryPoints>
            </MasteryInfo>
          </SecondMastery>
          <FirstMastery>
            <MasteryIcon
              src={firstMasteryLearned ? IconAccept : IconLock}
              disabled={!firstMasteryLearned}
            />
            <MasteryInfo>
              <MasteryName disabled={!firstMasteryLearned}>
                {t('masteries:masteries')?.find(({ mastery }) => mastery === firstMasteryType)
                  ?.name ?? firstMasteryType}
              </MasteryName>
              <MasteryPoints disabled={!firstMasteryLearned}>
                {firstMasteryLearned ? (
                  <>
                    {firstMasteryLearned.level}{' '}
                    {t('masteries:point.point', { count: firstMasteryLearned.level })}
                  </>
                ) : (
                  t('masteries:notLearned')
                )}
              </MasteryPoints>
            </MasteryInfo>
          </FirstMastery>
        </>
      ) : null}
      <MasteriesLinkWithTooltip
        onClick={disabled ? undefined : onOpenMasteries}
        disabled={disabled}
      >
        {t('profile:masteriesLinkText')}
      </MasteriesLinkWithTooltip>
      {!disabled ? (
        <LinksLine
          width="104"
          height="182"
          viewBox="0 0 104 182"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M104 0C104 36.3425 94.7012 72.0805 76.9882 103.814C59.2753 135.548 33.7373 162.222 2.80393 181.298L0.567858 177.672C30.8826 158.977 55.9098 132.837 73.2685 101.738C90.6272 70.6389 99.74 35.6156 99.74 0H104Z"
            fill="url(#paint0_linear_1692_129152)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1692_129152"
              x1="97.5"
              y1="-12.5"
              x2="2.50002"
              y2="181.5"
              gradientUnits="userSpaceOnUse"
            >
              {/* {secondMasteryLearned ? ( */}
              <stop stopColor="#D9D1BB" />
              <stop offset="1" stopColor="#808080" />
              {/* <stop offset="0.49" stopColor="transparent" />
               <stop offset="0.5" stopColor="#D9D1BB" />
               <stop offset="1" stopColor="#808080" /> */}
            </linearGradient>
          </defs>
        </LinksLine>
      ) : null}
    </Container>
  )
}

const Container = styled.div`
  position: absolute;
  z-index: 3;
  bottom: 14%;
  left: calc(50% + 110px);
`

const MasteryIcon = styled.div<{ src?: string; disabled?: boolean }>`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 50%;
  position: relative;
  background: ${({ disabled }) =>
    !disabled
      ? `linear-gradient(
    to bottom,
    #EAE4CF 0%,
    #D9D1BB 0.2%,
    #ADA08A 0.6%,
    #808080 100%
  )`
      : '#3C4860'};
  box-shadow: 0px 2px 10px 4px #1a1f2b;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 2px;
    top: 2px;
    right: 2px;
    bottom: 2px;
    border-radius: 50%;
  }

  &:before {
    background: #2a3041 url(${({ src }) => src}) no-repeat center;
    background-size: auto 20px;
    background-position-y: ${({ disabled }) => (disabled ? 'center' : 'calc(50% + 1px)')};
    z-index: 2;
  }

  &:after {
    border: 1px solid #131927;
    z-index: 3;
  }
`

const FirstMastery = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 26px;
  margin-left: 72px;
`

const SecondMastery = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 34px;
  margin-left: 99px;
`

const MasteryName = styled.div<{ disabled?: boolean }>`
  font-size: 20px;
  font-weight: 600;
  color: ${({ disabled }) => (disabled ? '#3C4860' : '#ada08a')};
  margin-bottom: 2px;
`

const MasteryPoints = styled.div<{ disabled?: boolean }>`
  font-size: 16px;
  color: ${({ disabled }) => (disabled ? '#3C4860' : '#646d85')};
`

const MasteriesLink = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: ${({ disabled }) => (disabled ? '#3C4860' : '#ada08a')};
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  letter-spacing: 0.25px;

  &:before {
    content: '';
    width: 48px;
    height: 48px;
    border-radius: 50%;
    box-shadow: 0px 2px 6px 3px #1a1f2b;
    background: url(${({ disabled }) => (disabled ? IconMasteriesLinkDisabled : IconMasteriesLink)})
      no-repeat center;
    background-size: contain;
    margin-right: 24px;
    top: -2px;
    position: relative;
    flex-shrink: 0;
  }
`

const MasteryInfo = styled.div`
  margin-left: 24px;
`

const LinksLine = styled.svg`
  position: absolute;
  z-index: -1;
  bottom: 21px;
  left: 24px;
`
