import styled from '@emotion/styled'
import { useEffect } from 'react'
import { useZonesQuery } from '~generated/graphql'
import { CityMap } from './model/CityMap'

export const City = () => {
  const { data } = useZonesQuery({
    notifyOnNetworkStatusChange: true,
  })

  useEffect(() => {
    if (!data?.zones?.length) return

    CityMap.getInstance(data.zones)

    return () => {
      CityMap.remove()
    }
  }, [data])

  return (
    <Container>
      <MapView id="city-map" />
    </Container>
  )
}

const Container = styled.div`
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: #131927;
  z-index: 11;
`

const MapView = styled.div`
  width: 100%;
  height: 100%;
`
