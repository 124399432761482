import { useCallback } from 'react'
import type { FC } from 'react'
import { Minimap } from '~components/map/Minimap/Minimap'
import { useMatchStore, useStore } from '~store'
import type { TwilightLevel } from '~types'
import { useAbility } from '../hooks/useAbility'
import { goTwilightLevelAbilityIdMap } from '../twilightLevelAbilityMap'

export const MatchMinimap: FC = () => {
  const { castAbilityUnitTargeted } = useAbility()

  const [session] = useStore((state) => [state.session])

  const [currentCharacter] = useMatchStore((state) => [state.currentCharacter])

  const setTwilightLevel = useCallback(
    async (twilightLevel: TwilightLevel) => {
      const ability = currentCharacter?.abilities.find(
        ({ id }) => id === goTwilightLevelAbilityIdMap[twilightLevel],
      )

      if (!ability) {
        return
      }

      await castAbilityUnitTargeted({
        variables: {
          characterId: session?.characterId as string,
          abilityId: ability?.id,
        },
      })
    },
    [castAbilityUnitTargeted, currentCharacter?.abilities, session?.characterId],
  )

  const [twilightLevel] = useMatchStore((state) => [state.currentCharacterTwilightLevel])

  return (
    <Minimap
      twilightLevel={twilightLevel}
      setTwilightLevel={setTwilightLevel}
      twilightAbilities={currentCharacter?.abilities}
    />
  )
}
