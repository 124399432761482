import type { Map } from 'maplibre-gl'
import { throttleTime, timer } from 'rxjs'
import type { CharacterEntity } from '../CharacterEntity'
import { AbilityAnimationUI } from '../../components/animation/AbilityAnimationUI'
import { AnimationMarker } from './AnimationMarker'
import { animationDurationMap } from '~utils/animation'

export class AttackMeleeAnimation {
  constructor(map: Map, caster: CharacterEntity, target: CharacterEntity) {
    const component = (
      <AbilityAnimationUI
        animationType="ATTACK_MELEE"
        side={caster.side}
        race={caster.race}
        id={`${caster.id}${target.id}${Date.now()}ATTACK_MELEE`}
      />
    )
    const marker = new AnimationMarker(map, component, target.position)

    const splashOffsetPositions = [
      { x: -55, y: -10 },
      { x: -55, y: 30 },
      { x: 55, y: 10 },
      { x: 40, y: 30 },
    ]
    const splashOffsetIndex = 1 * Math.floor(Math.random() * 4)
    const splashComponent = (
      <AbilityAnimationUI
        animationType="ATTACK_MELEE_SPLASH"
        side={caster.side}
        race={caster.race}
        id={`${caster.id}${target.id}ATTACK_MELEE_SPLASH`}
        transform={`translate(${splashOffsetPositions[splashOffsetIndex].x}px, ${splashOffsetPositions[splashOffsetIndex].y}px)`}
      />
    )
    const splashMarker = new AnimationMarker(map, splashComponent, target.position, true)

    const positionSubscription = target.positionObservable
      .pipe(throttleTime(50))
      .subscribe((position) => {
        marker.setLngLat(position)
        splashMarker.setLngLat(position)
      })

    const subscription = timer(animationDurationMap.ATTACK_MELEE * 1000).subscribe(() => {
      marker.remove()
      positionSubscription.unsubscribe()
      subscription.unsubscribe()
    })
    const splashMarkerSubscription = timer(
      animationDurationMap.ATTACK_MELEE_SPLASH * 1000,
    ).subscribe(() => {
      splashMarker.remove()
      splashMarkerSubscription.unsubscribe()
    })
  }
}
