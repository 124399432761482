import { DateTime, Duration } from 'luxon'
import type { Observable, Subscription } from 'rxjs'
import { BehaviorSubject, Subject, finalize, timer } from 'rxjs'
import type {
  MatchCharacterStatusEffectFragment as MatchCharacterStatusEffect,
  MatchCharacterPeriodicStatusEffectsGroupFragment as MatchCharacterPeriodicStatusEffect,
  Race,
  Side,
  MatchCharacterAbilityFragment as MatchCharacterAbility,
  MatchMapCharacterFragment as MatchMapCharacter,
  AbilityLastingStatusEffectsGroupFragment,
  AbilityPeriodicStatusEffectsGroupFragment,
  AbilityInstantEffectFragment,
  StatsFragment,
  AbilityChannelActionFragment,
  AbilityCastActionFragment,
  MatchCharacterAvatarFragment,
  MatchCharacterAbilityFragment,
  ShieldFragment,
  PeriodicStatusEffectsGroupTick,
  PeriodicStatusEffectsGroupTickSubscriptionResult,
  AbilityPeriodicStatusEffectsGroupTickFragment,
} from '~generated/match-graphql'
import type { TwilightLevel } from '~types'
import { isNull } from '~utils'
import type { Int, Point } from '~shared-types'
import { MatchCharacterFrame } from './MatchCharacterFrame'
import type { MapCharacterActionsManager } from './MapCharacterActionsManager'
import type { MatchMap } from './MatchMap'
import { CharacterStatusEffectsGroups } from './statusEffectsGroups/CharacterStatusEffectsGroups'
import { RouteManager } from '../../zone/model/RouteManager'
import type { CastAction } from './SpellCast'
import { CharacterTwilightRelation } from '~map-tools'

export type PeriodicEffect = MatchCharacterPeriodicStatusEffect &
  Pick<MatchCharacterStatusEffect, 'causedByAbilityEffect'>

export type Stats = StatsFragment & { shield: number }

export class CharacterEntity {
  public firstName: string
  public id: string
  public lastName: string
  public abilities: MatchCharacterAbility[]
  public race: Race
  public side: Side
  public value: number
  #twilightLevel: TwilightLevel
  #stats: StatsFragment
  #position: MatchMapCharacter['position']
  public teamId: string
  public characterFrame?: MatchCharacterFrame
  public statusEffectsGroups: CharacterStatusEffectsGroups
  #alive: boolean
  public isCurrentUser: boolean
  public routeManager: RouteManager
  public avatar: MatchCharacterAvatarFragment

  public spellCastSubscription?: Subscription
  public spellCastSubject: BehaviorSubject<CastAction | null>
  public channelAbilitySubscription?: Subscription
  public visibleSubject: BehaviorSubject<boolean>
  public areActionsVisibleSubject: BehaviorSubject<boolean>

  public statsSubject: Subject<StatsFragment>
  public aliveSubject: Subject<boolean>
  private positionSubject: Subject<Point>
  public effectChanges: Subject<{ health: number }>
  public twilightRelationSubject: BehaviorSubject<CharacterTwilightRelation>
  public twilightLevelSubject: Subject<TwilightLevel>
  public abilityCastedSubject: Subject<MatchCharacterAbilityFragment>
  public readonly level: Int
  public readonly experience: Int

  constructor(
    private mapClass: MatchMap,
    character: MatchMapCharacter,
    actionsManager: MapCharacterActionsManager,
    abilities: MatchCharacterAbility[],
    isCurrentUser: boolean,
  ) {
    this.level = character.level
    this.experience = character.experience
    this.twilightLevelSubject = new Subject()
    this.positionSubject = new Subject()
    this.aliveSubject = new Subject()
    this.effectChanges = new Subject()
    this.abilityCastedSubject = new Subject()
    this.visibleSubject = new BehaviorSubject(false)
    this.twilightRelationSubject = new BehaviorSubject<CharacterTwilightRelation>(
      isCurrentUser ? CharacterTwilightRelation.REACHABLE : CharacterTwilightRelation.NOT_VISIBLE,
    )
    this.id = character.id
    this.firstName = character.firstName
    this.lastName = character.lastName
    this.race = character.race
    this.side = character.side
    this.value = character.value
    this.#twilightLevel = character.twilightLevel as TwilightLevel
    this.spellCastSubject = new BehaviorSubject<
      AbilityCastActionFragment | AbilityChannelActionFragment | null
    >(null)

    this.statsSubject = new BehaviorSubject(character.stats)
    this.#stats = character.stats
    this.stats = this.#stats
    this.#position = character.position
    this.#alive = character.alive
    this.avatar = character.avatar
    this.abilities = abilities
    this.teamId = character.teamId
    actionsManager.setCharacterActions(this.id, [...character.actions])
    this.isCurrentUser = isCurrentUser
    this.areActionsVisibleSubject = new BehaviorSubject(this.isCurrentUser)
    this.statusEffectsGroups = new CharacterStatusEffectsGroups(
      [...character.statusEffectsGroups],
      this,
    )
    this.routeManager = new RouteManager(
      this.id,
      this.isCurrentUser ?? false,
      { map: mapClass.layerManager, minimap: mapClass.minimap?.layerManager },
      (newPosition) => {
        this.position = newPosition
      },
      this.areActionsVisibleSubject,
    )
    this.characterFrame = character.alive
      ? new MatchCharacterFrame(mapClass, this, mapClass.layerManager)
      : undefined
  }

  destroy() {
    this.characterFrame?.marker.remove()
  }

  set alive(alive: boolean) {
    this.#alive = alive
    this.aliveSubject.next(alive)
    if (!alive) {
      timer(1000).subscribe(() => {
        this.characterFrame = undefined
      })
    }
  }

  get alive() {
    return this.#alive
  }

  get positionObservable(): Observable<Point> {
    return this.positionSubject
  }

  public set position(point: Point) {
    this.#position = point
    this.positionSubject.next(point)
  }

  public get position(): Point {
    return this.#position
  }

  public set stats(stats: StatsFragment) {
    if (!this.#alive) return
    this.#stats = stats
    this.statsSubject.next(this.stats)
  }

  public get stats(): Stats {
    return {
      ...this.#stats,
      shield: this.#stats.shields.reduce((acc, { value }) => acc + value, 0),
    }
  }

  public addShieldEffect(shield: ShieldFragment) {
    this.stats = { ...this.#stats, shields: [...this.#stats.shields, shield] }
  }

  public removeShieldEffect(shield: ShieldFragment) {
    this.stats = {
      ...this.#stats,
      shields: [
        ...this.#stats.shields.filter((effect) => effect.statusEffectId !== shield.statusEffectId),
      ],
    }
  }

  public set twilightLevel(twilightLevel: TwilightLevel) {
    this.#twilightLevel = twilightLevel
    this.twilightLevelSubject.next(twilightLevel)
  }

  public get twilightLevel(): TwilightLevel {
    return this.#twilightLevel
  }

  public spellCast(castAction: CastAction | null) {
    if (!castAction) {
      this.spellCastSubject.next(castAction)
      this.spellCastSubscription?.unsubscribe()
      return
    }

    if (!this.#alive) return

    const startAtMillis = DateTime.fromISO(castAction.startAt).toMillis()
    const durationMillis = Duration.fromISO(castAction.duration).toMillis()
    const endAt = startAtMillis + durationMillis

    const animationDuration = endAt - Date.now()

    if (animationDuration <= 0) {
      return
    }

    this.spellCastSubject.next(castAction)

    const subscription = timer(animationDuration)
      .pipe(
        finalize(() => {
          this.spellCastSubject.next(null)
        }),
      )
      .subscribe()
    this.spellCastSubscription = subscription
  }

  public cancelSpellCast() {
    this.spellCastSubscription?.unsubscribe()
  }

  withDelay = (startAt: string, activity: () => void) => {
    const delayMillis = DateTime.fromISO(startAt).toMillis() - Date.now()

    timer(delayMillis).subscribe(() => {
      activity()
    })
  }

  public periodicStatusEffectsGroupTick(groupTick: AbilityPeriodicStatusEffectsGroupTickFragment) {
    if (!this.#alive) return

    const {
      changes,
      character: { stats },
    } = groupTick

    this.stats = { ...this.stats, ...stats }

    if (changes.health && changes.health < 0) {
      this.effectChanges.next({ health: changes.health })
    }
  }

  public applyInstantEffect(effect: AbilityInstantEffectFragment) {
    if (!this.#alive) return

    if (!isNull(effect.changes.twilightLevel)) {
      this.twilightLevel = effect.character.twilightLevel as TwilightLevel
    }
    if (!isNull(effect.changes.position)) {
      this.position = effect.character.position
    }

    this.stats = { ...this.stats, ...effect.character.stats }

    if (effect.changes.health) {
      this.effectChanges.next({ health: effect.changes.health })
    }
  }

  public applyLastingEffect(effect: AbilityLastingStatusEffectsGroupFragment) {
    if (!this.#alive) return

    this.statusEffectsGroups.add(effect)
  }

  public addPeriodicEffect(effect: AbilityPeriodicStatusEffectsGroupFragment) {
    if (!this.#alive) return

    this.statusEffectsGroups.add(effect)
  }
}
