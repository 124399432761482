import { filter, map, startWith, take, throttleTime, timer } from 'rxjs'
import type { Observable, Subscription } from 'rxjs'
import { Marker } from 'maplibre-gl'
import { createRoot } from 'react-dom/client'
import type { Point, Race, Side } from '~shared-types'
import { ZoneMap } from '../../views/zone/model/ZoneMap'
import { arePointsEqual, spatialDistance } from '~map-tools'
import type { ZoneCharacter } from '../../views/zone/model/ZoneCharacter'
import { MapMarker, MarkerIconType } from '../map/MapMarker'
import { client } from '../../apolloClient'
import { RouteWithinZoneDocument } from '../../generated/graphql'
import type { StoryTagMarker, StoryTagTarget } from './StoryTags'
import { CharacterTarget } from './components/CharacterTarget'
import { t } from '~utils/i18n'
import { CityMap } from '../../views/fullscreens/City/model/CityMap'
import { MinimapCharacterMarker } from '~components/map/Minimap/MinimapCharacterMarker'

export class QuestGameElements {
  private currentStepSubscriptions: Subscription[]
  private currentStepMarkers: Marker[]
  private currentStepMovingToTarget: Point | null

  constructor() {
    this.currentStepSubscriptions = []
    this.currentStepMarkers = []
    this.currentStepMovingToTarget = null
  }

  public removeAllElements() {
    this.currentStepSubscriptions.forEach((subscription) => subscription.unsubscribe())
    this.currentStepSubscriptions = []
    const markersToDelete = [...this.currentStepMarkers]
    // To avoid blinkering markers, when they are recreated between steps, remove old one after delay
    timer(500).subscribe(() => markersToDelete.forEach((marker) => marker.remove()))
    this.currentStepMarkers = []
    this.currentStepMovingToTarget = null
  }

  public addSubscription(subscription: Subscription) {
    this.currentStepSubscriptions.push(subscription)
  }

  public addMarker(marker: Marker) {
    this.currentStepMarkers.push(marker)
  }

  withMarker(marker: StoryTagMarker) {
    this.createMarker({ marker, type: 'marker' })
  }

  withTarget(target: StoryTagTarget) {
    this.createMarker({ marker: target, type: 'target' })
  }

  private createMarker(
    props: { marker: StoryTagMarker; type: 'marker' } | { marker: StoryTagTarget; type: 'target' },
  ): void {
    const { marker: storyMarker } = props

    const onTargetClick = async () => {
      this.currentStepMovingToTarget = props.marker.position
      await client.mutate({
        mutation: RouteWithinZoneDocument,
        variables: {
          destination: props.marker.position,
        },
      })
    }
    const targetMarkerProps =
      props.type === 'target'
        ? {
            actionLabel: props.marker.actionLabel ?? t('button:go'),
            onClick: onTargetClick,
          }
        : {}

    const div = document.createElement('div')
    const root = createRoot(div)
    root.render(
      storyMarker.avatar?.headShot ? (
        <CharacterTarget
          character={{
            ...storyMarker,
            id: Math.random().toString(),
            firstName: storyMarker.name ?? '',
            lastName: '',
            race: storyMarker.race ?? 'MAGICIAN',
            side: storyMarker.side ?? 'NEUTRAL',
            alive: true,
            stats: {
              health: storyMarker.stats?.health ?? 300,
              healthMax: storyMarker.stats?.healthMax ?? storyMarker.stats?.health ?? 300,
              energy: storyMarker.stats?.energy ?? 300,
              energyMax: storyMarker.stats?.energyMax ?? storyMarker.stats?.energy ?? 300,
              shield: 0,
            },
            twilightLevel: 0,
            avatar: {
              headShot: storyMarker.avatar.headShot,
              headShot2x: storyMarker.avatar.headShot2x ?? storyMarker.avatar.headShot,
            },
          }}
          {...targetMarkerProps}
        />
      ) : (
        <MapMarker
          iconType={storyMarker.icon || MarkerIconType.QUEST}
          tooltip={{
            title: storyMarker.name ?? '',
            address: storyMarker.address,
            description: storyMarker.description,
            ...targetMarkerProps,
          }}
        />
      ),
    )
    const marker = new Marker({
      element: div,
      className: 'maplibregl-marker-character',
    })

    marker.setLngLat(storyMarker.position)
    ZoneMap.getInstance().layerManager.addMarker(marker)
    this.addMarker(marker)

    const minimapMarker = this.getMinimapMarker(storyMarker)
    minimapMarker.setLngLat(storyMarker.position)
    ZoneMap.getInstance().minimap.layerManager.addMarker(minimapMarker)
    this.addMarker(minimapMarker)

    if (storyMarker.city) {
      CityMap.getInstanceObservable().subscribe((cityMap) => {
        const cityMarker = this.getCityMarker(storyMarker)
        cityMarker.setLngLat(storyMarker.position)
        cityMap.layerManager.addMarker(cityMarker)
        this.addMarker(cityMarker)

        cityMarker.getElement().addEventListener('click', (e) => {
          e.stopPropagation()
        })
      })
    }
  }

  getCityMarker(storyMarker: StoryTagMarker | StoryTagTarget): Marker {
    const div = document.createElement('div')
    const root = createRoot(div)
    root.render(
      <MapMarker
        iconType={storyMarker.icon || MarkerIconType.QUEST}
        size={36}
        tooltip={{
          title: storyMarker.name ?? '',
          address: storyMarker.address,
          description: storyMarker.description,
        }}
      />,
    )
    return new Marker({
      element: div,
      className: 'maplibregl-marker-character',
    })
  }

  getMinimapMarker(storyMarker: StoryTagMarker | StoryTagTarget): Marker {
    const div = document.createElement('div')
    const root = createRoot(div)
    root.render(
      storyMarker.avatar?.headShot ? (
        <MinimapCharacterMarker side={storyMarker.side} avatar={storyMarker.avatar} />
      ) : (
        <MapMarker iconType={storyMarker.icon || MarkerIconType.QUEST} size={24} borderSize={1} />
      ),
    )
    return new Marker({
      element: div,
      className: 'maplibregl-minimap-marker-character',
    })
  }

  whenReachPosition(
    requirements: { position: Point; range?: number; isChoice?: boolean },
    onComplete: () => void,
  ) {
    this.addSubscription(
      this.currentCharacter.subscribe((character) => {
        this.addSubscription(
          character.positionSubject
            .pipe(
              startWith(character.position),
              throttleTime(300, undefined, { leading: true, trailing: true }),
              filter(() =>
                requirements.isChoice ? this.currentStepMovingToTarget !== null : true,
              ),
              filter((position) =>
                requirements.range
                  ? spatialDistance(position, requirements.position) <= requirements.range
                  : arePointsEqual(position, requirements.position),
              ),
              take(1),
            )
            .subscribe(() => {
              onComplete()
            }),
        )
      }),
    )
  }

  whenAttribute(requirements: { sideOneOf?: Side[]; raceOneOf?: Race[] }, onComplete: () => void) {
    const { sideOneOf, raceOneOf } = requirements
    this.addSubscription(
      this.currentCharacter.subscribe((character) => {
        if (sideOneOf) {
          this.addSubscription(
            character.sideSubject
              .pipe(
                startWith(character.side),
                filter((side) => sideOneOf.includes(side)),
                take(1),
              )
              .subscribe(() => {
                onComplete()
              }),
          )
        }
        if (raceOneOf) {
          this.addSubscription(
            character.raceSubject
              .pipe(
                startWith(character.race),
                filter((race) => raceOneOf.includes(race)),
                take(1),
              )
              .subscribe(() => {
                onComplete()
              }),
          )
        }
      }),
    )
  }

  private get currentCharacter(): Observable<ZoneCharacter> {
    return ZoneMap.getInstance().charactersChangeSubject.pipe(
      filter((value) => value.action === 'added'),
      map((value) => value.character),
      startWith(
        Array.from(ZoneMap.getInstance().characters.values()).find(
          (character) => character.isCurrentUser,
        ),
      ),
      filter((character): character is ZoneCharacter => character?.isCurrentUser === true),
      take(1),
    )
  }
}
