import styled from '@emotion/styled'
import type { FC, PropsWithChildren } from 'react'
import type { Side } from '~shared-types'

type Props = {
  side: Side
  className?: string
}

export const MatchNotificationUI: FC<PropsWithChildren<Props>> = ({
  className,
  side,
  children,
}) => (
  <Container side={side} className={className}>
    {children}
  </Container>
)

const Container = styled.div<{ side: Side }>`
  position: fixed;
  left: 50%;
  top: 200px;
  width: 450px;
  min-height: 60px;
  text-align: center;
  transform: translateX(-50%);
  padding: 22px 20px 18px;
  color: #fff;
  font-size: 20px;
  font-weight: 500;
  background: linear-gradient(
    to bottom,
    #131927 0%,
    ${({ side }) => (side === 'DARKNESS' ? '#342530a8' : '#172E49a8')} 100%
  );
  border-radius: 12px;
  box-shadow: 0 12px 48px rgba(8, 13, 19, 0.5);
  z-index: 5;
  pointer-events: none;
`
