
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Ability": [
      "AbilityDirectionTargeted",
      "AbilityLocationTargeted",
      "AbilityUnitTargeted"
    ],
    "AbilityDirectionShapeUnion": [
      "AbilityDirectionShapeArc",
      "AbilityDirectionShapeLine"
    ],
    "AbilityLocationShapeUnion": [
      "AbilityLocationShapeCircle",
      "AbilityLocationShapeRectangle"
    ],
    "AbilityUnion": [
      "AbilityDirectionTargeted",
      "AbilityLocationTargeted",
      "AbilityUnitTargeted"
    ],
    "ChatRoom": [
      "GroupChatRoom",
      "PrivateChatRoom",
      "ZoneChatRoom"
    ],
    "EmailConfirmationFinished": [
      "EmailConfirmationFailure",
      "EmailConfirmationSuccess"
    ],
    "LearnMasteryResponse": [
      "LearnMasteriesAlternativeResponse",
      "LearnedMasteries"
    ],
    "Match": [
      "OpenMatch"
    ],
    "PasswordResetFinished": [
      "PasswordResetFailure",
      "PasswordResetSuccess"
    ],
    "StatusEffect": [
      "StatusEffectLasting",
      "StatusEffectLastingContinuous",
      "StatusEffectPeriodic",
      "StatusEffectPeriodicContinuous"
    ]
  }
};
      export default result;
    