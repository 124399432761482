import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Observable } from 'rxjs'
import type { CastAction } from '../../../views/match/model/SpellCast'
import { useValueFromObservable } from '../../quest/useValueFromObservable'
import { RhombusItem } from '../../RhombusItem'
import { LevelShield } from '../../LevelShield'
import { CharacterTwilightRelation } from '~map-tools'

type Props = {
  level: number
  twilightRelation: CharacterTwilightRelation
  spellCastObservable: Observable<CastAction | null>
  areActionsVisibleObservable: Observable<boolean>
}

export const ShieldOrAbilityIcon: FC<Props> = ({
  spellCastObservable,
  level,
  twilightRelation,
  areActionsVisibleObservable,
}) => {
  const areActionsVisible = useValueFromObservable(areActionsVisibleObservable)
  const spellCast = useValueFromObservable(spellCastObservable)
  const shouldShowSpellCast = spellCast && areActionsVisible

  return (
    <>
      {!shouldShowSpellCast && twilightRelation !== CharacterTwilightRelation.NOT_VISIBLE ? (
        <StyledLevelShield>{level}</StyledLevelShield>
      ) : null}

      {shouldShowSpellCast && spellCast ? (
        <CastingAbilityIcon size={24} icon={spellCast.ability.icon} />
      ) : null}
    </>
  )
}

const CastingAbilityIcon = styled(RhombusItem)`
  position: absolute;
  z-index: 2;
  bottom: -5px;
`

const StyledLevelShield = styled(LevelShield)`
  position: absolute;
  z-index: 2;
  bottom: -3px;
  margin-left: 1px;
`
