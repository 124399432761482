import type { CreateUserMutationVariables } from '~generated/graphql'
import { useCreateUserMutation, useLoginMutation } from '~generated/graphql'
import { useNavigate } from 'react-router-dom'

export const useSignup = () => {
  const navigate = useNavigate()
  const [login] = useLoginMutation({
    refetchQueries: ['Session'],
    onCompleted: () => {
      navigate('/create-character')
    },
  })
  const [createUser, query] = useCreateUserMutation({
    onError: (error) => {
      console.error(error)
    },
  })

  const signupMutation = async (data: CreateUserMutationVariables) => {
    const result = await createUser({ variables: data })
    if (!result.errors && result.data?.createUser?.id) {
      await login({
        variables: {
          data,
        },
      })
    }
  }

  return [signupMutation, query] as [typeof signupMutation, typeof query]
}
