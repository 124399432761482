import 'reflect-metadata'
import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Second } from '~shared-types'
import {
  commonAnimationSpriteUrlMap,
  animationIterationCountMap,
  animationSpriteCountMap,
  animationSizeMap,
  animationTransformMap,
} from '~utils/animation'

type Props = {
  id: string
}

export const AbilityPortalAnimationUI: FC<Props> = ({ id }) => {
  const sprite = commonAnimationSpriteUrlMap.PORTAL

  return (
    <Container size={animationSizeMap.PORTAL}>
      <AnimationContainer
        count={animationSpriteCountMap.PORTAL}
        size={animationSizeMap.PORTAL}
        spriteUrl={sprite}
        id={id}
        iterationCount={animationIterationCountMap.PORTAL}
        transform={animationTransformMap.PORTAL}
      />
    </Container>
  )
}

const Container = styled.div<{
  size?: number
}>`
  width: ${({ size = 0 }) => size}px;
  height: ${({ size = 0 }) => size}px;
  position: relative;
  pointer-events: none;
`

const AnimationContainer = styled.div<{
  count: number
  spriteUrl?: string
  id: string
  size?: number
  delay?: Second
  iterationCount: 'infinite' | number
  transform?: string
}>`
  background: url(${({ spriteUrl }) => spriteUrl || ''}) no-repeat;
  animation-name: ${({ id }) => `ability-animation-${id}, ability-animation-${id}`};
  animation-duration: ${20 / 24}s;
  animation-timing-function: ${({ count }) => `steps(${count - 1})`};
  animation-iteration-count: ${({ iterationCount }) => iterationCount};
  animation-fill-mode: forwards;
  animation-delay: 0s, ${20 / 24 + 15 / 24}s;
  animation-direction: normal, reverse;
  background-position-x: 0;
  background-size: ${({ count }) => count * 100}%;
  background-position-y: center;
  background-repeat: no-repeat;
  will-change: background-position-x;
  width: 100%;
  height: 100%;
  transition: opacity 0.1s;
  ${({ transform }) => transform && `transform: ${transform};`}

  @keyframes ${({ id }) => `ability-animation-${id}`} {
    0% {
      background-position-x: 0;
    }

    100% {
      background-position-x: -${({ size = 0, count }) => size * (count - 1)}px;
    }
  }

  @keyframes ${({ id }) => `ability-animation-reverse-${id}`} {
    0% {
      background-position-x: -${({ size = 0, count }) => size * count}px;
    }

    100% {
      background-position-x: 0;
    }
  }
`
