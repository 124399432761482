import { keyframes, css } from '@emotion/react'
import styled from '@emotion/styled'
import type { Second } from '~shared-types'

const timeCooldownMinutesAnimation = (cooldown: Second, animationName: string) => keyframes`
  from {
    --${animationName}CooldownMinutes: 0;
  }
  to {
    --${animationName}CooldownMinutes: ${Math.floor(cooldown / 60)};
  }
`

const timeCooldownSecondsAnimation = (animationName: string) => keyframes`
  from {
    --${animationName}CooldownSeconds: 0;
  }
  to {
    --${animationName}CooldownSeconds: 60;
  }
`

const timeCooldownAnimation = (cooldown: Second, animationName: string) => css`
  animation-name: ${timeCooldownMinutesAnimation(cooldown, animationName)},
    ${timeCooldownSecondsAnimation(animationName)};
  animation-duration: ${Math.floor(cooldown / 60) * 60}s, 60s;
  animation-iteration-count: 1, ${Math.ceil(cooldown / 60)};
  animation-direction: reverse;
  animation-timing-function: steps(${Math.max(1, Math.floor(cooldown / 60))}, end), steps(60, end);
  animation-delay: ${cooldown % 60}s, ${cooldown % 60 === 0 ? 0 : -60 + (cooldown % 60)}s;
  animation-fill-mode: forwards;
`

export const AnimatedTimeCooldown = styled.div<{ cooldown: Second; animationName: string }>`
  position: relative;

  @property ${({ animationName }) => `--${animationName}CooldownMinutes`} {
    syntax: '<integer>';
    initial-value: ${({ cooldown }) => Math.floor(cooldown / 60)};
    inherits: false;
  }

  @property ${({ animationName }) => `--${animationName}CooldownSeconds`} {
    syntax: '<integer>';
    initial-value: ${({ cooldown }) => cooldown % 60};
    inherits: false;
  }

  &::after {
    ${({ animationName, cooldown }) => timeCooldownAnimation(cooldown, animationName)};
    ${({ animationName }) => `
      counter-reset: ${animationName}CooldownMinutes var(--${animationName}CooldownMinutes) ${animationName}CooldownSeconds var(--${animationName}CooldownSeconds);
      content: counter(${animationName}CooldownMinutes) ':' counter(${animationName}CooldownSeconds, decimal-leading-zero);
    `}
  }
`
