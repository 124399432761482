import { forwardRef, useCallback } from 'react'
import { Duration } from 'luxon'
import styled from '@emotion/styled'
import { Button } from '~components/Button'
import { t } from '~utils/i18n'
import { MapPopover } from '~components/MapPopover'

type Props = {
  title?: string
  waitingTime: string
  distanceMeters: number
  onClick?: () => void
  onClose?: () => void
  style?: React.CSSProperties
}

type Ref = HTMLDivElement

export const ZoneDescriptionPopover = forwardRef<Ref, Props>(
  ({ title, waitingTime, distanceMeters, onClick, onClose, style }, ref) => {
    const distanceFormat = useCallback((meters: number) => {
      if (meters < 1000) {
        return `${meters} ${t('common:units.m')}`
      }

      return `${(meters / 1000).toFixed(1)} ${t('common:units.km')}`
    }, [])

    return (
      <StyledMapPopover
        ref={ref}
        style={style}
        title={title}
        stats={[
          {
            label: t('popover:cityMapRoute.waitingTimeLabel') || '',
            value: `${Math.round(Duration.fromISO(waitingTime).as('seconds'))} ${t(
              'common:units.seconds',
            )}`,
          },
          {
            label: t('popover:cityMapRoute.distanceLabel') || '',
            value: distanceFormat(distanceMeters),
          },
        ]}
        onClose={onClose}
        buttons={[
          <Button key="orderTaxi" onClick={onClick}>
            {t('button:orderTaxi')}
          </Button>,
        ]}
      />
    )
  },
)

const StyledMapPopover = styled(MapPopover)`
  width: 260px;
`
