/* eslint-disable @typescript-eslint/no-unused-vars */
import type { Map } from 'maplibre-gl'
import type { Point } from '~shared-types'
import type { CharacterEntity } from '../CharacterEntity'
import type {
  EffectAnimationType,
  MatchCharacterAnimatedAbilityFragment,
} from '~generated/match-graphql'
import type { AnimationType } from '~generated/graphql'
import { AttackRangedAnimation } from './AttackRangedAnimation'
import { SingleUnitAnimation } from './SingleUnitAnimation'
import { ChannelAnimation } from './ChannelAnimation'
import { AttackMeleeAnimation } from './AttackMeleeAnimation'
import { AttackLocationAnimation } from './AttackLocationAnimation'
import { DirectionAreaAnimation } from './DirectionAreaAnimation'
import { LocationAreaAnimation } from './LocationAreaAnimation'
import { AttackDirectionAnimation } from './AttackDirectionAnimation'
import { PortalAnimation } from './PortalAnimation'

export type MatchCharacterAbilityWithAnimation = MatchCharacterAnimatedAbilityFragment & {
  animationType: AnimationType | EffectAnimationType
}

export class CastedAbilityAnimation {
  constructor(
    map: Map,
    caster: CharacterEntity,
    target: CharacterEntity | Point,
    castedAbility: MatchCharacterAnimatedAbilityFragment,
  ) {
    if (!this.isAbilityWithAnimationType(castedAbility)) {
      return
    }

    // unit animation
    if (castedAbility.targetType === 'UNIT' && 'firstName' in target) {
      const singleUnitAnimationTypes: (AnimationType | EffectAnimationType)[] = [
        'DEBUFF',
        'HEAL',
        'BUFF',
      ]
      if (singleUnitAnimationTypes.includes(castedAbility.animationType)) {
        const singleUnitAnimation = new SingleUnitAnimation(
          map,
          caster,
          target,
          castedAbility.animationType,
        )
        return
      }

      if (castedAbility.animationType === 'ATTACK_MELEE') {
        const singleUnitAnimation = new AttackMeleeAnimation(map, caster, target)
        return
      }

      if (castedAbility.animationType === 'ATTACK_RANGED') {
        const attackRangedAnimation = new AttackRangedAnimation(map, caster, target)
        return
      }

      const selfTarget = caster.id === target.id
      if (castedAbility.animationType === 'CHANNEL' && !selfTarget) {
        const channelAnimation = new ChannelAnimation(
          map,
          caster,
          target,
          castedAbility.channelTime || undefined,
        )
        return
      }
    }

    if (castedAbility.targetType === 'LOCATION') {
      if (castedAbility.animationType === 'PORTAL' && !('firstName' in target)) {
        const portalAnimation = new PortalAnimation(map, caster, caster.position)
        return
      }

      // background area animation
      const locationAreaAnimation = new LocationAreaAnimation(map, caster, target, castedAbility)

      if (castedAbility.animationType === 'ATTACK_LOCATION') {
        // attack animation
        const attackLocationAnimation = new AttackLocationAnimation(
          map,
          caster,
          target,
          castedAbility,
        )
      }

      return
    }

    if (castedAbility.targetType === 'DIRECTION') {
      // background area animation
      const directionAreaAnimation = new DirectionAreaAnimation(map, caster, target, castedAbility)

      if (castedAbility.animationType === 'ATTACK_DIRECTION') {
        // attack animation
        const attackDirectionAnimation = new AttackDirectionAnimation(
          map,
          caster,
          target,
          castedAbility,
        )
      }
    }
  }

  private isAbilityWithAnimationType(
    castedAbility: MatchCharacterAnimatedAbilityFragment,
  ): castedAbility is MatchCharacterAbilityWithAnimation {
    return (castedAbility as MatchCharacterAbilityWithAnimation).animationType !== undefined
  }
}
