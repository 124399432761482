import styled from '@emotion/styled'
import type { FC } from 'react'
import IconSlider from '~assets/icon-masteries-slider.svg'
import { ReactComponent as IconPlus } from '~assets/icon-plus.svg'
import { ReactComponent as IconMinus } from '~assets/icon-minus.svg'

type Props = {
  direction?: 'left' | 'right'
  position?: string
  isIncreaseDisabled?: boolean
  isDecreaseDisabled?: boolean
  onIncrease: () => void
  onDecrease: () => void
  value?: number
}

export const PointsSlider: FC<Props> = ({
  direction = 'right',
  isIncreaseDisabled,
  isDecreaseDisabled,
  value,
  onDecrease,
  onIncrease,
  position,
}) => (
  <Slider direction={direction} position={position}>
    <ButtonAdd
      disabled={isIncreaseDisabled}
      onClick={() => {
        if (isIncreaseDisabled) {
          return
        }

        onIncrease()
      }}
    >
      <IconPlus />
    </ButtonAdd>
    <SliderValue direction={direction}>{value || 0}</SliderValue>
    <ButtonRemove
      disabled={isDecreaseDisabled}
      onClick={() => {
        if (isDecreaseDisabled) {
          return
        }

        onDecrease()
      }}
    >
      <IconMinus />
    </ButtonRemove>
  </Slider>
)

const SliderValue = styled.div<{ direction: 'left' | 'right' }>`
  width: 45px;
  height: 24px;
  line-height: 29px;
  display: grid;
  place-items: center;
  color: #ada08a;
  font-size: 14px;
  font-weight: 700;

  ${({ direction }) =>
    direction === 'left' ? `transform: scaleX(-1); padding-right: 8px;` : 'padding-left: 8px;'}
`

const Slider = styled.div<{ direction: 'left' | 'right'; position?: string }>`
  position: absolute;
  width: 45px;
  height: 72px;
  z-index: 2;
  transition: bottom 0.3s ease-in;
  background: url(${IconSlider}) right calc(50% + 2px) no-repeat;
  bottom: ${({ position }) => position || 0};
  margin-bottom: -35px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  ${({ direction }) =>
    direction === 'left' ? `left: -10px; transform: scaleX(-1);` : `right: -10px;`}
`

const SignButton = styled.div<{ disabled?: boolean }>`
  position: relative;
  width: 37px;
  height: 24px;
  ${({ disabled }) => (disabled ? 'cursor: auto;' : 'cursor: pointer;')}
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ disabled }) => (disabled ? 'cursor: auto;' : 'cursor: pointer;')}
`

const ButtonAdd = styled(SignButton)`
  svg {
    fill: ${({ disabled }) => (disabled ? '#3C4860' : 'url(#icon-plus-fill-gradient)')};
  }
`

const ButtonRemove = styled(SignButton)`
  svg {
    fill: ${({ disabled }) => (disabled ? '#3C4860' : 'url(#icon-minus-fill-gradient)')};
  }
`
