import { useEffect } from 'react'
import {
  usePrivateMessageReceivedSubscription,
  useZoneMessageReceivedSubscription,
} from '~generated/graphql'
import { useChatStore, useStore } from '~store'

export const useZoneChatSubscription = ({
  scrollToBottom,
}: {
  scrollToBottom?: (skipIfScrolled?: boolean) => void
}) => {
  const [session] = useStore((state) => [state.session])

  const [addGeneralMessage] = useChatStore((state) => [state.addGeneralMessage])

  const { data: zoneMessageData } = useZoneMessageReceivedSubscription({
    skip: !session?.character?.zoneId,
    variables: {
      zoneId: session?.character?.zoneId || 0,
    },
    onData: (data) => {
      if (!data.data?.data?.zoneMessageReceived) return
      addGeneralMessage(data.data.data.zoneMessageReceived)
    },
  })

  const { data: privateMessageData } = usePrivateMessageReceivedSubscription({
    onData: (data) => {
      if (!data.data?.data?.privateMessageReceived) return
      addGeneralMessage(data.data.data.privateMessageReceived)
    },
  })

  useEffect(() => {
    const isCurrentCharacterMessage =
      zoneMessageData?.zoneMessageReceived.characterId === session?.characterId
    scrollToBottom?.(!isCurrentCharacterMessage)
  }, [zoneMessageData])

  useEffect(() => {
    scrollToBottom?.(true)
  }, [privateMessageData])
}
