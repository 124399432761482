import styled from '@emotion/styled'
import { useMemo } from 'react'
import type { FC } from 'react'
import { distinctUntilChanged } from 'rxjs'
import type { Observable } from 'rxjs'
import { ReactComponent as IconPhysicalPower } from '~assets/icon-phys-power.svg'
import { ReactComponent as IconPhysicalResistance } from '~assets/icon-phys-resistance.svg'
import { ReactComponent as IconMovementSpeed } from '~assets/icon-movement-speed.svg'
import { ReactComponent as IconMagicPower } from '~assets/icon-magic-power.svg'
import { ReactComponent as IconMagicResistance } from '~assets/icon-magic-resistance.svg'
import { ReactComponent as IconCastingSpeed } from '~assets/icon-casting-speed.svg'
import { useValueFromObservable } from '../quest/useValueFromObservable'
import withTooltip from '~components/Tooltip/withTooltip'
import { t } from '~utils/i18n'

type Target = 'self' | 'target'

export type AdvancedStats = {
  physicalPower: number
  physicalResistance: number
  movementSpeed: number
  magicPower: number
  magicResistance: number
  castingSpeed: number
}

type Props = {
  statsObservable: Observable<AdvancedStats>
  target: Target
}

const statsKeys: (keyof AdvancedStats)[] = [
  'physicalPower',
  'physicalResistance',
  'castingSpeed',
  'magicPower',
  'magicResistance',
  'movementSpeed',
]
const iconMap = {
  physicalPower: <IconPhysicalPower />,
  physicalResistance: <IconPhysicalResistance />,
  castingSpeed: <IconCastingSpeed />,
  magicPower: <IconMagicPower />,
  magicResistance: <IconMagicResistance />,
  movementSpeed: <IconMovementSpeed />,
}

export const CharacterAdvancedStats: FC<Props> = ({ statsObservable, target }) => {
  const advancedStatsObservable = useMemo(
    () =>
      statsObservable.pipe(
        distinctUntilChanged(
          (prev, cur) =>
            !(
              prev.physicalPower !== cur.physicalPower ||
              prev.magicPower !== cur.magicPower ||
              prev.physicalResistance !== cur.physicalResistance ||
              prev.magicResistance !== cur.magicResistance ||
              prev.castingSpeed !== cur.castingSpeed ||
              prev.movementSpeed !== cur.movementSpeed
            ),
        ),
      ),
    [statsObservable],
  )

  const stats = useValueFromObservable(advancedStatsObservable)

  return (
    <Container target={target}>
      {statsKeys.map((key) => {
        const StatsItemWithTooltip = withTooltip(
          StatsItem,
          {
            type: 'text',
            content: t(`tooltip:stats.${key}`),
          },
          {
            placement: target === 'self' ? 'right' : 'left',
          },
        )
        return (
          <StatsItemWithTooltip key={key}>
            <IconContainer>{iconMap[key]}</IconContainer> {stats[key]}
          </StatsItemWithTooltip>
        )
      })}
    </Container>
  )
}

const Container = styled.div<{ target: Target }>`
  color: #646d85;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 400;
  position: relative;
  padding: 9px;
  font-size: 14px;
  padding-right: 13px;
  background: linear-gradient(178deg, #131927 1.94%, rgba(42, 48, 65, 0.5) 97.88%);
  box-shadow: (0px 12px 48px rgba(8, 13, 19, 0.5));
  column-count: 2;
  column-gap: 20px;
  pointer-events: auto;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    ${({ target }) =>
      target === 'target' ? `right: 100%; transform: scale(-1,1);` : `left: 100%;`}
    width: 13px;
    height: 100%;
    z-index: 1;
    background: linear-gradient(178deg, #131927 1.94%, rgba(42, 48, 65, 0.5) 97.88%);
    clip-path: polygon(0% 0%, 100% 0%, 7.692% 100%, 0% 100%, 0% 0%);
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    ${({ target }) =>
      target === 'target' ? `right: 100%; transform: scale(-1,1);` : `left: 100%;`}
    width: 16px;
    height: 100%;
    z-index: 2;
    clip-path: polygon(100% 0%, 75% 0%, 0% 100%, 25% 100%, 100% 0%);
    background: linear-gradient(191deg, #d9d1bb 0.47%, #808080 40.08%, #201e28 84.02%),
      linear-gradient(171deg, #131927 11.89%, rgba(42, 48, 65, 0.5) 98.39%);
  }

  ${({ target }) =>
    target === 'target'
      ? `
order: 1;
margin-right: 15px;
`
      : `
order: 3;
margin-left: -3px;
`}
`

const StatsItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  &:nth-of-type(3n) {
    margin-bottom: 0;
  }
`

const IconContainer = styled.div`
  width: 16px;
  margin-right: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`
