import { DateTime, Duration } from 'luxon'
import { timer } from 'rxjs'
import type {
  AbilityChannelActionFragment,
  EnRouteActionFragment,
  MatchActionFragment as MatchAction,
  AbilityCastActionFragment,
} from '~generated/match-graphql'
import type { Int, UUID } from '~shared-types'
import { MatchMap } from './MatchMap'

type CharacterId = string

export class MapCharacterActionsManager {
  protected static instance: MapCharacterActionsManager | undefined
  private characterActions: Map<CharacterId, MatchAction[]> = new Map([])
  private actions: Map<Int, MatchAction> = new Map()

  public static getInstance(): MapCharacterActionsManager {
    if (!MapCharacterActionsManager.instance) {
      MapCharacterActionsManager.instance = new MapCharacterActionsManager()
    }

    return MapCharacterActionsManager.instance
  }

  public static remove() {
    delete MapCharacterActionsManager.instance
  }

  public setCharacterActions(characterId: string, actions: MatchAction[]) {
    const oldActions = this.getCharacterActions(characterId)
    new Set([...oldActions, ...actions]).forEach((action) => {
      if (!actions.includes(action)) {
        this.actions.delete(action.id)
      }
      if (!oldActions.includes(action)) {
        this.actions.set(action.id, action)
      }
    })
    return this.characterActions.set(characterId, actions)
  }

  public getCharacterActions(characterId: string) {
    return this.characterActions.get(characterId) ?? []
  }

  public getAction(actionId: Int, characterId: UUID) {
    return (this.characterActions.get(characterId) ?? []).find((action) => action.id === actionId)
  }

  public getActionById(actionId: Int): MatchAction | undefined {
    return this.actions.get(actionId)
  }

  withDelay = (startAt: string, characterId: string, actionId: number, activity: () => void) => {
    const delayMillis = DateTime.fromISO(startAt).toMillis() - Date.now()

    timer(delayMillis).subscribe(() => {
      const characterActions = this.getCharacterActions(characterId)
      if (characterActions.findIndex(({ id }) => id === actionId) !== -1) {
        activity()
      }
    })
  }

  public addAction(
    action: EnRouteActionFragment | AbilityCastActionFragment | AbilityChannelActionFragment,
  ) {
    const { character, __typename, interruptedActionIds, startAt, duration } = action

    const actions = this.getCharacterActions(character.id)
    actions.push(action)

    const map = MatchMap.getInstance()
    const mapCharacter = map.characters.get(character.id)

    // cancel interrupted actions
    if (interruptedActionIds.length) {
      interruptedActionIds.forEach((interruptedActionId) => {
        this.cancelAction(interruptedActionId, character.id)
      })
    }

    // if route action, animate character along route
    if (__typename === 'EnRouteAction') {
      const { route } = action
      if (route) {
        const routeInfo = {
          ...route,
          startAt: DateTime.fromISO(startAt).toMillis(),
          duration: Duration.fromISO(duration).toMillis(),
        }

        this.withDelay(startAt, character.id, action.id, () => {
          mapCharacter?.routeManager.addRoute(routeInfo)
        })
      }
    }

    // if spell cast action, show cast progress
    if (__typename === 'AbilityCastAction' || __typename === 'AbilityChannelAction') {
      this.withDelay(startAt, character.id, action.id, () => {
        mapCharacter?.spellCast(action)
      })
    }
    this.actions.set(action.id, action)
    return this.characterActions.set(character.id, actions)
  }

  public cancelAction(actionId: number, characterId: string) {
    const actions = this.getCharacterActions(characterId)
    const actionIndex = actions.findIndex((action) => action.id === actionId)

    if (actionIndex === -1) return

    const [canceledAction] = actions.splice(actionIndex, 1)

    const map = MatchMap.getInstance()
    const mapCharacter = map.characters.get(characterId)
    this.setCharacterActions(characterId, actions)

    if (canceledAction.__typename === 'EnRouteAction') {
      mapCharacter?.routeManager.removeRoute()
    }
    if (
      canceledAction.__typename === 'AbilityCastAction' ||
      canceledAction.__typename === 'AbilityChannelAction'
    ) {
      mapCharacter?.cancelSpellCast()
    }
  }
}
