import type { EffectAnimationType, Race, Side, AnimationType } from '~generated/match-graphql'
import type { Second } from '~shared-types'

type AdditionalAnimationType =
  | 'ATTACK_RANGED_TARGET_VAR1'
  | 'ATTACK_RANGED_TARGET_VAR2'
  | 'ATTACK_RANGED_SOURCE'
  | 'ATTACK_LOCATION_AREA'
  | 'ATTACK_MELEE_SPLASH'
  | 'CHANNEL_SOURCE'

export type AbilityAnimationType = AnimationType | EffectAnimationType | AdditionalAnimationType

export const animationSpriteUrlMap: Partial<
  Record<Side, Partial<Record<Race, Partial<Record<AbilityAnimationType, string>>>>>
> = {
  DARKNESS: {
    CLAIRVOYANT: {
      // Main animations
      BUFF: 'https://images.dozormoskva.com/QUppDP8qX94b8IVzasg1hCZ5bRWCfX7wn7lb4lZEGfg/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYnVmZi9idWZmLWRhcmtuZXNzLWNsYWlydm95YW50LnBuZw',
      DEBUFF:
        'https://images.dozormoskva.com/POg80ctZjvXVX__mUyK_ylbTOdhW5dBc6PvHQt8j2rA/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvZGVidWZmL2RlYnVmZi1kYXJrbmVzcy1jbGFpcnZveWFudC5wbmc',
      HEAL: 'https://images.dozormoskva.com/Mus45CndTRe9mDJ0ugVzuIapTWd_gpsDjupnkbCoEgo/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvaGVhbC9oZWFsLWRhcmtuZXNzLWNsYWlydm95YW50LnBuZw',
      CHANNEL:
        'https://images.dozormoskva.com/2l3VoahGOlfPdCafyNpgxKHEtvKRF4slbXhBrqWPznc/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLWRhcmtuZXNzLWNsYWlydm95YW50LnBuZw',
      ATTACK_RANGED:
        'https://images.dozormoskva.com/Ip1QFD-4qLpZBekqbN7QpYhCUR4cmAUo-6hDAVXfWyo/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLWRhcmtuZXNzLWNsYWlydm95YW50LnBuZw',
      ATTACK_LOCATION:
        'https://images.dozormoskva.com/YW_8OegUpP8f8GJb79KjaMp6lZIQYdKNCsErLzKeLh8/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1kYXJrbmVzcy1jbGFpcnZveWFudC5wbmc',
      ATTACK_UNIT_LOCATION:
        'https://images.dozormoskva.com/yHSV8KG4qG26FWXvfcHAgukV1a_n6VodoSpKsuYKIpY/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXVuaXQtbG9jYXRpb24vYXR0YWNrLXVuaXQtbG9jYXRpb24tZGFya25lc3MtY2xhaXJ2b3lhbnQucG5n',
      ATTACK_DIRECTION:
        'https://images.dozormoskva.com/Lh_2gfCuVm42jMXWqSV2JrbsYp6qSJrmwH9g8gjC9CA/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLWRhcmtuZXNzLWNsYWlydm95YW50LnBuZw',

      // Additional animations
      ATTACK_RANGED_SOURCE:
        'https://images.dozormoskva.com/bU_43hJRru2J145mg-uju1RMVstPz2PIOFWlAY_UFVk/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXNvdXJjZS1kYXJrbmVzcy1jbGFpcnZveWFudC5wbmc',
      ATTACK_RANGED_TARGET_VAR1:
        'https://images.dozormoskva.com/F0JrRdzU2p46j0D8SR6DGZlF85D9myCGWZFonKgpvCc/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIxLWRhcmtuZXNzLWNsYWlydm95YW50LnBuZw',
      ATTACK_RANGED_TARGET_VAR2:
        'https://images.dozormoskva.com/Xz0tfkuBnYYw4hV_tQs2voJ3LvLE7Yy-MGryZTU_y1s/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIyLWRhcmtuZXNzLWNsYWlydm95YW50LnBuZw',
      DAMAGE:
        'https://images.dozormoskva.com/aFXTsBuzwniyqf8Ep6L6c_AZEplcxc4zhk3nFSY-pZA/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLXRhcmdldC1kYXJrbmVzcy1jbGFpcnZveWFudC5wbmc',
      CHANNEL_SOURCE:
        'https://images.dozormoskva.com/RO83IEJ4mdzelkj-FqBKKsekyrWPM63EWuGgANWmCLA/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLXNvdXJjZS1kYXJrbmVzcy1jbGFpcnZveWFudC5wbmc',
    },
    MAGICIAN: {
      // Main animations
      BUFF: 'https://images.dozormoskva.com/YDtn9p5HL-XmS8TbXUTLnNV5vorFOnVmufoaKBv-LRQ/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYnVmZi9idWZmLWRhcmtuZXNzLW1hZ2ljaWFuLnBuZw',
      DEBUFF:
        'https://images.dozormoskva.com/9fBXgmOf0pK52r4EY8eV1tSYl_fiPF0u9MoRRLaS9g8/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvZGVidWZmL2RlYnVmZi1kYXJrbmVzcy1tYWdpY2lhbi5wbmc',
      HEAL: 'https://images.dozormoskva.com/Q4zxlXJWth9365IW7yaA2Ib9RgqzjCBVKaKeFeFamlw/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvaGVhbC9oZWFsLWRhcmtuZXNzLW1hZ2ljaWFuLnBuZw',
      CHANNEL:
        'https://images.dozormoskva.com/2FvYFTMc-ZvcxpXcP1Oq1YgEdLpKqWOoaykDzoZFo2E/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLWRhcmtuZXNzLW1hZ2ljaWFuLnBuZw',
      ATTACK_RANGED:
        'https://images.dozormoskva.com/AM-IW2mDMe0HBxBrGgbQLPlKmq2ZF6y_4Bi-vITXWSk/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLWRhcmtuZXNzLW1hZ2ljaWFuLnBuZw',
      ATTACK_LOCATION:
        'https://images.dozormoskva.com/0Xtp0vB3jw3jPumpC5Pb9i1JUHKUbQIhsz-S5DTskG0/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1kYXJrbmVzcy1tYWdpY2lhbi5wbmc',
      ATTACK_UNIT_LOCATION:
        'https://images.dozormoskva.com/1cqf9aQ8C9_55rBPPOXYkrx_f9HngSpZzRa-k56oBws/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXVuaXQtbG9jYXRpb24vYXR0YWNrLXVuaXQtbG9jYXRpb24tZGFya25lc3MtbWFnaWNpYW4ucG5n',
      ATTACK_DIRECTION:
        'https://images.dozormoskva.com/4o9nzoi0IqfWAndPJap9KUUCk3qLLzQmjevhv0LG0-E/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLWRhcmtuZXNzLW1hZ2ljaWFuLnBuZw',

      // Additional animations
      ATTACK_RANGED_SOURCE:
        'https://images.dozormoskva.com/tD5HORNlL8bJfbBc2Q75jAw4VXigxHhnL3IbZTJHlT4/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXNvdXJjZS1kYXJrbmVzcy1tYWdpY2lhbi5wbmc',
      ATTACK_RANGED_TARGET_VAR1:
        'https://images.dozormoskva.com/VDRBJTnT79YZFNdwwkqP2nULpeDF-iujJKD4zu9EKn0/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIxLWRhcmtuZXNzLW1hZ2ljaWFuLnBuZw',
      ATTACK_RANGED_TARGET_VAR2:
        'https://images.dozormoskva.com/AgxWccOTMSn7muEkykfMJ-6_hh_Scyt5bLhUUqhTwYE/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIyLWRhcmtuZXNzLW1hZ2ljaWFuLnBuZw',
      DAMAGE:
        'https://images.dozormoskva.com/zL_KputXT2V-67_RvGikCKGbZgJrJsr6nY9WmyOr8UM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLXRhcmdldC1kYXJrbmVzcy1tYWdpY2lhbi5wbmc',
      CHANNEL_SOURCE:
        'https://images.dozormoskva.com/hIoA8_BRVvq7PYvjJuYE4OMzay0JVgTqJFnHSiZcsm0/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLXNvdXJjZS1kYXJrbmVzcy1tYWdpY2lhbi5wbmc',
    },
    VAMPIRE: {
      // Main animations
      BUFF: 'https://images.dozormoskva.com/8GuXE4QRpg3AMS9tAk_3rFkY1lK2pZmErKCXivMHeYM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYnVmZi9idWZmLWRhcmtuZXNzLXZhbXBpcmUucG5n',
      DEBUFF:
        'https://images.dozormoskva.com/ZD9K_1-9jl8v6OzFuWmYvTPSWkdrSGxdXLeRsxgAWR4/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvZGVidWZmL2RlYnVmZi1kYXJrbmVzcy12YW1waXJlLnBuZw',
      HEAL: 'https://images.dozormoskva.com/J2xMh4HWzv0FmjFYozp_8A5t0qtlb_zVIh-tuDoOHr8/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvaGVhbC9oZWFsLWRhcmtuZXNzLXZhbXBpcmUucG5n',
      CHANNEL:
        'https://images.dozormoskva.com/8yk1VOS2ViYwipjlj6D60QxVy-BfQJn9Nwlk3mZw2T8/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLWRhcmtuZXNzLXZhbXBpcmUucG5n',
      ATTACK_MELEE:
        'https://images.dozormoskva.com/Qc-soBgt4H0ekqv8VcQ68Tn3-uscyoSSpepS8-JawnI/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLW1lbGVlL2F0dGFjay1tZWxlZS1kYXJrbmVzcy12YW1waXJlLnBuZw',
      ATTACK_RANGED:
        'https://images.dozormoskva.com/xmQD6wCAFeOtgsRkgxMyHMiXs2VjAJ8vebBNluaxTw4/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLWRhcmtuZXNzLXZhbXBpcmUucG5n',
      ATTACK_LOCATION:
        'https://images.dozormoskva.com/U5HFomsPW7hSfmW-DHRXsFVF-P-pXoxoxn8NRoQtSL4/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1kYXJrbmVzcy12YW1waXJlLnBuZw',
      ATTACK_UNIT_LOCATION:
        'https://images.dozormoskva.com/t2TzkiFxlQSpfLbVJErxd4tkwCMGUhCqCuI6Ac2dmsk/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXVuaXQtbG9jYXRpb24vYXR0YWNrLXVuaXQtbG9jYXRpb24tZGFya25lc3MtdmFtcGlyZS5wbmc',
      ATTACK_DIRECTION:
        'https://images.dozormoskva.com/JkNUsajRIQtgkAFPPE010etMsvYDiu6F755oMObVZqY/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLWRhcmtuZXNzLXZhbXBpcmUucG5n',

      // Additional animations
      ATTACK_MELEE_SPLASH:
        'https://images.dozormoskva.com/ZB-cto9UUudKoYvAYulIsSJ2ytKo6hsrvolZVgmQhds/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLW1lbGVlL2F0dGFjay1tZWxlZS1zcGxhc2gtZGFya25lc3MtdmFtcGlyZS5wbmc',
      ATTACK_RANGED_SOURCE:
        'https://images.dozormoskva.com/0sqS7_-Ho5ZhO3pvppP_yf4dyB9gqIBZoi7-QptcFlk/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXNvdXJjZS1kYXJrbmVzcy12YW1waXJlLnBuZw',
      ATTACK_RANGED_TARGET_VAR1:
        'https://images.dozormoskva.com/JADsksfZTPlvzw6bJPBeZFIvAvrFc7jc34mlZBg0AzU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIxLWRhcmtuZXNzLXZhbXBpcmUucG5n',
      ATTACK_RANGED_TARGET_VAR2:
        'https://images.dozormoskva.com/UzeqQWSoaMsuTa4HlHWt4f13VJPe-XP7raYi2S7ufMg/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIyLWRhcmtuZXNzLXZhbXBpcmUucG5n',
      DAMAGE:
        'https://images.dozormoskva.com/0SeAsjEVnI87MJrO4qL2Gp5gkrtudT_SbOJQSirjwfs/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLXRhcmdldC1kYXJrbmVzcy12YW1waXJlLnBuZw',
      CHANNEL_SOURCE:
        'https://images.dozormoskva.com/qcsQF2UqHWW9ye4ejpB7uGb2jUh7wiCpe69NjPj2EZo/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLXNvdXJjZS1kYXJrbmVzcy12YW1waXJlLnBuZw',
    },
    WEREWOLF: {
      // Main animations
      BUFF: 'https://images.dozormoskva.com/1wTcw0et0Iox-DdmW8om6MvavuwKLCV39PfBhvXT9YY/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYnVmZi9idWZmLWRhcmtuZXNzLXdlcmV3b2xmLnBuZw',
      DEBUFF:
        'https://images.dozormoskva.com/yf0nYCUEgW-vhPe1dQubobug7WbQBZuVjd3GbPyd7hA/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvZGVidWZmL2RlYnVmZi1kYXJrbmVzcy13ZXJld29sZi5wbmc',
      HEAL: 'https://images.dozormoskva.com/1r0Vm0G7pcci51yAl5k5CGmqs70rKqVyMvNvdtsNs1Q/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvaGVhbC9oZWFsLWRhcmtuZXNzLXdlcmV3b2xmLnBuZw',
      CHANNEL:
        'https://images.dozormoskva.com/chP9U9sEZcoWJMEEgrOB7E7ug9O1kVn-EDkEM_jggfk/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLWRhcmtuZXNzLXdlcmV3b2xmLnBuZw',
      ATTACK_MELEE:
        'https://images.dozormoskva.com/GdiD8sW9s-JXaCeb1x5xRDov3-nWfDNOIkxdwnH70hM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLW1lbGVlL2F0dGFjay1tZWxlZS1kYXJrbmVzcy13ZXJld29sZi5wbmc',
      ATTACK_RANGED:
        'https://images.dozormoskva.com/ZTf-jaa8uBz4FjkpdvU7KYm9BWQnND9v6pTQfGR-hIo/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLWRhcmtuZXNzLXdlcmV3b2xmLnBuZw',
      ATTACK_LOCATION:
        'https://images.dozormoskva.com/MmhNhv7QY4g1vsUqqNma4I-8D6XzOkivQgJHsCcnaGU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1kYXJrbmVzcy13ZXJld29sZi5wbmc',
      ATTACK_UNIT_LOCATION:
        'https://images.dozormoskva.com/4D2-KcRXhfUpjeHCa9T8vueizEEUzIacVB_PYYnyZpc/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXVuaXQtbG9jYXRpb24vYXR0YWNrLXVuaXQtbG9jYXRpb24tZGFya25lc3Mtd2VyZXdvbGYucG5n',
      ATTACK_DIRECTION:
        'https://images.dozormoskva.com/pP0oUAJxUc1kigYZR7qODjWP6-jbg8z8ht8rDg9suto/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLWRhcmtuZXNzLXdlcmV3b2xmLnBuZw',

      // Additional animations
      ATTACK_MELEE_SPLASH:
        'https://images.dozormoskva.com/64vL07Ph0gBnkkKGuB6ytnt9OZqrd68IsNew-_h_y7I/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLW1lbGVlL2F0dGFjay1tZWxlZS1zcGxhc2gtZGFya25lc3Mtd2VyZXdvbGYucG5n',
      ATTACK_RANGED_SOURCE:
        'https://images.dozormoskva.com/9tsR_gHtyRU94dxp8ePUszkGvDweuO1A8BlS54cTPNw/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXNvdXJjZS1kYXJrbmVzcy13ZXJld29sZi5wbmc',
      ATTACK_RANGED_TARGET_VAR1:
        'https://images.dozormoskva.com/eNvvXApq32bUstJWd9pTPLJIuKEWLuV_vjN8MjkrvQA/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIxLWRhcmtuZXNzLXdlcmV3b2xmLnBuZw',
      ATTACK_RANGED_TARGET_VAR2:
        'https://images.dozormoskva.com/WkBgcLLZOC2Wwd_tr9x880wbuasEh6qGht94KyzDjkM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIyLWRhcmtuZXNzLXdlcmV3b2xmLnBuZw',
      DAMAGE:
        'https://images.dozormoskva.com/X7jhyOFVCXRu82kegcNMOpiyx9iu7BXLiz8ywdoI9Oo/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLXRhcmdldC1kYXJrbmVzcy13ZXJld29sZi5wbmc',
      CHANNEL_SOURCE:
        'https://images.dozormoskva.com/2caib0pplTkNiPVY7AAmGYTZ01z8GkySWhROEnNOgo4/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLXNvdXJjZS1kYXJrbmVzcy13ZXJld29sZi5wbmc',
    },
    WITCH: {
      // Main animations
      BUFF: 'https://images.dozormoskva.com/wSGAahX6B5DY0BgS7NcvwsVvjKxDmKG_nUpMhx2wfI0/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYnVmZi9idWZmLWRhcmtuZXNzLXdpdGNoLnBuZw',
      DEBUFF:
        'https://images.dozormoskva.com/_SrLH2PVHEf8jglVkGay6SZeRhkO1ncoysl81iiS90o/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvZGVidWZmL2RlYnVmZi1kYXJrbmVzcy13aXRjaC5wbmc',
      HEAL: 'https://images.dozormoskva.com/IxemMYoY7oKRb3WBxzyK14T_S-JSGFXsXg2I9LEUYYU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvaGVhbC9oZWFsLWRhcmtuZXNzLXdpdGNoLnBuZw',
      CHANNEL:
        'https://images.dozormoskva.com/FFR_U-x4lIX-ZGd0krUIZ4xVLiXv2fUvQigivy9XPUU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLWRhcmtuZXNzLXdpdGNoLnBuZw',
      ATTACK_RANGED:
        'https://images.dozormoskva.com/-i5rAn4Wypilpx_-ZSxjhmTF15nd_P43iGTKUQJ775Q/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLWRhcmtuZXNzLXdpdGNoLnBuZw',
      ATTACK_LOCATION:
        'https://images.dozormoskva.com/Z2kYyANCK40T3cxdZf2TRT20z45JXA9mc2vYWvSVbSw/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1kYXJrbmVzcy13aXRjaC5wbmc',
      ATTACK_UNIT_LOCATION:
        'https://images.dozormoskva.com/O0sDOTLHOvqA9m_pHCDTZ68i_KdHQWDYBW7xfWOqF5s/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXVuaXQtbG9jYXRpb24vYXR0YWNrLXVuaXQtbG9jYXRpb24tZGFya25lc3Mtd2l0Y2gucG5n',
      ATTACK_DIRECTION:
        'https://images.dozormoskva.com/L2n83YnMeexgzzbqatke9WRN3RI_6wieDrI_oIIPrcs/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLWRhcmtuZXNzLXdpdGNoLnBuZw',

      // Additional animations
      ATTACK_RANGED_SOURCE:
        'https://images.dozormoskva.com/jxzutp95fw7qVf8XX6rHPl1cZlYXKrLr393sN0uAEmo/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXNvdXJjZS1kYXJrbmVzcy13aXRjaC5wbmc',
      ATTACK_RANGED_TARGET_VAR1:
        'https://images.dozormoskva.com/Th3SWa3knghttVRDg0DHm3nyQ9YaU28QRQnWTNbS15Q/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIxLWRhcmtuZXNzLXdpdGNoLnBuZw',
      ATTACK_RANGED_TARGET_VAR2:
        'https://images.dozormoskva.com/nAQFFn0OTm3p_VnGgPPGwRyELoIsF5YYf_5lMqZXgnE/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIyLWRhcmtuZXNzLXdpdGNoLnBuZw',
      DAMAGE:
        'https://images.dozormoskva.com/Wwoo-gKUqDXhuEEaHS8on64etu0jVy6FwTuAu0FgfTI/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLXRhcmdldC1kYXJrbmVzcy13aXRjaC5wbmc',
      CHANNEL_SOURCE:
        'https://images.dozormoskva.com/VwmXNyD0LLJdsCLfPEDKf7PeGKDGpKaTXVRFnY_Erek/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLXNvdXJjZS1kYXJrbmVzcy13aXRjaC5wbmc',
    },
  },
  LIGHT: {
    CLAIRVOYANT: {
      // Main animations
      BUFF: 'https://images.dozormoskva.com/xfp9W9t_VfDGqQM9O_KuG6yI0CNEoOnpiNPXFe5WCcQ/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYnVmZi9idWZmLWxpZ2h0LWNsYWlydm95YW50LnBuZw',
      DEBUFF:
        'https://images.dozormoskva.com/dLhKTgBooRbzqEojxObVl-o9nMvaGap7vtSkbeVyCH0/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvZGVidWZmL2RlYnVmZi1saWdodC1jbGFpcnZveWFudC5wbmc',
      HEAL: 'https://images.dozormoskva.com/j3PdYZfhGNnN7yP0QPsP0e_ATqGD3MM_rXd1TKowwHU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvaGVhbC9oZWFsLWxpZ2h0LWNsYWlydm95YW50LnBuZw',
      CHANNEL:
        'https://images.dozormoskva.com/ciprMtvUcD268EY90ByLyUDbqbdBW3FrB8J0qGx3AqU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLWxpZ2h0LWNsYWlydm95YW50LnBuZw',
      ATTACK_RANGED:
        'https://images.dozormoskva.com/Lq0aCSrwCKuk2ZhCAZz8g8dvH1cAuQiMrHySazTtBD8/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLWxpZ2h0LWNsYWlydm95YW50LnBuZw',
      ATTACK_LOCATION:
        'https://images.dozormoskva.com/fBo4WZ8vS1cpj6oxtp8jubn1DkpqyzKPFdm-6O-sEjM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1saWdodC1jbGFpcnZveWFudC5wbmc',
      ATTACK_UNIT_LOCATION:
        'https://images.dozormoskva.com/nsrfk-2c4lvPl5Qvk2lx_9PdITs3fXoNeHZVWPuCpAA/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXVuaXQtbG9jYXRpb24vYXR0YWNrLXVuaXQtbG9jYXRpb24tbGlnaHQtY2xhaXJ2b3lhbnQucG5n',
      ATTACK_DIRECTION:
        'https://images.dozormoskva.com/-y6QsZSuwGoz3ej5IYTuDSPQIdxn-V2g3G1-kA1GrnU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLWxpZ2h0LWNsYWlydm95YW50LnBuZw',

      // Additional animations
      ATTACK_RANGED_SOURCE:
        'https://images.dozormoskva.com/s_TqCU_l9QEIF1IOYqKr2BTyP72VWgbmIkhpPhvONpI/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXNvdXJjZS1saWdodC1jbGFpcnZveWFudC5wbmc',
      ATTACK_RANGED_TARGET_VAR1:
        'https://images.dozormoskva.com/J6NnegDa_EPqCb_AlSYyo1y3akdr0-Fhbmkig95_wts/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIxLWxpZ2h0LWNsYWlydm95YW50LnBuZw',
      ATTACK_RANGED_TARGET_VAR2:
        'https://images.dozormoskva.com/WbP9wSVYbkBKhTEICmjn8xvRDL6KpJEI5uc40uwfw0g/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIyLWxpZ2h0LWNsYWlydm95YW50LnBuZw',
      DAMAGE:
        'https://images.dozormoskva.com/XAMRRIezfyHn3UKIBLrC2rb4zoxyj0LfgJShXBAuV0U/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLXRhcmdldC1saWdodC1jbGFpcnZveWFudC5wbmc',
      CHANNEL_SOURCE:
        'https://images.dozormoskva.com/EQ9UQqR4oCIZWjV7th_r-xxthYhrtyvBDfnqYqx7dYI/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLXNvdXJjZS1saWdodC1jbGFpcnZveWFudC5wbmc',
    },
    MAGICIAN: {
      // Main animations
      BUFF: 'https://images.dozormoskva.com/PBGtRgiwB9Ntml_wsLRor0CaLndI2mF3ehw1f2ljSN4/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYnVmZi9idWZmLWxpZ2h0LW1hZ2ljaWFuLnBuZw',
      DEBUFF:
        'https://images.dozormoskva.com/Xo0UTaRZ3WkXuBdJuCseKrYe7QT2wwi6MjoZJHx2ifs/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvZGVidWZmL2RlYnVmZi1saWdodC1tYWdpY2lhbi5wbmc',
      HEAL: 'https://images.dozormoskva.com/Jh8XLbICrmWiFBLGKJ5VeJKdHujOK9AxigOlX2mOxsc/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvaGVhbC9oZWFsLWxpZ2h0LW1hZ2ljaWFuLnBuZw',
      CHANNEL:
        'https://images.dozormoskva.com/SiHquUJJ0ZM4XP38DyOa3VIQbMVFeYMiBRqaWXTjvRM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLWxpZ2h0LW1hZ2ljaWFuLnBuZw',
      ATTACK_RANGED:
        'https://images.dozormoskva.com/Zz-ewF5Drec31J9SB3G6CYSzgj1VCEvuQ1Y-IXegPlE/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLWxpZ2h0LW1hZ2ljaWFuLnBuZw',
      ATTACK_LOCATION:
        'https://images.dozormoskva.com/-iknHPT08siFIzYwXmukctjtFNSUYJVzv_R1Z0vyJMU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1saWdodC1tYWdpY2lhbi5wbmc',
      ATTACK_UNIT_LOCATION:
        'https://images.dozormoskva.com/forKC_Jk_17cUS5mxkrDMklb8_6e63eJwfXOM_hvLYw/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXVuaXQtbG9jYXRpb24vYXR0YWNrLXVuaXQtbG9jYXRpb24tbGlnaHQtbWFnaWNpYW4ucG5n',
      ATTACK_DIRECTION:
        'https://images.dozormoskva.com/_1V0ROG76aYIBSHFo2N_yWSsk09bg7qSKZKCLeUJ8aY/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLWxpZ2h0LW1hZ2ljaWFuLnBuZw',

      // Additional animations
      ATTACK_RANGED_SOURCE:
        'https://images.dozormoskva.com/EDruX8_LNjRt8FADdlEJWGm58p83R0u8pnmS03Ysgsc/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXNvdXJjZS1saWdodC1tYWdpY2lhbi5wbmc',
      ATTACK_RANGED_TARGET_VAR1:
        'https://images.dozormoskva.com/g4l7ojKIjDyuBjxKAhI7BbehHNjz7myi1hBnvhdUekA/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIxLWxpZ2h0LW1hZ2ljaWFuLnBuZw',
      ATTACK_RANGED_TARGET_VAR2:
        'https://images.dozormoskva.com/e6GfpdOjO6vmMkLHSiZHUQxq3DVi7tx74s3NJp3v3kY/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIyLWxpZ2h0LW1hZ2ljaWFuLnBuZw',
      DAMAGE:
        'https://images.dozormoskva.com/HCUO43W_OzdQJ1uI43EYUi6aFZA2qM7YFzM-FBCyT48/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLXRhcmdldC1saWdodC1tYWdpY2lhbi5wbmc',
      CHANNEL_SOURCE:
        'https://images.dozormoskva.com/P-Ar7MlI4ijeyfLepCVpQbyXtnE-gxgDduowuqYBLPM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLXNvdXJjZS1saWdodC1tYWdpY2lhbi5wbmc',
    },
    SHAPESHIFTER_CAT_LIKE: {
      // Main animations
      BUFF: 'https://images.dozormoskva.com/i6ubiHLJPLPaFld46oKht0Jn5KgE_4_h_48ZuhC-Q3U/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYnVmZi9idWZmLWxpZ2h0LXNoYXBlc2hpZnRlci1jYXQucG5n',
      DEBUFF:
        'https://images.dozormoskva.com/a25tfYCvqcdD6-PbHuVM6epDr-YJ6x3gKTYs43ekdTA/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvZGVidWZmL2RlYnVmZi1saWdodC1zaGFwZXNoaWZ0ZXItY2F0LnBuZw',
      HEAL: 'https://images.dozormoskva.com/MUgpi4EXVYU8oW5S76qEsa-zqio4Xqm9PBfDvGboZPY/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvaGVhbC9oZWFsLWxpZ2h0LXNoYXBlc2hpZnRlci1jYXQucG5n',
      CHANNEL:
        'https://images.dozormoskva.com/sjQjjk8wXLJNMtsPPs5YoisH5UqrPmTbmxgx-hK00eU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLWxpZ2h0LXNoYXBlc2hpZnRlci1jYXQucG5n',
      ATTACK_RANGED:
        'https://images.dozormoskva.com/A_e2vDDN8tEajCiP892obADBc43wMnk-rsROZftqq2g/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLWxpZ2h0LXNoYXBlc2hpZnRlci1jYXQucG5n',
      ATTACK_LOCATION:
        'https://images.dozormoskva.com/YyBjFRyjpb5ldfMtst5Ih3PgbMrCzNhgT5ON_P5Ml8o/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1saWdodC1zaGFwZXNoaWZ0ZXItY2F0LnBuZw',
      ATTACK_UNIT_LOCATION:
        'https://images.dozormoskva.com/LysOVHqjPn4MMBVkfu9I1xm-sCr-1xvQHONMPT2D7Eo/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXVuaXQtbG9jYXRpb24vYXR0YWNrLXVuaXQtbG9jYXRpb24tbGlnaHQtc2hhcGVzaGlmdGVyLWNhdC5wbmc',
      ATTACK_DIRECTION:
        'https://images.dozormoskva.com/sLEfFUFnBORQnrFS-30sZUcRWm0YlfO45TcE4uZVBxM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLWxpZ2h0LXNoYXBlc2hpZnRlci1jYXQucG5n',

      // Additional animations
      ATTACK_MELEE_SPLASH:
        'https://images.dozormoskva.com/qApTOmodAtqBMGxGjV2gDL1_z72GLHyEu1S3EsvKzWU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLW1lbGVlL2F0dGFjay1tZWxlZS1zcGxhc2gtbGlnaHQtc2hhcGVzaGlmdGVyLWNhdC5wbmc',
      ATTACK_RANGED_SOURCE:
        'https://images.dozormoskva.com/sh5q7rZsbGvc9HjkuM1A1uH7kC4OhJ9HRF5VKwmSm2I/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXNvdXJjZS1saWdodC1zaGFwZXNoaWZ0ZXItY2F0LnBuZw',
      ATTACK_RANGED_TARGET_VAR1:
        'https://images.dozormoskva.com/rS1EV_914jDcolRY3fN0vc2FvB8F69sFl2QKeQK20uI/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIxLWxpZ2h0LXNoYXBlc2hpZnRlci1jYXQucG5n',
      ATTACK_RANGED_TARGET_VAR2:
        'https://images.dozormoskva.com/GlB97gSV6_kpKFxPjjoU3nxdOkzplu90bYrd2g9mxIU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIyLWxpZ2h0LXNoYXBlc2hpZnRlci1jYXQucG5n',
      DAMAGE:
        'https://images.dozormoskva.com/ZeBPN4Ns6vyc193EUnAwCDnMRTQBBhTHpmkHO2AazXc/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLXRhcmdldC1saWdodC1zaGFwZXNoaWZ0ZXItY2F0LnBuZw',
      CHANNEL_SOURCE:
        'https://images.dozormoskva.com/eAjpRa7XJlJEFHjTHTFqJKWfAnyfGpH-VXxemgaxnlI/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLXNvdXJjZS1saWdodC1zaGFwZXNoaWZ0ZXItY2F0LnBuZw',
    },
    SHAPESHIFTER_DOG_LIKE: {
      // Main animations
      BUFF: 'https://images.dozormoskva.com/GTRPLMKQRsi0HtGF8UBWuwUr5wiNYMqtvvrsPJ13BZg/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYnVmZi9idWZmLWxpZ2h0LXNoYXBlc2hpZnRlci1kb2cucG5n',
      DEBUFF:
        'https://images.dozormoskva.com/h4Ugf5B5bIsdp-8wx0d6LJmF1WNjcubXPPVSFCIRBck/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvZGVidWZmL2RlYnVmZi1saWdodC1zaGFwZXNoaWZ0ZXItZG9nLnBuZw',
      HEAL: 'https://images.dozormoskva.com/TmjCgWX-fjiA8AAdlL_pGDrXdFXXG_TXLMgnHwOVO2c/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvaGVhbC9oZWFsLWxpZ2h0LXNoYXBlc2hpZnRlci1kb2cucG5n',
      CHANNEL:
        'https://images.dozormoskva.com/XrvSg6tc425keZ25hbGLuSiWKTQQ1qAAnBlKrrkt0QA/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLWxpZ2h0LXNoYXBlc2hpZnRlci1kb2cucG5n',
      ATTACK_RANGED:
        'https://images.dozormoskva.com/ASZ4N6FswyPoDVWcgvlZujDoiFd-u7Kh-Xv3KAaYOpg/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLWxpZ2h0LXNoYXBlc2hpZnRlci1kb2cucG5n',
      ATTACK_LOCATION:
        'https://images.dozormoskva.com/ZliQJoT1p_B9E89JiCAkJ4buZahhdZ9uXVlvexnB8IQ/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1saWdodC1zaGFwZXNoaWZ0ZXItZG9nLnBuZw',
      ATTACK_UNIT_LOCATION:
        'https://images.dozormoskva.com/D6kH1Razm63Nku6CnK-AF4Ante6MgfeY9Yu9IHizp_A/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXVuaXQtbG9jYXRpb24vYXR0YWNrLXVuaXQtbG9jYXRpb24tbGlnaHQtc2hhcGVzaGlmdGVyLWRvZy5wbmc',
      ATTACK_DIRECTION:
        'https://images.dozormoskva.com/5xn9Zi39mkNd7BxtIZ51ZBdwlStJv0vNgisemudjvu0/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLWxpZ2h0LXNoYXBlc2hpZnRlci1kb2cucG5n',

      // Additional animations
      ATTACK_MELEE_SPLASH:
        'https://images.dozormoskva.com/JNsFeodBriSfvxPRyrI3nvf7whUFEZyQFz48I5SiN4M/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLW1lbGVlL2F0dGFjay1tZWxlZS1zcGxhc2gtbGlnaHQtc2hhcGVzaGlmdGVyLWRvZy5wbmc',
      ATTACK_RANGED_SOURCE:
        'https://images.dozormoskva.com/UZcmJuhbvB6fW_HuU1Ic_I5CxN7DLplzjwYYwTp3e0M/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXNvdXJjZS1saWdodC1zaGFwZXNoaWZ0ZXItZG9nLnBuZw',
      ATTACK_RANGED_TARGET_VAR1:
        'https://images.dozormoskva.com/x8fIm0e6CuHvBvGSqs-2chB-XzpLtG2LvqiaDAUCB9o/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIxLWxpZ2h0LXNoYXBlc2hpZnRlci1kb2cucG5n',
      ATTACK_RANGED_TARGET_VAR2:
        'https://images.dozormoskva.com/5OqEjQHpwxMdw9ikebcqr2sa26FmrQHYbovIQFJTGZw/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIyLWxpZ2h0LXNoYXBlc2hpZnRlci1kb2cucG5n',
      DAMAGE:
        'https://images.dozormoskva.com/_v28R1WBRlG4wdbtL94TP8qWbTu6RCwVXonNxgF07Mg/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLXRhcmdldC1saWdodC1zaGFwZXNoaWZ0ZXItZG9nLnBuZw',
      CHANNEL_SOURCE:
        'https://images.dozormoskva.com/Xg380W1iBGO1D0eil2x2pcI62nUXF3Tf0h3HKkocYyc/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLXNvdXJjZS1saWdodC1zaGFwZXNoaWZ0ZXItZG9nLnBuZw',
    },
    WITCH: {
      // Main animations
      BUFF: 'https://images.dozormoskva.com/_lzAQ5p0mKAutM2y5zOJlTV562v7k9FZhbFQdMCgEqU/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYnVmZi9idWZmLWxpZ2h0LXdpdGNoLnBuZw',
      DEBUFF:
        'https://images.dozormoskva.com/urKODW5OKPyckaLtiwTTGrZZRsFETjUByJ2QGcgOaKM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvZGVidWZmL2RlYnVmZi1saWdodC13aXRjaC5wbmc',
      HEAL: 'https://images.dozormoskva.com/AwopPewOejJAmNCH1lgkuHcKOUUK0OAdpUNdDLoWAZY/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvaGVhbC9oZWFsLWxpZ2h0LXdpdGNoLnBuZw',
      CHANNEL:
        'https://images.dozormoskva.com/HFXxrU2lAajzmaqjcS2B2B5PrSM57c9m9f767LobpfM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLWxpZ2h0LXdpdGNoLnBuZw',
      ATTACK_RANGED:
        'https://images.dozormoskva.com/WtA7vRBWXhaqhw34h4oyFlx9Ntf49GWLOedzMPBmi9c/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLWxpZ2h0LXdpdGNoLnBuZw',
      ATTACK_LOCATION:
        'https://images.dozormoskva.com/gl6yHraUaQsM5bm-k_gxfBxuZIEd0lNhUF6ozrddRnM/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1saWdodC13aXRjaC5wbmc',
      ATTACK_UNIT_LOCATION:
        'https://images.dozormoskva.com/O84HlbPFa2AxeQ2M0zxZkLYvyAlL2ysnwaiiugaMuBo/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXVuaXQtbG9jYXRpb24vYXR0YWNrLXVuaXQtbG9jYXRpb24tbGlnaHQtd2l0Y2gucG5n',
      ATTACK_DIRECTION:
        'https://images.dozormoskva.com/Qiik2GR0oW7xkHx4MssGKLcVz3vL_wPeJ6txLd_ivbY/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLWxpZ2h0LXdpdGNoLnBuZw',

      // Additional animations
      ATTACK_RANGED_SOURCE:
        'https://images.dozormoskva.com/yaNZCBLQ_dt5glUk_hUdBuWZd-Azxa2cvj5a2M-rVrI/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXNvdXJjZS1saWdodC13aXRjaC5wbmc',
      ATTACK_RANGED_TARGET_VAR1:
        'https://images.dozormoskva.com/sFjRze2pEgWj88-bV5EO152-47fEjtSEcBgPLosfmiE/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIxLWxpZ2h0LXdpdGNoLnBuZw',
      ATTACK_RANGED_TARGET_VAR2:
        'https://images.dozormoskva.com/4QlYgbMbssQV1G2rgkaTASzAq29-gsDGIHB519OwxXw/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLXJhbmdlZC9hdHRhY2stcmFuZ2VkLXRhcmdldC12YXIyLWxpZ2h0LXdpdGNoLnBuZw',
      DAMAGE:
        'https://images.dozormoskva.com/Yo8qkZuIR2rOfSdR07DZUoaASH56gVhOY7QyLyXGPU0/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWRpcmVjdGlvbi9hdHRhY2stZGlyZWN0aW9uLXRhcmdldC1saWdodC13aXRjaC5wbmc',
      CHANNEL_SOURCE:
        'https://images.dozormoskva.com/KMVaf2Qm7XaD4sB1UV8OdpKy-LjLOTb52vtHyPxwtek/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvY2hhbm5lbC9jaGFubmVsLXNvdXJjZS1saWdodC13aXRjaC5wbmc',
    },
  },
}

export const commonAnimationSpriteUrlMap = {
  PORTAL:
    'https://images.dozormoskva.com/FYorfAC4zXuCwkoMxgY4XlP28R-_WUHZQPY1-DgbpK0/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvcG9ydGFsL3BvcnRhbC5wbmc',
  ATTACK_LOCATION_AREA:
    'https://images.dozormoskva.com/jSJKcO-3c-djR7PsXg9vgFxqXVm6bQ4F20yklZJYQ3U/q:70/czM6Ly9hYmlsaXR/5LWFuaW1hdGlvbnMvYXR0YWNrLWxvY2F0aW9uL2F0dGFjay1sb2NhdGlvbi1hcmVhLnBuZw',
} as const satisfies Partial<Record<AbilityAnimationType, string>>

export const animationDurationMap: Record<AbilityAnimationType, Second> = {
  ATTACK_LOCATION: 28 / 24,
  ATTACK_UNIT_LOCATION: 0.6,
  ATTACK_RANGED: 8 / 24,
  ATTACK_MELEE: 18 / 24,
  ATTACK_DIRECTION: 12 / 24,
  BUFF: 24 / 24,
  DEBUFF: 24 / 24,
  PORTAL: 2 * (20 / 24) + 15 / 24,
  HEAL: 23 / 24,
  CHANNEL: 12 / 24,

  ATTACK_MELEE_SPLASH: 20 / 24,
  DAMAGE: 23 / 24,
  ATTACK_LOCATION_AREA: 28 / 24,
  ATTACK_RANGED_SOURCE: 12 / 24,
  ATTACK_RANGED_TARGET_VAR1: 16 / 24,
  ATTACK_RANGED_TARGET_VAR2: 12 / 24,
  CHANNEL_SOURCE: 8 / 24,
}

export const animationSpriteCountMap: Record<AbilityAnimationType, number> = {
  ATTACK_LOCATION: 14,
  ATTACK_UNIT_LOCATION: 10,
  ATTACK_RANGED: 4,
  ATTACK_MELEE: 9,
  ATTACK_DIRECTION: 6,
  BUFF: 12,
  DEBUFF: 12,
  PORTAL: 12,
  HEAL: 12,
  CHANNEL: 6,

  ATTACK_MELEE_SPLASH: 3,
  DAMAGE: 12,
  ATTACK_LOCATION_AREA: 10,
  ATTACK_RANGED_SOURCE: 6,
  ATTACK_RANGED_TARGET_VAR1: 8,
  ATTACK_RANGED_TARGET_VAR2: 6,
  CHANNEL_SOURCE: 8,
}

export const animationIterationCountMap: Record<AbilityAnimationType, 'infinite' | number> = {
  ATTACK_LOCATION: 1,
  ATTACK_UNIT_LOCATION: 1,
  ATTACK_RANGED: 'infinite',
  ATTACK_MELEE: 1,
  ATTACK_DIRECTION: 'infinite',
  BUFF: 1,
  DEBUFF: 1,
  PORTAL: 1,
  HEAL: 1,
  CHANNEL: 'infinite',

  ATTACK_MELEE_SPLASH: 1,
  DAMAGE: 1,
  ATTACK_LOCATION_AREA: 1,
  ATTACK_RANGED_SOURCE: 1,
  ATTACK_RANGED_TARGET_VAR1: 1,
  ATTACK_RANGED_TARGET_VAR2: 1,
  CHANNEL_SOURCE: 'infinite',
}

export const animationSizeMap: Record<AbilityAnimationType, number> = {
  ATTACK_LOCATION: 0,
  ATTACK_UNIT_LOCATION: 0,
  ATTACK_RANGED: 120,
  ATTACK_MELEE: 190,
  ATTACK_DIRECTION: 0,
  BUFF: 170,
  DEBUFF: 170,
  PORTAL: 400,
  HEAL: 170,
  CHANNEL: 120,

  ATTACK_MELEE_SPLASH: 190,
  DAMAGE: 180,
  ATTACK_LOCATION_AREA: 0,
  ATTACK_RANGED_SOURCE: 200,
  ATTACK_RANGED_TARGET_VAR1: 180,
  ATTACK_RANGED_TARGET_VAR2: 180,
  CHANNEL_SOURCE: 200,
}

export const attackLocationAreaSpriteIndexMap: Partial<
  Record<Side, Partial<Record<Race, number>>>
> = {
  DARKNESS: {
    CLAIRVOYANT: 0,
    MAGICIAN: 2,
    WITCH: 4,
    WEREWOLF: 6,
    VAMPIRE: 8,
  },
  LIGHT: {
    CLAIRVOYANT: 1,
    MAGICIAN: 3,
    WITCH: 5,
    SHAPESHIFTER_DOG_LIKE: 7,
    SHAPESHIFTER_CAT_LIKE: 9,
  },
}

export const animationTransformMap: Record<AbilityAnimationType, string> = {
  ATTACK_LOCATION: '',
  ATTACK_UNIT_LOCATION: '',
  ATTACK_RANGED: '',
  ATTACK_MELEE: '',
  ATTACK_DIRECTION: '',
  BUFF: '',
  DEBUFF: '',
  PORTAL: '',
  HEAL: '',
  CHANNEL: '',

  ATTACK_MELEE_SPLASH: '',
  DAMAGE: 'translateY(15px)',
  ATTACK_LOCATION_AREA: '',
  ATTACK_RANGED_SOURCE: 'translateY(-50px) rotate(-2deg)',
  ATTACK_RANGED_TARGET_VAR1: 'translateY(-50px) rotate(215deg)',
  ATTACK_RANGED_TARGET_VAR2: 'translateY(-50px) rotate(215deg)',
  CHANNEL_SOURCE: 'translateY(-27px) rotate(-113deg)',
}

export const locationSpriteOffset = 50
