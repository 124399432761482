import { forwardRef, useImperativeHandle, useState } from 'react'
import styled from '@emotion/styled'
import { useStore } from '~store'
import { usePopover } from '~hooks/usePopover'
import type { OpenMatchTeam } from './OpenMatchPopover'
import { OpenMatchPopover } from './OpenMatchPopover'
import { OpenMatchMarkerTimer } from './OpenMatchMarkerTimer'
import type { Millisecond } from '~shared-types'

import IconMatchDark from '~assets/icon-map-match-dark.svg'
import IconMatchLight from '~assets/icon-map-match-light.svg'
import IconMatchNeutral from '~assets/icon-map-match-neutral.svg'

export type Props = {
  leftTeam: OpenMatchTeam
  rightTeam: OpenMatchTeam
  address: string | undefined
  startedAt: Millisecond
  onJoinMatch?: () => void
}

export type OpenMatchMarkerRef = {
  updateTeamsStats: (stats: {
    leftTeam?: Partial<OpenMatchTeam>
    rightTeam?: Partial<OpenMatchTeam>
  }) => void
}

export const OpenMatchMarker = forwardRef<OpenMatchMarkerRef, Props>(
  (
    { leftTeam: initialLeftTeam, rightTeam: initialRightTeam, startedAt, address, onJoinMatch },
    ref,
  ) => {
    const { isPopoverOpen, popoverProps, parentProps, closePopover } = usePopover()
    const [currentCharacter] = useStore((state) => [state.currentCharacter])
    const [teams, setTeams] = useState({ leftTeam: initialLeftTeam, rightTeam: initialRightTeam })

    useImperativeHandle(
      ref,
      () => ({
        updateTeamsStats({ leftTeam, rightTeam }) {
          setTeams(({ leftTeam: oldLeftTeam, rightTeam: oldRightTeam }) => ({
            leftTeam: { ...oldLeftTeam, leftTeam },
            rightTeam: { ...oldRightTeam, rightTeam },
          }))
        },
      }),
      [],
    )

    if (!currentCharacter) return null

    const lightTeamDominance =
      teams.leftTeam.side === 'LIGHT'
        ? teams.leftTeam.dominancePercent
        : teams.rightTeam.dominancePercent
    const markerIcon =
      lightTeamDominance > 50
        ? IconMatchLight
        : lightTeamDominance < 50
        ? IconMatchDark
        : IconMatchNeutral

    return (
      <Container {...parentProps}>
        <MarkerContainer>
          <img src={markerIcon} alt="openMatchIcon" />
          <StyledOpenMatchMarkerTimer startedAt={startedAt} />
        </MarkerContainer>
        {isPopoverOpen ? (
          <OpenMatchPopover
            leftTeam={teams.leftTeam}
            rightTeam={teams.rightTeam}
            address={address}
            onSubmit={() => {
              onJoinMatch?.()
              closePopover()
            }}
            onClose={closePopover}
            {...popoverProps}
          />
        ) : null}
      </Container>
    )
  },
)

const Container = styled.div`
  position: relative;
`

const MarkerContainer = styled.div`
  position: relative;
  height: 116px;
  display: flex;
  align-items: flex-end;

  img {
    margin-bottom: -26px;
    display: block;
    pointer-events: none;
  }
`

const StyledOpenMatchMarkerTimer = styled(OpenMatchMarkerTimer)`
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
`
