import styled from '@emotion/styled'
import { useMatchStore } from '~store'
import { CastProgressUI } from '../../../components/CastProgress'

export const MatchCastProgress = () => {
  const [castAction, currentCharacter] = useMatchStore((state) => [
    state.castAction,
    state.currentCharacter,
  ])
  return <StyledCastProgress castAction={castAction} side={currentCharacter?.side} />
}

const StyledCastProgress = styled(CastProgressUI)`
  position: fixed;
  bottom: 170px;
  z-index: 5;
  width: 240px;
  left: 50%;
  margin-left: -80px;
`
