import styled from '@emotion/styled'
import type { FC } from 'react'
import type { Quest } from './quest'
import { useValueFromObservable } from './useValueFromObservable'
import { raceIconMap } from '~utils/character'
import { t } from '~utils/i18n'

import { ReactComponent as BgPanel } from '~assets/bg-quest-dialog-panel.svg'
import { ReactComponent as BgPanelLight } from '~assets/bg-quest-dialog-panel-light.svg'
import { ReactComponent as BgPanelDark } from '~assets/bg-quest-dialog-panel-dark.svg'
import IconArrowNext from '~assets/icon-arrow-next.svg'
import { Hint } from './components/Hint'

type Props = {
  quest: Quest
}

export const QuestDialog: FC<Props> = ({ quest }) => {
  const { showDialog, background, text, choices, showNext, speaker, listeners, isAction, hint } =
    useValueFromObservable(quest.dialogSubject)

  if (!showDialog) {
    return null
  }

  return (
    <>
      {/* <Highlight elementId="header-menu-item-profile" /> */}
      {background ? (
        <Background
          overlay={background.url}
          positionX={background.positionX}
          positionY={background.positionY}
          darken={!!speaker}
        />
      ) : null}
      {background?.blur ? <Background overlay="blur" /> : null}
      <Panel>
        <StyledBgPanel
          as={
            speaker?.side === 'LIGHT'
              ? BgPanelLight
              : speaker?.side === 'DARKNESS'
              ? BgPanelDark
              : BgPanel
          }
        />
        {speaker ? (
          <>
            {speaker.avatar?.fullBody ? (
              <CharacterImage
                style={{
                  backgroundImage: `image-set(url(${speaker.avatar.fullBody}) 1x, url(${speaker.avatar.fullBody2x}) 2x)`,
                }}
              />
            ) : null}
            <CharacterInfo>
              {speaker.name ? <Name>{speaker.name}</Name> : null}
              {speaker.race ? <RaceIcon>{raceIconMap[speaker.race]}</RaceIcon> : null}
            </CharacterInfo>
          </>
        ) : null}
        {listeners
          .filter((listener) => listener.avatar?.fullBody)
          .map((listener) => (
            <CharacterImage
              onBackground
              key={listener.id}
              style={{
                backgroundImage: `image-set(url(${listener.avatar?.fullBody}) 1x, url(${listener.avatar?.fullBody2x}) 2x)`,
              }}
            />
          ))}
        {choices.length ? (
          <Choices>
            {choices.map((choice) => (
              <Choice key={choice.id} onClick={() => quest.selectChoice(choice.id)}>
                <ChoiceContent>{choice.text}</ChoiceContent>
              </Choice>
            ))}
          </Choices>
        ) : null}
        <Content isAction={isAction}>
          {text} {hint ? <Hint tooltipContent={hint.text} /> : null}
        </Content>
        {showNext ? (
          <Navigation>
            <QuestLink onClick={() => quest.goToNextDialog()}>
              {t('button:continueQuest')}
            </QuestLink>
          </Navigation>
        ) : null}
      </Panel>
    </>
  )
}

const Background = styled.div<{
  overlay?: 'blur' | string
  positionX?: string
  positionY?: string
  darken?: boolean
}>`
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 3;

  ${({ overlay }) =>
    overlay === 'blur'
      ? `background: #13192780;
    backdrop-filter: blur(6px);`
      : overlay
      ? `background: url("${overlay}");`
      : ''}

  background-size: cover;
  background-position-x: ${({ positionX = 'center' }) => positionX};
  background-position-y: ${({ positionY = 'center' }) => positionY};
  background-repeat: no-repeat;

  &:before {
    content: '';
    position: absolute;
    height: 50vh;
    width: 100%;
    left: 0;
    bottom: 0;
    background: linear-gradient(to top, #131927a7 0, transparent 100%);
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    background: #13192762;
    pointer-events: none;
    display: ${({ darken }) => (darken ? 'block' : 'none')};
  }
`

const Panel = styled.div`
  width: 100%;
  min-height: 210px;
  bottom: 0;
  position: fixed;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 275px 30px;

  &:before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    bottom: 0;
    background: linear-gradient(to top, #13192771 0, transparent 100%);
    pointer-events: none;
  }
`

const Content = styled.div<{ isAction?: boolean }>`
  position: relative;
  z-index: 1;
  color: #fff;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  width: 800px;
  max-width: 100%;
  text-align: center;
  flex: 1;
  text-shadow: 0 1px 8px #081f2d;
  font-style: ${({ isAction }) => (isAction ? 'italic' : 'normal')};
`

const Navigation = styled.div`
  position: relative;
  z-index: 1;
  width: 1000px;
  max-width: 100%;
  display: flex;
  justify-content: flex-end;
`

const Choices = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 100%;
  margin-bottom: 36px;
  left: calc(50% + 300px);
  width: calc(100vw - 300px);
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:before {
    content: '';
    position: absolute;
    top: 1px;
    bottom: 0;
    left: -6px;
    width: 1px;
    z-index: 1;
    background-image: linear-gradient(0deg, #646d85 1px, hsla(0, 0%, 100%, 0) 0);
    background-position: right 1px;
    background-size: 1px 5px;
    background-repeat: repeat-y;
  }
`

const Choice = styled.div`
  color: #ada08a;
  font-size: 18px;
  font-weight: 600;
  height: 48px;
  cursor: pointer;
  position: relative;

  &:hover {
    &:before {
      background: #ada08a;
    }
  }

  &:before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #646d85;
    position: absolute;
    right: calc(100% + 2px);
    top: 50%;
    margin-top: -4px;
    transition: background 0.2s;
  }
`

const ChoiceContent = styled.div`
  padding: 0 24px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-shadow: 0 1px 2px #081f2d;
  transition: color 0.2s;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    transition: opacity 0.2s;
    mask-image: radial-gradient(circle 10px at -7px 50%, transparent 12px, #000 0);
    pointer-events: none;
  }

  &:before {
    background: linear-gradient(to right, #646d85 0%, #646d857c 80px, #646d8500 300px);
  }

  &:after {
    background: linear-gradient(to right, #ada08a 0%, #ada08a7c 80px, #ada08a00 300px);
    opacity: 0;
  }

  &:hover {
    color: #fff;

    &:before {
      opacity: 0;
    }

    &:after {
      opacity: 1;
    }
  }
`

const QuestLink = styled.button`
  color: #ada08a;
  font-size: 16px;
  font-weight: 600;
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  transition: color 0.2s;
  padding: 0;
  text-shadow: 0 1px 2px #081f2db3;

  &:after {
    content: '';
    display: inline-block;
    width: 12px;
    height: 12px;
    margin-left: 5px;
    background: url(${IconArrowNext}) no-repeat;
    transition: transform 0.2s;
  }

  &:hover {
    &:after {
      transform: translateX(4px);
    }
  }
`

const StyledBgPanel = styled.div`
  height: 210px;
  pointer-events: none;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  mask-image: radial-gradient(ellipse 972px 573px at top, #000000 25%, transparent 55%);
`

const CharacterImage = styled.div<{ onBackground?: boolean }>`
  position: absolute;
  z-index: -1;
  max-height: ${({ onBackground }) => (onBackground ? 60 : 100)}vh;
  height: ${({ onBackground }) => (onBackground ? 500 : 800)}px;
  width: 100%;
  left: ${({ onBackground }) => (onBackground ? -230 : 0)}px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  bottom: ${({ onBackground }) => (onBackground ? 60 : -70)}px;
  mask-image: linear-gradient(0deg, transparent 10%, #000 40%);
  pointer-events: none;
  filter: ${({ onBackground }) => (onBackground ? 'blur(1px)' : 'none')};
  transition: all 0.2s;
`

const CharacterInfo = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
`

const Name = styled.div`
  font-size: 32px;
  line-height: 32px;
  color: #fff;
  font-weight: 600;
  text-shadow: 0 1px 2px #081f2db3;
`

const RaceIcon = styled.div`
  margin-left: 10px;
  display: flex;
  place-items: center;

  svg {
    width: 34px;
  }
`
