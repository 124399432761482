import type { Subscription } from 'rxjs'
import {
  distinctUntilChanged,
  filter,
  finalize,
  first,
  map,
  startWith,
  throttleTime,
  timer,
} from 'rxjs'
import type { Point } from '~shared-types'
import type { MatchCharacterAbilityFragment, UnitType } from '../../../generated/match-graphql'
import {
  CastAbilityCoordinateTargetedDocument,
  CastAbilityUnitTargetedDocument,
  RouteDocument,
} from '../../../generated/match-graphql'
import { matchStore } from '../../../store/matchStore'
import type { CharacterEntity } from './CharacterEntity'
import {
  CharacterTwilightAvailability,
  getCharacterTwilightAvailability,
  getPointInDirection,
  spatialDistance,
  arePointsEqual,
  CharacterTwilightRelation,
} from '~map-tools'
import { CrowdControlsService } from './statusEffectsGroups/crowdControls.service'
import { eventBus } from '../../../eventBus'

export class ActionPlanner {
  private subscriptions: Subscription[] = []

  private cancelPlannedAction() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.subscriptions = []
  }

  async castAbilityUnitTargeted(
    unitCharacter: CharacterEntity,
    ability: MatchCharacterAbilityFragment,
    myCharacter: CharacterEntity,
  ) {
    this.cancelPlannedAction()
    if (!ability.targetUnitType) {
      return
    }

    const isAlly = unitCharacter.teamId === myCharacter.teamId
    const allyUnitTypes: UnitType[] = ['ALLY', 'ANY', 'SELF_ALLY']
    const enemyUnitTypes: UnitType[] = ['ANY', 'ENEMY']

    const isPossibleTarget = isAlly
      ? allyUnitTypes.includes(ability.targetUnitType)
      : enemyUnitTypes.includes(ability.targetUnitType)

    if (!isPossibleTarget) {
      return
    }

    const characterTwilightAvailability = getCharacterTwilightAvailability(
      myCharacter.twilightLevel,
      unitCharacter.twilightLevel,
    )
    if (characterTwilightAvailability !== CharacterTwilightAvailability.REACHABLE) {
      return
    }

    this.subscriptions.push(
      unitCharacter.twilightRelationSubject.subscribe((relation) => {
        if (
          relation !== CharacterTwilightRelation.ONE_BELOW_REACHABLE &&
          relation !== CharacterTwilightRelation.REACHABLE
        ) {
          this.cancelPlannedAction()
          return
        }
        const getTargetPosition = () => unitCharacter.position
        const mutation = async () => {
          await matchStore.getState().wsClient?.mutate({
            mutation: CastAbilityUnitTargetedDocument,
            variables: {
              characterId: unitCharacter.id,
              abilityId: ability.id,
            },
          })
        }
        this.castAbility(getTargetPosition, myCharacter, ability, mutation)

        eventBus.emit('action.triggered', {})
      }),
    )
  }

  async castAbilityCoordinateTargeted(
    coordinate: Point,
    ability: MatchCharacterAbilityFragment,
    myCharacter: CharacterEntity,
  ) {
    this.cancelPlannedAction()

    const getTargetPosition = (): Point => [coordinate[0], coordinate[1]]
    const mutation = async () => {
      await matchStore.getState().wsClient?.mutate({
        mutation: CastAbilityCoordinateTargetedDocument,
        variables: {
          destination: { lng: coordinate[0], lat: coordinate[1] },
          abilityId: ability.id,
        },
      })
    }
    this.castAbility(getTargetPosition, myCharacter, ability, mutation)

    eventBus.emit('action.triggered', {})
  }

  async castAbilitySelfTargeted(
    ability: MatchCharacterAbilityFragment,
    myCharacter: CharacterEntity,
  ) {
    this.cancelPlannedAction()

    await matchStore.getState().wsClient?.mutate({
      mutation: CastAbilityUnitTargetedDocument,
      variables: {
        characterId: myCharacter.id,
        abilityId: ability.id,
      },
    })

    eventBus.emit('action.triggered', {})
  }

  private castAbility(
    getTargetPosition: () => Point,
    myCharacter: CharacterEntity,
    ability: MatchCharacterAbilityFragment,
    castMutation: () => Promise<void>,
  ) {
    const isInDistance =
      spatialDistance(myCharacter.position, getTargetPosition()) <= ability.range.max

    if (!isInDistance) {
      let previousTargetPosition: Point | undefined
      const followTargetSubscription = timer(0, 1000)
        .pipe(
          map(() => getTargetPosition()),
          filter(
            (currentPosition) =>
              !previousTargetPosition ||
              spatialDistance(currentPosition, previousTargetPosition) > 5,
          ),
          distinctUntilChanged((previous, current) => arePointsEqual(previous, current)),
        )
        .subscribe((position) => {
          const destination = getPointInDirection(
            position,
            [position, myCharacter.position],
            ability.range.max - 10,
          )

          matchStore
            .getState()
            .wsClient?.mutate({
              mutation: RouteDocument,
              variables: {
                destination,
              },
            })
            .catch((error) => console.log(error))
        })

      this.subscriptions.push(
        myCharacter.positionObservable
          .pipe(
            startWith(myCharacter.position),
            throttleTime(100, undefined, { leading: true, trailing: true }),
            filter(
              (position) => spatialDistance(position, getTargetPosition()) <= ability.range.max,
            ),
            first(),
            finalize(() => followTargetSubscription.unsubscribe()),
          )
          .subscribe(() => {
            castMutation().catch((error) => console.log(error))
          }),
      )
      return
    }

    castMutation().catch((error) => console.log(error))
  }

  async routeToCoordinate(coordinate: Point, myCharacter: CharacterEntity) {
    this.cancelPlannedAction()
    if (
      CrowdControlsService.hasRouteControls(
        myCharacter.statusEffectsGroups.crowdControlList.map((control) => control.type),
      )
    ) {
      return
    }
    await matchStore.getState().wsClient?.mutate({
      mutation: RouteDocument,
      variables: {
        destination: coordinate,
      },
    })

    eventBus.emit('action.triggered', {})
  }
}

export const actionPlanner = new ActionPlanner()
