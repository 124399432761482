import { useNavigate } from 'react-router-dom'
import type { LoginInput } from '~generated/graphql'
import { SessionDocument, useEnterGameMutation, useLoginMutation } from '~generated/graphql'

export const useLogin = () => {
  const navigate = useNavigate()
  const [enterGame] = useEnterGameMutation({
    refetchQueries: [SessionDocument],
  })
  const [login, query] = useLoginMutation({
    onError: (error) => {
      console.error(error)
    },
    onCompleted: async (loginResult) => {
      if (!loginResult?.login.user) {
        return
      }

      if (!loginResult?.login.user?.characters?.length) {
        navigate('/create-character')
      } else {
        await enterGame({
          variables: {
            characterId: loginResult.login.user.characters[0].id,
          },
        })
      }
    },
  })

  const loginMutation = async (data: LoginInput) => {
    await login({ variables: { data } })
  }

  return [loginMutation, query] as [typeof loginMutation, typeof query]
}
