import type { FC } from 'react'
import styled from '@emotion/styled'
import TopLeftBg from '~assets/bg-map-top-corner.svg'
import BottomRightBg from '~assets/bg-map-bottom-corner.svg'
import { MatchAbilitiesPanel } from './components/MatchAbilitiesPanel'
import { useMatchInit } from './hooks/useMatchInit'
import { MatchSubscriptions } from './MatchSubscriptions'
import { MatchCharacterPanel } from './components/MatchCharacterPanel'
import { MatchMinimap } from './components/MatchMinimap'
import { TargetMatchCharacterPanel } from './components/TargetMatchCharacterPanel'
import { MatchCastProgress } from './components/MatchCastProgress'
import { MatchInfoPanel } from './components/MatchInfoPanel'
import { MatchChat } from './components/chat/MatchChat'
import { MatchNotification } from './components/MatchNotification'

export const Match: FC = () => {
  useMatchInit()
  return (
    <Container>
      <MapView id="match-map" />
      <MatchInfoPanel />
      <MatchMinimap />
      <MatchAbilitiesPanel />
      <MatchCastProgress />
      <MatchCharacterPanel />
      <TargetMatchCharacterPanel />
      <MatchSubscriptions />
      <MatchNotification />
      <MatchChat />
    </Container>
  )
}

const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    user-select: none;
    pointer-events: none;
    background: url(${TopLeftBg}) no-repeat left -45px, url(${BottomRightBg}) no-repeat right bottom;
  }
`

const MapView = styled.div`
  width: 100%;
  height: 100%;
`
