import type { Map } from 'maplibre-gl'
import { timer } from 'rxjs'
import type { CharacterEntity } from '../CharacterEntity'
import { AnimationMarker } from './AnimationMarker'
import type { MatchCharacterAbilityWithAnimation } from './CastedAbilityAnimation'
import type { Point, Second } from '~shared-types'
import { convertMetersToPixels } from '~utils/map'
import { angleBetweenTwoPoints, spatialDistance } from '~map-tools'
import { AbilityAnimationUI } from '../../components/animation/AbilityAnimationUI'
import { animationDurationMap } from '~utils/animation'
import { AbilityAttackDirectionAnimationUI } from '../../components/animation/AbilityAttackDirectionAnimationUI'

export class AttackDirectionAnimation {
  constructor(
    map: Map,
    caster: CharacterEntity,
    target: CharacterEntity | Point,
    castedAbility: MatchCharacterAbilityWithAnimation,
    motionDuration: Second = 1,
    motionDelay: Second = 0.5,
  ) {
    const angle = angleBetweenTwoPoints(
      caster.position,
      'firstName' in target ? target.position : target,
    )

    // source animation
    const sourceSubscription = timer(motionDelay * 1000).subscribe(() => {
      sourceSubscription.unsubscribe()
      const sourceMarkerComponent = (
        <AbilityAnimationUI
          side={caster.side}
          race={caster.race}
          animationType="ATTACK_RANGED_SOURCE"
          id={`${caster.id}${castedAbility.id}${Date.now()}ATTACK_RANGED_SOURCE`}
        />
      )
      const sourceMarker = new AnimationMarker(
        map,
        sourceMarkerComponent,
        caster.position,
        false,
        angle,
      )
      const sourceRemoveSubscription = timer(
        animationDurationMap.ATTACK_RANGED_SOURCE * 1000,
      ).subscribe(() => {
        sourceMarker.remove()
        sourceRemoveSubscription.unsubscribe()
      })
    })

    const distance = spatialDistance(
      caster.position,
      'firstName' in target ? target.position : target,
    )
    const widthPx = Math.round(convertMetersToPixels(map, distance))
    const height =
      castedAbility.shape?.__typename === 'AbilityDirectionShapeLine'
        ? castedAbility.shape.width
        : 0
    const heightPx = Math.round(convertMetersToPixels(map, height))
    const component = (
      <AbilityAttackDirectionAnimationUI
        side={caster.side}
        race={caster.race}
        width={widthPx}
        height={heightPx}
        id={`${caster.id}${castedAbility.id}${Date.now()}ATTACK_DIRECTION`}
        motionDelay={motionDelay}
        motionDuration={motionDuration}
      />
    )
    const marker = new AnimationMarker(map, component, caster.position, true, angle + 90)

    const motionSubscription = timer(motionDelay * 1000 + motionDuration * 1000 + 500).subscribe(
      () => {
        motionSubscription.unsubscribe()
        marker.remove()
      },
    )
  }
}
