import styled from '@emotion/styled'
import { forwardRef } from 'react'

import IconTooltipPin from '~assets/icon-tooltip-pin.svg'
import { ReactComponent as IconClose } from '~assets/icon-dialog-close.svg'
import IconStats from '~assets/icon-stats.svg'

type TooltipStatsItem = {
  label: string
  value: string | React.ReactNode
}

export type MapPopoverProps = {
  cover?: {
    src: string
    src2x?: string
  }
  title: string | React.ReactNode
  address?: string
  description?: string | React.ReactNode
  stats?: TooltipStatsItem[]
  className?: string
  style?: React.CSSProperties
  buttons?: React.ReactNode[]
  onClose?: () => void
}

type Ref = HTMLDivElement

export const MapPopover = forwardRef<Ref, MapPopoverProps>(
  ({ title, address, description, className, style, buttons, stats, onClose, ...props }, ref) => (
    <Container className={className} ref={ref} style={style} {...props}>
      <Title>{title}</Title>
      {address ? (
        <Address>
          <img src={IconTooltipPin} alt="pin" />
          {address}
        </Address>
      ) : null}
      {stats ? (
        <Stats>
          {stats.map(({ label, value }) => (
            <StatsItem key={label}>
              <StatsItemLabel>
                <img src={IconStats} alt="stats-icon" />
                {label}
              </StatsItemLabel>
              <StatsItemValue>{value}</StatsItemValue>
            </StatsItem>
          ))}
        </Stats>
      ) : null}
      {description ? <Description>{description}</Description> : null}
      {buttons ? <ButtonContainer>{buttons}</ButtonContainer> : null}
      {onClose ? <StyledIconClose onClick={onClose} /> : null}
    </Container>
  ),
)

const Container = styled.div`
  background: linear-gradient(to bottom, #131927, #2a3041);
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 12px 48px #080d137d;
  font-size: 14px;
  color: #fff;
  z-index: 100;
  width: 300px;
  cursor: default;
`

const Title = styled.div`
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
`

const Address = styled.div`
  display: flex;
  align-items: center;
  color: #646d85;
  margin-top: 4px;

  img {
    margin-right: 4px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  gap: 12px;
`

const Description = styled.div`
  margin-top: 14px;
  line-height: 18px;
`

const StyledIconClose = styled(IconClose)`
  position: absolute;
  top: 16px;
  right: 12px;
  cursor: pointer;
  width: 16px;
  height: 16px;
`

const Stats = styled.div`
  margin-top: 14px;
  display: flex;
  flex-direction: column;
`

const StatsItem = styled.div`
  padding: 8px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid #3c4860;
  }
`

const StatsItemLabel = styled.div`
  color: #ada08a;
  display: flex;
  align-items: center;

  img {
    margin-right: 8px;
    display: block;
    position: relative;
    margin-top: -1px;
  }
`

const StatsItemValue = styled.div`
  color: #fff;
  font-weight: 600;
`
