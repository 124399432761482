import styled from '@emotion/styled'
import { Duration } from 'luxon'
import type { FC } from 'react'
import { useEffect, useState } from 'react'
import { interval } from 'rxjs'
import type { Millisecond } from '~shared-types'

type Props = {
  startAt?: Millisecond
}

export const MatchInfoPanelTimer: FC<Props> = ({ startAt = 0 }) => {
  const [time, setTime] = useState<number>(0)

  useEffect(() => {
    if (!startAt) return
    const timer$ = interval(1000).subscribe(() => {
      setTime(() => Date.now() - startAt + 1000)
    })

    return () => timer$.unsubscribe()
  }, [startAt])

  return <Container>{Duration.fromMillis(time).toFormat('mm:ss')}</Container>
}

const Container = styled.div`
  font-size: 20px;
  line-height: 20px;
  font-weight: 600;
  margin: 0 10px;
  color: #fff;
  flex-shrink: 0;
  width: 60px;
  display: flex;
  justify-content: center;
`
