import { Marker } from 'maplibre-gl'
import { createRoot } from 'react-dom/client'
import type { Subscription } from 'rxjs'
import type { Side, MatchMapCharacterFragment as MatchMapCharacter } from '~generated/match-graphql'
import type { TwilightLevel } from '~types'
import { MinimapCharacterMarker } from '~components/map/Minimap/MinimapCharacterMarker'
import type { Point } from '~shared-types'
import type { CharacterEntity } from './CharacterEntity'
import type { MatchMinimap } from './MatchMinimap'
import { imageUrlMap } from '~constants/imageUrlMap'

export class MinimapCharacter {
  public id: string
  public side: Side
  public twilightLevel: TwilightLevel
  public position: MatchMapCharacter['position']
  public teamId: string
  #marker: Marker
  public isCurrentUser?: boolean
  private subscriptions: Subscription[]

  constructor(private minimapClass: MatchMinimap, private character: CharacterEntity) {
    this.id = character.id
    this.side = character.side
    this.twilightLevel = character.twilightLevel as TwilightLevel
    this.position = character.position
    this.teamId = character.teamId
    this.isCurrentUser = character.isCurrentUser

    const div = document.createElement('div')
    const root = createRoot(div)
    const component = (
      <MinimapCharacterMarker
        side={character.side}
        avatar={character.avatar || imageUrlMap.ImgFallbackCharacter}
      />
    )
    root.render(component)
    this.#marker = new Marker({ element: div, className: 'maplibregl-minimap-marker-character' })
    this.#marker.setLngLat(character.position)
    minimapClass.layerManager.addMarker(this.#marker)

    character.positionObservable.subscribe((position) => this.updatePosition(position))

    const positionSubscription = character.positionObservable.subscribe((position) =>
      this.updatePosition(position),
    )
    this.subscriptions = [positionSubscription]
  }

  updatePosition(position: Point) {
    this.#marker.setLngLat(position)
    this.minimapClass.minimap.setCenter(position)
  }

  destroy() {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe())
    this.#marker.remove()
  }
}
