import styled from '@emotion/styled'
import { useState } from 'react'
import type { ChangeEventHandler, InputHTMLAttributes } from 'react'
import type { UseFormRegister, FieldValues, Path, RegisterOptions } from 'react-hook-form'
import { ReactComponent as IconEye } from '~assets/icon-eye.svg'
import { ReactComponent as IconEdit } from '~assets/icon-edit.svg'

type Props<T extends FieldValues> = InputHTMLAttributes<HTMLInputElement> & {
  register: UseFormRegister<T>
  name: Path<T>
  rules?: RegisterOptions
  className?: string
  label?: string
  error?: string
  onChange?: ChangeEventHandler<string>
  onEdit?: () => void
}

export const Input = <T extends FieldValues>({
  name,
  className,
  label,
  error,
  register,
  rules,
  onChange: onInputChange,
  onEdit,
  type: initialType,
  ...inputProps
}: Props<T>) => {
  const { onChange } = register(name, rules)
  const [type, setType] = useState<InputHTMLAttributes<HTMLInputElement>['type']>(
    initialType || 'text',
  )

  const toggleType = () => {
    setType((prev) => (prev === 'password' ? 'text' : 'password'))
  }

  return (
    <Container className={className}>
      {label ? <Label htmlFor={name}>{label}</Label> : null}
      <InputContainer>
        <StyledInput
          type={type}
          {...inputProps}
          {...register?.(name, rules)}
          onChange={async (e) => {
            await onChange(e)
            onInputChange?.(e)
          }}
          error={!!error}
        />
        {initialType === 'password' ? (
          <IconEye fill={type === 'text' ? '#ADA08A' : '#3C4860'} onClick={toggleType} />
        ) : null}
        {onEdit ? <IconEdit onClick={onEdit} /> : null}
      </InputContainer>
      {error ? <Error>{error}</Error> : null}
    </Container>
  )
}

const Label = styled.label`
  flex: 1;
  display: block;
  color: #646d85;
  font-size: 16px;
  font-weight: 600;
  padding-right: 16px;
  flex-shrink: 0;
`

const StyledInput = styled.input<{ error?: boolean }>`
  width: 100%;
  height: 100%;
  background: #131927;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  border: none;
  /* border-color: ${({ error }) => (error ? '#EA4357' : '#3c4860')}; */
  padding: 0 12px;
  font-size: 16px;
  font-weight: 500;
  appearance: none;
  outline: none;
  color: ${({ error }) => (error ? '#EA4357' : '#fff')};

  &::placeholder {
    color: #3c4860;
    font-family: Overpass;
    font-size: 16px;
    font-weight: 400;
  }
`

const Container = styled.div`
  width: 100%;
  background: linear-gradient(to bottom, #2a304126 0%, #2a3041 100%);
  display: flex;
  padding: 2px 2px 2px 16px;
  height: 48px;
  border-radius: 12px;
  align-items: center;

  &:not(:first-of-type) {
    margin-top: 12px;
  }
`

const InputContainer = styled.div`
  position: relative;
  width: 52%;
  min-width: 250px;
  height: 100%;

  svg {
    position: absolute;
    right: 16px;
    cursor: pointer;
    top: 50%;
    margin-top: -12px;
  }
`

const Error = styled.div`
  color: #ea4357;
  font-size: 14px;
  margin-top: 12px;
  padding-left: 18px;
`
