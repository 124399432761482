import type { FC } from 'react'
import styled from '@emotion/styled'
import { raceColorMap } from '../../../../utils/character'
import type { Race } from '~shared-types'
import { ReactComponent as IconDamageSource } from '~assets/icon-marker-damage-source.svg'

type Props = {
  race: Race
}

export const AbilityDamageSourceUI: FC<Props> = ({ race }) => <StyledIconDamageSource race={race} />

const StyledIconDamageSource = styled(IconDamageSource)<{ race: Race }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  --icon-damage-source-dark-color: ${({ race }) => raceColorMap[race].dark};
  --icon-damage-source-main-color: ${({ race }) => raceColorMap[race].main};
`
