import type { ComponentType } from 'react'
import React, { useState } from 'react'
import type { Placement } from '@floating-ui/react-dom'
import { offset, shift, flip } from '@floating-ui/react-dom'
import styled from '@emotion/styled'
import { FloatingPortal, useHover, useInteractions, useFloating } from '@floating-ui/react'
import type { Observable } from 'rxjs'
import type { Ability } from '~generated/match-graphql'
import { AbilityTooltip } from './AbilityTooltip'
import type { AdvancedStats } from '../CharacterPanel/CharacterAdvancedStats'

type TooltipInfo =
  | {
      type: 'ability'
      ability: Partial<
        Pick<Ability, 'name' | 'description' | 'castTime' | 'cooldown' | 'cost' | 'costType'>
      >
      statsObservable?: Observable<AdvancedStats>
    }
  | {
      type: 'text'
      content: string | React.ReactNode
    }

type TooltipOptions = {
  placement?: Placement
  maxWidth?: number
  offset?: number
}

const withTooltip =
  <T extends object>(Component: ComponentType<T>, tooltip: TooltipInfo, options?: TooltipOptions) =>
  (props: T) => {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const { refs, floatingStyles, context } = useFloating({
      placement: options?.placement || 'top',
      strategy: 'fixed',
      middleware: [offset(options?.offset ?? 10), shift(), flip()],
      open: isTooltipOpen,
      onOpenChange: setIsTooltipOpen,
    })

    const hover = useHover(context)

    const { getReferenceProps, getFloatingProps } = useInteractions([hover])

    return (
      <>
        {isTooltipOpen ? (
          <FloatingPortal>
            {tooltip.type === 'ability' ? (
              <AbilityTooltip
                {...tooltip.ability}
                statsObservable={tooltip.statsObservable}
                ref={refs.setFloating}
                style={{
                  ...floatingStyles,
                  zIndex: 100,
                }}
                {...getFloatingProps()}
              />
            ) : (
              <Tooltip
                ref={refs.setFloating}
                style={{
                  ...floatingStyles,
                  maxWidth: options?.maxWidth,
                  zIndex: 100,
                }}
                {...getFloatingProps()}
              >
                {tooltip.content}
              </Tooltip>
            )}
          </FloatingPortal>
        ) : null}
        <Component {...(props as T)} ref={refs.setReference} {...getReferenceProps()} />
      </>
    )
  }

export default withTooltip

const Tooltip = styled.div`
  background: linear-gradient(to bottom, #131927, #2a3041);
  border-radius: 8px;
  padding: 8px 12px;
  color: #646d85;
  font-size: 14px;
`
