import styled from '@emotion/styled/macro'
import type { UseFormRegister, FieldValues, Path, RegisterOptions } from 'react-hook-form'
import type { ChangeEventHandler, InputHTMLAttributes, PropsWithChildren } from 'react'
import IconCheckbox from '~assets/icon-checkbox.svg'

type Props<T extends FieldValues> = InputHTMLAttributes<HTMLInputElement> & {
  register: UseFormRegister<T>
  name: Path<T>
  rules?: RegisterOptions<T>
  className?: string
  error?: string
  onChange?: ChangeEventHandler<string>
}

export const Checkbox = <T extends FieldValues>({
  children,
  name,
  className,
  error,
  register,
  rules,
  onChange: onInputChange,
  ...inputProps
}: PropsWithChildren<Props<T>>) => {
  const { onChange } = register(name, rules)

  return (
    <Container className={className}>
      <Label>
        <StyledInput
          type="checkbox"
          {...inputProps}
          {...register?.(name, rules)}
          onChange={async (e) => {
            await onChange(e)
            onInputChange?.(e)
          }}
        />
        <Checkmark error={!!error} />
        <ChildrenContainer>{children}</ChildrenContainer>
      </Label>
    </Container>
  )
}

const ChildrenContainer = styled.div``

const Checkmark = styled.div<{ error?: boolean }>`
  width: 24px;
  height: 24px;
  background-color: #131927;
  border-radius: 6px;
  margin-right: 8px;
  display: inline-block;
  position: relative;

  ${({ error }) =>
    error &&
    `&:before {
      opacity: 1;
    }`}

  &:before {
    content: '';
    opacity: ${({ error }) => (error ? 1 : 0)};
    position: absolute;
    border: 1px solid #ea4357;
    width: 100%;
    height: 100%;
    border-radius: 6px;
    z-index: 1;
  }

  &:after {
    content: '';
    opacity: 0;
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0px;
    left: 0px;
    background: url(${IconCheckbox}) 50% 50% no-repeat;
    transition: opacity 0.1s ease-in;
  }
`

const Label = styled.label`
  display: flex;
  align-items: flex-end;
  position: relative;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;

  > input:checked + ${Checkmark}:after {
    opacity: 1;
  }
`

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`

const Container = styled.div``

const Error = styled.div`
  color: #ea4357;
  font-size: 12px;
  margin-top: 5px;
  padding-left: 32px;
  position: absolute;
`
