import { useMemo } from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import { of, mergeMap, concat, EMPTY, delay, map } from 'rxjs'
import type { Observable } from 'rxjs'
import type { Race, Side } from '~shared-types'
import { useValueFromObservable } from '../../quest/useValueFromObservable'
import { MarkerIconUI } from './MarkerIconUI'
import { CharacterTwilightRelation } from '~map-tools'
import type { MarkerAvatarFragment } from '../../../generated/graphql'
import type { CrowdControl } from '../../../views/match/model/statusEffectsGroups/CharacterStatusEffectsGroup'

type Props = {
  race?: Race
  side?: Side
  characterAvatar?: Partial<MarkerAvatarFragment>
  className?: string
  twilightRelation?: CharacterTwilightRelation
  crowdControlsObservable?: Observable<CrowdControl[]>
  castedAbilityObservable?: Observable<{ icon: string }>
  alive?: boolean
}

export const MarkerIcon: FC<Props> = ({
  race,
  side,
  characterAvatar,
  className,
  twilightRelation = CharacterTwilightRelation.REACHABLE,
  crowdControlsObservable = of([]),
  castedAbilityObservable = EMPTY,
  alive = true,
}) => {
  const crowdControls = useValueFromObservable(crowdControlsObservable)
  const castedAbilityImgObservable = useMemo(
    () =>
      castedAbilityObservable.pipe(
        map((ability) => ability.icon),
        mergeMap((ability) => concat(of(ability), of(null).pipe(delay(1000)))),
      ),
    [castedAbilityObservable],
  )
  const castedAbilityImg = useValueFromObservable(castedAbilityImgObservable)

  return (
    <StyledMarkerIcon
      race={race}
      alive={alive}
      side={side}
      characterAvatar={characterAvatar}
      className={className}
      crowdControls={crowdControls}
      twilightRelation={twilightRelation}
      castedAbilityImageUrl={castedAbilityImg ?? undefined}
    />
  )
}

const StyledMarkerIcon = styled(MarkerIconUI)`
  grid-row-start: 1;
  grid-row-end: 4;
  box-shadow: 0 1px 3px 1px #081f2dbc;
`
