import styled from '@emotion/styled'
import type { FC } from 'react'
import { RhombusItem } from '../RhombusItem'
import type { AbilityTooltipProps } from '../Tooltip/AbilityTooltip'
import { AbilityTooltip } from '../Tooltip/AbilityTooltip'
import withTooltip from '~components/Tooltip/withTooltip'

type DoubleAbilityProps = {
  ability1: AbilityTooltipProps & AbilityComponentIcon
  ability2: AbilityTooltipProps & AbilityComponentIcon
} & AbilityComponentState

export const DoubleAbility: FC<DoubleAbilityProps> = ({
  ability1,
  ability2,
  disabled,
  inactive,
  ...props
}) => {
  const DoublePart1RhombusItemWithTooltip = ability1.name
    ? withTooltip(DoublePartRhombusItem, {
        type: 'ability',
        ability: ability1,
      })
    : DoublePartRhombusItem
  const DoublePart2RhombusItemWithTooltip = ability2.name
    ? withTooltip(DoublePartRhombusItem, {
        type: 'ability',
        ability: ability2,
      })
    : DoublePartRhombusItem
  return (
    <DoubleAbilityContainer>
      <DoublePart1RhombusItemWithTooltip
        disabled={disabled}
        inactive={inactive}
        icon={ability1.icon}
        size={48}
        borderSize={3}
        borderColor="#808080"
      />
      <DoublePart2RhombusItemWithTooltip
        disabled={disabled}
        inactive={inactive}
        icon={ability2.icon}
        size={48}
        borderSize={3}
        borderColor="#808080"
      />
    </DoubleAbilityContainer>
  )
}

const DoubleAbilityContainer = styled.div`
  height: 68px;
  width: 68px;
  position: relative;

  > div {
    top: 0;
    left: 0;
    position: absolute;
  }

  > div:last-child {
    top: 19px;
    left: 19px;

    > div > div:before {
      left: auto;
      top: auto;
      bottom: -9px;
      right: -9px;
    }
  }
`

const StyledTooltip = styled(AbilityTooltip)``

const StyledRhombusItem = styled(RhombusItem)<{ disabled?: boolean; inactive?: boolean }>`
  > div {
    position: relative;
    z-index: 2;

    &:before {
      content: '';
      display: ${({ disabled }) => (disabled ? 'block' : 'none')};
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      background: #131927;
      opacity: 0.8;
      z-index: 2;
    }

    > div:after {
      content: '';
      display: ${({ disabled, inactive }) => (disabled || inactive ? 'block' : 'none')};
      position: absolute;
      top: -9px;
      left: -9px;
      right: -9px;
      bottom: -9px;
      top: -7px;
      left: -7px;
      right: -7px;
      bottom: -7px;
      background: linear-gradient(to bottom, #725f9a 0%, #322262 100%);
      opacity: 0.2;
      z-index: 1;
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
      transform: rotate(-45deg);
      z-index: 1;
    }

    &:hover {
      z-index: 2;

      ${StyledTooltip} {
        display: block;
      }
    }

    ${({ disabled, inactive }) =>
      disabled || inactive
        ? `
      > div:before {
    filter: grayscale(50%);
    background-color: #00000075;
    background-blend-mode: saturation;
  }`
        : null}
  }
`

const DoublePartRhombusItem = styled(StyledRhombusItem)`
  > div {
    width: 21px;
    height: 48px;
  }
`

type AbilityComponentState = {
  disabled?: boolean
  inactive?: boolean
}

type AbilityComponentIcon = {
  icon: string
}

type AbilityProps = AbilityComponentIcon & AbilityTooltipProps & AbilityComponentState

export const ActiveAbility: FC<AbilityProps> = ({
  disabled = false,
  inactive = false,
  ...props
}) => {
  const RhombusItemWithTooltip = props.name
    ? withTooltip(StyledRhombusItem, {
        type: 'ability',
        ability: props,
      })
    : StyledRhombusItem

  return (
    <RhombusItemWithTooltip
      disabled={disabled}
      inactive={inactive}
      size={48}
      borderSize={3}
      borderColor="#808080"
      {...props}
    />
  )
}
