import styled from '@emotion/styled'
import type { Observable } from 'rxjs'
import { EffectItem } from './EffectItem'
import type { StatusEffectsGroupFragment } from '../../views/match/model/statusEffectsGroups/CharacterStatusEffectsGroups'
import { AbilityManager } from '../../views/match/model/AbilityManager'
import { useValueFromObservable } from '../quest/useValueFromObservable'

type Props = {
  statusEffectsGroupsSubject: Observable<StatusEffectsGroupFragment[]>
}

export const CharacterStatusEffects = ({ statusEffectsGroupsSubject }: Props) => {
  const statusEffectsGroups = useValueFromObservable(statusEffectsGroupsSubject)

  return (
    <EffectsContainer>
      {statusEffectsGroups
        ?.map((effectGroup) => ({
          ...effectGroup,
          ability: AbilityManager.getInstance().get(effectGroup.causedByAbilityEffect.ability.id),
        }))
        .filter((effectGroup) => effectGroup.ability !== undefined)
        .map((effectGroup) => (
          <EffectItem
            key={effectGroup.id}
            effectGroup={{
              ...effectGroup,
              statusEffects: effectGroup.statusEffectIds
                .map((effectId) =>
                  effectGroup.ability?.statusEffects.find(({ id }) => id === effectId),
                )
                .filter((effect) => effect !== undefined),
            }}
            ability={effectGroup.ability!}
          />
        ))}
    </EffectsContainer>
  )
}

const EffectsContainer = styled.div`
  display: flex;
  margin-top: 5px;
  margin-left: -5px;
  pointer-events: auto;
`
