import type { FC } from 'react'
import styled from '@emotion/styled'
import type { Side } from '~shared-types'
import { sideColorMap } from '../../../utils/character'

type Props = {
  id: string
  progress: number
  side: Side
}

export const EnergyBarSvg: FC<Props> = ({ id, progress, side }) => {
  const color = sideColorMap[side]
  return (
    <Svg viewBox="0 0 108 108">
      <defs>
        <filter
          id={`energy-filter-${id}`}
          x="0"
          y="0.107422"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.835294 0 0 0 0 0.121569 0 0 0 0 0.207843 0 0 0 0.75 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1421_143513" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1421_143513"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.866667 0 0 0 0 0.517647 0 0 0 0.5 0"
          />
          <feBlend mode="overlay" in2="shape" result="effect2_innerShadow_1421_143513" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="0.5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
          <feBlend
            mode="overlay"
            in2="effect2_innerShadow_1421_143513"
            result="effect3_innerShadow_1421_143513"
          />
        </filter>
        <linearGradient id={`energy-gradient-${id}`} x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={color.light} />
          <stop offset="50%" stopColor={color.medium} />
          <stop offset="100%" stopColor={color.dark} />
        </linearGradient>
      </defs>
      <circle
        cx="54"
        cy="54"
        r="50"
        stroke={side === 'DARKNESS' ? '#6B222F' : '#11337D'}
        strokeWidth={5}
        fill="none"
        strokeDasharray={314}
        strokeDashoffset={156}
        transform="rotate(90, 54 54)"
      />
      {/* <g filter="url(#energy-filter)"> */}
      <g>
        <circle
          shapeRendering="auto"
          cx="54"
          cy="54"
          r="50"
          stroke={`url(#energy-gradient-${id})`}
          strokeWidth={5}
          fill="none"
          strokeDasharray={314}
          strokeDashoffset={314 - 146 * progress}
          transform="rotate(100, 54 54)"
          strokeLinecap="round"
        />
      </g>
    </Svg>
  )
}

const Svg = styled.svg`
  width: 86px;
  height: 86px;
  position: absolute;
  left: 3px;
`
