import styled from '@emotion/styled'
import type { FC } from 'react'
import { Button } from '~components/Button'
import { t } from '~utils/i18n'
import { usePopover } from '~hooks/usePopover'
import { MapPopover } from '~components/MapPopover'

import IconMapHome from '~assets/icon-map-home.svg'
import IconMapShop from '~assets/icon-map-shop.svg'
import IconMapDozorLight from '~assets/icon-map-dozor-light.svg'
import IconMapDozorDark from '~assets/icon-map-dozor-dark.svg'
import { ReactComponent as IconMapQuest } from '~assets/icon-map-quest.svg'

export enum MarkerIconType {
  SHOP = 'SHOP',
  HOME = 'HOME',
  LIGHT_ORGANISATION = 'LIGHT_ORGANISATION',
  DARK_ORGANISATION = 'DARK_ORGANISATION',
  QUEST = 'QUEST',
}

export type MarkerTooltip = {
  title: string
  address?: string
  description?: string
  actionLabel?: string
  onClick?: () => void
}

type Props = {
  iconType?: MarkerIconType
  tooltip?: MarkerTooltip
  size?: number
  borderSize?: number
  disabled?: boolean
}

const iconMap: Record<MarkerIconType, string> = {
  [MarkerIconType.QUEST]: '',
  [MarkerIconType.SHOP]: IconMapShop,
  [MarkerIconType.HOME]: IconMapHome,
  [MarkerIconType.LIGHT_ORGANISATION]: IconMapDozorLight,
  [MarkerIconType.DARK_ORGANISATION]: IconMapDozorDark,
}

const DEFAULT_SIZE = 52

export const MapMarker: FC<Props> = ({
  iconType = MarkerIconType.QUEST,
  tooltip,
  size = DEFAULT_SIZE,
  borderSize = 2,
}) => {
  const { isPopoverOpen, popoverProps, parentProps, closePopover } = usePopover()
  return (
    <>
      {isPopoverOpen && tooltip ? (
        <MapPopover
          title={tooltip.title}
          address={tooltip.address}
          description={tooltip.description}
          onClose={closePopover}
          buttons={
            tooltip.onClick && [
              <SubmitButton
                key="submit"
                onClick={() => {
                  tooltip.onClick?.()
                  closePopover()
                }}
              >
                {tooltip.actionLabel || t('button:choose')}
              </SubmitButton>,
            ]
          }
          {...popoverProps}
        />
      ) : null}
      {iconType === MarkerIconType.QUEST ? (
        <ContainerQuest
          size={size}
          disabled={!tooltip}
          active={isPopoverOpen && !!tooltip}
          {...(!tooltip ? {} : parentProps)}
        >
          <IconMapQuest />
        </ContainerQuest>
      ) : (
        <ContainerPin
          size={size}
          borderSize={borderSize}
          disabled={!tooltip}
          active={isPopoverOpen && !!tooltip}
          {...(!tooltip ? {} : parentProps)}
        >
          <img src={iconMap[iconType]} alt="map-marker" />
        </ContainerPin>
      )}
    </>
  )
}

const Container = styled.div<{ active: boolean; size: number; disabled: boolean }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  display: flex;
  justify-content: center;
  transform: scale(${({ active }) => (active ? 1.15 : 1)});
  transition: transform 0.2s;
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  &:hover {
    transform: scale(1.15);
  }
`

const ContainerQuest = styled(Container)`
  align-items: center;

  svg {
    pointer-events: none;
    flex-shrink: 0;
    max-width: 200%;
  }
`

const ContainerPin = styled(Container)<{ borderSize: number }>`
  border-radius: 50%;
  background: linear-gradient(
    331deg,
    #808080 11.11%,
    #ada08a 33.94%,
    #d9d1bb 62.58%,
    #eae4cf 85.42%
  );
  overflow: hidden;
  box-shadow: ${({ size }) => (size >= DEFAULT_SIZE ? '0 2px 3px 3px #090c133f' : 'none')};

  &:before {
    content: '';
    position: absolute;
    left: ${({ borderSize }) => borderSize}px;
    top: ${({ borderSize }) => borderSize}px;
    right: ${({ borderSize }) => borderSize}px;
    bottom: ${({ borderSize }) => borderSize}px;
    background: #131927;
    border-radius: 50%;
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
`

const SubmitButton = styled(Button)`
  min-width: auto;
  flex-grow: 1;
`
