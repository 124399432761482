import styled from '@emotion/styled'
import { useCallback, useEffect } from 'react'
import Logo from '~assets/logo-main.png'
import Logo2x from '~assets/logo-main_2x.png'
import { ReactComponent as IconClose } from '~assets/icon-close.svg'
import { SessionDocument, useLogoutMutation } from '~generated/graphql'
import { Dialog } from '../../Dialog'
import { Button } from '../../Button'
import { useStore, useMatchStore } from '~store'
import { t } from '~utils/i18n'

export const EscDialog = () => {
  const [clearStore, fullscreenId, setFullscreenId] = useStore((state) => [
    state.clearStore,
    state.fullscreenId,
    state.setFullscreenId,
  ])
  const [clearMatchStore] = useMatchStore((state) => [state.clearMatchStore])
  const [logout] = useLogoutMutation({
    refetchQueries: [SessionDocument],
  })

  const handlePageKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        setFullscreenId(fullscreenId === 'esc' ? null : 'esc')
      }
    },
    [fullscreenId],
  )

  const handleLogout = async () => {
    await logout()
    clearStore()
    clearMatchStore()
  }

  useEffect(() => {
    document.addEventListener('keyup', handlePageKeyUp)

    return () => {
      document.removeEventListener('keyup', handlePageKeyUp)
    }
  }, [])

  if (fullscreenId !== 'esc') {
    return null
  }

  return (
    <Container>
      <StyledIconClose onClick={() => setFullscreenId(null)} />
      <StyledDialog relative>
        <StyledLogo srcSet={`${Logo} 1x, ${Logo2x} 2x`} alt="logo" />
        <ButtonList>
          {/* <Button outline>{t('dialog:escDialog.support')}</Button> */}
          <Button outline onClick={() => setFullscreenId('settings')}>
            {t('dialog:escDialog.settings')}
          </Button>
          <LogoutButton onClick={handleLogout}>{t('button:logOut')}</LogoutButton>
        </ButtonList>
      </StyledDialog>
    </Container>
  )
}

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  z-index: 1000;
  background: rgba(19, 25, 39, 0.5);
  backdrop-filter: blur(8px);
`

const StyledDialog = styled(Dialog)`
  top: 0;
  bottom: 0;
  border-top: 0;
  border-bottom: 0;
  width: 446px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 74px 20px;
`

const StyledLogo = styled.img`
  width: 608px;
  height: auto;
  flex-shrink: 0;
`

const ButtonList = styled.div`
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 24px;
  }
`

const LogoutButton = styled(Button)`
  /* margin-top: 100px; */
  margin-top: 32px;
`
const StyledIconClose = styled(IconClose)`
  position: absolute;
  top: 36px;
  right: 36px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 1;
`
