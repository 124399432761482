import type { MatchCharacterAbilityFragment } from '~generated/match-graphql'
import type { Int } from '~shared-types'

export class AbilityManager {
  protected static instance: AbilityManager | undefined
  private abilities: Map<Int, MatchCharacterAbilityFragment> = new Map([])

  public static getInstance(): AbilityManager {
    if (!AbilityManager.instance) {
      AbilityManager.instance = new AbilityManager()
    }

    return AbilityManager.instance
  }

  public static remove() {
    delete AbilityManager.instance
  }

  public setAbilities(abilities: MatchCharacterAbilityFragment[]) {
    abilities.forEach((ability) => this.abilities.set(ability.id, ability))
  }

  public get(abilityId: Int): MatchCharacterAbilityFragment | undefined {
    return this.abilities.get(abilityId)
  }
}
