import { SourceSpecification, Marker } from 'maplibre-gl'
import type {
  AddLayerObject,
  LayerSpecification,
  Map as MaplibreMap,
  GeoJSONSource,
} from 'maplibre-gl'
import type { Point } from '~shared-types'

export function MapReady(target: unknown, propertyName: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value
  const mapsReady = new Map<unknown, boolean>()

  // eslint-disable-next-line no-param-reassign
  descriptor.value = function (...args: unknown[]) {
    const { map: instanceMap, isMapLoaded } = this as PropertyDescriptor & {
      isMapLoaded: boolean | Promise<true>
      map: MaplibreMap
    }

    if (!mapsReady.has(instanceMap)) {
      mapsReady.set(target, false)
    }
    const isReady = mapsReady.get(instanceMap)!
    if (isReady) {
      originalMethod.apply(this, args)
      return
    }

    if (isMapLoaded instanceof Promise) {
      isMapLoaded
        .then(() => {
          originalMethod.apply(this, args)
        })
        .catch((error: unknown) => console.error(`LayerManager ${propertyName} failed`, error))
        .finally(() => mapsReady.set(instanceMap, true))
    } else {
      mapsReady.set(instanceMap, true)
      originalMethod.apply(this, args)
    }
  }
  return descriptor
}

export class LayerManager {
  private isMapLoaded: boolean | Promise<true>
  constructor(private map: MaplibreMap) {
    this.isMapLoaded = map.loaded()
    if (!this.isMapLoaded) {
      this.isMapLoaded = new Promise((res) => {
        map.on('load', () => {
          this.isMapLoaded = true
          res(true)
        })
      })
    }
  }

  @MapReady
  placeLayerAfterBase(layerId: string) {
    const nextAfterBaseLayerId = this.getFirstLayerIdAfter('afterBase')
    if (nextAfterBaseLayerId) {
      this.map.moveLayer(layerId, nextAfterBaseLayerId)
    }
  }

  private getFirstLayerIdAfter(after: 'afterBase'): string | undefined {
    const { layers } = this.map.getStyle()
    // highway_major_inner is the latest layer in our tile server
    const highwayLayerIndex = layers.findIndex((layer) => layer.id === 'highway_major_inner')
    if (layers[highwayLayerIndex + 1]) {
      return layers[highwayLayerIndex + 1].id
    }
    return undefined
  }

  @MapReady
  createLayerWithSource(
    layerSpecification: AddLayerObject & { source: string },
    sourceSpecification: SourceSpecification,
    position?: 'afterBase',
  ) {
    this.removeLayer(layerSpecification.id)
    this.removeSource(layerSpecification.source)
    const beforeLayerId = position ? this.getFirstLayerIdAfter(position) : undefined
    this.map.addSource(layerSpecification.source, sourceSpecification)
    this.map.addLayer(
      layerSpecification as Omit<LayerSpecification, 'source'> & { source: SourceSpecification },
      beforeLayerId,
    )
  }

  @MapReady
  createLayersWithSharedSource(
    layerSpecifications: (AddLayerObject & { source: string })[],
    sourceSpecification: SourceSpecification,
    position?: 'afterBase',
  ) {
    layerSpecifications.forEach((layerSpecification) => {
      this.removeLayer(layerSpecification.id)
      this.removeSource(layerSpecification.source)
    })
    const beforeLayerId = position ? this.getFirstLayerIdAfter(position) : undefined
    this.map.addSource(layerSpecifications[0].source, sourceSpecification)
    layerSpecifications.forEach((layerSpecification) => {
      this.map.addLayer(
        layerSpecification as Omit<LayerSpecification, 'source'> & { source: SourceSpecification },
        beforeLayerId,
      )
    })
  }

  setNewSourceData(sourceId: string, sourceData: GeoJSON.GeoJSON) {
    const existingSource = this.map.getSource(sourceId) as GeoJSONSource | undefined
    existingSource?.setData(sourceData)
  }

  @MapReady
  removeLayer(layerId: string) {
    if (this.map.getLayer(layerId)) {
      this.map.removeLayer(layerId)
    }
  }

  @MapReady
  removeSource(sourceId: string) {
    if (this.map.getSource(sourceId)) {
      this.map.removeSource(sourceId)
    }
  }

  @MapReady
  addMarker(marker: Marker) {
    if (marker.getLngLat()) {
      marker.addTo(this.map)
    } else {
      console.error('LayerManager addMarker failed: missing position')
    }
  }

  @MapReady
  setMapBounds(bbox: [Point, Point]) {
    this.map.setMaxBounds(bbox)
  }
}
