import type { Subscription } from 'rxjs'
import { animationFrames, finalize, takeWhile, tap } from 'rxjs'
import { getPointOnTheRoute, getProgress } from '~map-tools'
import type { From0To100, Millisecond, Path, Point } from '~shared-types'

export type RouteData = {
  path: Path
  startAt: Millisecond
  duration: Millisecond
  source: Point
  destination: Point
}

export class Route {
  public progress: From0To100 = 0
  public path: Path
  public startAt: Millisecond
  public duration: Millisecond
  public source: Point
  public destination: Point
  public position: Point
  private subscription: Subscription

  constructor(
    public characterId: string,
    route: RouteData,
    onChange: (newState: { position: Point; progress: From0To100 }) => void,
    onEnd?: () => void,
  ) {
    this.duration = route.duration
    this.source = route.source
    this.destination = route.destination
    this.path = route.path
    this.startAt = route.startAt
    this.position = route.source

    this.subscription = animationFrames()
      .pipe(
        tap(() => {
          this.progress = getProgress(
            { startAt: this.startAt, duration: this.duration },
            Date.now(),
          )
          this.position = getPointOnTheRoute(this, this.progress)
          onChange({ position: this.position, progress: this.progress })
        }),
        takeWhile(() => this.progress < 100),
        finalize(() => (onEnd ? onEnd() : null)),
      )
      .subscribe()
  }

  cancel() {
    this.subscription.unsubscribe()
  }
}
