import styled from '@emotion/styled/macro'
import type { Race } from '~generated/graphql'
import { Side } from '~generated/graphql'
import type { FC, PropsWithChildren } from 'react'
import { RaceIconFrame } from './RaceIconFrame'
import { raceColorMap } from '../../../utils/character'

type Props = {
  side: Side
  race: Race
  active?: boolean
}

export const RaceRibbon: FC<PropsWithChildren<Props>> = ({
  side,
  race,
  children,
  active = false,
}) => {
  const id = race.toLowerCase()
  return (
    <Container id={id}>
      <Svg
        id={id}
        active={active}
        width="200"
        height="692"
        viewBox="0 0 200 692"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <Ribbon
          id={id}
          opacity="0.35"
          d="M100 1.41421L181 82.4142V609.586L100 690.586L19 609.586V82.4142L100 1.41421Z"
          stroke={`url(#${id}_paint_stroke_ribbon)`}
          strokeWidth="2"
        />
        <circle cx="100" cy="345" r="100" fill="#131927" />
        {side === 'DARKNESS' ? (
          <>
            <g opacity="0.35">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M110.525 22.7854L153.48 65.7404L136.2 62.4926L136.125 62.4181L136.032 62.4104L122.286 48.665C118.783 45.1613 113.098 45.1655 109.589 48.6744L103.419 54.8448L103.966 52.9341L99.6991 67.832L95.4544 52.9404L95.9988 54.8503L89.8375 48.6889C86.3338 45.1852 80.6489 45.1894 77.1401 48.6983L63.0214 62.8169L62.9145 62.8226L62.8341 62.9029L46.0001 65.7404L88.9392 22.8013L99.7045 60.5694L110.525 22.7854ZM104.55 50.8937L108.179 47.2646C110.805 44.6383 114.429 43.6184 117.827 44.2057L108.995 35.3735L104.55 50.8937ZM137.071 60.6256L147.502 62.5862L111.51 26.5932L109.624 33.1785L137.071 60.6256ZM90.4511 35.3872L94.8731 50.9008L91.2493 47.277C88.6269 44.6546 85.0048 43.6401 81.6059 44.2324L90.4511 35.3872ZM89.8257 33.193L61.9977 61.021L51.8242 62.7358L87.9495 26.6106L90.0887 34.1155L89.8257 33.193Z"
                fill={raceColorMap[race].main}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M92.8456 29.1072L90.9237 22.2266L99.7392 13.4111L108.542 22.2136L106.61 29.0971L99.7327 22.2201L92.8456 29.1072ZM99.7348 19.3985L105.611 25.2747L106.305 22.801L99.7371 16.2327L93.1592 22.8107L93.8499 25.2834L99.7348 19.3985Z"
                fill={raceColorMap[race].main}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M99.7091 54.3249L93.4269 32.4114L99.7298 26.1085L106.024 32.4021L99.7091 54.3249ZM103.781 32.983L99.7144 47.1006L95.6689 32.989L99.7278 28.9301L103.781 32.983Z"
                fill={raceColorMap[race].main}
              />
            </g>
            <g opacity="0.35">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M88.955 669.695L46.0001 626.74L63.2806 629.988L63.3552 630.062L63.4487 630.07L77.1941 643.815C80.6978 647.319 86.3826 647.315 89.8915 643.806L96.062 637.636L95.5147 639.546L99.7814 624.648L104.026 639.54L103.482 637.63L109.643 643.792C113.147 647.295 118.832 647.291 122.34 643.782L136.459 629.664L136.566 629.658L136.646 629.578L153.48 626.74L110.541 669.679L99.776 631.911L88.955 669.695ZM94.9304 641.587L91.3013 645.216C88.675 647.842 85.0514 648.862 81.6533 648.275L90.4856 657.107L94.9304 641.587ZM62.4097 631.855L51.978 629.894L87.971 665.887L89.8569 659.302L62.4097 631.855ZM109.029 657.093L104.607 641.58L108.231 645.203C110.854 647.826 114.476 648.84 117.875 648.248L109.029 657.093ZM109.655 659.287L137.483 631.459L147.656 629.745L111.531 665.87L109.392 658.365L109.655 659.287Z"
                fill={raceColorMap[race].dark}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M106.635 663.373L108.557 670.254L99.7413 679.069L90.9387 670.267L92.8708 663.383L99.7478 670.26L106.635 663.373ZM99.7457 673.082L93.8694 667.206L93.1751 669.68L99.7433 676.248L106.321 669.67L105.631 667.197L99.7457 673.082Z"
                fill={raceColorMap[race].dark}
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M99.7714 638.156L106.054 660.069L99.7506 666.372L93.4569 660.078L99.7714 638.156ZM95.6998 659.497L99.7661 645.38L103.812 659.491L99.7527 663.55L95.6998 659.497Z"
                fill={raceColorMap[race].dark}
              />
            </g>
          </>
        ) : (
          <>
            <path
              opacity="0.35"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M100 12L46.2599 65.7401L61.9677 62.7097L78.128 46.5494L93.7452 46.7929L100 59.3024L106.253 46.7961L121.957 46.6346L138.032 62.7097L153.74 65.7401L100 12ZM52.3208 62.4964L61.1245 60.7357L77.3157 44.5445L94.9859 44.8199L100 54.8481L105.016 44.8167L122.774 44.634L138.875 60.7357L147.679 62.4964L125.003 39.8199L123.946 39.7595L76.0537 39.7594L75.0368 39.7804L52.3208 62.4964ZM77.0498 37.7674L92.2527 22.5645L96.4785 26.7903L85.5014 37.7674L77.0498 37.7674ZM88.3186 37.7674L97.8871 28.1989L99.004 29.3158L99.004 37.7674L88.3186 37.7674ZM100.996 37.7674L100.996 29.3158L102.113 28.1989L111.681 37.7674L100.996 37.7674ZM114.499 37.7674L103.522 26.7903L107.747 22.5645L122.95 37.7674L114.499 37.7674ZM99.9247 14.7419L93.6613 21.1559L100 27.4946L106.263 21.0806L99.9247 14.7419Z"
              fill={raceColorMap[race].main}
            />
            <path
              opacity="0.35"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M100 680.48L153.74 626.74L138.032 629.771L121.872 645.931L106.255 645.688L100 633.178L93.7469 645.684L78.0429 645.846L61.9677 629.771L46.2599 626.74L100 680.48ZM147.679 629.984L138.875 631.745L122.684 647.936L105.014 647.661L100 637.632L94.9843 647.664L77.2262 647.846L61.1245 631.745L52.3208 629.984L74.9973 652.661L76.0538 652.721L123.946 652.721L124.963 652.7L147.679 629.984ZM122.95 654.713L107.747 669.916L103.522 665.69L114.499 654.713L122.95 654.713ZM111.681 654.713L102.113 664.282L100.996 663.165L100.996 654.713L111.681 654.713ZM99.004 654.713L99.004 663.165L97.8871 664.282L88.3186 654.713L99.004 654.713ZM85.5014 654.713L96.4785 665.69L92.2527 669.916L77.0498 654.713L85.5014 654.713ZM100.075 677.739L106.339 671.325L100 664.986L93.7366 671.4L100.075 677.739Z"
              fill={raceColorMap[race].dark}
            />
          </>
        )}
        <Circles>
          <circle
            cx="100"
            cy="345"
            r="91"
            fill="#131927"
            stroke={`url(#${id}_paint_stroke_circle1)`}
            strokeWidth="2"
          />
          <circle
            cx="100"
            cy="345"
            r="85"
            fill="#131927"
            stroke={`url(#${id}_paint_stroke_circle2)`}
            strokeWidth="2"
          />
        </Circles>
        <defs>
          <radialGradient
            id={`${id}_paint_fill_ribbon`}
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(98.2183 346) rotate(90) scale(320.5 144.868)"
          >
            <stop stopColor={raceColorMap[race].main} />
            <stop offset="0.763542" stopColor={raceColorMap[race].dark} />
            <stop offset="1" stopColor={raceColorMap[race].dark} stopOpacity="0" />
          </radialGradient>
          <linearGradient
            id={`${id}_paint_stroke_ribbon`}
            x1="98.2183"
            y1="0"
            x2="98.2183"
            y2="692"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={raceColorMap[race].main} />
            <stop offset="1" stopColor={raceColorMap[race].dark} />
          </linearGradient>
          <linearGradient
            id={`${id}_paint_stroke_circle1`}
            x1="98.0445"
            y1="255"
            x2="98.0445"
            y2="435"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={raceColorMap[race].main} />
            <stop offset="1" stopColor={raceColorMap[race].dark} />
          </linearGradient>
          <linearGradient
            id={`${id}_paint_stroke_circle2`}
            x1="98.1314"
            y1="259"
            x2="98.1314"
            y2="431"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor={raceColorMap[race].main} />
            <stop offset="1" stopColor={raceColorMap[race].dark} />
          </linearGradient>
        </defs>
      </Svg>
      <StyledRaceIconFrame race={race} />
      <Content>{children}</Content>
    </Container>
  )
}

const Ribbon = styled.path<{ id: string }>`
  transition: all 0.3s;
  fill: ${({ id }) => `url(#${id}_paint_fill_ribbon)`};
  fill-opacity: 0;
`

const Circles = styled.g`
  opacity: 0.5;
  transition: opacity 0.3s;
`

const StyledRaceIconFrame = styled(RaceIconFrame)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
`

const Container = styled.div<{ id: string }>`
  position: relative;

  &:hover {
    ${Ribbon} {
      fill-opacity: 0.5;
    }

    ${Circles} {
      opacity: 1;
    }

    #icon-frame-hover-path {
      fill: url(#paint_icon_frame_hover_${({ id }) => id});
    }
  }
`

const Svg = styled.svg<{ id: string; active: boolean }>`
  position: relative;

  ${({ active, id }) =>
    active
      ? `
    ${Ribbon} {
      fill-opacity: 0.5;
    }

    ${Circles} {
      opacity: 1;
    }

    ~ ${StyledRaceIconFrame} path#icon-frame-hover-path {
      fill: url(#paint_icon_frame_hover_${id});
    }
  `
      : ''}
`

const Content = styled.div`
  position: absolute;
  width: 100%;
  padding: 0 16px;
  top: calc(50% + 120px);
  text-align: center;
`
