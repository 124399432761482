import styled from '@emotion/styled'
import { raceIconMap } from 'apps/client-app/src/utils/character'
import type { FC } from 'react'
import type { Gender, Race } from '~generated/graphql'
import { Tabs, Tab } from '~components/Tabs'
import { t } from '~utils/i18n'

type Props = {
  gender: Gender
  race: Race
}

export const ProfileTabs: FC<Props> = ({ gender, race }) => {
  const raceTranslation = t('common:racesList')?.find((raceObj) => raceObj.race === race)

  return (
    <Tabs>
      <Tab id="profile" title={t('profile:title') || 'Title'}>
        <CharacterInfoTable>
          <CharacterInfoItem>
            <CharacterInfoItemTitle>{t('profile:raceLabel')}</CharacterInfoItemTitle>
            <CharacterInfoItemValue>
              {raceIconMap[race]} {raceTranslation?.[gender === 'MALE' ? 'nameMale' : 'nameFemale']}
            </CharacterInfoItemValue>
          </CharacterInfoItem>
          {/* <CharacterInfoItem>
            <CharacterInfoItemTitle>{t('profile:matchesPlayedLabel')}</CharacterInfoItemTitle>
            <CharacterInfoItemValue>1100</CharacterInfoItemValue>
          </CharacterInfoItem> */}
        </CharacterInfoTable>
        <Description>{raceTranslation?.keyPoints}</Description>
      </Tab>
    </Tabs>
  )
}

const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #fff;
`

const CharacterInfoTable = styled.div`
  display: flex;
  margin-bottom: 30px;
`

const CharacterInfoItem = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-right: 1px solid #2a3041;
  padding: 20px 24px 0;
  height: 88px;

  &:last-of-type {
    border-right: none;
  }

  &:first-of-type {
    padding-left: 0;
  }
`

const CharacterInfoItemTitle = styled.div`
  font-size: 16px;
  color: #646d85;
  margin-bottom: 8px;
`

const CharacterInfoItemValue = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  display: flex;
  align-items: center;

  svg {
    width: 26px;
    margin-right: 4px;
    position: relative;
    top: -2px;
  }
`
