import { Story } from 'inkjs'
import { BehaviorSubject, timer } from 'rxjs'
import type {
  StoryChoiceTags,
  StoryTagBackground,
  StoryTagHint,
  StoryTagListener,
  StoryTagSpeaker,
  StoryTextTags,
} from './StoryTags'
import { StoryTags } from './StoryTags'
import { QuestGameElements } from './QuestGameElements'
import { client } from '../../apolloClient'
import type {
  SaveQuestProgressMutation,
  SaveQuestProgressMutationVariables,
} from '../../generated/graphql'
import { SaveQuestProgressDocument } from '../../generated/graphql'
import { store } from '../../store'

export type QuestDialog = {
  text: string
  choices: { text: string; id: number }[]
  background?: StoryTagBackground
  showDialog: boolean
  showNext: boolean
  speaker?: StoryTagSpeaker
  listeners: StoryTagListener[]
  isAction?: boolean
  hint?: StoryTagHint
}

export class Quest {
  private story: InstanceType<typeof Story>
  private id: number
  public gameElements: QuestGameElements
  public dialogSubject: BehaviorSubject<QuestDialog>
  private isFulfilledShowWhenPosition: boolean = false

  constructor(id: number, story: string, state?: string) {
    this.id = id
    this.story = new Story(story)
    this.gameElements = new QuestGameElements()
    this.story.onDidContinue = () => {
      this.gameElements.removeAllElements()
    }
    this.loadProgress(state)
    this.dialogSubject = new BehaviorSubject(this.currentDialog())
  }

  private applyInkTags() {
    const questDialog = this.currentStoryStep()

    // Should be first as it changes screen
    const { openScreen } = questDialog.tags
    if (openScreen && store.getState().fullscreenId !== openScreen) {
      switch (openScreen) {
        case 'side':
        case 'race':
          timer(0).subscribe(() => store.getState().setFullscreenId(openScreen))
          break
        default:
          console.error(`unknown screen in quest ${openScreen}`)
      }
    }

    if (questDialog.tags.markers) {
      questDialog.tags.markers.forEach((target) => this.gameElements.withMarker(target))
    }

    if (questDialog.tags.targets) {
      questDialog.tags.targets.forEach((target) => this.gameElements.withTarget(target))
    }

    if (questDialog.choices) {
      questDialog.choices.forEach((choice) => {
        const { selectWhenPosition, selectWhenRace, selectWhenSide } = choice.tags
        if (selectWhenPosition) {
          this.gameElements.whenReachPosition(
            { position: selectWhenPosition, isChoice: true },
            () => {
              this.selectChoice(choice.id)
            },
          )
        }
        if (selectWhenSide) {
          this.gameElements.whenAttribute({ sideOneOf: selectWhenSide }, () => {
            this.selectChoice(choice.id)
          })
        }
        if (selectWhenRace) {
          this.gameElements.whenAttribute({ raceOneOf: selectWhenRace }, () => {
            this.selectChoice(choice.id)
          })
        }
      })
    }

    const { showWhenPosition, showWhenPositionRadius } = questDialog.tags

    if (showWhenPosition) {
      this.isFulfilledShowWhenPosition = false
      this.gameElements.whenReachPosition(
        {
          position: showWhenPosition,
          range: showWhenPositionRadius,
        },
        () => {
          this.isFulfilledShowWhenPosition = true
          this.dialogSubject.next(this.currentDialog())
        },
      )
    }

    const { goNextWhenPosition, goNextWhenPositionRadius, goNextWhenRace, goNextWhenSide } =
      questDialog.tags

    if (goNextWhenPosition) {
      this.gameElements.whenReachPosition(
        { position: goNextWhenPosition, range: goNextWhenPositionRadius },
        () => {
          const dialog = this.goToNextDialog()
          if (dialog) {
            this.dialogSubject.next(this.currentDialog())
          } else {
            alert('END')
          }
        },
      )
    }

    if (goNextWhenSide) {
      this.gameElements.whenAttribute({ sideOneOf: goNextWhenSide }, () => {
        const dialog = this.goToNextDialog()
        if (dialog) {
          this.dialogSubject.next(this.currentDialog())
        } else {
          alert('END')
        }
      })
    }
    if (goNextWhenRace) {
      this.gameElements.whenAttribute({ raceOneOf: goNextWhenRace }, () => {
        const dialog = this.goToNextDialog()
        if (dialog) {
          this.dialogSubject.next(this.currentDialog())
        } else {
          alert('END')
        }
      })
    }
  }

  public currentStoryStep() {
    const tags: StoryTextTags = new StoryTags(this.story.currentTags, this.story.variablesState)
    return {
      text: this.story.currentText ?? '',
      choices: this.story.currentChoices.map((choice) => ({
        tags: new StoryTags(
          choice.tags,
          this.story.variablesState,
        ) satisfies StoryChoiceTags as StoryChoiceTags,
        text: choice.text,
        id: choice.index,
      })),
      tags,
    }
  }

  public currentDialog(): QuestDialog {
    const currentStoryStep = this.currentStoryStep()
    return {
      text: currentStoryStep.text,
      choices: currentStoryStep.choices
        .filter((choice) => choice.tags.selectWhenPosition === undefined)
        .map((choice) => ({
          text: choice.text,
          id: choice.id,
        })),
      showDialog:
        currentStoryStep.tags.hideDialog !== true &&
        (currentStoryStep.tags.showWhenPosition === undefined || this.isFulfilledShowWhenPosition),
      showNext:
        currentStoryStep.tags.goNextWhenPosition === undefined &&
        currentStoryStep.choices.length === 0,
      background: currentStoryStep.tags.background,
      speaker: currentStoryStep.tags.speaker,
      listeners: currentStoryStep.tags.listeners,
      isAction: currentStoryStep.tags.isAction,
      hint: currentStoryStep.tags.hint,
    }
  }

  private continue() {
    this.story.Continue()

    const isEmptyTextAfterChoiceWithTag =
      this.story.currentText === '\n' && this.story.currentChoices.length === 0
    if (isEmptyTextAfterChoiceWithTag) {
      this.story.Continue()
    }

    this.applyInkTags()
    this.dialogSubject.next(this.currentDialog())
  }

  private finish() {
    this.gameElements.removeAllElements()
    this.dialogSubject.next({
      text: '',
      choices: [],
      showDialog: false,
      showNext: false,
      listeners: [],
    })
  }

  selectChoice(choiceId: number) {
    this.story.ChooseChoiceIndex(choiceId)
    this.continue()
    this.saveProgress()
  }

  private saveProgress(): void {
    client
      .mutate<SaveQuestProgressMutation, SaveQuestProgressMutationVariables>({
        mutation: SaveQuestProgressDocument,
        variables: {
          id: this.id,
          state: this.story.state.ToJson(),
        },
      })
      .then((result) => {
        if (result.data?.saveQuestProgress.status === 'COMPLETED') {
          this.finish()
        }
      })
      .catch((error) => console.error('Cannot save quest progress', error))
  }

  private loadProgress(state?: string): void {
    if (state) {
      try {
        this.story.state.LoadJson(state)
        if (
          String(this.story.variablesState.GetVariableWithName('version')) !==
          String(this.story.variablesState.GetVariableWithName('stateVersion'))
        ) {
          this.story.ResetState()
          this.story.Continue()
        }
      } catch (e) {
        this.story.Continue()
      }
    } else {
      this.story.Continue()
    }
    this.applyInkTags()
  }

  goToNextDialog(): QuestDialog | undefined {
    if (this.story.canContinue) {
      this.continue()
      this.saveProgress()
      return this.currentDialog()
    }
  }
}
