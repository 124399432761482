import type { FC } from 'react'
import styled from '@emotion/styled'
import { raceColorMap } from '../../../utils/character'
import type { Race } from '~shared-types'

type Props = {
  healthProgress: number
  shieldProgress: number
  sectorsCount: number
  race: Race
  id: string
}

export const HealthBarSvg: FC<Props> = ({
  healthProgress,
  sectorsCount,
  shieldProgress,
  race,
  id,
}) => {
  const color = raceColorMap[race]
  return (
    <Svg viewBox="0 0 118 118">
      <defs>
        <radialGradient
          id={`health-gradient-${id}`}
          cx="59"
          cy="59"
          r="55"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor={color.dark} />
          <stop offset="0.8" stopColor={color.dark} />
          <stop offset="1" stopColor={color.main} />
        </radialGradient>
        <linearGradient id={`sector-gradient-${id}`} x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stopColor={color.dark} />
          <stop offset="15%" stopColor={color.dark} />
          <stop offset="100%" stopColor="transparent" />
        </linearGradient>
        <radialGradient
          id={`shield-gradient-${id}`}
          cx="59"
          cy="59"
          r="55"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="85%" stopColor="#3C4860" />
          <stop offset="100%" stopColor="#A7AFC4" />
        </radialGradient>
      </defs>
      <circle
        cx="59"
        cy="59"
        r="50"
        stroke="#3C4860"
        strokeLinecap="round"
        strokeWidth={11}
        fill="none"
        strokeDasharray={314}
        strokeDashoffset={164}
        transform="rotate(-86, 59 59)"
      />
      <circle
        cx="59"
        cy="59"
        r="50"
        stroke="#131927"
        strokeLinecap="round"
        strokeWidth={9}
        fill="none"
        strokeDasharray={314}
        strokeDashoffset={164}
        transform="rotate(-86, 59 59)"
      />
      {shieldProgress ? (
        <circle
          cx="59"
          cy="59"
          r="50"
          fill="none"
          stroke={`url(#shield-gradient-${id})`}
          strokeWidth={11}
          strokeDasharray={314}
          strokeLinecap="round"
          strokeDashoffset={314 - 154 * shieldProgress}
          transform="rotate(-90, 59 59)"
          // strokeDashoffset={
          //   314 - 154 * (shieldProgress > healthProgress ? healthProgress : shieldProgress)
          // }
          // style={{
          //   transform: `rotate(${87 - (1 - healthProgress) * 177}deg) scale(1, -1)`,
          //   transformOrigin: 'center',
          // }}
        />
      ) : null}
      <circle
        cx="59"
        cy="59"
        r="50"
        fill="none"
        stroke={`url(#health-gradient-${id})`}
        strokeWidth={11}
        strokeDasharray={314}
        strokeLinecap="round"
        strokeDashoffset={314 - 154 * healthProgress}
        transform="rotate(-90, 59 59)"
      />
      {Array.from({ length: sectorsCount + 1 }, (_, index) => {
        const progress = healthProgress > shieldProgress ? healthProgress : shieldProgress
        const angle = 180 / sectorsCount
        const hidden = progress < index / sectorsCount || (progress === 1 && index === sectorsCount)
        const width = index % 3 === 0 ? 4 : 2
        const isInShieldArea = healthProgress < index / sectorsCount

        return (
          <rect
            x="59"
            y="3.5"
            width={width}
            height={11}
            fill={isInShieldArea ? '#3C4860' : `url(#sector-gradient-${id})`}
            transform={`rotate(${angle * index}, 59 59)`}
            key={index}
            opacity={hidden ? 0 : 1}
          />
        )
      })}
    </Svg>
  )
}

const Svg = styled.svg`
  width: 96px;
  height: 96px;
  position: absolute;
  right: -3px;
  transform: scale(1, -1);
`
