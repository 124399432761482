import styled from '@emotion/styled/macro'
import type { ReactElement } from 'react'
import React, { useState } from 'react'
import type { TabProps } from './Tab'

type Props = {
  children: ReactElement<TabProps> | ReactElement<TabProps>[]
  className?: string
}

export const Tabs: React.FC<Props> = ({ className, children }) => {
  const childrenArray = Array.isArray(children) ? children : [children]
  const [selectedTabId, setSelectedTabId] = useState(childrenArray[0].props.id || undefined)

  return (
    <Container className={className}>
      <Header>
        {childrenArray.map((item, index) => {
          const active = item.props.id === selectedTabId
          return (
            <HeaderTab
              key={item.props.id}
              active={active}
              isFirst={index === 0}
              onClick={() => {
                item.props?.onChange?.()
                setSelectedTabId(item.props.id)
              }}
            >
              {item.props.title}
            </HeaderTab>
          )
        })}
      </Header>
      <Content>{childrenArray.find((i) => i.props.id === selectedTabId) || null}</Content>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const Header = styled.div`
  position: relative;
  display: flex;
  overflow-x: auto;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 2px;
    background: linear-gradient(90deg, #d9d1bb 0%, #808080 50%, #131927 100%);
    width: 100%;
  }
`

const HeaderTab = styled.div<{ active: boolean; isFirst: boolean }>`
  display: flex;
  cursor: pointer;
  position: relative;
  font-size: 24px;
  font-weight: 600;
  color: ${({ active }) => (active ? '#ADA08A' : '#3C4860')};
  padding: 10px 15px;
  padding-left: ${({ isFirst }) => (isFirst ? '0' : '15px')};
  transition: color 0.3s;
  white-space: nowrap;
`

const Content = styled.div`
  flex: 1;
  padding: 40px 0 20px;
  font-size: 14px;
  color: #646d85;
`
