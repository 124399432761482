import { useForm } from 'react-hook-form'
import { useState } from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import { useSettingsUserQuery } from '~generated/graphql'
import { useStore } from '~store'
import { Button } from '~components/Button'
import { Row, Column, Title, StatusText } from './Layout'
import { Input } from './controls/Input'
import { ChangeEmailDialog } from './ChangeEmailDialog'
import { ConfirmEmailDialog } from './ConfirmEmailDialog'
import { t } from '~utils/i18n'

type AccountInputs = {
  login: string
  email: string
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

type Props = {
  values?: AccountInputs
}

export const AccountTab: FC<Props> = () => {
  const [showChangeEmailDialog, setShowChangeEmailDialog] = useState(false)
  const [showConfirmEmailDialog, setShowConfirmEmailDialog] = useState(false)
  const {
    register,
    setValue,
    handleSubmit: validateForm,
    formState: { errors },
  } = useForm<AccountInputs>()

  const [session] = useStore((state) => [state.session])

  const { data } = useSettingsUserQuery({
    skip: !session?.userId,
    variables: {
      userId: session?.userId as string,
    },
    onCompleted: (completedData) => {
      if (!completedData.user) return
      setValue('login', completedData.user.id)
      setValue('email', completedData.user.email)
    },
  })

  const handleSubmit = (result: AccountInputs) => {
    console.log(result)
  }

  return (
    <Row columns={2} as="form" onSubmit={validateForm(handleSubmit)}>
      {showChangeEmailDialog ? (
        <ChangeEmailDialog
          email={data?.user?.email || ''}
          onEmailChange={(email: string) => setValue('email', email)}
          onClose={() => setShowChangeEmailDialog(false)}
        />
      ) : showConfirmEmailDialog ? (
        <ConfirmEmailDialog
          email={data?.user?.email || ''}
          onClose={() => setShowConfirmEmailDialog(false)}
        />
      ) : null}
      <Column>
        <Title>{t('settings:account.userData')}</Title>
        <Input
          register={register}
          label={t('form:emailLabel')}
          name="email"
          type="email"
          autoComplete="off"
          error={errors.email?.message}
          onEdit={() => setShowChangeEmailDialog(true)}
        />
        {data?.user?.isEmailConfirmed ? (
          <Footer>
            <StatusText status="success">{t('settings:account.emailIsConfirmed')}</StatusText>
          </Footer>
        ) : (
          <Footer>
            <StatusText status="error">{t('settings:account.emailIsNotConfirmed')}</StatusText>
            <Button onClick={() => setShowConfirmEmailDialog(true)}>{t('button:confirm')}</Button>
          </Footer>
        )}
      </Column>
      {/* <Column>
        <Title>Изменение пароля</Title>
        <p>
          Для восстановления, а также изменения пароля необходимо{' '}
          <TextLink onClick={() => {}}>подтвердить адрес электронной почты</TextLink>
        </p>
        <Input
          register={register}
          label="Старый пароль"
          name="oldPassword"
          type="password"
          autoComplete="off"
          error={errors.oldPassword?.message}
        />
        <Input
          register={register}
          label="Новый пароль"
          name="newPassword"
          type="password"
          autoComplete="off"
          error={errors.newPassword?.message}
        />
        <Input
          register={register}
          label="Новый пароль ещё раз"
          name="confirmPassword"
          type="password"
          autoComplete="off"
          error={errors.confirmPassword?.message}
        />
        <Footer>
          <TextLink onClick={() => {}}>Забыли пароль?</TextLink>
          <Button onClick={() => {}}>Сохранить</Button>
        </Footer>
      </Column> */}
    </Row>
  )
}

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
`
