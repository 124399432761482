import { DateTime } from 'luxon'
import { memo, useEffect, useState } from 'react'
import type { FC } from 'react'
import { finalize, timer } from 'rxjs'
import { store } from '~store'
import { environment } from '../../../environments/environment'

const radius = 201
const progressUiOffset = 9.5
const loadTime = environment.production ? 30_000 : 3_000

type Props = {
  startAt?: string
  className?: string
}

export const LoadingProgress: FC<Props> = memo(({ startAt, className }) => {
  const [session, hideOpenMatchWaitDialog] = store((state) => [
    state.session,
    state.hideOpenMatchWaitDialog,
  ])

  const [progress, setProgress] = useState(100 - progressUiOffset)
  const countdown = startAt
    ? loadTime - (Date.now() - DateTime.fromISO(startAt).toMillis())
    : loadTime

  useEffect(() => {
    if (session?.matchAccess) {
      setProgress(0)
      const countdown$ = timer(countdown).pipe(finalize(() => hideOpenMatchWaitDialog()))

      const subscription = countdown$.subscribe()
      return () => subscription.unsubscribe()
    }
  }, [session?.matchAccess])

  return (
    <svg width="462" height="453" viewBox="0 0 462 453" fill="none" className={className}>
      <g filter="url(#filter0_ddii_2684_162683)">
        <circle
          strokeLinecap="round"
          strokeDasharray={calculateDasharray(radius)}
          strokeDashoffset={calculateDashoffset(progress, calculateDasharray(radius))}
          cx="231"
          cy="222"
          r={radius}
          stroke="url(#paint0_linear_2684_162683)"
          strokeWidth="6"
          transform="rotate(287 231 222)"
          style={{ transition: `stroke-dashoffset ${countdown}ms linear` }}
        />
      </g>
      <defs>
        <filter
          id="filter0_ddii_2684_162683"
          x="0.619835"
          y="0.871788"
          width="460.76"
          height="451.508"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="13.1901" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.880833 0 0 0 0 0.988915 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2684_162683" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="4.39669" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.952941 0 0 0 0 0.992157 0 0 0 0 0.984314 0 0 0 0.35 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_2684_162683"
            result="effect2_dropShadow_2684_162683"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_2684_162683"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.39669" />
          <feGaussianBlur stdDeviation="3.84711" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.827451 0 0 0 0 0.980392 0 0 0 0 1 0 0 0 0.5 0"
          />
          <feBlend mode="overlay" in2="shape" result="effect3_innerShadow_2684_162683" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.09917" />
          <feGaussianBlur stdDeviation="1.64876" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.75 0" />
          <feBlend
            mode="overlay"
            in2="effect3_innerShadow_2684_162683"
            result="effect4_innerShadow_2684_162683"
          />
        </filter>
        <linearGradient
          id="paint0_linear_2684_162683"
          x1="400"
          y1="150"
          x2="0"
          y2="250"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D3FAFF" />
          <stop offset="1" stopColor="#597E81" />
        </linearGradient>
      </defs>
    </svg>
  )
})

const calculateDasharray = (r: number) => Math.PI * r * 2
const calculateDashoffset = (progress: number, circumference: number) =>
  ((100 - progress) / 100) * circumference
