import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { useStore } from '~store'
import { t } from '~utils/i18n'
import withTooltip from '~components/Tooltip/withTooltip'

import BgHeaderPattern from '~assets/bg-header-pattern.png'
import BgHeaderPattern2x from '~assets/bg-header-pattern_2x.png'
import HeaderTitleArrow from '~assets/header-title-arrow.svg'
import MenuItemHoverBg from '~assets/menu-item-hover-bg.svg'
import { ReactComponent as Logo } from '~assets/logo.svg'
import { ReactComponent as IconMenuAccount } from '~assets/icon-menu-account.svg'
import { ReactComponent as IconMenuMap } from '~assets/icon-menu-map.svg'

export type HeaderMenuId = 'profile' | 'cityMap'

type HeaderMenuItem = {
  id: HeaderMenuId
  icon: JSX.Element
}

const items: HeaderMenuItem[] = [
  {
    id: 'profile',
    icon: <IconMenuAccount />,
  },
  {
    id: 'cityMap',
    icon: <IconMenuMap />,
  },
]

export const Header = () => {
  const [headerTitle, fullscreenId, setFullscreenId, session] = useStore((state) => [
    state.headerTitle,
    state.fullscreenId,
    state.setFullscreenId,
    state.session,
  ])

  return (
    <Container>
      <LeftPanel>
        <LogoContainer>
          <Link to="/">
            <Logo />
          </Link>
        </LogoContainer>
        <MainMenu>
          {items.map(({ id, icon }) => {
            const disabled = !!session?.matchAccess
            const tooltipContent = t(`tooltip:mainMenu.${id}`)
            const MenuItemWithTooltip =
              tooltipContent && !disabled
                ? withTooltip(MenuItem, { type: 'text', content: tooltipContent })
                : MenuItem
            return (
              <MenuItemWithTooltip
                key={id}
                id={`header-menu-item-${id}`}
                active={fullscreenId === id}
                disabled={disabled}
                onClick={disabled ? undefined : () => setFullscreenId(id)}
              >
                {icon}
              </MenuItemWithTooltip>
            )
          })}
        </MainMenu>
      </LeftPanel>
      {headerTitle ? <Title>{headerTitle}</Title> : null}
      <RightPanel>
        <CurrencyContainer />
      </RightPanel>
    </Container>
  )
}

const Container = styled.header`
  background: #131927 image-set(url('${BgHeaderPattern}') 1x, url('${BgHeaderPattern2x}') 2x)
    no-repeat center;
  box-shadow: 0px 12px 48px 0px #080d13;
  background-size: cover;
  position: relative;
  z-index: 18;
  display: grid;
  align-items: center;
  grid-template-columns: 400px 1fr 400px;

  &:before {
    content: '';
    background: url(${HeaderTitleArrow}) no-repeat center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100% - 12px);
    width: 100%;
    height: 24px;
    z-index: 2;
    pointer-events: none;
  }

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    top: calc(100% - 12px);
    width: 17px;
    height: 17px;
    background: #241f28;
    pointer-events: none;
  }
`

const LogoContainer = styled.div`
  margin-top: 4px;
  margin-right: 36px;
`

const MainMenu = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`

const MenuItem = styled.div<{ active?: boolean; disabled?: boolean }>`
  display: grid;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 100%;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  place-items: center;

  > svg {
    fill: ${({ disabled }) => (disabled ? '#3C4860' : 'url(#menu-item-icon-gradient)')};
  }

  ${({ disabled }) =>
    !disabled &&
    `
  &:hover {
    background: url(${MenuItemHoverBg}) no-repeat center;

    > svg {
      fill: url(#menu-item-icon-gradient-hover);
    }
}`}

  ${(props) =>
    props.active &&
    `
    background: url(${MenuItemHoverBg}) no-repeat center;
        > svg {
          fill: url(#menu-item-icon-gradient-hover);
        }
    `}
`

const Title = styled.div`
  color: #fff;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  position: relative;
  z-index: 2;
  white-space: nowrap;
`

const CurrencyContainer = styled.div``

const LeftPanel = styled.div`
  padding-left: 56px;
  display: flex;
  height: 100%;
  align-items: center;
`

const RightPanel = styled.div`
  display: flex;
`
