import type { Side, ZoneOpenMatchFragment } from '~generated/graphql'
import type { Subject } from 'rxjs'
import type { UUID } from '~shared-types'
import type { LayerManager } from '../../match/model/LayerManager'
import type { MatchParticipant } from './OpenMatch'
import { OpenMatch } from './OpenMatch'

export type OpenMatchCharacterSubject = Subject<
  MatchParticipant & {
    status: 'joinedMatch' | 'leftMatch'
  }
>

export class OpenMatchManager {
  private openMatches: OpenMatch[]

  constructor(
    private layerManager: LayerManager,
    private miniMapLayerManager: LayerManager,
    private characterSubject: OpenMatchCharacterSubject,
  ) {
    this.openMatches = []
  }

  public isInMatch(characterId: UUID): boolean {
    return this.openMatches.some((openMatch) => openMatch.isInMatch(characterId))
  }

  public setOpenMatches(openMatches: ZoneOpenMatchFragment[]) {
    this.openMatches = openMatches.map(
      (openMatch) =>
        new OpenMatch(
          this.layerManager,
          this.miniMapLayerManager,
          this.characterSubject,
          openMatch,
        ),
    )
  }

  public updateMatch(openMatch: ZoneOpenMatchFragment) {
    const existingMatch = this.openMatches.find((match) => match.id === openMatch.id)
    if (!existingMatch) {
      this.openMatches.push(
        new OpenMatch(
          this.layerManager,
          this.miniMapLayerManager,
          this.characterSubject,
          openMatch,
        ),
      )
      return
    }
    existingMatch.updateData(openMatch)
    if (existingMatch.status === 'Ended') {
      this.openMatches = this.openMatches.filter((math) => math.id !== existingMatch.id)
    }
  }
}
