import type { TOptionsBase } from 'i18next'
import i18next from 'i18next'
import type { DetectorOptions } from 'i18next-browser-languagedetector'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import resourcesToBackend from 'i18next-resources-to-backend'
import type { Translations } from '~locales'
import { languages, namespaces } from '~locales'

const detectionOptions: DetectorOptions = {
  order: ['cookie', 'localStorage', 'navigator'],
  lookupCookie: 'lang',
}

const namespacesExcludeKeys = ['landing', 'news']
const clientNamespaces = namespaces.filter((ns) => !namespacesExcludeKeys.includes(ns))

const fallbackLng = 'en'

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend(
      (language: string, namespace: string) =>
        import(`../../../../libs/cms/locales/${language}/${namespace}.json`),
    ),
  )
  .init({
    ns: clientNamespaces,
    debug: true,
    detection: detectionOptions,
    fallbackLng,
    supportedLngs: languages,
    returnObjects: true,
    parseMissingKeyHandler: () => undefined,
    interpolation: {
      format(value: string, format?: string) {
        if (format === 'lowercase') {
          return value.toLowerCase()
        }
        return value
      },
    },
    missingKeyHandler: (missingLocales, namespace, key) =>
      console.warn(
        `Missing translation for ns: "${namespace}", key: "${key}", locales: "${missingLocales.join()}"`,
      ),
  })
  .catch((e) => console.log(e))

export const t = <T extends keyof Translations>(
  key: T,
  options?: Parameters<typeof i18next.t>[1],
): Translations[T] | undefined => {
  const notFallbackResult = i18next.t(key, {
    ...(options as TOptionsBase),
    fallbackLng: [],
  })

  if (notFallbackResult) {
    return notFallbackResult as Translations[T]
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return i18next.t(key, options as any) as Translations[T]
}
