import { memo, useEffect, useRef } from 'react'
import type { ZoneMessagesQuery, ZoneMessagesQueryVariables } from '~generated/graphql'
import {
  SortOrder,
  useSendPrivateMessageMutation,
  useSendZoneMessageMutation,
  useZoneMessagesQuery,
} from '~generated/graphql'
import { useStore, useChatStore } from '~store'
import type { InfiniteListRef } from '~components/Chat/InfiniteList'
import { parseGeneralMessage } from '~utils/messages'
import { useZoneChatSubscription } from './useZoneChatSubscription'
import { ChatUI } from '~components/Chat/ChatUI'

export const ZoneChat = memo(() => {
  const [session, charactersMap] = useStore((state) => [state.session, state.characters])
  const [messages, setGeneralMessages, addGeneralMessages, addGeneralMessage] = useChatStore(
    (state) => [
      state.messages,
      state.setGeneralMessages,
      state.addGeneralMessages,
      state.addGeneralMessage,
    ],
  )
  const listRef = useRef<InfiniteListRef>(null)
  const shouldFetchMore = useRef(true)
  const [sendZoneMessage] = useSendZoneMessageMutation()
  const [sendPrivateMessage, { data: sendPrivateMessageData }] = useSendPrivateMessageMutation({
    onCompleted: (data) => {
      if (!data.sendPrivateMessage) return
      addGeneralMessage(data.sendPrivateMessage)
    },
  })
  const { data, fetchMore } = useZoneMessagesQuery({
    skip: !session?.character?.zoneId,
    variables: {
      zoneId: session?.character?.zoneId || 0,
      pagination: {
        cursor: '',
        first: 10,
        sortOrder: 'ASC',
      },
    },
    onCompleted: (d) => {
      if (!d.zoneMessages.edges) return
      setGeneralMessages(d.zoneMessages.edges.map((e) => e.node))
      shouldFetchMore.current = d.zoneMessages.pageInfo.hasNextPage
    },
  })

  const handleFetchMore = async () => {
    if (!shouldFetchMore.current) return
    const lastZoneMessage = messages.find((message) =>
      message.chatRoomId.endsWith(`:${session?.character?.zoneId}`),
    )
    const { data: fetchMoreData } = await fetchMore<ZoneMessagesQuery, ZoneMessagesQueryVariables>({
      variables: {
        pagination: {
          cursor: lastZoneMessage?.id,
          first: 10,
        },
      },
    })
    shouldFetchMore.current = fetchMoreData.zoneMessages.pageInfo.hasNextPage
    addGeneralMessages(fetchMoreData.zoneMessages.edges.map((e) => e.node))
  }

  useZoneChatSubscription({
    scrollToBottom: listRef.current?.scrollToBottom,
  })

  useEffect(() => {
    listRef.current?.scrollToBottom()
  }, [sendPrivateMessageData])

  useEffect(() => {
    if (data) {
      // list component is not mounted yet, so we need to wait for it to mount
      setTimeout(() => {
        listRef.current?.scrollToBottom()
      }, 0)
    }
  }, [data])

  const handleSendMessage = async (message: string) => {
    const characters = Array.from(charactersMap, ([id, { firstName, lastName }]) => ({
      id,
      firstName,
      lastName,
    }))
    const parsedMessage = parseGeneralMessage(message, characters)
    if (!parsedMessage) {
      return
    }

    const isPrivate = parsedMessage.recipientId !== undefined
    if (isPrivate) {
      await sendPrivateMessage({
        variables: {
          characterId: parsedMessage.recipientId,
          message: parsedMessage.message,
        },
      })
      return
    }

    if (session?.character?.zoneId === undefined || session?.character?.zoneId === null) {
      return
    }

    await sendZoneMessage({
      variables: {
        zoneId: session?.character?.zoneId,
        message: parsedMessage.message,
      },
    })
  }

  return (
    <ChatUI
      listRef={listRef}
      messages={messages}
      charactersMap={charactersMap}
      onSendMessage={handleSendMessage}
      onFetchMore={handleFetchMore}
    />
  )
})
